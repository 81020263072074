import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';
import Method from './services';

/**
 * role id's of type of users in backend.
 */
const userRoleIds = {
  adminRoleId: 1,
  accountHolderRoleId: 2,
  operatorRoleId: 3
};

/**
 * service to create a user.
 * @param {*} values
 */
function createUserSubmission (values) {
  const params = values;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(API_INTERFACE.CREATE_USER, header, params);
}

/**
 * service to fetch a user by id.
 * @param {*} id
 * @param {*} cancelToken
 */
function fetchUserById (id, cancelToken) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.fetchDataByIdAsParam(
    API_INTERFACE.GET_USER + '/' + id,
    headers,
    cancelToken
  );
}

/**
 * service to update properties of a user
 * @param {*} values
 */
function updateUserSubmission (values) {
  const params = values;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.updateDataById(API_INTERFACE.UPDATE_USER_V2, header, params);
}

/**
 * service to get roles of users accross OEM's from backend.
 */
function getOemRoles () {
  const allRoles = AppStorageHelper.getUserTypesFromSessionStorage();
  if (allRoles) {
    return Promise.resolve(allRoles);
  } else {
    const url = API_INTERFACE.GET_ROLES;
    const header = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': API_INTERFACE.X_API_KEY,
      Authorization: AuthUtils.getAccessToken()
    };
    return baseService.get(url, header).then(function (response) {
      AppStorageHelper.setUserTypesToSessionStorage(
        response.data.items
      );
      return response.data.items;
    }, function (error) {
      return Promise.reject(error);
    });
  }
}

/**
 * service to get a user details with id as passed userId.
 * @param {*} userId
 */
function getUser (userId) {
  const url = API_INTERFACE.GET_USER + '/' + userId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to fetch all registered users from backend.
 * @param {*} queryParams
 */
function getUsers (queryParams) {
  const url = API_INTERFACE.GET_USERS + '?' + queryParams;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * deletes a specific user.
 * @param {*} userId
 */
function deleteUser (userId) {
  const url = API_INTERFACE.GET_USER + '/' + userId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * deletes a specific user.
 * @param {*} userId
 */
function reactivateUser (userId) {
  const url = API_INTERFACE.GET_USER + '/' + userId + '/reactivate';
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(url, header);
}

/**
 * resend confirmation email a specific user.
 * @param {*} userId
 */
 function resendConfirmationEmail (emailId) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID,
    Authorization: AuthUtils.getAccessToken()
  };
  const reqBody = {
    'email': emailId
  }
  return baseService.put(API_INTERFACE.RESEND_CONFIRMATION_EMAIL, header, reqBody);
}

/**
 * service to get all account holders under whom the operator with passed operator id is working.
 * @param {*} queryParams
 * @param {*} forid
 */
function getAccountHoldersByOperatorId (queryParams, forid) {
  const url = API_INTERFACE.ASSIGNMENTS + forid + '/assignments?' + queryParams;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * change password for a user.
 * @param {*} currentPassword
 * @param {*} newPassword
 */
function changePassword (currentPassword, newPassword) {
  const reqBody = {
    oldPassword: currentPassword,
    newPassword: newPassword
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.CHANGE_PASSWORD, header, reqBody);
}


/**
 * change password for a user by admin.
 * @param {*} emailId
 * @param {*} newPassword
 */
function changeUserPassword (emailId, newPassword) {
  const reqBody = {
    email: emailId,
    password: newPassword
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.ADMIN_CHANGE_PASSWORD, header, reqBody);
}

/**
 * service to request a assignment for one user to other.
 * Account holder initiate a assignment for a operator.
 * Operator initiate asssignment request to a account holder.
 * @param {*} values
 * @param {*} assigner
 */
function assignUsers (values, assigner) {
  let params = {};
  if (values.roleId === userRoleIds.accountHolderRoleId) {
    params = {
      operatorEmailId: assigner.assignerEmail,
      accountEmailId: values.email
    };
  } else {
    params = {
      operatorEmailId: values.email,
      accountEmailId: assigner.assignerEmail
    };
  }
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  let endpoint = '';
  if (assigner.accountId && assigner.accountId !== '') {
    endpoint = API_INTERFACE.ASSIGN_USER + assigner.accountId + '/adminassign';
  } else {
    endpoint = API_INTERFACE.ASSIGN_USER + assigner.operatorId + '/adminassign';
  }
  return baseService.mapUsersForAssignment(endpoint, header, params);
}

/**
 * service to revoke an assignment request.
 * @param {*} userId
 * @param {*} forUser
 */
function deleteAssignment (userId, forUser) {
  const url = API_INTERFACE.DELETE_ASSIGNMENT + forUser + '/adminassign/' + userId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * service to force change an initial password.
 * @param {*} emailId
 * @param {*} tempPwd
 * @param {*} newPwd
 */
function forceChangePassword (emailId, tempPwd, newPwd) {
  const reqBody = {
    email: emailId,
    password: tempPwd,
    newPassword: newPwd
  };

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    oemid: API_INTERFACE.OEM_ID
  };
  return baseService.put(API_INTERFACE.FORCE_CHANGE_PASSWORD, header, reqBody);
}

/**
 * service to get the code generated for an email change request.
 * @param {*} newEmail
 */
function getEmailChangeConfirmationCode (newEmail) {
  const reqBody = {
    email: newEmail
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.CHANGE_EMAIL, header, reqBody);
}

/**
 * service to confirm email change request.
 * @param {*} newEmail
 * @param {*} confirmationCode
 */
function confirmChangeEmail (newEmail, confirmationCode) {
  const reqBody = {
    email: newEmail,
    confirmationCode: confirmationCode
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.CONFIRM_CHANGE_EMAIL, header, reqBody);
}

///new 
function fetchUsersByProfile (values) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.GET_USERS_BY_PROFILE + values,
    header
  );
}

export const UserService = {
  createUserSubmission,
  fetchUserById,
  updateUserSubmission,
  getOemRoles,
  getUser,
  getUsers,
  deleteUser,
  getAccountHoldersByOperatorId,
  changePassword,
  assignUsers,
  deleteAssignment,
  forceChangePassword,
  getEmailChangeConfirmationCode,
  confirmChangeEmail,
  changeUserPassword,
  reactivateUser,
  resendConfirmationEmail,
  fetchUsersByProfile
};
