export let SCREEN_ID = {
    ListUsers: "LIST_USER_SCREEN",
    ListFirmwares: "LIST_FIRMWARE_SCREEN",
    ListVehicle: "LIST_VEHICLE_SCREEN",
    ListDevices: "LIST_DEVICE_SCREEN",
    ListSubcriptions: "LIST_SUBSCRIPTION_SCREEN",
    ListCompatibility: "LIST_COMPATIBILITY_SCREEN",
    ListKnowledge: "LIST_KNOWLEDGE_SCREEN",
    ListSupport: "LIST_SUPPORT_SCREEN",
    ListFirmwaresTypes: "LIST_FIRMWARE_TYPE_SCREEN",
}

const SCREEN_INDEX = {
    LIST_USER_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_FIRMWARE_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_VEHICLE_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_DEVICE_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_SUBSCRIPTION_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_COMPATIBILITY_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_KNOWLEDGE_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_SUPPORT_SCREEN: {
        LIST_INDEX: ""
    },
    LIST_FIRMWARE_TYPE_SCREEN: {
        LIST_INDEX: ""
    }
};

export const getListIndex = (screenId) => {
    return SCREEN_INDEX[screenId].LIST_INDEX || 1
}

export const setListIndex = (screenId, value) => {
    SCREEN_INDEX[screenId] = { "LIST_INDEX": value }
}