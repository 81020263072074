import React from 'react';
import i18n from 'i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../../Component/Header';
import { LeftMenu } from '../../Component/LeftMenu';
import BaseScreen from './BaseScreen';
import { SCREENS } from '../../constants/screens.constant';
import axios from 'axios';
import Home from './Home';
import Users from './user/Users';
import Vehicle from './vehicle/Vehicle';
import Firmwares from './firmware/Firmwares';
import Device from './device/Device';
import Compatibility from './compatibility/Compatibility';
import Knowledge from './knowledge/Knowledge';
import Support from './support/Support';
import AppInfoHelper from '../../shared/utils/appInfoHelper';
import { AppStorageHelper } from '../../shared/utils/appStorageHelper';
import LoadingOverlay from '../../Component/loader/LoadingOverlay';
import { ROLE_TYPES, APP_BUILD_VERSION } from '../../constants/app.constants';

const routes = [
  { path: '/home', exact: true, component: Home }
];

/**
 * Default layout component.
 */
class DefaultLayout extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      isAuthorizedForAdminPortal: false,
      isLoading: true,
      isErrorOnLoad: false,
      isErrorOnMenuItemsLoad: false,
      onLoadErrorMsg: ''
    };

    this.navItems = [];
  }

  /**
   * during the mount this function get user information, load menu items to render as left menu.
   */
  componentDidMount () {
    var that = this;

    /**
     * get userInfo
     */
    if (this.state.isAuthenticated) {
      const userInfoInstance = AppInfoHelper.getUserInstance();
      userInfoInstance.getUserInfo().then(function (response) {
        const loggenInUserInfo = response;

        if (loggenInUserInfo.roleId === ROLE_TYPES.OEM_ADMIN.ID) {
          that.setState({
            isAuthorizedForAdminPortal: true
          });

          /**
           * loading menuitems from http
           */
          axios.get('./config/menuItems.json?preventCaching=' + APP_BUILD_VERSION)
            .then(function (response) {
              that.navItems = response.data;
              that.prepareRoutes();
            }, function (error) {
              const errorMsg = i18n.t('defaultView.messages.menuItemsLoadError');
              that.setState(
                {
                  isLoading: false,
                  isErrorOnMenuItemsLoad: true,
                  onLoadErrorMsg: errorMsg
                });
            });
        } else {
          that.setState({
            isAuthorizedForAdminPortal: false,
            isLoading: false
          });
        }
      }, function (error) {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        that.setState(
          {
            isLoading: false,
            isErrorOnLoad: true,
            onLoadErrorMsg: errorMsg
          });
      });
    }
  }

  /**
   * prepare routing information and land to respective main component of module.
   * each subsequest sub component routing takes place from the main component of respective module.
   */
  prepareRoutes () {
    this.navItems.map((item, idx) => {
      if (item.key === 'users') {
        routes.push({ path: '/user', component: Users });
      } else if (item.key === 'firmware') {
        routes.push({ path: '/firmware', component: Firmwares });
      } else if (item.key === 'vehicle') {
        routes.push({ path: '/vehicle', component: Vehicle });
      } else if (item.key === 'device') {
        routes.push({ path: '/device', component: Device });
      } else if (item.key === 'compatibility') {
        routes.push({ path: '/compatibility', component: Compatibility });
      } else if (item.key === 'knowledge') {
        routes.push({ path: '/knowledge', component: Knowledge });
      } else if (item.key === 'support') {
        routes.push({ path: '/support', component: Support });
      }
      return true;
    });

    this.setState({ isLoading: false });
  }

  /**
   * render left menu items
   */
  renderLayout () {
    return (
      <div className="outer">
        <Header routerData={this.props.routerData} leftMenuItems={this.navItems} />
        <div className="main-container">
          <LeftMenu leftMenuItems={this.navItems} routerData={this.props.routerData} />

          <div className="right-column dashboard-bg">
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component routerData={this.props.routerData}/>
                    )} />
                ) : (null);
              })}
              <Redirect from="/" to="/home" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  /**
   * render loader
   */
  renderLoading () {
    return (
      <LoadingOverlay active={this.state.isLoading} customClass="default_layout_loading_overlay">
      </LoadingOverlay>
    );
  }

  /**
   * render error occured during the operation.
   */
  renderError () {
    return (
      <div className="align-center">
        <p>{this.state.onLoadErrorMsg}</p>
        <p>{i18n.t('defaultView.messages.noUserInfo')}</p>
        <button type="button" className="btn btn-primary"
          onClick={this.goToLogin.bind(this)}>
          {i18n.t('defaultView.button.goToLogin')}
        </button>
      </div>
    );
  }

  /**
   * shows unauthorization error if user is not authorized for any operation.
   */
  renderUnauthorisedMessage () {
    return (
      <div className="align-center">
        <p data-testid="user-not-authorized-msg">
          {i18n.t('defaultView.messages.notAuthorized')}
        </p>
        <button type="button" className="btn btn-primary"
          onClick={this.goToLogin.bind(this)}>
          {i18n.t('defaultView.button.goToLogin')}
        </button>
      </div>
    );
  }

  /**
   * shows error occured during menu item load.
   */
  renderErrorOnMenuItemsLoad () {
    return (
      <div className="align-center">
        <p>{this.state.onLoadErrorMsg}</p>
        <button type="button" className="btn btn-primary"
          onClick={this.goToLogin.bind(this)}>
          {i18n.t('defaultView.button.goToLogin')}
        </button>
      </div>
    );
  }

  /**
   * go to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * react render function
   */
  render () {
    if (this.state.isLoading) {
      return this.renderLoading();
    } else {
      if (this.state.isErrorOnLoad) {
        return this.renderError();
      } else if (this.state.isErrorOnMenuItemsLoad) {
        return this.renderErrorOnMenuItemsLoad();
      } else if (this.state.isAuthorizedForAdminPortal) {
        return this.renderLayout();
      } else {
        return this.renderUnauthorisedMessage();
      }
    }
  }
}

export default DefaultLayout;
