import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { DocumentsService } from '../../../services/service.documents';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { FormErrors } from '../../../Component/SubmissionStatus';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { StyledText } from '../../../Component/StyledText';

/**
 * Edit document component. Encapsulate all edit functionalities.
 */
class EditDocument extends BaseScreen {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        let isAuthenticated = true;

        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }

        this.state = {
            isAuthenticated: isAuthenticated,
            formfields: {
                id: '',
                fileName: '',
                description: '',
                fileSize: '',
                location: ''
            },
            formErrors: {
                description: '',
            },
            formValid: false,
            alert: {
                type: null,
                message: null
            },
            isLoading: true,
            isErrorOnLoad: false,
            savedFormFields: {},
        }
    }

    /**
     * React life cycle method, called after component is first rendered.
     */
    componentDidMount() {
        if (this.state.isAuthenticated) {
            this.displayDocumentInfo();
        }
    }

    /**
     * fetch specific document data from backend to display in edit form
     */
    displayDocumentInfo() {
        let documentId = this.props.match.params.documentId;

        DocumentsService.getDocument(documentId).then(res => {
            this.setState({
                formfields: res.data,
                savedFormFields: { ...res.data },
                isLoading: false
            });
        }, err => {
            let errorMsg = i18n.t('common.genericApiError');
            if (_.has(err, 'data.message')) {
                errorMsg = err.data.message;
            }
            this.setState({
                isLoading: false,
                isErrorOnLoad: true,
                alert: {
                    type: 'danger',
                    message: errorMsg
                },
            });
        });
    }

    /**
     * handle uer input for validation purpose
     * @param {*} event 
     */
    handleUserInput = (event) => {
        event.preventDefault();

        const name = event.target.name;
        const value = event.target.value;

        let formfields = this.state.formfields;
        formfields[name] = value;

        let fieldValidationErrors = this.state.formErrors;
        let msg = '';

        switch (name) {
            case 'description':
                if (value.length === 0) {
                    msg = i18n.t('documentViews.alert.required');
                } else if (value.length > 256) {
                    msg = i18n.t('documentViews.alert.descriptionLength');
                }
                break;

            default:
                break;
        }

        fieldValidationErrors[name] = msg;

        let isFormValid = formfields.description && fieldValidationErrors.description === '';
        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetFormFields = () => {
        let prevSavedFormFields = this.state.savedFormFields;
        this.setState({
            formfields: { ...prevSavedFormFields },
            formErrors: {
                description: ''
            },
        });
    }

    /**
     * Navigate to previous view.
     */
    handleBack = () => {
        this.props.history.goBack();
    }

    /**
     * handles form submit
     * @param {*} event 
     */
    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        DocumentsService.updateDocument(this.state.formfields).then(res => {
            let lastSavedFormFields = this.state.formfields;
            this.setState({
                isLoading: false,
                savedFormFields: { ...lastSavedFormFields },
                alert: {
                    type: 'success',
                    message: i18n.t('documentViews.alert.documentUpdated')
                }
            });
        }, err => {
            let alertMsg = i18n.t('common.genericApiError');

            if (_.has(err, 'data.message')) {
                alertMsg = err.data.message;
            }

            this.setState({
                alert: {
                    type: 'danger',
                    message: alertMsg
                },
                isLoading: false
            });
        });
    }

    /**
     * helper method to compare objects
     * @param {*} obj1 
     * @param {*} obj2 
     */
    isEqual(obj1, obj2) {
        let result = true;
        _.forOwn(obj1, function (value, key) {
            if (_.toString(value) !== _.toString(obj2[key])) {
                result = false;
            }
        });

        return result;
    }

    /**
     * validate if form is valid
     */
    isFormValid() {
        let result = this.isEqual(this.state.formfields, this.state.savedFormFields);
        let returnVal = (!result && this.state.formValid);
        return returnVal;
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * render edit document form ui
     */
    renderEditDocument = () => {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <div className='p-4'>
                    <h2> {i18n.t('documentViews.form.editDocument')} </h2>
                    <div className='view-profile-details'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <div className='form-group'>
                                    <label htmlFor='description'>{i18n.t('documentViews.form.label.description')} <StyledText uiText='*' /></label>
                                    <textarea className='form-control' name='description'
                                        value={this.state.formfields.description}
                                        placeholder={i18n.t('documentViews.form.placeholder.description')}
                                        onChange={this.handleUserInput}
                                        cols={50}
                                        rows={4}
                                        maxLength='256' />
                                    <FormErrors formErrors={this.state.formErrors.description} />
                                </div>
                            </div>
                        </div>
                        <div className='hr-line-dashed'></div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <label className='emphasized-label'>
                                    {i18n.t('documentViews.form.label.fileName')}
                                </label>
                                <p>{this.state.formfields.fileName}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <label className='emphasized-label'>
                                    {i18n.t('documentViews.form.label.fileSize')}
                                </label>
                                <p>{this.state.formfields.fileSize + ' ' + i18n.t('documentViews.form.bytes')}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <a className={'btn btn-primary' + (this.state.formfields.location ? '' : ' disabled')}
                                    href={this.state.formfields.location}>
                                    {i18n.t('documentViews.form.button.download')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            <button type='button' className='btn btn-secondary mr-2'
                                onClick={this.resetFormFields}>
                                {i18n.t('documentViews.form.button.reset')}
                            </button>
                            <button type='button' className='btn btn-secondary mr-2'
                                onClick={this.handleBack}>
                                {i18n.t('documentViews.form.button.back')}
                            </button>
                            <button type='submit' className='btn btn-primary'
                                disabled={!this.isFormValid()}
                                onClick={this.submitHandler}>
                                {i18n.t('documentViews.form.button.save')}
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * function to render errors
     */
    renderError() {
        return (
            <div className='col-md-12 text-right f-btn'>
                <button className='btn btn-secondary mr-2' onClick={this.handleBack}>
                    {i18n.t('documentViews.form.button.back')}
                </button>
            </div>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('documentViews.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'knowledge', displayName: i18n.t('documentViews.breadcrumb.knowledge'), className: '', link: false },
            { id: 'edit', displayName: i18n.t('documentViews.breadcrumb.edit'), className: '', link: false }
        ];
        return (
            <div className='container-fluid p-4'>
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className='clearfix'></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderEditDocument()
                }
            </div>
        );
    }
}

export default EditDocument;
