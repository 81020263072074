import React from 'react';
import { SubmissionAlert } from '../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../Component/loader/Loader";
import CustomFileInput from '../../../Component/customFileInput/CustomFileInput';
import { FirmwareService } from '../../../services/service.firmwares';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

/**
 * view firmware component
 */
class ViewFirmware extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            version: '',
            description: '',
            deviceType: '',
            fname: '',
            location: '',
            isErrorOnLoad: false,
            showMessage: false,
            apiResponseIsSuccess: false,
            apiResponseMessage: ''
        }
    }

    componentDidMount() {
        let firmwareId = this.props.match.params.firmwareId;
        FirmwareService.fetchFirmwareByIdSubmission(firmwareId).then(
            (response) => {
                let firmware = response.data;
                this.setState({
                    isLoading: false,
                    version: firmware.version,
                    description: firmware.description,
                    deviceType: firmware.deviceType,
                    fname: firmware.fileName,
                    location: firmware.location,
                    apiResponseIsSuccess: true,
                    showMessage: false
                });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    isErrorOnLoad: true,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }

    /**
     * handles alert dismiss
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}> {i18n.t('firmwareListCreateViewAndEdit.form.button.back')} </button>
            </div>
        );
    }

    /**
     * render view firmware form
     */
    renderViewFirmwareForm() {
        const { history } = this.props;
        return (
            <div>
                <h2>{i18n.t('firmwareListCreateViewAndEdit.form.viewFirmware')}</h2>
                <div className="view-profile-details">

                <div className="row">
                    <div className="col-md-12">
                        <label className="emphasized-label">{i18n.t('firmwareListCreateViewAndEdit.form.label.deviceType')}</label>
                        <p>{this.state.deviceType}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="emphasized-label">{i18n.t('firmwareListCreateViewAndEdit.form.label.version')}</label>
                        <p>{this.state.version}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="emphasized-label">{i18n.t('firmwareListCreateViewAndEdit.form.label.description')}</label>
                        <p>{this.state.description}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CustomFileInput id="firmware" value={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.download')} action="download" predefinedName={this.state.fname} predefinedValue={this.state.location} />
                    </div>
                </div>
                </div>
                <div className="col-md-12 text-right f-btn">
                    <div>
                        <button type="button" className="btn btn-secondary mr-2" onClick={history.goBack} > {i18n.t('firmwareListCreateViewAndEdit.form.button.back')} </button>
                        <Link to={{ pathname: '/firmware/edit-firmware/' + this.props.match.params.firmwareId }}
                            className="btn btn-primary mr-2">{i18n.t('firmwareListCreateViewAndEdit.form.button.edit')}</Link>
                    </div>

                </div>
            </div>
        )
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'firmware', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmware'), className: '', link: false },
            { id: 'detail', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.detail'), className: '', link: false }
        ];

        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return (
                <div>
                    <div className="container-fluid p-4">
                        <div className="float-left">
                            <BreadcrumbCustom breadcrumb={breadcrumb} />
                        </div>
                        <div className="clearfix"></div>
                        {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                        {this.state.isErrorOnLoad ? this.renderError() : this.renderViewFirmwareForm()}
                    </div>
                </div>
            );
        }
    }
}

export default ViewFirmware;