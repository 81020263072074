import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { AppStorageHelper } from './appStorageHelper';

/**
 * valid email RFC standard.
 */
const REG_EXP_EMAIL_1 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

/**
 * regex for any email
 */
const REG_EXP_EMAIL_2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * strong password format regex
 */
// eslint-disable-next-line
const REG_EXP_STRONG_PWD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,50}$/;
//    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(^.{6,50})$/
//    "^$|^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,50}$"

/**
 * regex for username pattern
 */
// eslint-disable-next-line
const REG_EXP_USER_NAME = /[~`!@#$%^&*)(\-+=/|}{\\[\]\":;'?><,_\.]/;

/**
* Description: isEmpty will check the empty value
* @param {string} _param
* @return {boolean}
*/
function isEmpty(_param) {
  return (_param === 'undefined' || _param === undefined || _param === '' || _param === null);
}

/**
* Description: isEmptyObject will check the empty object
* @param {object} _obj
* @return {boolean}
*/
function isEmptyObject(_obj) {
  if (_obj == null) return true;
  if (_obj.length > 0) return false;
  if (_obj.length === 0) return true;
  for (var key in _obj) {
    if (hasOwnProperty.call(_obj, key)) return false;
  }
  return true;
}

/**
 * listing of years from 1970
 */
function getYearsList() {
  const currentYear = new Date().getFullYear();
  const yearsList = [];
  for (let i = 1970; i <= currentYear; i++) {
    yearsList.push(i);
  }
  return yearsList;
}

/**
 * epoch to readable date time
 * @param {*} dateAsNumber
 */
function getReadableDate(dateAsNumber) {
  let displayData = '';
  if (dateAsNumber) {
    dateAsNumber = parseInt(dateAsNumber, 10);
    displayData = moment(dateAsNumber).format('lll');
  }
  return displayData;
}

/**
 * email pattern validation
 * @param {*} email
 */
function isValidEmail(email) {
  let valid = false;
  if (!isEmpty(email) && REG_EXP_EMAIL_1.test(email) && REG_EXP_EMAIL_2.test(email)) {
    valid = true;
  }
  return valid;
}

/**
 * username pattern validation
 * @param {*} nameAsString
 */
function isValidName(nameAsString) {
  let valid = false;
  try {
    if (_.isString(nameAsString) && !REG_EXP_USER_NAME.test(nameAsString)) {
      valid = true;
    }
  } catch (err) {
    console.log('error parsing regular expression for name field');
  }
  return valid;
}

/**
 * strong password pattern validation
 * @param {*} pwdStr
 */
function isStrongPassword(pwdStr) {
  let valid = false;
  if (!isEmpty(pwdStr) && REG_EXP_STRONG_PWD.test(pwdStr)) {
    valid = true;
  }
  return valid;
}

/**
 * check if passed falls into http valid status codes.
 * @param {*} code
 */
function isValidHttpCode(code) {
  if (code >= 200 && code < 400) {
    return true;
  }
  return false;
}

/**
 * Description: leftTrim will trim of string
 * @param {string} _str
 * @return {string}
 */
function leftTrim(_str) {
  return _str.replace(/^\s+/, '');
}

/**
 * get current selected language
 */
function getI18SelectedLanguage() {
  return (i18n && _.has(i18n, 'language')) ? i18n.language : '';
}

/**
 * fetch preffered language from local storage
 */
function getPreferredLangCodeFromLocalStorage() {
  const lang = AppStorageHelper.getPreferredLangFromLocalStorage();
  return (lang && _.has(lang, 'prefLanguage')) ? lang.prefLanguage : '';
}

/**
 * Extract uploaded file name from the url.
 * @param {*} url
 */
function getFileNameFromUrl(url) {
  let fileName;
  try {
    let urlObj = new URL(url);
    // url path without host and query string
    let pathName = decodeURIComponent(urlObj.pathname);
    // last part of the pathname is backend {fileName}
    let parts = _.without(pathName.split('/'), '');
    fileName = parts[parts.length - 1];
  } catch (err) {
    fileName = '';
  }

  return fileName;
}

function getxyStickeFace(ecuOrientation) {
  if(ecuOrientation) {
    const myArr = ecuOrientation.split("_");
    return myArr[3].charAt(0).toUpperCase() + myArr[3].slice(1);
  }
}

function getcableAttachDirection(ecuOrientation) {
  if(ecuOrientation) {
    const myArr = ecuOrientation.split("_");
    return myArr[1].charAt(0).toUpperCase() + myArr[1].slice(1);
  } 
}

/**
   * helper function to validate decimal values entered.
   * @param {*} num 
   */
function validateDecimalNum(num) {
    if ((num.length > 0) &&
      ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
        !(/^[+-]?\d+(\.\d{1,6})?$/.test(num)))) {
      return false;
    }
    return true;
  }

export const CommonUtilities = {
  isEmpty,
  isEmptyObject,
  getYearsList,
  getReadableDate,
  isValidEmail,
  isStrongPassword,
  isValidHttpCode,
  leftTrim,
  getI18SelectedLanguage,
  getPreferredLangCodeFromLocalStorage,
  isValidName,
  getFileNameFromUrl,
  getxyStickeFace,
  getcableAttachDirection,
  validateDecimalNum
};
