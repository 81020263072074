import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to create a vihicle in backend.
 * @param {*} reqBody
 */
function createVehicle (reqBody) {
  const url = API_INTERFACE.CREATE_VEHICLE_V2;

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(url, header, reqBody);
}

/**
 * service to fetch a vehicle based on vehicle id
 * @param {*} vehicleId
 */
function getVehicle (vehicleId) {
  const url = API_INTERFACE.GET_VEHICLE + '/' + vehicleId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to get all default vehicles from backend
 * @param {*} queryString 
 */
function getVehicles (queryString) {
  const url = API_INTERFACE.GET_VEHICLES + '?' + queryString;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to fetch all vehicles assigned to an account holder.
 * @param {*} accountId
 */
function getVehiclesByUserId (accountId) {
  const url = API_INTERFACE.GET_VEHICLES_BY_ID + '?accountId=' + accountId;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to delete a vehicle with id as vehicleId
 * @param {*} vehicleId
 */
function deleteVehicle (vehicleId) {
  const url = API_INTERFACE.GET_VEHICLE + '/' + vehicleId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * Fetch upload url for vehicle file.
 */
function getFileUploadUrl () {
  const url = API_INTERFACE.VEHICLE_POST_FILE;

  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, {});
}

/**
 * Service to upload vehicle file to a url.
 * @param {*} uploadUrl 
 * @param {*} fileObj 
 */
function uploadFile (uploadUrl, fileObj) {
  const header = {
    'Content-Type': 'binary/octet-stream'
  };

  return baseService.put(uploadUrl, header, fileObj);
}

/**
 * service to retrieve status of vehicle file 
 * process based on fileName
 * @param {*} fileName
 */
function getFileStatus (fileName) {
  const url = API_INTERFACE.VEHICLE_FILE_STATUS + '/' + fileName;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to update a default vehicle.
 * @param {*} body
 */
function updateVehicle (body) {
  const url = API_INTERFACE.UPDATE_VEHICLE;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(url, header, body);
}

/**
 * service to assign a default vehicle to an account holder.
 * The default vehicle listing do not change by this assignment in the system.
 * It just create a new vehicle under the account holder with same properties.
 * @param {*} accountId
 * @param {*} body
 */
function assignDefaultVehicleToAccountHolder (accountId, body) {
  body.accountId = accountId;
  const url = API_INTERFACE.UPDATE_VEHICLE;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(url, header, [body]);
}

export const VehiclesService = {
  createVehicle,
  getVehicle,
  getVehicles,
  getVehiclesByUserId,
  deleteVehicle,
  getFileUploadUrl,
  uploadFile,
  getFileStatus,
  updateVehicle,
  assignDefaultVehicleToAccountHolder
};
