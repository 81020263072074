import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { VehiclesService } from '../../../services/service.vehicles';
import { CalibrationsService } from "../../../services/service.calibrations";
import AlertMessage from '../../../Component/alert/AlertMessage';
import i18n from 'i18next';
import { CommonConstants } from '../../../constants/app.constants';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { CommonUtilities } from "../../../shared/utils/commonUtilities";

/**
 * view vehicle component.
 */
class ViewVehicle extends BaseScreen {
  constructor (props) {
    super(props);


    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      vehicleData: '',
      calibrationData: '',
      aTabEnabled: true,
      bTabEnabled: true,
      cTabEnabled: true,
      dTabEnabled: true,
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };

    this.vehicleTypes = CommonConstants.getVehicleTypes();
    this.ecuOrientationTypes = CommonConstants.getEcuOrientationTypes();
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      this.displayVehicleInfo();
    }
  }
  
  setActiveTab = (index) => {
	this.setState({
		activeTab : index
	});	
  }

  /**
   * fetch data from backend to display on ui.
   */
  displayVehicleInfo () {
    const vehicleId = this.props.match.params.vehicleId;
    VehiclesService.getVehicle(vehicleId).then(response => {
      this.setState({
        vehicleData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        vehicleData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
    
    //fetch calibrations    
    CalibrationsService.getDefaultCalibrations(vehicleId).then(calibResponse => {
      let cbData = {};
      let tempData = calibResponse.data.items;
      tempData.forEach((element) => {
			if(element.calibrationType === 'A'){
				cbData.leftRadius = element.leftRadius;
				cbData.rightRadius = element.rightRadius;
				this.setState({aTabEnabled:false});
			}else if(element.calibrationType === 'B'){
				cbData.lockToLockLeft = element.lockToLockLeft;
				cbData.lockToLockRight = element.lockToLockRight;
				this.setState({bTabEnabled:false});
			}else if(element.calibrationType === 'C'){
				cbData.rollBias = element.rollBias;
				cbData.pitchBias = element.pitchBias;
				this.setState({cTabEnabled:false});
			}else if(element.calibrationType === 'D'){
				cbData.steeringRatio = element.steeringRatio;
				this.setState({dTabEnabled:false});
			}else{
				
			}
		});
      this.setState({
        calibrationData: cbData,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        calibrationData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * go to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * display vehicles types
   * @param {*} vehicleType 
   */
  getVehicleTypeToDisplay (vehicleType) {
    const vehicleTypeToDisplay = this.vehicleTypes.find(type => {
      return type.value === vehicleType;
    });
    return (vehicleTypeToDisplay ? vehicleTypeToDisplay.displayName : '');
  }

  /**
   * display ECU orientation
   * @param {*} ecuType 
   */
  getEcuOrientationTypeToDisplay (ecuType) {
    if (ecuType) {
      return ecuType;
    } else {
      return 'N/A';
    }
  }

  resolveStickeFaceAndCableAttachDirection = (type) => {
    if(type === "Forward") {
      return "Front"
    }else if(type === "Aft") {
      return "Rear"
    } else {
      return type
    }
  }

  /**
   * vehicle details form for ui
   */
  renderVehicleDetails () {
    return (
      <>
        <h2> {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.viewVehicle')} </h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.make')}</label>
              <p>{this.state.vehicleData.make}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.model')}</label>
              <p>{this.state.vehicleData.model}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.year')}</label>
              <p>{this.state.vehicleData.year}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.type')}</label>
              <p>{this.getVehicleTypeToDisplay(this.state.vehicleData.type)}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.attack')}</label>
              <p>{this.state.vehicleData.attack}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.backlash')}</label>
              <p>{this.state.vehicleData.backlash}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.sensitivity')}</label>
              <p>{this.state.vehicleData.sensitivity}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.localId')}</label>
              <p>{this.state.vehicleData.localId ? this.state.vehicleData.localId : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.steeringWheelType')}</label>
              <p>{this.state.vehicleData.steeringWheelType ? this.state.vehicleData.steeringWheelType : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.wheelbase')}</label>
              <p>{this.state.vehicleData.wheelbase ? this.state.vehicleData.wheelbase : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaHeight')}</label>
              <p>{this.state.vehicleData.antennaHeight ? this.state.vehicleData.antennaHeight : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaPivot')}</label>
              <p>{this.state.vehicleData.antennaPivot ? this.state.vehicleData.antennaPivot : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaOffset')}</label>
              <p>{this.state.vehicleData.antennaOffset ? this.state.vehicleData.antennaOffset : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.frontHitch')}</label>
              <p>{this.state.vehicleData.frontHitch ? this.state.vehicleData.frontHitch : 'N/A'}</p>
            </div>

            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.rearHitch')}</label>
              <p>{this.state.vehicleData.rearHitch ? this.state.vehicleData.rearHitch : 'N/A'}</p>
            </div>
            
            <div className="col-md-12">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.ecuOrientation')}</label>
            </div>
            <div className="col-md-4">
              <label >{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.CableAttachDirection')}</label>
              <input type="text" className="form-control" name="cableAttachDirection" disabled
                            value={this.resolveStickeFaceAndCableAttachDirection(CommonUtilities.getcableAttachDirection(this.state.vehicleData.ecuOrientation))}
                            />
            </div>
            <div className="col-md-4">
              <label >{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.XYStickeFace')}</label>
              <input type="text" className="form-control" name="xyStickeFace" disabled
                            value={this.resolveStickeFaceAndCableAttachDirection(CommonUtilities.getxyStickeFace(this.state.vehicleData.ecuOrientation))}
                            />
            </div>
            <div className="col-md-12"><br/></div>
            <div className="col-md-6">
              <label className="emphasized-label">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.profileVersion')}</label>
              <p>{this.state.vehicleData.profileVersion ? this.state.vehicleData.profileVersion : 'N/A'}</p>
            </div>
			<div className="col-md-12">
				<label className="emphasized-label">{i18n.t('user.usersListCreateViewAndEdit.calibrations')}</label>
			</div>
			<div className="col-md-8">
             	  <Nav tabs>
				        <NavItem>
				          <NavLink disabled={this.state.aTabEnabled} onClick={() => this.setActiveTab('A')}>
				            {i18n.t('common.calibrationTypes.a')}
				          </NavLink>
				        </NavItem>
				        <NavItem>
				          <NavLink disabled={this.state.bTabEnabled} onClick={() => this.setActiveTab('B')}>
				            {i18n.t('common.calibrationTypes.b')}
				          </NavLink>
				        </NavItem>
				        <NavItem>
				          <NavLink disabled={this.state.cTabEnabled} onClick={() => this.setActiveTab('C')}>
				            {i18n.t('common.calibrationTypes.c')}
				          </NavLink>
				        </NavItem>
				        <NavItem>
				          <NavLink disabled={this.state.dTabEnabled} onClick={() => this.setActiveTab('D')}>
				            {i18n.t('common.calibrationTypes.d')}
				          </NavLink>
				        </NavItem>
			      </Nav>
			      <TabContent activeTab={this.state.activeTab} style={{maxHeight:"none"}}>
				        <TabPane tabId="A">
				        	<div className="form-group">
					        	<label> {i18n.t('user.viewAndListUserCalibration.form.label.leftRadius')} </label>:&nbsp;&nbsp;
					        	{this.state.calibrationData ? this.state.calibrationData.leftRadius : 'N/A'}
					        	<br/>
					        	<label>{i18n.t('user.viewAndListUserCalibration.form.label.rightRadius')}</label>:&nbsp;&nbsp;
					        	{this.state.calibrationData ? this.state.calibrationData.rightRadius : 'N/A'}							        	
				        	</div>					        	
				        </TabPane>
				        <TabPane tabId="B">
				        	<label>{i18n.t('user.viewAndListUserCalibration.form.label.lockRight')}</label>:&nbsp;&nbsp;
				        	{this.state.calibrationData ? this.state.calibrationData.lockToLockRight : 'N/A'}
				        	<br/>
				        	<label>{i18n.t('user.viewAndListUserCalibration.form.label.lockLeft')}</label>:&nbsp;&nbsp;
				        	{this.state.calibrationData ? this.state.calibrationData.lockToLockLeft : 'N/A'}				        	
				        									        	
				        </TabPane>
				        <TabPane tabId="C"> 
							<label>{i18n.t('user.viewAndListUserCalibration.form.label.pitchBias')}</label>:&nbsp;&nbsp;
							{this.state.calibrationData ? this.state.calibrationData.pitchBias : 'N/A'}
							<br/>
				        	<label>{i18n.t('user.viewAndListUserCalibration.form.label.rollBias')}</label>:&nbsp;&nbsp;
				        	{this.state.calibrationData ? this.state.calibrationData.rollBias : 'N/A'}
						</TabPane>
				        <TabPane tabId="D">
				        	<label>{i18n.t('user.viewAndListUserCalibration.form.label.steeringRatio')}</label>:&nbsp;&nbsp;
				        	{this.state.calibrationData.steeringRatio ? this.state.calibrationData.steeringRatio : 'N/A'}						        				        	
				        </TabPane>
			      </TabContent>	         
                </div>
          </div>
        </div>
        <div className="col-md-12 text-right f-btn">
          <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.back')}</button>
          <Link to={{
            pathname: '/vehicle/edit-vehicle/' + this.state.vehicleData.id,
            state: { vehicleData: this.state.vehicleData }
          }} className="btn btn-primary">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.edit')}</Link>
        </div>
      </>
    );
  }

  /**
   * render error
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.back')}</button>
      </div>
    );
  }

  /*
        alert message handling
    */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'vehicle', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.vehicle'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>

        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderVehicleDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewVehicle;
