import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { OperationsService } from '../../../services/service.operations';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';

/**
 * view operation component.
 */
class ViewOperation extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      operationId: this.props.match.params.operationId,
      isLoading: true,
      operationData: '',
      isErrorOnLoad: false,
      apiResponseMsg: '',
      // for alert messages
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      this.displayOperationInfo();
    }
  }

  /**
   * functionn to fetch operation details from backend
   */
  displayOperationInfo () {
    const operationId = this.state.operationId;

    OperationsService.getOperation(operationId).then(response => {
      this.setState({
        operationData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        operationData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * go back to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * operation detail form.
   */
  renderOperationDetails () {
    return (
      <div>
        <h2> {i18n.t('user.viewEditAndListUserOperation.viewOperation')} </h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserOperation.form.label.name')} </label>
              <p>{this.state.operationData.name}</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserOperation.form.label.active')}</label>
              <p>{this.state.operationData.active ? 'True' : 'False' }</p>
            </div>
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserOperation.form.label.localId')}</label>
              <p>
                {
                  this.state.operationData.localId
                    ? this.state.operationData.localId
                    : i18n.t('user.viewEditAndListUserOperation.form.label.notAvailable')
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right f-btn">
          <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
            {i18n.t('user.viewEditAndListUserOperation.form.button.back')}
          </button>
          {/* <Link to={{
            pathname: '/user/edit-operation/' + this.state.operationId
          }} className="btn btn-primary">{i18n.t('user.viewEditAndListUserOperation.form.button.edit')}</Link> */}
        </div>
      </div>
    );
  }

  /**
   * render error message
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('user.viewEditAndListUserOperation.form.button.back')}
        </button>
      </div>
    );
  }

  /*
        alert message handling
    */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to render operation details form
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('user.viewEditAndListUserOperation.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'operation', displayName: i18n.t('user.viewEditAndListUserOperation.breadcrumb.operation'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('user.viewEditAndListUserOperation.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>

        {
          this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                      type={this.state.alert.type}
                      isAlertOpen={!!(this.state.alert.message)}
                      handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderOperationDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewOperation;
