import React from 'react';
import axios from 'axios';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import { SubmissionAlert } from '../../../Component/SubmissionStatus';
import { SupportService } from "../../../services/service.support";
import { UserService } from "../../../services/service.users";
import i18n from 'i18next';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';

/**
 * Informations to be shown in support listing grid, along with options per rows in support details page.
 */
let supportListConfig = {};

/**
 * Listing components of supports.
 */
class ListSupport extends BaseScreen {
    constructor(props) {
        super(props);

        // initialize supportListConfig with updated i18n resources
        supportListConfig = {
            'id': {
                'label': i18n.t('supportListViewAndConcerns.list.id'),
                'sort': false,
                'filter': false,
                'display': true,
                'filterMaxLength': 118
            },
            'firstName': {
                'label': i18n.t('supportListViewAndConcerns.list.firstName'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 118
            },
            'lastName': {
                'label': i18n.t('supportListViewAndConcerns.list.lastName'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 118
            },
            'userId': {
                'label': i18n.t('supportListViewAndConcerns.list.userId'),
                'sort': true,
                'filter': true,
                'display': true,
                'filterMaxLength': 118
            },
            'serialNumber': {
                'label': i18n.t('supportListViewAndConcerns.list.serialNumber'),
                'sort': false,
                'filter': false,
                'display': true,
                'filterMaxLength': 50
            },
            'ecuApplicationVersion': {
                'label': i18n.t('supportListViewAndConcerns.list.ecuApplicationVersion'),
                'sort': false,
                'filter': false,
                'display': true,
                'filterMaxLength': 50
            },
            'firmwareVersion': {
                'label': i18n.t('supportListViewAndConcerns.list.firmwareVersion'),
                'sort': true,
                'filter': true,
                'display': true,
                'filterMaxLength': 50
            }, 
            'createdAt': {
                'label': i18n.t('supportListViewAndConcerns.list.createdAt'),
                'sort': true,
                'filter': false,
                'display': true,
                'isDate': true,
                'filterMaxLength': 0
            },
            'options': {
                'label': i18n.t('supportListViewAndConcerns.list.options.options'),
                'sort': false,
                'filter': false,
                'display': true,
                'list': [
                    {
                        'actionType': 'link',
                        'action': 'view',
                        'label': i18n.t('supportListViewAndConcerns.list.options.view')
                    }
                ]
            }
        };

        this.state = {
            supports: [],
            isLoading: true,
            cancelToken: '',
            pgnConfig: {
                currentPage: getListIndex(SCREEN_ID.ListSupport),
                numberOfPageLinks: 0,
                countPerPage: 25,
                totalCount: 0,
                pageList: [25, 50, 100]
            },
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: "",
                modalMsg: "",
                modalData: "",
                modalAction: "",
                modalType: ""
            },
            reinitialize: false,
            sortFieldsConfig: {
                "id": {sortOrder:1}
            },
            filterFieldsConfig: {},
            defaultSortField: "modifiedAt",
            defaultSortOrder: -1,
            
            apiResponseIsSuccess: false,
            showMessage: false,
            apiResponseMessage: ''
        }
    }

    componentDidMount() {
        let args = {
            currentPage: this.state.pgnConfig.currentPage -1,
            pageSize: this.state.pgnConfig.countPerPage
        };
        this.getsupports(args).then(
            (response) => {
                let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.total);
                this.setState({
                    supports: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig
                });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }

    componentWillUnmount () {
        setListIndex(SCREEN_ID.ListSupport, this.state.pgnConfig.currentPage)
    }

    /**
     * functionality to fetch all support logs from backend to render in listing
     * @param {*} args 
     * @param {*} sortFieldsConfig 
     * @param {*} filterFieldsConfig 
     */
    getsupports(args, sortFieldsConfig, filterFieldsConfig) {
        let queryParams = "?currentPage=" + args.currentPage + "&pageSize=" + args.pageSize;
       
        let sortFiledsQueryParams = '';
        if (!sortFieldsConfig) {
            sortFieldsConfig = this.state.sortFieldsConfig;
        }
        let sortQueryTemp = "";
        let userSortField = "";
        let key;
        for (key in sortFieldsConfig) {
            let sortValue = sortFieldsConfig[key]["sortOrder"];
            if (sortValue) {
                if(key === 'firstName' || key === 'lastName') {
                    userSortField = "&sortField=" + key + "&orderType=" + ((sortValue === 1) ? "desc" : "asc");
                    sortQueryTemp = "sortField=others" ;
                    sortFiledsQueryParams += "&" + sortQueryTemp;
                } else {
                    sortQueryTemp = "sortField=" + key + "&orderType=" + ((sortValue === 1) ? "desc" : "asc");
                    sortFiledsQueryParams += "&" + sortQueryTemp;
                }   
            }
        }

        let filterFielddQueryParams = "";
        if (!filterFieldsConfig) {
            filterFieldsConfig = this.state.filterFieldsConfig;
        }

        var userQueryParams = queryParams;
        queryParams += sortFiledsQueryParams;
        userQueryParams += userSortField;

        let filterQueryTemp = "";
        for (key in filterFieldsConfig) {
            let filterValue = filterFieldsConfig[key]["value"];
            if (filterValue) {
                filterQueryTemp = key + "=" + filterValue;
                if(key !== 'userId' && key !== 'firstName' && key !== 'lastName')
                    filterFielddQueryParams += "&" + filterQueryTemp;
                if(key === 'userId' || key === 'firstName' || key === 'lastName')
                    userQueryParams += "&" + filterQueryTemp;
            }
        }
        queryParams += filterFielddQueryParams;
        if(this.state.cancelToken) {
            this.state.cancelToken.cancel("Cancelling request");
        }
        const CancelToken = axios.CancelToken;
        this.state.cancelToken = CancelToken.source();

        if (userQueryParams && (userQueryParams.includes('userId') || userQueryParams.includes('firstName') || userQueryParams.includes('lastName')) && !userQueryParams.includes('sortField=userId')) {
            return UserService.fetchUsersByProfile(userQueryParams).then(
                (response) => {
                    return SupportService.fetchSupportsBySubs(queryParams, response.data).then(
                        (resp) => {
                            this.resolveRequesterDataFromSupportLog(resp.data);
                            return resp.data;
                        }
                    )
                }
            )
        } else {
            return SupportService.fetchSupports(queryParams).then(
                (response) => {
                    this.resolveRequesterDataFromSupportLog(response.data);
                    return response.data;
                }
            )
        }
    }

    /**
     * functionality to get support log requester data from backend using the userId in support log.
     * @param {*} data 
     */
    resolveRequesterDataFromSupportLog(data) {
        let response = {};
        response["total"] = data.total;        
    }

    /**
     * resolve emails to display in ui from userId
     * @param {*} userId 
     */
    changeIdToUserEmailToDisplay(userId) {
        return UserService.fetchUserById(userId, this.state.cancelToken).then(
            (response) => {
                return response.data;
            },
            (error) => {
                return Promise.reject(error);
            }
        )
    }

    /**
     * helper function for pagination configuration
     * @param {*} currentPage 
     * @param {*} countPerPage 
     * @param {*} totalCount 
     */
    setPaginationConfig(currentPage, countPerPage, totalCount) {
        return {
            currentPage: currentPage,
            numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
            countPerPage: countPerPage,
            totalCount: totalCount,
            pageList: [...this.state.pgnConfig.pageList]
        }
    }

    /**
     * handler when page number change, like user move to next page or previous page from current page he is viewing
     * @param {*} pageNumber 
     */
    onPgnChange(pageNumber) {
        if (pageNumber !== this.state.pgnConfig.currentPage) {
            window.scrollTo(0, 0);
            this.setState({ isLoading: true, supports: [] });
            let arg = {
                currentPage: (pageNumber - 1),
                pageSize: this.state.pgnConfig.countPerPage,
                // sortField: this.state.pgnConfig.sortField,
                // orderType: this.state.pgnConfig.orderType
            };
            this.getsupports(arg).then(
                (response) => {
                    let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.total);
                    this.setState({
                        supports: response.items,
                        isLoading: false,
                        pgnConfig: pgnConfig,
                        modalShow: false
                    });
                });
        }
    }

    /**
     * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
     * @param {*} event 
     */
    onPgnRowsCountChange(event) {
        window.scrollTo(0, 0);
        let perPageItems = Number(event.target.value);
        let currentPage;
        if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
            currentPage = 1;
        } else {
            currentPage = this.state.pgnConfig.currentPage;
        }
        this.setState({ isLoading: true, firmwares: [] });
        let args = {
            currentPage: currentPage - 1,
            pageSize: perPageItems,
            // sortField: this.state.pgnConfig.sortField,
            // orderType: this.state.pgnConfig.orderType
        };
        this.getsupports(args).then(
            (response) => {
                let pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.total);
                this.setState({
                    supports: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig,
                    modalShow: false
                });
            });
    }

    /**
     * lazy load function when user use filter or sort functionality from grid header
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    onLazyLoad(sortFieldsObj, filterFieldsObj) {
        this.setState({ isLoading: true, supports: [] });
        let pageNumber = 1;
        let arg = {
            currentPage: pageNumber - 1,
            pageSize: this.state.pgnConfig.countPerPage
        };
        this.getsupports(arg, sortFieldsObj, filterFieldsObj)
            .then((response) => {
                let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.total);
                if (!sortFieldsObj) {
                    sortFieldsObj = this.state.sortFieldsConfig;
                }
                if (!filterFieldsObj) {
                    filterFieldsObj = this.state.filterFieldsObj
                }
                this.setState({
                    supports: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                    modalShow: false,
                    sortFieldsConfig: sortFieldsObj,
                    filterFieldsConfig: filterFieldsObj,
                });
            });
    }

    /**
     * routing to relevant page based on row option selection
     * @param {*} actionType 
     * @param {*} row 
     */
    actionPaths(actionType, row) {
        if (actionType.action === "view") {
            return ({
                pathname: '/support/view-log/' + row.id,
                state: { supports: row }
            });
        }
    }

    /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    /**
     * react render function to render the list of firmwares.
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'support', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.support'), className: '', link: false },
            { id: 'list', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.list'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className="clearfix"></div>
                    {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                    <div>
                        <Grid id="supportListGrid"
                            keyColumn='id'
                            loading={this.state.isLoading}
                            columnDefs={supportListConfig}
                            rowData={this.state.supports}
                            reinitialize={this.state.reinitialize}
                            pagination={true}
                            pgnConfig={this.state.pgnConfig}
                            pageList={this.state.pageList}
                            lazyLoading={true}
                            onPgnChange={this.onPgnChange.bind(this)}
                            onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
                            onLazyLoad={this.onLazyLoad.bind(this)}
                            actionPaths={this.actionPaths.bind(this)}
                            defaultSortField={this.state.defaultSortField}
                            defaultSortOrder={this.state.defaultSortOrder} />
                    </div>
                </div>
            </div>
        );
    }
}
export default ListSupport;