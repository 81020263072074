import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { ImplementsService } from '../../../services/service.implements';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';

/**
 * view implement component.
 */
class ViewImplement extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      implementId: this.props.match.params.implementId,
      isLoading: true,
      implementData: '',
      isErrorOnLoad: false,
      apiResponseMsg: '',
      // for alert messages
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      this.displayImplementInfo();
    }
  }

  /**
   * functionn to fetch implement details from backend
   */
  displayImplementInfo () {
    const implementId = this.state.implementId;

    ImplementsService.getImplement(implementId).then(response => {
      this.setState({
        implementData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }

      this.setState({
        implementData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * go back to previous page
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * implement detail form.
   */
  renderImplementDetails () {
    return (
      <div>
        <h2> {i18n.t('user.viewEditAndListUserImplement.viewImplement')} </h2>
        <div className="view-profile-details width-70per">
          <div className="row">
            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserImplement.form.label.name')} </label>
              <p>{this.state.implementData.name}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserImplement.form.label.length')}</label>
              <p>{this.state.implementData.length}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserImplement.form.label.position')}</label>
              <p>{this.state.implementData.position ? this.state.implementData.position : 'N/A'}</p>
            </div>

            <div className="col-md-7">
              <label className="emphasized-label">{i18n.t('user.viewEditAndListUserImplement.form.label.localId')}</label>
              <p>{this.state.implementData.localId ? this.state.implementData.localId : 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="col-md-12 text-right f-btn">
          <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
            {i18n.t('user.viewEditAndListUserImplement.form.button.back')}
          </button>
          <Link to={{
            pathname: '/user/edit-implement/' + this.state.implementId
          }} className="btn btn-primary">{i18n.t('user.viewEditAndListUserImplement.form.button.edit')}</Link>
        </div>  

      </div>
    );
  }

  /**
   * render error message
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>
          {i18n.t('user.viewEditAndListUserImplement.form.button.back')}
        </button>
      </div>
    );
  }

  /*
        alert message handling
    */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to render implement details form
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('user.viewEditAndListUserImplement.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'implement', displayName: i18n.t('user.viewEditAndListUserImplement.breadcrumb.implement'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('user.viewEditAndListUserImplement.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>

        {
          this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                      type={this.state.alert.type}
                      isAlertOpen={!!(this.state.alert.message)}
                      handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
        }

        <LoadingOverlay active={this.state.isLoading}>
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderImplementDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewImplement;
