import React from 'react';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { CalibrationsService } from '../../../services/service.calibrations';
import i18n from 'i18next';
import AlertMessage from '../../../Component/alert/AlertMessage';

/**
 * Informations to be shown in associated calibrations listing grid, along with options per 
 * rows, in User details page.
 */
let calibrationsListConfig = {};

/**
 * Component to display user's associated calibrations.
 */
class ListUserCalibrations extends BaseScreen {
  /**
   * Component initialization
   * @param {object} props
   */
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize calibrationsListConfig with updated i18n resources
    calibrationsListConfig = {
      make: {
        label: i18n.t('user.viewAndListUserCalibration.list.make'),
        display: true,
      },
      model: {
        label: i18n.t('user.viewAndListUserCalibration.list.model'),
        display: true,
      },
      calibrationType: {
        label: i18n.t('user.viewAndListUserCalibration.list.type'),
        display: true,
      },
      calibrationStatus: {
        label: i18n.t('user.viewAndListUserCalibration.list.status'),
        display: true,
      },
      calibrationStartTime: {
        label: i18n.t('user.viewAndListUserCalibration.list.startTime'),
        display: true,
        isDate: true,
      },
      calibrationEndTime: {
        label: i18n.t('user.viewAndListUserCalibration.list.endTime'),
        display: true,
        isDate: true,
      },
      options: {
        label: i18n.t('user.viewAndListUserCalibration.list.options.options'),
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('user.viewAndListUserCalibration.list.options.view')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      userCalibrationsData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10, // default page size
        totalCount: 0,
        pageList: [10]
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * React component life cycle method called after first render.
   */
  componentDidMount() {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCalibrationsByAccountId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          userCalibrationsData: response.data.items,
          pgnConfig: pgnConfig,
          loader: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userCalibrationsData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * function to communicate with backend to fetch all associated 
   * calibrations of an account holder.
   */
  getCalibrationsByAccountId(requiredParams) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' +
      params.pageSize + '&accountId=' + this.props.userId;

    return CalibrationsService.getCalibrations(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig(currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange = (pageNumber) => {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, userCalibrationsData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCalibrationsByAccountId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          userCalibrationsData: response.data.items,
          pgnConfig: pgnConfig,
          loader: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userCalibrationsData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths = (actionType, row) => {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user/view-calibration/' + row.id
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss = () => {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display associated calibrations with selected user.
   */
  render() {
    return (
      <div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss}
          />
        }
        <div>
          <Grid id="userCalibrationsListGrid"
            keyColumn="id"
            rowData={this.state.userCalibrationsData}
            columnDefs={calibrationsListConfig}
            loading={this.state.loader}
            actionPaths={this.actionPaths}
            pagination={true}
            pgnConfig={this.state.pgnConfig}
            onPgnChange={this.onPgnChange} />
        </div>
      </div>
    );
  }
}

export default ListUserCalibrations;
