import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import i18n from 'i18next';
import { Collapse } from 'react-collapse';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import BaseScreen from '../BaseScreen';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { StyledText } from '../../../Component/StyledText';
import { FormErrors } from '../../../Component/SubmissionStatus';
import { UserService } from '../../../services/service.users';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import { SCREENS } from '../../../constants/screens.constant';
import { APP_COMMON_CONSTANTS } from '../../../constants/app.constants';

/**
 * modal component to change email
 */
class ChangeEmailModal extends BaseScreen {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      formfields: {
        email: '',
        confirmationCode: ''
      },
      formErrors: {
        email: '',
        confirmationCode: ''
      },
      isFormValid: false,
      // step 1 - get email change confirmation code
      isStep1Done: false,
      // step 2 - send email change request with confirmation code
      isStep2Done: false,
      // for alert messages
      alert: {
        type: null,
        message: null
      }
    };
  }

  /**
   * handle user input for validation purpose.
   * @param {*} event 
   */
  handleUserInput (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = _.trim(event.target.value);

    const formfields = this.state.formfields;
    formfields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };
    let msg = '';

    switch (name) {
      case 'email':
        if (value.length === 0) {
          msg = i18n.t('user.changeEmail.alert.requiredField');
        } else if (value.length > 320) {
          msg = i18n.t('user.changeEmail.alert.email320');
        } else {
          msg = CommonUtilities.isValidEmail(value) ? '' : i18n.t('user.changeEmail.alert.emailInvalid');
        }
        fieldValidationErrors.email = msg;
        break;

      case 'confirmationCode':
        if (value.length === 0) {
          msg = i18n.t('user.changeEmail.alert.requiredField');
        }

        fieldValidationErrors[name] = msg;
        break;

      default:
        break;
    }

    let isFormValid;

    if (this.state.isStep1Done) {
      isFormValid = (formfields.email && formfields.confirmationCode) &&
        (fieldValidationErrors.email === '' && fieldValidationErrors.confirmationCode === '');
    } else {
      isFormValid = formfields.email && fieldValidationErrors.email === '';
    }

    this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      isFormValid: isFormValid
    });
  }

  /**
   * handler to handle event when user press submit button
   * @param {*} e
   */
  handleSubmit (e) {
    e.preventDefault();
    this.setState({
      isLoading: true
    });

    if (this.state.isStep1Done) {
      /**
       * send email change request with confirmation code
       */
      this.changeEmailRequest();
    } else {
      /**
       * get confirmation code for email change
       */
      this.getEmailChangeConfirmationCode();
    }
  }

  /**
   * get the confirmation code require for successfully accomplice email change functionality
   */
  getEmailChangeConfirmationCode () {
    const newEmail = this.state.formfields.email;

    UserService.getEmailChangeConfirmationCode(newEmail).then(
      (response) => {
        this.setState({
          isLoading: false,
          isStep1Done: true,
          isFormValid: false,
          alert: {
            type: 'success',
            message: i18n.t('user.changeEmail.alert.confirmationCodeSent')
          }
        });
      }, (error) => {
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }

        this.setState({
          isLoading: false,
          alert: {
            message: alertMsg,
            type: 'danger'
          }
        });
      });
  }

  /**
   * confirm email change request to backend. updates with new email
   */
  changeEmailRequest () {
    const newEmail = this.state.formfields.email;
    const confirmationCode = this.state.formfields.confirmationCode;

    UserService.confirmChangeEmail(newEmail, confirmationCode).then(
      (response) => {
        this.setState({
          isLoading: false,
          isStep2Done: true,
          isFormValid: false,
          alert: {
            type: 'success',
            message: i18n.t('user.changeEmail.alert.emailChangedSuccessful')
          }
        });

        /**
         * remove authToken from app storage - on email change completion
         */
        AppStorageHelper.removeTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);
      }, (error) => {
        let alertMsg = i18n.t('common.genericApiError');

        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          isLoading: false,
          alert: {
            message: alertMsg,
            type: 'danger'
          }
        });
      });
  }

  /**
   * validate if form is valid
   */
  getFormValidity () {
    return this.state.isFormValid;
  }

  /*
        alert message handling
    */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * redirect to login page
   */
  goToLogin () {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * react render function to display the change email modal.
   */
  render () {
    return (
      <div>
        <Modal isOpen={this.props.isModalOpen}
          onClosed={this.props.onModalClosed}
          unmountOnClose={true}
          centered={true}
          wrapClassName="modal-wrapper-custom"
          size="lg"
        >
          <LoadingOverlay active={this.state.isLoading} customClass="login_loading_overlay">
            <div className="modal-header custom-modal-header">
              <h5 className="modal-title">{this.props.modalTitle}</h5>
              <Button className="close" onClick={this.props.handleModalDismiss}>
                <span>×</span>
              </Button>
            </div>
            <ModalBody>
              {
                this.state.alert.message &&
                <AlertMessage message={this.state.alert.message}
                  type={this.state.alert.type}
                  isAlertOpen={!!(this.state.alert.message)}
                  handleDismiss={this.handleAlertDismiss.bind(this)}
                />
              }
              <div>
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">{i18n.t('user.changeEmail.form.label.email')} <StyledText uiText="*" /></label>
                        <input type="text" required className="form-control" name="email"
                          placeholder={i18n.t('user.changeEmail.form.placeholder.email')}
                          value={this.state.formfields.email}
                          onChange={this.handleUserInput.bind(this)} maxLength="320"
                          disabled={this.state.isStep1Done} />
                        <FormErrors formErrors={this.state.formErrors.email} />
                      </div>
                    </div>
                  </div>

                  <Collapse isOpened={this.state.isStep1Done}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="confirmationCode">{i18n.t('user.changeEmail.form.label.confirmationCode')} <StyledText uiText="*" /></label>
                          <input type="text" required className="form-control" name="confirmationCode"
                            placeholder={i18n.t('user.changeEmail.form.placeholder.confirmationCode')}
                            value={this.state.formfields.confirmationCode}
                            onChange={this.handleUserInput.bind(this)} maxLength="320"
                            disabled={this.state.isStep2Done} />
                          <FormErrors formErrors={this.state.formErrors.confirmationCode} />
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <div className="col-md-12 text-right">
                    {
                      !this.state.isStep2Done
                        ? (
                          <React.Fragment>
                            <button type="button" className="btn btn-secondary mr-2" onClick={this.props.handleModalDismiss}>
                              {i18n.t('user.changeEmail.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                              onClick={this.handleSubmit.bind(this)}
                              disabled={!(this.getFormValidity())}>
                              {
                                (this.state.isStep1Done)
                                  ?
                                  i18n.t('user.changeEmail.form.button.save')
                                  :
                                  i18n.t('user.changeEmail.form.button.getCode')}
                            </button>
                          </React.Fragment>
                        )
                        : (
                          <button type="button" className="btn btn-primary" onClick={this.goToLogin.bind(this)}>
                            {i18n.t('user.changeEmail.form.button.gotoLogin')}
                          </button>
                        )
                    }
                  </div>
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <br />
            </ModalFooter>
          </LoadingOverlay>
        </Modal>
      </div>
    );
  }
}

export default withRouter(ChangeEmailModal);
