import { API_INTERFACE } from '../constants/uri.constant';
import Method from './services';
import { AuthUtils } from './service.auth.utils';

const SEPARATOR = '/';

/**
 * service to create firmware.
 * create firmware with version, description and filename and then upload a firmware file using presigned url generated from backend.
 * @param {*} values
 */
function createFirmwareSubmission (values) {
  const params = {
    version: values.version,
    description: values.description,
    deviceType: values.deviceType,
    fileName: values.filedata.name
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(API_INTERFACE.FIRMWARE, header, params).then(
    resp => {
      const uploadedResponse = uploadFirmwareWithPreSignUrl(resp.data.url, values.filedata);
      return uploadedResponse;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

/**
 * firmware file upload helper function used in createFirmwareSubmission method.
 * @param {*} url
 * @param {*} firmware
 */
function uploadFirmwareWithPreSignUrl (url, firmware) {
  const headers = {
    'Content-Type': 'binary/octet-stream'
  };
  return Method.put(url, headers, firmware);
}

/**
 * fetch a firmware details based on id.
 * @param {*} id
 */
function fetchFirmwareByIdSubmission (id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.FIRMWARE + SEPARATOR + id,
    headers
  );
}

/**
 * update a firmware details.
 * @param {*} values
 */
function updateFirmwareSubmission (values) {
  const params = values;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(
    API_INTERFACE.FIRMWARE,
    header,
    params
  );
}

/**
 * fetch a list of firmwares from backend.
 * @param {*} values
 */
function fetchFirmwares (values) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(
    API_INTERFACE.FIRMWARES + values,
    header
  );
}

/**
 * deletes a firmware based on id.
 * @param {*} id
 */
function deleteFirmwareById (id) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.delete(
    API_INTERFACE.FIRMWARE + SEPARATOR + id,
    header
  );
}

function createFirmwareTypeSubmission (values) {
  const params = {
    deviceType: values.deviceType,
    description: values.description
  };
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(API_INTERFACE.FIRMWARE_TYPE, header, params);
}

function fetchFirmwaresTypes () {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(API_INTERFACE.FIRMWARE_TYPE, header);
}

function deleteFirmwaresTypesById (id) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.delete(
    API_INTERFACE.FIRMWARE_TYPE + SEPARATOR + id,
    header
  );
}


export const FirmwareService = {
  createFirmwareSubmission,
  fetchFirmwareByIdSubmission,
  updateFirmwareSubmission,
  fetchFirmwares,
  deleteFirmwareById,
  createFirmwareTypeSubmission,
  fetchFirmwaresTypes,
  deleteFirmwaresTypesById
};
