import React from "react";
import BreadcrumbCustom from "../../../../Component/breadcrumb/BreadcrumbCustom";
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { DeviceService } from "../../../../services/service.devices";
import { UserService } from "../../../../services/service.users";
import { StyledText } from "../../../../Component/StyledText";
import i18n from 'i18next';
import { CommonUtilities } from "../../../../shared/utils/commonUtilities";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/**
 * Device Create component.
 */
class CreateDeviceSubscription extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            isLoading: true,
            isInactive: true,
            formfields: {
                deviceSerialId: '',
                code: '',
                partId: '',
                expiration: '',                
                accountemail: '',
                accountId: ''                
            },
            formErrors: {
                deviceSerialId: '',
                partId: '',                
                accountemail: '',
                code: '',
            },
            formValid: false,
            emailValid: false,            
            deviceSerialIdValid: false,
            partIdValid: false,
            codeValid: false,
            alert: {
                type: null,
                message: null
            },
            isWaitingApiResponse: false,
            isErrorOnLoad: false,
            isClearable: false,
            accountemailEditing: false            
        }
    }

    componentDidMount() {
    }

    searchUserEmail = async (event, roleId) => {
        if (!CommonUtilities.isValidEmail(event.target.value)) {
            return null
        }       
        if (event.target.name === 'accountemail') {
            this.setState({
                accountemailEditing: false
            })
        }
       
        this.setState({
            isWaitingApiResponse: true
        })
        let email = this.state.formfields.accountemail;
        let params = "roleId=" + 2 + "&email=" + email + "&pageSize=10&currentPage=0";

        let response = await UserService.getUsers(params);
        let id = 0
        if (response.data.items.length > 0) {
            response.data.items.filter(function (item) {
                id = (item.email === email) ? item.id : 0
            })
        }
        let state = { accountId: id};
        this.setState((prevState) => ({
            ...prevState,
            formfields: { ...prevState.formfields, ...state },
            formErrors: {
                ...prevState.formErrors,
                accountemail: (id == 0) ? "Email Address does not match with any record!" : ''                
            },            
            emailValid: id === 0 ? false : true,
            isWaitingApiResponse: false
        }));

        
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        let fieldValidationErrors = this.state.formErrors;
        let formfields = this.state.formfields;
        let emailValid = this.state.emailValid;        
        let deviceSerialIdValid = this.state.deviceSerialIdValid;
        let partIdValid = this.state.partIdValid;
        let codeValid = this.state.codeValid;        
        let accountemailEditing = false
        formfields[name] = value;

     
        if (name === 'accountemail') {
            accountemailEditing = true
        }

        switch (name) {
            case "deviceSerialId":
                if (value.length === 0) {
                    deviceSerialIdValid = false
                    fieldValidationErrors.deviceSerialId = deviceSerialIdValid ? '' : i18n.t('deviceListCreateViewEditAndUpload.alert.serialNumber');
                } else {
                    deviceSerialIdValid = true
                    fieldValidationErrors.deviceSerialId = ''
                }
                break;

            case "partId":
                if (value.length === 0) {
                    partIdValid = false
                    fieldValidationErrors.partId = partIdValid ? '' : i18n.t('deviceListCreateViewEditAndUpload.alert.partNumber');
                } else {
                    partIdValid = true
                    fieldValidationErrors.partId = ''
                }
                break;

            case "code":
                if (value.length === 0) {
                    codeValid = false
                    fieldValidationErrors.code = codeValid ? '' : "Code" + i18n.t('deviceListCreateViewEditAndUpload.alert.minLength');
                } else {
                    codeValid = true
                    fieldValidationErrors.code = ''
                }
                break;        

            case "accountemail":
                if (value.length === 0) {
                    emailValid = false;
                    fieldValidationErrors.accountemail = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else {
                    emailValid = CommonUtilities.isValidEmail(value);
                    fieldValidationErrors.accountemail = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.emailInvalid');
                }
                break;

            default:
                break;
        }

        let isFormValid = (formfields.deviceSerialId && formfields.partId && formfields.code && formfields.accountemail) &&
            (fieldValidationErrors.deviceSerialId === '' && fieldValidationErrors.partId === '' 
                && fieldValidationErrors.accountemail === '' && fieldValidationErrors.code === '')  && !accountemailEditing;

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
            emailValid: emailValid,            
            deviceSerialIdValid: deviceSerialIdValid,
            partIdValid: partIdValid,
            codeValid: codeValid,
            accountemailEditing: accountemailEditing            
        });
    }

    /**
     * submit handler, this method calls respective service method to get the job done by communicating to backend.
     * @param {*} event 
     */
    submitHandler(event) {
        event.preventDefault();
        let that = this;
        that.setState({
            isWaitingApiResponse: true
        });
        DeviceService.createSubscriptionCode(this.state.formfields).then((res) => {
            that.setState({
                alert: {
                    type: "success",
                    message: i18n.t('deviceListCreateViewEditAndUpload.alert.deviceSubsCodeCreate')
                },
                isWaitingApiResponse: false,
            });
            this.resetDeviceFormFields();
        }, function (res) {
            let alertMsg = i18n.t('common.genericApiError');
            if (res && res.data && res.data.message) {
                alertMsg = res.data.message;
            }
            that.setState({
                alert: {
                    type: "danger",
                    message: alertMsg
                },
                isWaitingApiResponse: false
            });
        });
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * validates the form before submitting. 
     * save button should not be activated if invalid form.
     */
    isFormValid() {
        let isFormValid = (this.state.formfields.deviceSerialId && this.state.formfields.partId && this.state.formfields.code && this.state.formfields.accountemail) &&
            (this.state.formErrors.deviceSerialId === '' && this.state.formErrors.partId === '' 
                && this.state.formErrors.accountemail === '' && this.state.formErrors.code === '')
            && ((!this.state.accountemailEditing));
        return isFormValid;
    }

    /**
     * go back function. goes to previous view.
     */
    handleBack() {
        this.props.history.goBack();
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetDeviceFormFields() {
        this.setState({
            formfields: {
                deviceSerialId: '',
                partId: '',
                code: '',
                accountemail: '',                
                expiration: ''
            },
            formErrors: {
                deviceSerialId: '',
                partId: '',                
                accountemail: '',
                code: ''
            },
            formValid: false,
        });
    }

    dateHandleChange = (date) => {
        let expiration = new Date(date);
        this.setState({ formfields: { ...this.state.formfields, expiration: Math.round(expiration) }, isClearable: date ? true : false });
    }

    /**
     * encapsulate react render function
     */
    renderDeviceForm() {
        return (
            <div className="">
                <form autoComplete="off">
                    <h2> {i18n.t('deviceListCreateViewEditAndUpload.form.createSubscription')} </h2>
                    <div className="view-profile-details">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="code"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.subscode')} <StyledText uiText="*" /></label>
                                    <input type="text" required className="form-control" name="code"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.subscode')}
                                        value={this.state.formfields.code}
                                        onChange={this.handleUserInput} maxLength="60" />
                                    <FormErrors formErrors={this.state.formErrors.code} />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className={`form-group ${this.errorClass(this.state.formErrors.accountemail)}`}>
                                    <label htmlFor="accountemail"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.accountemail')} <StyledText uiText="*" /></label>
                                    <input type="text" required className="form-control" name="accountemail"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.accountemail')}
                                        value={this.state.formfields.accountemail}
                                        onBlur={(e) => this.searchUserEmail(e, 2)}
                                        onChange={this.handleUserInput} maxLength="320" />
                                    <FormErrors formErrors={this.state.formErrors.accountemail} />
                                </div>
                            </div>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="partId"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.partid')} <StyledText uiText="*" /></label>
                                    <input type="text" required className="form-control" name="partId"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.partid')}
                                        value={this.state.formfields.partId}
                                        onChange={this.handleUserInput} maxLength="40" />
                                    <FormErrors formErrors={this.state.formErrors.partId} />
                                </div>
                            </div> 
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="deviceSerialId"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.serialid')} <StyledText uiText="*" /> </label>
                                    <input type="text" className="form-control" name="deviceSerialId"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.serialid')}
                                        cols={50} rows={6}
                                        value={this.state.formfields.deviceSerialId}
                                        onChange={this.handleUserInput} maxLength="40" />
                                    <FormErrors formErrors={this.state.formErrors.deviceSerialId} />
                                </div>
                            </div>                           
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="row">                            
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="expiration"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.expiration')} </label>
                                    <DatePicker
                                        className="form-control expiratio-date"
                                        isClearable={this.state.isClearable}
                                        selected={this.state.formfields.expiration ? new Date(this.state.formfields.expiration) : ''}
                                        onChange={this.dateHandleChange}
                                    />
                                    <FormErrors formErrors={this.state.formErrors.expiration} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetDeviceFormFields.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.reset')}
                            </button>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.handleBack.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.isFormValid() || this.state.isWaitingApiResponse}
                                onClick={this.submitHandler.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.save')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>Back</button>
            </div>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: '', link: false },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.subscriptions'), className: '', link: false },
            { id: 'create', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.create'), className: '', link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderDeviceForm()
                }
            </div>
        );
    }
}

export default CreateDeviceSubscription;
