import React from "react";
import _ from 'lodash';
import { FormGroup, Label, Input } from 'reactstrap';
import i18n from 'i18next';
import BaseScreen from "../BaseScreen";
import { SCREENS } from "../../../constants/screens.constant";
import "../../css/app/login.scss";
import { AuthenticationServices } from "../../../services/service.authentication";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";
import { API_ERROR_CODES, SUPPORTED_LANGUAGES, DEFAULT_LANG, APP_BUILD_VERSION } from "../../../constants/app.constants";
import { FormErrors } from "../../../Component/SubmissionStatus";
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import { AppStorageHelper } from '../../../shared/utils/appStorageHelper';
import {customer_portal} from '../../../constants/app.constants'
/**
 * Login component.
 */
class Login extends BaseScreen {
  constructor(props) {
    super(props);

    if (this.isAuth()) {
      this.goToScreen(SCREENS.home);
    }

    this.state = {
      isBusy: false,
      formfields: {
        username: "",
        password: "",
      },
      formErrors: { username: "", password: "" },
      isFormValid: false,
      isApiResponseHandled: false,
      apiResponseMessage: "",
      isApiSuccessResponse: false,
      // for language selection
      selectedLanguage: DEFAULT_LANG,
      supportedLanguages: [],
    };
  }

  /**
   * on component mount, this function sets the suppported languages list and 
   * preferred language in component state.
   */
  componentDidMount() {
    this.setState({
      supportedLanguages: SUPPORTED_LANGUAGES,
      selectedLanguage: CommonUtilities.getPreferredLangCodeFromLocalStorage()
    });
  }

  /**
   * handler of language change event at login form
   * @param {*} e 
   */
  onLanguageChange(e) {
    let currentLng = this.state.selectedLanguage;
    let selectedLng = e.target.value;

    if (currentLng !== selectedLng) {
      AppStorageHelper.setPreferredLangToLocalStorage({
        prefLanguage: e.target.value
      });

      this.setState({ selectedLanguage: e.target.value });
      window.location.reload();
    }
  }

  /**
   * validate login form fields
   */
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let formfields = this.state.formfields;
    formfields[name] = value;

    let fieldValidationErrors = this.state.formErrors;

    let msg = "";
    switch (name) {
      case "username":
        if (value.length === 0) {
          msg = i18n.t('auth.alert.emailRequired');
        } else {
          let emailValid = CommonUtilities.isValidEmail(value);
          msg = emailValid ? "" : i18n.t('auth.alert.emailInvalid');
        }
        break;

      case "password":
        if (value.length === 0) {
          msg = i18n.t('auth.alert.required');
        }
        break;

      default:
        break;
    }

    fieldValidationErrors[name] = msg;
    let isFormValid = (formfields.username && formfields.password) &&
      (fieldValidationErrors.username === "") && (fieldValidationErrors.password === "");

    this.setState(
      {
        formfields: formfields,
        formErrors: fieldValidationErrors,
        isFormValid: isFormValid,
      }
    );
  }

  /**
   *  handles login button press event, communicates to backend to get valid access token
   */
  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isBusy: true });

    AuthenticationServices.login(this.state.formfields).then(
      (response) => {
        this.setState({
          isBusy: false,
          isApiResponseHandled: true,
          apiResponseMessage: "Success",
          isApiSuccessResponse: true
        });

        /**
         * move to previous accessed url (if available) or home page
         */
        let nextScreen = SCREENS.home;
        if (_.has(this.props, 'routerData.location.state.prevPath')) {
          let prevPath = this.props.routerData.location.state.prevPath;
          if (_.isString(prevPath) && prevPath.indexOf('support/view-log') > -1) {
            nextScreen = this.props.routerData.location.state.prevPath;
          }
        }

        this.goToScreen(nextScreen);
      },
      (error) => {
        let errMessage = i18n.t('common.genericApiError');
        let errorCode;
        if (error.data && error.data.message) {
          errMessage = error.data.message;
          errorCode = error.data.error;
        }

        this.setState({
          isBusy: false,
          isApiResponseHandled: true,
          apiResponseMessage: errMessage,
          isApiSuccessResponse: false
        });

        /**
         * if errorCode is "USR_24", redirect to force change password screen
         */
        if (errorCode === API_ERROR_CODES.USR_24) {
          this.props.routerData.history.push({
            pathname: '/' + SCREENS.forceChangePassword,
            search: "email=" + this.state.formfields.username + "&tempPassword=" + this.state.formfields.password,
          });
        }
      }
    );
  }

  /**
   * react render function to render login component.
   */
  render() {
    let logoSrc = process.env.PUBLIC_URL + '/assets/images/oem_logo.png?preventCaching=' + APP_BUILD_VERSION;
    return (
      <div className="container">
        <div className="loginContainer">
          <div style={{textAlign: "center", paddingBottom: "10px"}}>
            ADMINISTRATION SITE
          </div>
          <LoadingOverlay active={this.state.isBusy} customClass="login_loading_overlay">
            <div className="form-signin">
              <img src={logoSrc} alt="" className="mb-5 brand-logo" data-testid="brand-logo-image" />
              <div className="loginFormInner">
                {
                  (this.state.isApiResponseHandled && !this.state.isApiSuccessResponse) &&
                  <div className="danger-alert" data-testid="login-alert-message">
                    {this.state.apiResponseMessage}
                  </div>
                }
                <form autoComplete="off">
                  <div className="form-group">
                    <input type="email" className="form-control" name="username"
                      placeholder={i18n.t('auth.form.placeholder.emailEnter')}
                      value={this.state.formfields.username}
                      onChange={this.handleUserInput} 
                      data-testid="login-input-uname"/>
                    <FormErrors formErrors={this.state.formErrors.username} 
                      testId="login-uname"/>
                  </div>

                  <div className="form-group">
                    <input type="password" required className="form-control" name="password"
                      placeholder={i18n.t('auth.form.placeholder.password')}
                      value={this.state.formfields.password}
                      onChange={this.handleUserInput} 
                      data-testid="login-input-pwd"/>
                    <FormErrors formErrors={this.state.formErrors.password} />
                  </div>

                  <button type="submit" className="btn btn-primary btn-block"
                    disabled={!this.state.isFormValid}
                    onClick={this.handleSubmit} 
                    data-testid="sign-in-btn">
                    {i18n.t('auth.form.button.signIn')}
                  </button>
                </form>
              </div>
              <div className="mt-3 fgt-pass anchor-override">
                <a href="#/reset-password" data-testid="reset-pwd-link">
                  {i18n.t('auth.form.button.reset')}
                </a>
                <br />
                <br />
                <div className="container">
                  <FormGroup>
                    <Label for="language"> {i18n.t('common.langPreference')}: </Label>
                    <Input type="select"
                      id="language"
                      className="custom-select-override"
                      name="languageSelect"
                      onChange={(e) => { this.onLanguageChange(e); }}
                      value={this.state.selectedLanguage}>
                      <option key="placeHolderSelectLanguage" value="">{i18n.t('common.selectLang')}</option>
                      {
                        this.state.supportedLanguages.map((elem, id) => {
                          return (
                            <option key={elem.lng + id} value={elem.lng} className="custom-select-dropdown-option">
                              {elem.name}
                            </option>
                          );
                        })
                      }
                    </Input>
                  </FormGroup>
                </div>
              </div>
            </div>
          </LoadingOverlay>
          <div style={{textAlign: "center" }}>
            If you are a customer, please click <a href={customer_portal}> <u>customer portal</u> </a>.
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
