import React from 'react';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { FormErrors } from '../../../Component/SubmissionStatus';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import { StyledText } from '../../../Component/StyledText';
import i18n from 'i18next';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
// import { CommonUtilities } from '../../../shared/utils/commonUtilities';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';
/**
 * change password component.
 */
class ChangePassword extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      currentPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      formFields: {
        email: '',
        newPassword: '',
        confirmPassword: ''
      },
      formErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formValid: false,
      alert: {
        type: null,
        message: null
      },
      emailDisabled : true,
      isWaitingApiResponse: false
    };
  }

   /**
   * fetch Email Address for user's change password.
   */

  componentDidMount(){
    this.fetchEmail();
  }

 componentDidUpdate =(prevProps)=>{
   if(prevProps.match !== this.props.match){
    this.fetchEmail(); 
   }
 }

  fetchEmail = ()=>{
    let email = this.props.match.params.emailId;
    
    if(email){
      let isEmail =  this.validEmail(email);
      if(isEmail){
        this.setState({formFields: {...this.state.formFields ,email : email}});
      } else {
         UserService.getUser(email).then(user=>{
          this.setState({formFields: {...this.state.formFields ,email : user.data.email}});
         }).catch(e=>{
          this.setState({ alert: {
            type: 'danger',
            message: i18n.t('user.changePassword.alert.emailError')
          }});
         });
      }
    }
  }

 validEmail(e) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(e).search (filter) != -1;
}

  /**
   * go back to previous page.
   */
  goBack () {
    this.props.history.goBack();
  }

  /**
   * reset the form to previous state
   */
  resetFormFields () {
    this.setState({
      formFields: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formValid: false
    });
  }

  /**
   * submit handler when user press the save button.
   * @param {*} event
   */
  submitHandler (event) {
    event.preventDefault();
    let email = this.state.formFields.email;
    // api request/response
    const newPassword = this.state.formFields.newPassword;
    this.setState({
      isWaitingApiResponse: true
    });
    UserService.changeUserPassword(email, newPassword).then(
      response => {
        this.resetFormFields();
        this.setState({
          alert: {
            type: 'success',
            message: i18n.t('user.changePassword.alert.passwordUpdate')
          },
          isWaitingApiResponse: false
        });
      }, error => {
        let alertMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          alertMsg = error.data.message;
        }
        this.setState({
          alert: {
            type: 'danger',
            message: alertMsg
          },
          isWaitingApiResponse: false
        });
      });
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * toggle field from normal text to encrypted type.
   * @param {*} fieldName 
   * @param {*} event 
   */
  toggleFieldType (fieldName, event) {
    event.preventDefault();
    if (fieldName === 'currentPassword') {
      this.setState({ currentPasswordFieldType: this.state.currentPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'newPassword') {
      this.setState({ newPasswordFieldType: this.state.newPasswordFieldType === 'password' ? 'text' : 'password' });
    } else if (fieldName === 'confirmPassword') {
      this.setState({ confirmPasswordFieldType: this.state.confirmPasswordFieldType === 'password' ? 'text' : 'password' });
    }
  }

  /**
   * handle user input for validation purpose.
   * @param {*} event 
   */
  handleUserInput (event) {
    event.preventDefault();

    const name = event.target.name;
    const value = event.target.value;

    const formFields = this.state.formFields;
    formFields[name] = value;

    const fieldValidationErrors = { ...this.state.formErrors };

    const newPwd = formFields.newPassword;
    const cnfPwd = formFields.confirmPassword;

    let msg = '';

    switch (name) {
      case 'newPassword':
      case 'confirmPassword':
        if (value.length === 0) {
          msg = i18n.t('user.changePassword.alert.requiredField');
        } else if (value.length < 8) {
          msg = i18n.t('user.changePassword.alert.profilePasswordMin');
        } else if (name === 'newPassword' && cnfPwd) {
          if (cnfPwd !== value) {
            msg = i18n.t('user.changePassword.alert.profilePasswordNomatch');
          } else {
            fieldValidationErrors.confirmPassword = '';
          }
        } else if (name === 'confirmPassword' && newPwd) {
          if (newPwd !== value) {
            msg = i18n.t('user.changePassword.alert.profilePasswordNomatch');
          } else {
            fieldValidationErrors.newPassword = '';
          }
        }
        fieldValidationErrors[name] = msg;
        break;
      default:
        break;
    }

    const isFormValid = ( formFields.newPassword && formFields.confirmPassword) &&
      ( fieldValidationErrors.newPassword === '' &&
        fieldValidationErrors.confirmPassword === '');

    this.setState({
      formFields: formFields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid
    });
  }

  /**
   * react render function to render change password form
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('user.changePassword.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'users', displayName: i18n.t('user.changePassword.breadcrumb.users'), className: '',link :false },
      { id: 'change-password', displayName: i18n.t('user.changePassword.breadcrumb.cpassword'), className: '', link: false }
    ];
    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }
          <LoadingOverlay active={this.state.isWaitingApiResponse} text="Your new password is being processed.  This may take a moment">
            <div className=" change-password">
              <form autoComplete="off" list="autocompleteOff">
                <h2>{i18n.t('user.changePassword.cpassword')}</h2>
                <div className="row">
                  <div className="view-profile-details">
                    <div className="col-md-8 margin-auto">

                    <div className="form-group">
                        <div className="input-group">
                          <label htmlFor="newPassword"> {i18n.t('user.editAndViewProfile.form.label.email')} <StyledText uiText="*"/></label>
                          <input type="text"
                            className="form-control"
                            name="newPassword"
                            disabled={this.state.emailDisabled}
                            value={this.state.formFields.email}
                            />
                         
                        </div>
                     
                      </div>

                      <div className="form-group">
                        <div className="input-group">
                          <label htmlFor="newPassword"> {i18n.t('user.changePassword.form.label.newPassword')} <StyledText uiText="*"/></label>
                          <input type={this.state.newPasswordFieldType}
                            required
                            className="form-control"
                            name="newPassword" placeholder={i18n.t('user.changePassword.form.placeholder.newPassword')}
                            value={this.state.formFields.newPassword}
                            onChange={this.handleUserInput.bind(this)} minLength="8" />
                          <button className="btn input-group-addon" onClick={this.toggleFieldType.bind(this, 'newPassword')}>
                            <i className={'fa ' + (this.state.newPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                          </button>
                        </div>
                        <FormErrors formErrors={this.state.formErrors.newPassword} />
                      </div>
                      <div className="hr-line-dashed "></div>
                      <div className="form-group">
                        <div className="input-group">
                          <label htmlFor="confirmPassword"> {i18n.t('user.changePassword.form.label.confirmPassword')} <StyledText uiText="*"/></label>
                          <input type={this.state.confirmPasswordFieldType}
                            required
                            className="form-control"
                            name="confirmPassword" placeholder={i18n.t('user.changePassword.form.placeholder.confirmPassword')}
                            value={this.state.formFields.confirmPassword}
                            onChange={this.handleUserInput.bind(this)} minLength="8" />
                          <button className="btn input-group-addon" onClick={this.toggleFieldType.bind(this, 'confirmPassword')}>
                            <i className={'fa ' + (this.state.confirmPasswordFieldType === 'password' ? 'fa-eye-slash' : 'fa-eye')}></i>
                          </button>
                        </div>
                        <FormErrors formErrors={this.state.formErrors.confirmPassword} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-right f-btn">
                    <button type="button" className="btn btn-secondary mr-2"
                      onClick={this.resetFormFields.bind(this)}>
                      {i18n.t('user.changePassword.form.button.reset')}
                    </button>
                    <button type="button" className="btn btn-secondary mr-2"
                      onClick={this.goBack.bind(this)}>
                      {i18n.t('user.changePassword.form.button.back')}
                    </button>
                    <button type="submit" className="btn btn-primary"
                      disabled={this.state.formFields.email =="" || (!this.state.formValid || this.state.isWaitingApiResponse)}
                      onClick={this.submitHandler.bind(this)}>
                      {i18n.t('user.changePassword.form.button.save')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
