import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to create a document in backend.
 * @param {*} reqBody
 */
function createDocument (reqBody) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(API_INTERFACE.CREATE_DOCUMENT, header, reqBody);
}

/**
 * service to delete a document with id as documentId
 * @param {*} documentId 
 */
function deleteDocument (documentId) {
  const url = API_INTERFACE.DELETE_DOCUMENT + '/' + documentId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * service to fetch a document based on documentId
 * @param {*} documentId 
 */
function getDocument (documentId) {
  const url = API_INTERFACE.GET_DOCUMENT + '/' + documentId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to update a document.
 * @param {*} body
 */
function updateDocument (body) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(API_INTERFACE.UPDATE_DOCUMENT, header, body);
}

/**
 * service to get list of documents
 * @param {*} queryString 
 */
function getDocuments(queryString) {
  const url = API_INTERFACE.GET_DOCUMENTS + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * Service to upload document file to a url.
 * @param {*} url 
 * @param {*} fObject 
 */
function uploadFile(url, fObject) {
  const headers = {
    'Content-Type': 'binary/octet-stream'
  };
  return baseService.put(url, headers, fObject);
}

/**
 * service to get file download url for document file
 * @param {*} documentId 
 */
function getDownloadUrl(documentId) {
  const url = API_INTERFACE.GET_DOCUMENT_DOWNLOAD_URL + '/' + documentId;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

export const DocumentsService = {
  createDocument,
  uploadFile,
  deleteDocument,
  getDocument,
  updateDocument,
  getDocuments,
  getDownloadUrl
};
