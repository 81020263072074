import React from 'react';
import { SubmissionAlert } from '../../../Component/SubmissionStatus';
import CustomFileInput from '../../../Component/customFileInput/CustomFileInput';
import { FirmwareService } from '../../../services/service.firmwares';
import { FormErrors } from '../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../Component/loader/Loader";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';
import _ from 'lodash';

/**
 * create firmware component. encapsulate firmware creation functionalities.
 */
class CreateFirmware extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                version: '',
                description: '',
                deviceType: 'wheelman',
                filedata: {},
                isFilename: false
            },
            formErrors: { version: '', description: '' },
            versionIsValid: false,
            descriptionIsValid: false,
            isLoading: true,
            apiResponseIsHandled: false,
            apiResponseMessage: '',
            apiResponseIsSuccess: false,
            formreset: false,
            availableDeviceType: []
        }
    }

    componentDidMount() { 
        FirmwareService.fetchFirmwaresTypes().then(
            response=> {
                const types = _.isArray(response.data.types) ? response.data.types : [];
                this.setState({
                    availableDeviceType: types,
                    isLoading: false,
                });
            },
            error=> {}
        )
    }

    /**
     * handles when save button is pressed. Create firmware at backend or shows error.
     */
    createFirmwareOnSubmit = (event) => {
        event.preventDefault();
        FirmwareService.createFirmwareSubmission(this.state.formfields).then(
            (theResponse) => {
                let message = i18n.t('firmwareListCreateViewAndEdit.alert.firmwareCreate');
                this.setState({ apiResponseIsHandled: true, apiResponseMessage: message, apiResponseIsSuccess: true, isLoading:false });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({ apiResponseMessage: message, apiResponseIsHandled: true, apiResponseIsSuccess: false });
            }
        );
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        this.setState({ formfields: formfields },
            () => { this.validateField(name, value) });
    }

    /**
     * helper function for version string validation.
     */
    validateProvidedVersion = value => {
        let isValidVersion = false;
        if (value.length >= 1) {
            isValidVersion = value.match(/^[0-9]+[\\.][0-9]+[\\.][0-9]+[\\.][0-9]+[\\.][0-9]+$/i) ? true : false;
        }
        return isValidVersion;
    }

    /**
     * firmware form field validation
     * @param {*} fieldName 
     * @param {*} value 
     */
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let versionIsValid = this.state.versionIsValid;
        let descriptionIsValid = this.state.descriptionIsValid;
        switch (fieldName) {
            case 'version':
                if (value.length === 0) {
                    versionIsValid = false;
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.version');
                } else if (value.length > 32) {
                    versionIsValid = false;
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.char32');
                } else {
                    versionIsValid = this.validateProvidedVersion(value);
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.versionFormat');
                }
                break;
            case 'description':
                if (value.length === 0) {
                    descriptionIsValid = false;
                    fieldValidationErrors.description = descriptionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.description');
                } else {
                    descriptionIsValid = (value.length >= 1 && value.length <= 256);
                    fieldValidationErrors.description = descriptionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.char256');
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            versionIsValid: versionIsValid,
            descriptionIsValid: descriptionIsValid
        }, this.validateForm);
    }

    /**
     * check if form is valid
     */
    validateForm() {
        this.setState({ formValid: this.state.versionIsValid && this.state.descriptionIsValid && this.state.formfields.isFilename });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    /**
     * handles alert dismiss
     */
    onDismiss = () => {
        this.setState({ apiResponseIsHandled: false });
    }

    /**
     * handler for selecting file by openning file explorer
     */
    onSelection = (filedata) => {
        let formValid = this.state.versionIsValid && this.state.descriptionIsValid && true
        this.setState({
            formfields: {
                version: this.state.formfields.version,
                description: this.state.formfields.description,
                deviceType: this.state.formfields.deviceType,
                filedata: filedata,
                isFilename: true
            },
            formValid: formValid,
            formreset: false
        });
    }

    /**
     * reset function to reset the form to previous state.
     */
    onResetForm = () => {
        let formValid = false;
        this.setState({
            formfields: {
                version: '',
                description: '',
                deviceType: 'wheelman',
                filedata: {},
                isFilename: false
            },
            formValid: formValid,
            formreset: true,
            versionIsValid: false,
            descriptionIsValid: false
        });
    }

    /**
     * reset custom component of file selection.
     */
    resetCustomInput = () => {
        this.setState({ formreset: true })
    }

    /**
     * create firmware form
     */
    renderCreateFirmwareForm() {
        const { history } = this.props;
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'firmware', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmware'), className: '', link: false },
            { id: 'create', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.create'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <div className="float-left">
                        <BreadcrumbCustom breadcrumb={breadcrumb} />
                    </div>
                    <div className="clearfix"></div>
                    {this.state.apiResponseIsHandled && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                    <form className="createFirmwareForm" id="createFirmwareForm">
                        <h2> {i18n.t('firmwareListCreateViewAndEdit.form.createFirmware')} </h2>
                        <div className="view-profile-details">
                            <div className="row">
                                <div className="col-md-8 margin-auto">
                                    <div className="form-group">
                                        <label htmlFor="deviceType">{i18n.t('firmwareListCreateViewAndEdit.form.label.deviceType')} <StyledText uiText="*" /></label>
                                        <select className="form-control" id="deviceType" name="deviceType" onChange={this.handleUserInput} required 
                                        value={this.state.formfields.deviceType}>
                                            {
                                            this.state.availableDeviceType.map((elem, id) => {
                                                return <option key={elem.type + id} value={elem.type}>{elem.type}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                                <div className="col-md-8 margin-auto">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.version)}`}>
                                        <label htmlFor="version"> {i18n.t('firmwareListCreateViewAndEdit.form.label.version')} <StyledText uiText="*"/></label>
                                        <input type="text" required className="form-control" name="version"
                                            placeholder={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.version')}
                                            value={this.state.formfields.version}
                                            onChange={this.handleUserInput} />
                                        <FormErrors formErrors={this.state.formErrors.version} />
                                    </div>
                                </div>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                                <div className="col-md-8 margin-auto">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.description)}`}>
                                        <label htmlFor="description"> {i18n.t('firmwareListCreateViewAndEdit.form.label.description')} <StyledText uiText="*"/> </label>
                                        <textarea className="form-control" name="description"
                                            value={this.state.formfields.description}
                                            placeholder={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.description')}
                                            onChange={this.handleUserInput} cols={50} rows={6} />
                                        <FormErrors formErrors={this.state.formErrors.description} />
                                    </div>
                                </div></div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                                <div className="col-md-8 margin-auto">
                                    <CustomFileInput id="firmware" value={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.filename')} action="select" onSelection={this.onSelection}
                                        isFilename={this.state.formfields.isFilename} formreset={this.state.formreset} resetCustomInput={this.resetCustomInput} />
                                </div></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div>
                                    <button type="button" className="btn btn-secondary mr-2" onClick={history.goBack} > {i18n.t('firmwareListCreateViewAndEdit.form.button.back')} </button>
                                    <button type="button" className="btn btn-secondary mr-2" onClick={this.onResetForm.bind(this)} > {i18n.t('firmwareListCreateViewAndEdit.form.button.reset')} </button>
                                    <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this.createFirmwareOnSubmit} > {i18n.t('firmwareListCreateViewAndEdit.form.button.save')}  </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    /**
     * react render
     */
    render() {
        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return this.renderCreateFirmwareForm()
        }
    }
}
export default CreateFirmware;