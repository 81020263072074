import { API_INTERFACE } from '../constants/uri.constant';
import Method from './services';
import { AuthUtils } from './service.auth.utils';

const SEPARATOR = '/';

/**
 * service to create a device by using create device api.
 * @param {*} values
 */
function createSingleDevice(values) {
  const params = {
    serialNumber: values.serialNumber,
    partNumber: values.partNumber,
    oemId: values.oemId,
    activationCode: values.activationCode,
    manufactureData: values.manufactureData
  };
  const arr = [];
  arr.push(params);
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(API_INTERFACE.CREATE_DEVICE, header, arr);
}

/**
 * service to fetch list of created devices.
 * @param {*} values
 */
function fetchDevices(values) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.LIST_DEVICES + values,
    header
  );
}

/**
 * service to fetch a single device specific id.
 * @param {*} id
 */
function fetchDeviceById(id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.GET_DEVICE + SEPARATOR + id,
    headers
  );
}

/**
 * service to update a single device.
 * @param {*} values 
 */
function updateDeviceById(values) {
  const params = {
    serialNumber: values.serialNumber,
    partNumber: values.partNumber,
    oemId: values.oemId,
    activationCode: values.activationCode,
    manufactureData: values.manufactureData
  };
  const arr = [];
  arr.push(params);
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(
    API_INTERFACE.UPDATE_DEVICE,
    header,
    arr
  );
}

/**
 * Fetch upload url for device file.
 */
function getFileUploadUrl() {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.post(API_INTERFACE.UPLOAD_DEVICE, header);
}

/**
 * Service to upload device file to a url.
 * @param {*} url
 * @param {*} fObject
 */
function uploadFile(url, fObject) {
  const headers = {
    'Content-Type': 'binary/octet-stream'
  };
  return Method.put(url, headers, fObject);
}

/**
 * service to retrieve status of device file 
 * process based on fileName
 * @param {*} fileName
 */
function getFileStatus(fileName) {
  const url = API_INTERFACE.DEVICE_FILE_STATUS + '/' + fileName;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return Method.get(url, header);
}

/**
 * delete a specific device.
 * @param {*} id
 */
function deleteDeviceById(id) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.delete(
    API_INTERFACE.DELETE_DEVICE + id,
    header
  );
}

      /**
     * get a specific device subscriptions lists.
     * @param {*} id
     */
    function getDeviceSubscriptionsList(id) {
      const header = {
        'Content-Type': 'application/json',
        'x-api-key': API_INTERFACE.X_API_KEY,
        Authorization: AuthUtils.getAccessToken()
      };
      return Method.get(
        API_INTERFACE.DEVICE_SUBCRIPTION_LIST+id,
        header
      );
    }

    /**
 * service to create a device subscription code.
 * @param {*} values
 */
function createSubscriptionCode(values) {
  const params = {
    deviceSerialId: values.deviceSerialId,
    code: values.code,
    accountId: values.accountId,
    userId: values.userId,
    expiration: values.expiration,
    partId: values.partId
  };
  const arr = [];
  arr.push(params);
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.post(API_INTERFACE.CREATE_SUBSCODE, header, arr);
}

/**
 * service to fetch a single device specific id.
 * @param {*} id
 */
function fetchSubscriptionCodeById(id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.GET_SUBSCODE + id,
    headers
  );
}

/**
 * service to fetch a single device specific id.
 * @param {*} id
 */
function updateSubscription(data) {
  const params = {
    id: data.id,
    code: data.code,
    partId: data.partId,
    deviceSerialId: data.deviceSerialId,
    accountId: data.accountId,
    userId: data.userId,
    expiration: data.expiration
  };
 
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.put(
    API_INTERFACE.UPDATE_SUBSCODE,
    header,
    params
  );
}


      /**
     * delete a specific device subscriptions lists.
     * @param {*} id
     */
    function deleteDeviceSubcriptionById(id) {
      console.log("params----service" ,id)
      const header = {
        'Content-Type': 'application/json',
        'x-api-key': API_INTERFACE.X_API_KEY,
        Authorization: AuthUtils.getAccessToken()
      };
      return Method._deleteWithBody(
        API_INTERFACE.DELETE_SUBSCODE,
        header,
        id
      );
    }

    

export const DeviceService = {
  createSingleDevice,
  fetchDevices,
  fetchDeviceById,
  updateDeviceById,
  getFileUploadUrl,
  uploadFile,
  getFileStatus,
  deleteDeviceById,
  getDeviceSubscriptionsList,
  deleteDeviceSubcriptionById,
  createSubscriptionCode,
  fetchSubscriptionCodeById,
  updateSubscription
};
