import React from "react";
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import BaseScreen from "../BaseScreen";
import { SCREENS } from "../../../constants/screens.constant";
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../Component/SubmissionStatus";
import { DeviceService } from "../../../services/service.devices";
import { OemService } from "../../../services/service.oem";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';

/**
 * Device Create component.
 */
class CreateDevice extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            isLoading: true,
            isInactive: true,
            formfields: {
                serialNumber: '',
                oemId: '',
                partNumber: '',
                activationCode: '',
                manufactureData: '',
                activationStatus: '',
            },
            oems: [],
            formErrors: {
                serialNumber: '',
                partNumber: '',
                activationCode: '',
                manufactureData: '',
            },
            formValid: false,
            activationCodeType: 'password',
            alert: {
                type: null,
                message: null
            },
            isWaitingApiResponse: false,
            isErrorOnLoad: false,
        }
    }

    componentDidMount() {
        if (this.state.isAuthenticated) {
            OemService.fetchOEMS().then(
                (response) => {
                    this.setState({ oems: response.data });
                },
                (error) => {
                    this.setState({ oems: [] });
                }
            )
        }
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        let fieldValidationErrors = this.state.formErrors;
        let msg = "";
        switch (name) {
            case "serialNumber":
                if (value.length === 0) {
                    msg = i18n.t('deviceListCreateViewEditAndUpload.alert.serialNumber');
                }
                fieldValidationErrors.serialNumber = msg;
                break;
            case "partNumber":
                if (value.length === 0) {
                    msg = i18n.t('deviceListCreateViewEditAndUpload.alert.partNumber');
                }
                fieldValidationErrors.partNumber = msg;
                break;
            case "activationCode":
                if (value.length === 0) {
                    msg = i18n.t('deviceListCreateViewEditAndUpload.alert.activationCode');
                }
                fieldValidationErrors.activationCode = msg;
                break;
            case "manufactureData":
                if (value.length > 256) {
                    msg =  i18n.t('deviceListCreateViewEditAndUpload.alert.manufactureData');
                }
                fieldValidationErrors.manufactureData = msg;
                break;
            case "oemId":
                if (value === "-1") {
                    msg = "";
                }
                fieldValidationErrors.oemId = msg;
                break;
            default:
                break;
        }

        let isFormValid = (formfields.serialNumber && formfields.partNumber && formfields.activationCode && formfields.oemId && formfields.oemId !== "-1") &&
            (fieldValidationErrors.serialNumber === "" && fieldValidationErrors.partNumber === "" && fieldValidationErrors.activationCode === "");

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * submit handler, this method calls respective service method to get the job done by communicating to backend.
     * @param {*} event 
     */
    submitHandler(event) {
        event.preventDefault();
        let that = this;
        that.setState({
            isWaitingApiResponse: true
        });
        DeviceService.createSingleDevice(this.state.formfields).then(function (res) {
            let lastSavedFormFields = that.state.formfields;
            that.setState({
                alert: {
                    type: "success",
                    message: i18n.t('deviceListCreateViewEditAndUpload.alert.deviceCreate')
                },
                isWaitingApiResponse: false,
                savedFormFields: { ...lastSavedFormFields }
            });
        }, function (res) {
            let alertMsg = i18n.t('common.genericApiError');
            if (res && res.data && res.data.message) {
                alertMsg = res.data.message;
            }
            that.setState({
                alert: {
                    type: "danger",
                    message: alertMsg
                },
                isWaitingApiResponse: false
            });
        });

    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * validates the form before submitting. 
     * save button should not be activated if invalid form.
     */
    isFormValid() {
        let returnVal = (this.state.formValid);
        return returnVal;
    }

    /**
     * go back function. goes to previous view.
     */
    handleBack() {
        this.props.history.goBack();
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetDeviceFormFields() {
        this.setState({
            formfields: {
                serialNumber: '',
                oemId: '',
                partNumber: '',
                activationCode: '',
                manufactureData: '',
                activationStatus: ''
            },
            formErrors: {
                serialNumber: '',
                partNumber: '',
                activationCode: '',
                manufactureData: ''
            },
            formValid: false,
        });
    }

    /**
     * toggle password field from normal text to encrypted and vice-versa.
     * @param {*} fieldName 
     * @param {*} event 
     */
    toggleFieldType(fieldName, event) {
        event.preventDefault();
        if (fieldName === "activationCode") {
            this.setState({ activationCodeType: this.state.activationCodeType === "password" ? "text" : "password" });
        }
    }

    /**
     * encapsulate react render function
     */
    renderDeviceForm() {
        return (
            <div className="">
                <form autoComplete="off">
                    <h2> {i18n.t('deviceListCreateViewEditAndUpload.form.createDevice')} </h2>
                    <div className="view-profile-details">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="serialNumber"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.serialNumber')} <StyledText uiText="*"/></label>
                                <input type="text" required className="form-control" name="serialNumber"
                                    placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.serialNumber')}
                                    value={this.state.formfields.serialNumber}
                                    onChange={this.handleUserInput} maxLength="32" />
                                <FormErrors formErrors={this.state.formErrors.serialNumber} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="oemId"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.oem')} <StyledText uiText="*"/></label>
                                <select className="form-control" id="oemId" name="oemId" onChange={this.handleUserInput} value={this.state.formfields.oemId}>
                                    <option key="placeHolderSelectRole" value="-1">{i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.oem')}</option>
                                    {
                                        this.state.oems.map((elem, id) => {
                                            return <option key={elem.oemName + id} value={elem.oemId}>{elem.oemName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="partNumber"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.partNumber')} <StyledText uiText="*"/></label>
                                <input type="text" required className="form-control" name="partNumber"
                                    placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.partNumber')}
                                    value={this.state.formfields.partNumber}
                                    onChange={this.handleUserInput} maxLength="32" />
                                <FormErrors formErrors={this.state.formErrors.partNumber} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="activationCode"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.activationCode')} <StyledText uiText="*"/></label>
                                <div className="input-group">
                                    <input type={this.state.activationCodeType} required className="form-control" name="activationCode"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.activationCode')}
                                        autoComplete="new-password"
                                        value={this.state.formfields.activationCode}
                                        onChange={this.handleUserInput} maxLength="32" />
                                    <button onClick={this.toggleFieldType.bind(this, "activationCode")}>
                                        <i className={"fa " + (this.state.activationCodeType === "password" ? "fa-eye-slash" : "fa-eye")}></i>
                                    </button>
                                </div>
                                <FormErrors formErrors={this.state.formErrors.activationCode} />
                            </div>
                        </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="form-group">
                                <label htmlFor="manufactureData"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.manufactureData')} </label>
                                <textarea className="form-control" name="manufactureData"
                                    cols={50} rows={6}
                                    value={this.state.formfields.manufactureData}
                                    onChange={this.handleUserInput} maxLength="256" />
                                <FormErrors formErrors={this.state.formErrors.manufactureData} />
                            </div>
                        </div>
                    </div>
                    
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetDeviceFormFields.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.reset')}
                            </button>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.handleBack.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                                onClick={this.submitHandler.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.save')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>Back</button>
            </div>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: "home", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: "#/home", className: "", link: true },
            { id: "device", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: "", link: false },
            { id: "create", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.create'), className: "", link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderDeviceForm()
                }
            </div>
        );
    }
}

export default CreateDevice;
