import React from 'react';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import Grid from '../../../Component/grid/Grid';
import ModalDialog, { ModalWindowForAssignment } from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { VehiclesService } from '../../../services/service.vehicles';
import i18n from 'i18next';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';

/**
 * Informations to be shown in vehicle listing grid, along with options per rows.
 */
let vehicleListConfig = {};

/**
 * List vehicle component
 */
class ListVehicle extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize vehicleListConfig with updated i18n resources
    vehicleListConfig = {
      make: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.make'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      model: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.model'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      year: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.year'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      type: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.type'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      createdAt: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.createdAt'),
        sort: true,
        filter: false,
        display: true,
        isDate: true,
        filterMaxLength: 0
      },
      modifiedAt: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.modifiedAt'),
        sort: true,
        filter: false,
        display: true,
        isDate: true,
        filterMaxLength: 0
      },
      options: {
        label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.options.view')
          }, {
            actionType: 'link',
            action: 'edit',
            label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.options.edit')
          }, {
            actionType: '',
            action: 'assign_vehicle',
            label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.options.assign')
          }, {
            actionType: '',
            action: 'delete',
            label: i18n.t('vehicleListCreateViewAndEditAndAssignment.list.options.delete')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      vehiclesData: [],
      loader: true,
      pgnConfig: {
        currentPage: getListIndex(SCREEN_ID.ListVehicle),
        numberOfPageLinks: 0,
        countPerPage: 25, // default page size
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      reinitialize: false,

      defaultSortField: 'modifiedAt',
      defaultSortOrder: -1
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: this.state.pgnConfig.currentPage -1,
        pageSize: this.state.pgnConfig.countPerPage
      };
      this.getVehicleData(arg, this.state.sortFieldsConfig, this.state.filterFieldsConfig)
        .then(response => {
          const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
          this.setState({ vehiclesData: response.data.items, loader: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
        }, error => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              vehiclesData: [],
              loader: false,
              modalShow: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  componentWillUnmount () {
    setListIndex(SCREEN_ID.ListVehicle, this.state.pgnConfig.currentPage)
  }
  
  /**
   * fetch vehicles based on pagination, sort fields and filter
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  getVehicleData (requiredParams, sortFieldsObj, filterFieldsObj) {
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }
    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;
    let sortFiledsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }
    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFiledsQueryParams += '&' + sortQueryTemp;
      }
    }

    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    reqQueryParams += sortFiledsQueryParams + filterFielddQueryParams;

    return VehiclesService.getVehicles(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, vehiclesData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getVehicleData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          vehiclesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }

    this.setState({ loader: true, vehiclesData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems
    };

    this.getVehicleData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        vehiclesData: response.data.items,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          vehiclesData: [],
          loader: false,
          modalShow: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, vehiclesData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage
    };

    this.getVehicleData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);

        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          vehiclesData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/vehicle/view-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    } else if (actionType.action === 'edit') {
      return ({
        pathname: '/vehicle/edit-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    }
  }

  /**
   * per row option handler
   * @param {*} actionObject 
   * @param {*} row 
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('vehicleListCreateViewAndEditAndAssignment.form.modal.deleteMsg') + row.make + '?',
        modalTitle: i18n.t('vehicleListCreateViewAndEditAndAssignment.form.modal.deleteTitle'),
        modalAction: 'delete_vehicle'
      };
    } else if (actionObject.action === 'assign_vehicle') {
      modalInfo = {
        modalTitle: i18n.t('vehicleListCreateViewAndEditAndAssignment.form.modal.assignTitle'),
        modalAction: 'assign_vehicle'
      };
    }

    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   * handle modal delete action.
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_vehicle') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_vehicle') {
      this.deleteVehicle();
    }
  }

  /**
   * delete a vehicle from backend
   */
  deleteVehicle () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
      VehiclesService.deleteVehicle(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.vehiclesData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }
          this.setState({
            vehiclesData: [],
            alert: {
              type: 'success',
              message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleDelete')
            }
          });
          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };
          this.getVehicleData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              vehiclesData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                vehiclesData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function for redering a listing vehicles
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'vehicle', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.vehicle'), className: '', link: false },
      { id: 'list', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.list'), className: '', link: false }
    ];

    return (
      <div>
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={breadcrumb} />
          <div className="clearfix"></div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }
          <div>
            <Grid id="vehiclesListGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn="id"
              rowData={this.state.vehiclesData}
              reinitialize={this.state.reinitialize}
              columnDefs={vehicleListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
              actionPaths={this.actionPaths.bind(this)}
              defaultSortField={this.state.defaultSortField}
              defaultSortOrder={this.state.defaultSortOrder} />
          </div>
        </div>
        {
          this.state.modal.shouldRenderModal &&
          (this.state.modal.modalAction === 'assign_vehicle'
            ? <ModalWindowForAssignment
              modalTitle={this.state.modal.modalTitle}
              modalData={this.state.modal.modalData}
              isModalOpen={this.state.modal.modalShow}
              modalAction={this.onModalAction.bind(this)}
              handleModalDismiss={this.handleModalDismiss.bind(this)}
              onModalClosed={this.onModalClosed.bind(this)}
              modalType='vehicleAssignment'/>
            : <ModalDialog modalTitle={this.state.modal.modalTitle}
              modalMessage={this.state.modal.modalMsg}
              modalData={this.state.modal.modalData}
              isModalOpen={this.state.modal.modalShow}
              modalAction={this.onModalAction.bind(this)}
              handleModalDismiss={this.handleModalDismiss.bind(this)}
              onModalClosed={this.onModalClosed.bind(this)}
            />
          )
        }
      </div>);
  }
}

export default ListVehicle;
