import React from 'react';
import i18n from 'i18next';

/**
 * page loader UI
 * @param {*} props 
 */
const Loader = (props) => {
  return (
    <div className="loading-section">
      <div className="loading"></div>
      <p>{i18n.t('common.loaderMsg')}</p>
    </div>
  );
};
export default Loader;
