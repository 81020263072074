import { Component } from 'react';
import _ from 'lodash';
import { AppStorageHelper } from '../../shared/utils/appStorageHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

class BaseScreen extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: []
    };
    this.goToScreen = this.goToScreen.bind(this);
  }

  /**
   * check userLogin auth
   * return {Boolean}
   */
  isAuth () {
    const tokenInfo = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);

    if (tokenInfo && _.has(tokenInfo, 'acessToken') && _.has(tokenInfo, 'refresh_token')) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Description: To handle key events
   * @param {object}  Event
   * @return {null}
   */
  handleKey (event) { }

  /**
   * Description:This method must be called to switch/open another screen
   * @param {string} screenId
   * @param {object} params is meant to be sent to next screen if required in future.
   */
  goToScreen (screenId, params) {
    screenId = _.startsWith(screenId, '/') ? screenId : ('/' + screenId);
    
    // To be decided if we should call it via screen manager or not.
    if (this.props.routerData && this.props.routerData.history) {
      this.props.routerData.history.push({
        pathname: screenId,
        state: {
          prevPath: this.props.routerData.location.pathname
        }
      });
    } else if (this.props && this.props.history) {
      this.props.history.push({
        pathname: screenId,
        state: {
          prevPath: this.props.location.pathname
        }
      });
    }
  }
};

export default BaseScreen;
