import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import Grid from '../../../Component/grid/Grid';
import { FirmwareService } from '../../../services/service.firmwares';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import ModalDialog, { ModalDialogWithLazyDataLoading } from "../../../Component/modaldialog/ModalDialog";
import { SubmissionAlert } from '../../../Component/SubmissionStatus';
import i18n from 'i18next';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';
import { CommonConstants } from "../../../constants/app.constants";
import { SCREENS } from '../../../constants/screens.constant';
import { AuthenticationServices } from '../../../services/service.authentication';
import BaseScreen from '../BaseScreen';
/**
 * Informations to be shown in device listing grid, along with options per rows.
 */
let firmwareListConfig = {};

/**
 * List firmware component
 */
class ListFirmwares extends BaseScreen {
    constructor(props) {
        super(props);
        
        // initialize firmwareListConfig with updated i18n resources
        firmwareListConfig = {
            'version': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.version'),
                'sort': true,
                'filter': true,
                'display': true,
                'filterMaxLength': 50
            },
            'description': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.description'),
                'sort': true,
                'filter': false,
                'display': true,
                'filterMaxLength': 50
            },
            'createdAt': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.createdAt'),
                'sort': true,
                'filter': false,
                'display': true,
                'isDate': true,
                'filterMaxLength': 0
            },
            'modifiedAt': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.modifiedAt'),
                'sort': true,
                'filter': false,
                'display': true,
                'isDate': true,
                'filterMaxLength': 0
            },
            'options': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.options.options'),
                'sort': false,
                'filter': false,
                'display': true,
                'list': [
                    {
                        'actionType': 'link',
                        'action': 'view',
                        'label': i18n.t('firmwareListCreateViewAndEdit.list.options.view')
                    }, {
                        'actionType': 'link',
                        'action': 'edit',
                        'label': i18n.t('firmwareListCreateViewAndEdit.list.options.edit')
                    }, {
                        'actionType': '',
                        'action': 'delete',
                        'label':  i18n.t('firmwareListCreateViewAndEdit.list.options.delete')
                    }, {
                        'actionType': '',
                        'action': 'download',
                        'label': i18n.t('firmwareListCreateViewAndEdit.list.options.download')
                    }
                ]
            }
        };
        const defaultDeviceType = 'wheelman';
        this.state = {
            firmwares: [],
            isLoading: true,
            pgnConfig: {
                currentPage: getListIndex(SCREEN_ID.ListFirmwares),
                numberOfPageLinks: 0,
                countPerPage: 25,
                totalCount: 0,
                pageList: [25, 50, 100]
            },
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: "",
                modalMsg: "",
                modalData: "",
                modalAction: "",
                modalType: ""
            },
            sortFieldsConfig: {},
            filterFieldsConfig: {},
            reinitialize: false,
            defaultSortField: "modifiedAt",
            defaultSortOrder: -1,
            apiResponseIsSuccess: false,
            showMessage: false,
            apiResponseMessage: '',
            selectedDeviceType: defaultDeviceType,
            availableDeviceType: []
        };
    }

    componentDidMount() {
            FirmwareService.fetchFirmwaresTypes().then(
                response=> {
                    const types = _.isArray(response.data.types) ? response.data.types : [];
                    
                    this.setState({
                        availableDeviceType: types
                    });
                    let args = {
                        currentPage: this.state.pgnConfig.currentPage -1,
                        pageSize: this.state.pgnConfig.countPerPage,
                        deviceType: this.state.selectedDeviceType
                    };
                    this.getFirmwares(args).then(
                        (response) => {
                            let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
                            this.setState({
                                firmwares: response.data.items,
                                isLoading: false,
                                pgnConfig: pgnConfig
                            });
                            return response
                        },
                        (error) => {
                            let message = i18n.t('common.genericApiError');
                            if (error.data && error.data.message) {
                                message = error.data.message;
                            }
                            this.setState({
                                isLoading: false,
                                apiResponseMessage: message,
                                showMessage: true,
                                apiResponseIsSuccess: false
                            });
                        }
                    );
                },
                error=> {}
            )
    }

    createTypesArray (response) {
        let type = response.map(({ type }) => type)
        return type
    }
    componentWillUnmount () {
        setListIndex(SCREEN_ID.ListFirmwares, this.state.pgnConfig.currentPage)
    }

    /**
     * Method to fetch all Firmwares from backend.
     * @param {*} args 
     * @param {*} sortFieldsConfig 
     * @param {*} filterFieldsConfig 
     */
    getFirmwares(args, sortFieldsConfig, filterFieldsConfig) {
        let queryParams = "?currentPage=" + args.currentPage + "&pageSize=" + args.pageSize + "&deviceType=" + args.deviceType ;

        let sortFiledsQueryParams = '';
        if (!sortFieldsConfig) {
            sortFieldsConfig = this.state.sortFieldsConfig;
        }
        let sortQueryTemp = "";
        let key;
        for (key in sortFieldsConfig) {
            let sortValue = sortFieldsConfig[key]["sortOrder"];
            if (sortValue) {
                sortQueryTemp = "sortField=" + key + "&orderType=" + ((sortValue === 1) ? "asc" : "desc");
                sortFiledsQueryParams += "&" + sortQueryTemp;
            }
        }

        let filterFielddQueryParams = "";
        if (!filterFieldsConfig) {
            filterFieldsConfig = this.state.filterFieldsConfig;
        }
        let filterQueryTemp = "";
        for (key in filterFieldsConfig) {
            let filterValue = filterFieldsConfig[key]["value"];
            if (filterValue) {
                filterQueryTemp = key + "=" + filterValue;
                filterFielddQueryParams += "&" + filterQueryTemp;
            }
        }
        queryParams += sortFiledsQueryParams + filterFielddQueryParams;
        return FirmwareService.fetchFirmwares(queryParams).then(
            (response) => {
                return response
            }
        )
    }

    /**
     * delete a firmware based on passed id. 
     * @param {*} id 
     */
    deleteFirmware(id) {
        if (this.state.modal.modalData) {
            this.setState({
                isLoading: true,
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
            FirmwareService.deleteFirmwareById(id).then(
                (response) => {
                    this.setState({
                        //isLoading: true,
                        firmwares: [],
                        apiResponseMessage: i18n.t('firmwareListCreateViewAndEdit.alert.firmwareDelete'),
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                    let args = {
                        currentPage: this.state.pgnConfig.currentPage - 1,
                        pageSize: this.state.pgnConfig.countPerPage,
                        sortField: this.state.pgnConfig.sortField,
                        orderType: this.state.pgnConfig.orderType,
                        deviceType: this.state.selectedDeviceType
                    };
                    this.getFirmwares(args).then(
                        (response) => {
                            let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
                            this.setState({ firmwares: response.data.items, isLoading: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
                        }
                    );
                },
                (error) => {
                    let message = i18n.t('common.genericApiError');
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    }
                    this.setState({
                        isLoading: false,
                        apiResponseMessage: message,
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                }
            )
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * Modal window to show each row options.
     * @param {*} state 
     * @param {*} rowData 
     * @param {*} modalInfo 
     */
    modalPopup(state, rowData, modalInfo) {
        if (state) {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalMsg: modalInfo.modalMsg,
                    modalTitle: modalInfo.modalTitle,
                    modalData: rowData ? rowData : "",
                    modalAction: modalInfo.modalAction,
                    modalType: modalInfo.modalType
                },
            });
        } else {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalData: rowData ? rowData : "",
                    modalAction: this.state.modal.modalAction
                },
            });
        }
    }

     /**
     * modal dismissal action
     */
    handleModalDismiss() {
        this.modalPopup(false);
    }

    /**
     * handle modal delete action.
     * @param {*} data 
     */
    onModalAction(data) {
        let modalAction = this.state.modal.modalAction;
        if (modalAction === "delete") {
            this.modalPopup(false, data);
        } else if (modalAction === "download") {
            this.modalPopup(false, data);
        }
    }

     /**
     * function after modal is closed, re-render listing page after delete operation.
     */
    onModalClosed() {
        if (this.state.modal.modalAction === "delete") {
            this.deleteFirmware(this.state.modal.modalData.id);
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * helper function for pagination configuration
     * @param {*} currentPage 
     * @param {*} countPerPage 
     * @param {*} totalCount 
     */
    setPaginationConfig(currentPage, countPerPage, totalCount) {
        return {
            currentPage: currentPage,
            numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
            countPerPage: countPerPage,
            totalCount: totalCount,
            pageList: [...this.state.pgnConfig.pageList]
        }
    }

    /**
     * handler when page number change, like user move to next page or previous page from current page he is viewing
     * @param {*} pageNumber 
     */
    onPgnChange(pageNumber) {
        if (pageNumber !== this.state.pgnConfig.currentPage) {
            window.scrollTo(0, 0);
            this.setState({ isLoading: true, firmwares: [] });
            let arg = {
                currentPage: (pageNumber - 1),
                pageSize: this.state.pgnConfig.countPerPage,
                sortField: this.state.pgnConfig.sortField,
                orderType: this.state.pgnConfig.orderType,
                deviceType: this.state.selectedDeviceType
            };
            this.getFirmwares(arg).then(
                (response) => {
                    let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
                    this.setState({
                        firmwares: response.data.items,
                        isLoading: false,
                        pgnConfig: pgnConfig,
                        modalShow: false
                    });
                });
        }
    }

    /**
     * per row option handler
     * @param {*} actionObject 
     * @param {*} row 
     */
    optionHandler(actionObject, row) {
        let modalInfo;
        if (actionObject.action === "delete") {
            modalInfo = {
                modalMsg: i18n.t('firmwareListCreateViewAndEdit.form.modal.deleteMsg') + row.version,
                modalTitle: i18n.t('firmwareListCreateViewAndEdit.form.modal.deleteTitle'),
                modalAction: "delete"
            };
        } else if (actionObject.action === "download") {
            modalInfo = {
                modalMsg: i18n.t('firmwareListCreateViewAndEdit.form.modal.downloadMsg') + row.version,
                modalTitle: i18n.t('firmwareListCreateViewAndEdit.form.modal.downloadTitle'),
                modalAction: "download",
                modalType: "firmware_download"
            };
        }
        this.modalPopup(true, row, modalInfo);
    }

    /**
     * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
     * @param {*} event 
     */
    onPgnRowsCountChange(event) {
        window.scrollTo(0, 0);
        let perPageItems = Number(event.target.value);
        let currentPage;
        if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
            currentPage = 1;
        } else {
            currentPage = this.state.pgnConfig.currentPage;
        }
        this.setState({ isLoading: true, firmwares: [] });
        let args = {
            currentPage: currentPage - 1,
            pageSize: perPageItems,
            sortField: this.state.pgnConfig.sortField,
            orderType: this.state.pgnConfig.orderType,
            deviceType: this.state.selectedDeviceType
        };
        this.getFirmwares(args).then(
            (response) => {
                let pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
                this.setState({
                    firmwares: response.data.items,
                    isLoading: false,
                    pgnConfig: pgnConfig,
                    modalShow: false
                });
            });
    }

    /**
     * lazy load function when user use filter or sort functionality from grid header
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    onLazyLoad(sortFieldsObj, filterFieldsObj) {
        this.setState({ isLoading: true, usersData: [] });
        let pageNumber = 1;
        let arg = {
            currentPage: pageNumber - 1,
            pageSize: this.state.pgnConfig.countPerPage,
            deviceType: this.state.selectedDeviceType
        };
        this.getFirmwares(arg, sortFieldsObj, filterFieldsObj)
            .then(
                (response) => {
                    let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
                    if (!sortFieldsObj) {
                        sortFieldsObj = this.state.sortFieldsConfig;
                    }
                    if (!filterFieldsObj) {
                        filterFieldsObj = this.state.filterFieldsObj
                    }
                    this.setState({
                        firmwares: response.data.items,
                        isLoading: false,
                        pgnConfig: pgnConfig,
                        alert: {
                            type: null,
                            message: null
                        },
                        modalShow: false,
                        sortFieldsConfig: sortFieldsObj,
                        filterFieldsConfig: filterFieldsObj,
                    });
                }, (error) => {
                    let message = i18n.t('common.genericApiError');
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    }
                    this.setState({
                        isLoading: false,
                        apiResponseMessage: message,
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                }
            );
    }

    /**
     * routing to relevant page based on row option selection
     * @param {*} actionType 
     * @param {*} row 
     */
    actionPaths(actionType, row) {
        if (actionType.action === "view") {
            return ({
                pathname: '/firmware/view-firmware/' + row.id,
                state: { firmwares: row }
            });
        } else if (actionType.action === "edit") {
            return ({
                pathname: '/firmware/edit-firmware/' + row.id,
                state: { firmwares: row }
            });
        }
    }

     /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    onDeviceTypeChange (e) {
        const selectedDeviceType = e.target.value;
        this.setState({ isLoading: true, firmwares: [], selectedDeviceType: selectedDeviceType, reinitialize: true });
    
        const pageNumber = 1;
    
        const arg = {
          currentPage: pageNumber - 1,
          pageSize: this.state.pgnConfig.countPerPage,
          deviceType: selectedDeviceType
        };
    
        const sortFieldsObj = {};
        const filterFieldsObj = {};
    
        this.getFirmwares(arg, sortFieldsObj, filterFieldsObj).then(response => {
          const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
          this.setState(
            {
            //   userListConfig: userListOptions(selectedDeviceType),
              firmwares: response.data.items,
              isLoading: false,
              pgnConfig: pgnConfig,
              modalShow: false,
              selectedDeviceType: selectedDeviceType,
              sortFieldsConfig: sortFieldsObj,
              filterFieldsConfig: filterFieldsObj,
              reinitialize: false
            });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              firmwares: [],
              isLoading: false,
              modalShow: false,
              selectedDeviceType: selectedDeviceType,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
      }

    /**
     * react render function to render the list of firmwares.
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'firmware', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmware'), className: '', link: false },
            { id: 'list', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.list'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className="float-right">
                        <FormGroup>
                            <Input type="select"
                                className="custom-select-dropdown"
                                id="deviceType"
                                name="deviceType"
                                onChange={(e) => { this.onDeviceTypeChange(e); }}
                                value={this.state.selectedDeviceType} disabled={this.state.isLoading}>
                                {
                                    this.state.availableDeviceType.map((elem, id) => {
                                        return (
                                            <option key={elem.type + id} value={elem.type} className="custom-select-dropdown-option">{elem.type}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="clearfix"></div>
                    {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                    <div>
                        <Grid id="firmwaresListGrid"
                            keyColumn='id'
                            loading={this.state.isLoading}
                            columnDefs={firmwareListConfig}
                            rowData={this.state.firmwares}
                            reinitialize={this.state.reinitialize}
                            pagination={true}
                            pgnConfig={this.state.pgnConfig}
                            pageList={this.state.pageList}
                            lazyLoading={true}
                            onPgnChange={this.onPgnChange.bind(this)}
                            optionHandler={this.optionHandler.bind(this)}
                            onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
                            onLazyLoad={this.onLazyLoad.bind(this)}
                            actionPaths={this.actionPaths.bind(this)}
                            defaultSortField={this.state.defaultSortField}
                            defaultSortOrder={this.state.defaultSortOrder} />
                    </div>
                </div>
                    {
                        this.state.modal.shouldRenderModal 
                        ? 
                            ( this.state.modal.modalType === 'firmware_download' 
                            ? <ModalDialogWithLazyDataLoading
                                modalTitle={this.state.modal.modalTitle}
                                modalMessage={this.state.modal.modalMsg}
                                modalData={this.state.modal.modalData}
                                isModalOpen={this.state.modal.modalShow}
                                modalAction={this.onModalAction.bind(this)}
                                handleModalDismiss={this.handleModalDismiss.bind(this)}
                                onModalClosed={this.onModalClosed.bind(this)}
                                modalType={this.state.modal.modalType} /> 
                            : <ModalDialog
                                modalTitle={this.state.modal.modalTitle}
                                modalMessage={this.state.modal.modalMsg}
                                modalData={this.state.modal.modalData}
                                isModalOpen={this.state.modal.modalShow}
                                modalAction={this.onModalAction.bind(this)}
                                handleModalDismiss={this.handleModalDismiss.bind(this)}
                                onModalClosed={this.onModalClosed.bind(this)} />
                            ) 
                        : null}
            </div>
        );
    }
}
export default ListFirmwares;