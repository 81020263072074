import React from 'react';
import Grid from '../../../Component/grid/Grid';
import AlertMessage from '../../../Component/alert/AlertMessage';
import i18n from 'i18next';
import { UserService } from '../../../services/service.users';
import { VehiclesService } from '../../../services/service.vehicles';
import { userAssignmentListOptions } from '../user/UserHelperClass';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';

const USER_ROLE = {
  accountHolder_roleId: '2'
};

/**
 * List Users For Vehicle Assignment modal component, shows all account holders to assign a vehicle
 */
export default class ListUsersForVehicleAssignment extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      userListConfig: userAssignmentListOptions(),
      usersData: [],
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 25,
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      selectedRoleID: USER_ROLE.accountHolder_roleId,
      reinitialize: false,
      showMessage: false,
      apiResponseMessage: ''
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage,
        roleId: this.state.selectedRoleID
      };
      this.getUserData(arg, this.state.sortFieldsConfig, this.state.filterFieldsConfig).then(
        response => {
          const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
          this.setState({ usersData: response.data.items, isLoading: false, pgnConfig: pgnConfig, reinitialize: false });
        },
        error => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              usersData: [],
              isLoading: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /**
   * fetch users based on sort fields and filter object
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  getUserData (requiredParams, sortFieldsObj, filterFieldsObj) {
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize,
        roleId: this.state.selectedRoleID
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage,
        roleId: this.state.selectedRoleID
      };
    }
    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize + '&roleId=' + params.roleId;

    let sortFiledsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }
    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFiledsQueryParams += '&' + sortQueryTemp;
      }
    }
    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }
    let filterQueryTemp = '';
    const tempFilter = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }
    reqQueryParams += sortFiledsQueryParams + filterFielddQueryParams + tempFilter;
    return UserService.getUsers(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ isLoading: true, usersData: [] });
      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage,
        roleId: this.state.selectedRoleID
      };
      this.getUserData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          usersData: response.data.items,
          isLoading: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false
        });
      });
    }
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }
    this.setState({ isLoading: true, usersData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems,
      roleId: this.state.selectedRoleID
    };
    this.getUserData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        usersData: response.data.items,
        isLoading: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ isLoading: true, usersData: [] });
    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage,
      roleId: this.state.selectedRoleID
    };
    this.getUserData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }
        this.setState({
          usersData: response.data.items,
          isLoading: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            usersData: [],
            isLoading: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * dissmissal of alert handler
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * option handler for options associated with each row data.
   * @param {*} actionObject
   * @param {*} row
   */
  optionHandler (actionObject, row) {
    this.completeAssignment(row);
  }

  /**
   * handler for assignment of vehicle to a account holder user.
   * @param {*} row
   */
  completeAssignment (row) {
    VehiclesService.assignDefaultVehicleToAccountHolder(row.id, this.props.data.modalData).then(
      (response) => {
        let pageNumber = this.state.pgnConfig.currentPage;
        if ((this.state.usersData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
          pageNumber = this.state.pgnConfig.currentPage - 1;
        }
        this.setState({
          isLoading: true,
          usersData: [],
          alert: {
            type: 'success',
            message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleAssign')
          }
        });
        const arg = {
          currentPage: (pageNumber - 1),
          pageSize: this.state.pgnConfig.countPerPage
        };
        this.getUserData(arg).then((response) => {
          const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
          this.setState({
            usersData: response.data.items,
            isLoading: false,
            pgnConfig: pgnConfig,
            modal: {
              modalShow: false,
              shouldRenderModal: false
            }
          });
        });
      },
      (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            accountHoldersData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      }
    );
  }

  /**
   * react render func
   */
  render () {
    return (
      <div>
        <div className="container-fluid">
          <div className="clearfix"></div>
          {
            this.state.alert.message &&
                        <AlertMessage message={this.state.alert.message}
                          type={this.state.alert.type}
                          isAlertOpen={!!(this.state.alert.message)}
                          handleDismiss={this.handleAlertDismiss.bind(this)}
                        />
          }
          <div>
            <div>
              <Grid id="userForVehicleAssignmentListGrid"
                optionHandler={this.optionHandler.bind(this)}
                keyColumn="id"
                rowData={this.state.usersData}
                reinitialize={this.state.reinitialize}
                columnDefs={this.state.userListConfig}
                loading={this.state.isLoading}
                pagination={true}
                pgnConfig={this.state.pgnConfig}
                onPgnChange={this.onPgnChange.bind(this)}
                onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
                pageList={this.state.pageList}
                lazyLoading={true}
                onLazyLoad={this.onLazyLoad.bind(this)}
                singleOption={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
