import React, { Component } from 'react';
import { Collapse } from "react-collapse";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import _ from 'lodash';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import { VehiclesService } from    "../../../services/service.vehicles";
import { CalibrationsService } from "../../../services/service.calibrations";
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../Component/SubmissionStatus";
import { StyledText } from "../../../Component/StyledText";
import { CommonConstants } from "../../../constants/app.constants";
import i18n from 'i18next';
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";


/**
 * max file size allowed for upload.
 */
const MAX_CSV_FILE_SIZE_BYTES = 1024 * 1024;

const FILE_PROCESSING_STATUS_DELAY = 3000; // 3 seconds


/**
 * create vehicle components. holds all functionalities for creating a vehicle.
 */
class CreateVehicle extends Component {
  constructor(props) {
    super(props);

    let defaultFormFields = {
      make: "",
      model: "",
      year: "",
      type: "",
      localId: "",
      steeringWheelType: "",
      wheelbase: "",
      antennaHeight: "",
      antennaPivot: "",
      antennaOffset: "",
      frontHitch: "",
      rearHitch: "",
      attack: 70,
      backlash: 0,
      sensitivity: 50,
      profileVersion: "",
      ecuOrientation: "",
      xyStickeFace: "", 
      cableAttachDirection: "",
      aLeftRadius: 0,
	  aRightRadius: 0,
	  bLockRight: 0,
	  bLockLeft: 0,
	  cPitchBias: 0,
	  cRollBias: 0,
	  dSteeringRatio: 0
    };

    this.state = {
      formfields: { ...defaultFormFields },
      formErrors: {
        make: "",
        model: "",
        year: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
        aLeftRadius: "",
	    aRightRadius: "",
	    bLockRight: "",
	    bLockLeft: "",
	    cPitchBias: "",
	    cRollBias: "",
	    dSteeringRatio: ""
      },
      formValid: false,
      shouldCreateSingleVehicle: false,
      shouldAddCalibration: false,
      aTabEnabled: true,
      bTabEnabled: true,
      cTabEnabled: true,
      dTabEnabled: true,
      savedVehicleId:0,

      /**
       * csv file data
       */
      vehicleCsvFileObj: "",
      vehicleCsvFileName: "",
      vehicleCsvFileFieldError: "",
      isValidCsvSelected: false,

      alert: {
        type: null,
        message: null
      },

      isWaitingApiResponse: false,
      savedFormFields: { ...defaultFormFields },
      activeTab : 1
    }

	this.vehicleTypes = CommonConstants.getVehicleTypes();
    this.calibTypes = CommonConstants.getCalibrationTypes();
    this.ecuOrientationTypes = CommonConstants.getEcuOrientationTypes();
    this.xyStickeFaceTypes = CommonConstants.getXYStickeFace();
    this.cableAttachDirectionTypes = CommonConstants.getCableAttachDirection();
    this.csvFileInputRef = React.createRef();

    // for processing status of vehicle csv file 
    this.processingStatusTimer = null;
    this.isFileStatusApiInProgress = false;
    this.backendFileName = '';

    this._isMounted = false;
  }

  /**
   * React life cycle method, called after component is first rendered.
   */
  componentDidMount() {
    this._isMounted = true;
  }

  /**
   * cleanup resources 
   */
  componentWillUnmount() {
    this._isMounted = false;
    this.stopTimer();
  }

  /**
   * Stop and clear interval timer. 
   */
  stopTimer = () => {
    clearInterval(this.processingStatusTimer);
    this.processingStatusTimer = null;
  }

  setActiveTab = (index) => {
	this.setState({
		activeTab : index
	});	
  }
  
  updateCalibTabs(vType){
	  if(vType === 'STANDARD' || vType === 'MFWD' || vType === 'SPRAYER'){
	  		this.setState({aTabEnabled:false, bTabEnabled:false, cTabEnabled:false, dTabEnabled:true});
	  }else if(vType === "ARTICULATED" || vType === 'COMBINE'){
	  		this.setState({aTabEnabled:false, bTabEnabled:false, cTabEnabled:false, dTabEnabled:false});
	  }else if(vType === "RICE_TRANSPLANTER" || vType === 'ONION_PLANTER'){
	  		this.setState({aTabEnabled:true, bTabEnabled:true, cTabEnabled:false, dTabEnabled:true});
	  }else{
		//nothing
	  }
	}
    
  /**
   * handle user input for validation purpose.
   */
  handleUserInput = (e) => {
    let name = e.target.name;
    if (name === "vehicleCSVFilename") {
      /**
       * handle csv file field
       */
      this.handleCsvFile(e);
    } else if (name === "createSingleVehicle") {
      /**
       * handle checkbox
       */
      this.handleCreateSingleVehicle(e);
    } else if (name === "AddCalibration") {
      /**
       * handle checkbox
       */
      this.handleAddCalibration(e);
    }else {
      /**
       * handle form fields
       */
      this.handleVehicleFormFields(e);
    }
  }

  /**
   * enable and disabple the calibration panel.
   * @param {*} event 
   */
  handleAddCalibration(event){
	let checked = event.target.checked;
	this.setState({
        shouldAddCalibration: checked
      });
	}
  
  /**
   * validate vehicle create form either from CSV file or form fields.
   * @param {*} event 
   */
  handleCreateSingleVehicle(event) {
    let checked = event.target.checked;
    let isFormValid;
    if (checked) {
      // if "createSingleVehicle" is true, clear input file selection
      if (this.csvFileInputRef && this.csvFileInputRef.current) {
        this.csvFileInputRef.current.value = "";
      }

      let fieldValidationErrors = this.state.formErrors;
      isFormValid = fieldValidationErrors.make === "" && fieldValidationErrors.model === "" &&
        fieldValidationErrors.type === "" && fieldValidationErrors.attack === "" && fieldValidationErrors.backlash === "" && fieldValidationErrors.sensitivity === "";
      this.setState({
        shouldCreateSingleVehicle: checked,
        formValid: isFormValid,
        vehicleCsvFileFieldError: "",
        vehicleCsvFileObj: "",
        vehicleCsvFileName: ""
      });
    } else {
      isFormValid = !!this.state.vehicleCsvFileObj && !!this.state.vehicleCsvFileName && !!this.state.isValidCsvSelected;
      this.setState({
        shouldCreateSingleVehicle: checked,
        formValid: isFormValid
      });
    }
  }

  /**
   * helper function to validate postive decimal values entered.
   * @param {*} num 
   */
  validateDecimalNumbers(num) {
    if ((num.length > 0) &&
      ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
        !(/^\d+(\.\d{1,6})?$/.test(num)))) {
      return false;
    }
    return true;
  }

  
  /**
   * handles and validate form fields entry.
   * @param {*} event 
   */
  handleVehicleFormFields(event) {
    const name = event.target.name;
    const value = event.target.value;

    let formfields = this.state.formfields;
    formfields[name] = value;

    let fieldValidationErrors = this.state.formErrors;
    let msg = "";

    switch (name) {
      case 'make':
      case 'model':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if (value.length > 32) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char32');
        }
        break;

      case 'year':
        let parsedValue = parseInt(value, 10);
        if ((value.length > 0) && (!(/^\d+$/.test(value)) || (parsedValue < 1970) || (parsedValue > 9999))) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.year');
        }
        break;

      case 'type':
        if (value === "") {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.type');
        }
        this.updateCalibTabs(value);
        break;

      case 'localId':
        if (value.length > 256) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char256');
        }
        break;

      case 'steeringWheelType':
        if (value.length > 32) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char32');
        }
        break;

      case 'wheelbase':
      case 'antennaHeight':
      case 'frontHitch':
      case 'rearHitch':
      case 'aLeftRadius':
      case 'aRightRadius':
      case 'bLockRight':
      case 'bLockLeft':
      case 'cPitchBias':
      case 'cRollBias':
      case 'dSteeringRatio':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        }
        break;
      case 'antennaPivot':
      case 'antennaOffset':
        if ((value.length > 0) && !CommonUtilities.validateDecimalNum(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimalAll');
        }
        break;
      case 'attack':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length >= 1) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 100 || value < 1) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.attack');
        }
        break;

      case 'backlash':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 90 || value < 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.backlash');
        }
        break;

      case 'sensitivity':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 100 || value < 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.sensitivity');
        }
        break;

      case 'profileVersion':
        if (value.length > 64) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char64');
        }
        break;

      default:
        break;
    }

    fieldValidationErrors[name] = msg;

    let isFormValid = (formfields.make && formfields.model && formfields.type
      && formfields.attack && !CommonUtilities.isEmpty(formfields.backlash) && !CommonUtilities.isEmpty(formfields.sensitivity)) &&
      (fieldValidationErrors.make === "" && fieldValidationErrors.model === "" && fieldValidationErrors.year === "" &&
        fieldValidationErrors.type === "" && fieldValidationErrors.localId === "" && fieldValidationErrors.steeringWheelType === "" &&
        fieldValidationErrors.wheelbase === "" && fieldValidationErrors.antennaHeight === "" && fieldValidationErrors.antennaPivot === "" &&
        fieldValidationErrors.antennaOffset === "" && fieldValidationErrors.frontHitch === "" && fieldValidationErrors.rearHitch === "" &&
        fieldValidationErrors.profileVersion === "");

    if (this.state.shouldCreateSingleVehicle) {
      this.setState({
        formfields: formfields,
        formErrors: fieldValidationErrors,
        formValid: isFormValid,
      });
    } else {
      this.setState({
        formfields: formfields,
        formErrors: fieldValidationErrors,
      });
    }
  }

  /**
   * helper func to handle and validate CSV file input.
   * @param {*} event 
   */
  handleCsvFile(event) {
    event.preventDefault();
    let fileObj, fileName = "", isValidCsvFileExtension, isValidCsvFileSize, formValid, vehicleCsvFileFieldError = "";
    if (event.target.files && event.target.files.length > 0) {
      fileObj = event.target.files[0];
      fileName = event.target.files[0].name;
      isValidCsvFileExtension = /.+(\.csv)$/.test(fileName);
      isValidCsvFileSize = (fileObj.size <= MAX_CSV_FILE_SIZE_BYTES);
      formValid = !!fileObj && !!fileName && isValidCsvFileExtension && isValidCsvFileSize;
      vehicleCsvFileFieldError = "";

      if (!formValid) {
        if (!isValidCsvFileExtension) {
          vehicleCsvFileFieldError = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.csvFile');
        } else if (!isValidCsvFileSize) {
          vehicleCsvFileFieldError = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.fileSize');
        }
      }

      if (!this.state.shouldCreateSingleVehicle) {
        this.setState({
          vehicleCsvFileObj: fileObj,
          vehicleCsvFileName: fileName,
          formValid: formValid,
          vehicleCsvFileFieldError: vehicleCsvFileFieldError,
          isValidCsvSelected: formValid
        });
      } else {
        this.setState({
          vehicleCsvFileObj: fileObj,
          vehicleCsvFileName: fileName,
          vehicleCsvFileFieldError: vehicleCsvFileFieldError,
          isValidCsvSelected: formValid
        });
      }

      // clear input type file field, to prevent the issue when 
      // reselecting the same file again and saving to backend
      if (this.csvFileInputRef && this.csvFileInputRef.current) {
        this.csvFileInputRef.current.value = "";
      }
    }
  }

  /**
   * handles creation of vehicles from valid form fields or using CSV files.
   * @param {*} event 
   */
  handleSubmit = (event) => {
    event.preventDefault();

    // set the loader on
    this.setState({
      isWaitingApiResponse: true
    });

    if (this.state.shouldCreateSingleVehicle) {
      this.createVehicleFromFormFields();
    } else {
      this.createVehiclesFromFile();
    }
  }

  getCalibRequestBody(vehicleId){
	let calibReqBody;
	let commonCalibObj = {
		"calibrationType": "",
        "leftRadius": 0,
        "lockToLockLeft": 0,
        "lockToLockRight": 0,
        "pitchBias": 0,
        "rightRadius": 0,
        "rollBias": 0,
        "tiltPointALat": 0.0,
        "tiltPointALong": 0.0,
        "tiltPointBLat": 0.0,
        "tiltPointBLong": 0.0,
        "vehicleId": vehicleId
	};
	
	const aObject = {...commonCalibObj , ...{
			"calibrationType": "A",
        	"leftRadius": this.state.formfields.aLeftRadius,
        	"rightRadius": this.state.formfields.aRightRadius
		}};
	
	const bObject = {...commonCalibObj , ...{
			"calibrationType": "B",
        	"lockToLockLeft": this.state.formfields.bLockLeft,
        	"lockToLockRight": this.state.formfields.bLockRight
		}};	
	
	const cObject = {...commonCalibObj , ...{
		"calibrationType": "C",
        "pitchBias": this.state.formfields.cPitchBias,
        "rollBias":this.state.formfields.cRollBias    
		}};	
	
	const dObject = {...commonCalibObj , ...{
		"calibrationType": "D",
        "steeringRatio": this.state.formfields.dSteeringRatio  
		}};	
		
	if(this.state.formfields.type === 'ARTICULATED' ||
		this.state.formfields.type === 'COMBINE'){
		calibReqBody = [ aObject, bObject, cObject, dObject ];
	}else if(this.state.formfields.type === 'STANDARD' ||
		this.state.formfields.type === 'MFWD' || 
		this.state.formfields.type === 'SPRAYER') {
		calibReqBody = [ aObject, bObject, cObject ];
	}else if(this.state.formfields.type === 'RICE_TRANSPLANTER' ||
		this.state.formfields.type === 'ONION_PLANTER' ) {
		calibReqBody = [ cObject ];
	}else{
		calibReqBody = [ aObject, bObject, cObject, dObject ];
	}
	return calibReqBody;	
}
	  
  /**
   * create single vehicle using valid form field values
   */
  createVehicleFromFormFields = () => {
    let lastSavedFormFields = this.state.formfields;
    let reqBody = [{
      make: this.state.formfields.make,
      model: this.state.formfields.model,
      year: this.state.formfields.year,
      type: this.state.formfields.type,
      localId: this.state.formfields.localId,
      steeringWheelType: this.state.formfields.steeringWheelType,
      wheelbase: this.state.formfields.wheelbase,
      antennaHeight: this.state.formfields.antennaHeight,
      antennaPivot: this.state.formfields.antennaPivot,
      antennaOffset: this.state.formfields.antennaOffset,
      frontHitch: this.state.formfields.frontHitch,
      rearHitch: this.state.formfields.rearHitch,
      attack: this.state.formfields.attack,
      backlash: this.state.formfields.backlash,
      sensitivity: this.state.formfields.sensitivity,
      profileVersion: this.state.formfields.profileVersion,
      ecuOrientation: "CONNECTOR_" + this.state.formfields.cableAttachDirection.toLowerCase() + "_TOP_" + this.state.formfields.xyStickeFace.toLowerCase()
    }];

	VehiclesService.createVehicle(reqBody).then(res => {
      this.state.formfields.profileVersion = res.data[0].profileVersion;
      this.setState({
        alert: {
          type: "success",
          message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleCreate')
        },
        isWaitingApiResponse: false,
        savedFormFields: { ...lastSavedFormFields }	
      });
      
      if(res.data[0].id != 0  && this.state.shouldAddCalibration){
		CalibrationsService.createCalibration(this.getCalibRequestBody(res.data[0].id)).then( calibResp => {
			this.setState({
	        alert: {
	          type: "success",
	          message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.calibrationCreate')
	        },
	        isWaitingApiResponse: false	                
	      });
	      
		}, err => {
	      this.handleApiError(err);
	    }		
		);
    }
            	
    }, err => {
      this.handleApiError(err);
    });   
    
  }
  
  
  /**
   * Create vehicles using csv file.
   */
  createVehiclesFromFile = () => {
    // get the url for file upload
    VehiclesService.getFileUploadUrl().then(res => {
      let uploadUrl = res.data.url;

      // extract backend {fileName} (to be used in status api call) from the uploadUrl
      this.backendFileName = CommonUtilities.getFileNameFromUrl(uploadUrl);

      // call backend api to upload vehicle csv file
      VehiclesService.uploadFile(uploadUrl, this.state.vehicleCsvFileObj).then(res => {
        this.setState({
          alert: {
            type: "success",
            message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleCSVCreate')
          }
        });

        // after successful file upload, turn on the timer to 
        // check file processing status
        if (!this.processingStatusTimer && this._isMounted) {
          this.isFileStatusApiInProgress = false;
          this.processingStatusTimer = setInterval(() => {
            this.checkFileProcessingStatus();
          }, FILE_PROCESSING_STATUS_DELAY);
        }
      }, err => {
        this.handleApiError(err);
      });
    }, err => {
      this.handleApiError(err);
    });
  }

  /**
   * Handle api error and display failure message.
   * @param {*} apiError
   */
  handleApiError = (apiError) => {
    let alertMsg = i18n.t('common.genericApiError');

    if (_.has(apiError, 'data.message')) {
      alertMsg = apiError.data.message;
    }

    this.setState({
      alert: {
        type: "danger",
        message: alertMsg
      },
      isWaitingApiResponse: false
    });
  }

  /**
   * Check processing status of vehicle csv file at given 
   * interval. Handle success/error status for vehicle file.
   */
  checkFileProcessingStatus = () => {
    // see if a status api call is already pending
    if (this.isFileStatusApiInProgress) {
      return;
    }

    this.isFileStatusApiInProgress = true;

    VehiclesService.getFileStatus(this.backendFileName).then(res => {
      if ((res.status === 200) && !(_.has(res, 'data.error'))) {
        this.setState({
          alert: {
            type: "success",
            message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleCSVProcessed')
          },
          isWaitingApiResponse: false
        });
        this.stopTimer();
      } else {
        this.isFileStatusApiInProgress = false;
      }
    }, err => {
      this.handleApiError(err);
      this.stopTimer();
    });
  }

  /*
   alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * reset vehicle form fields to previous value.
   */
  resetVehicleFormFields() {
    let prevSavedFormFields = this.state.savedFormFields, isFormValid = false;

    if (this.state.shouldCreateSingleVehicle) {
      isFormValid = prevSavedFormFields.make !== "" && prevSavedFormFields.model !== "" && prevSavedFormFields.type !== ""
        && prevSavedFormFields.attack !== "" && prevSavedFormFields.backlash !== "" && prevSavedFormFields.sensitivity !== "";
    }

    let updatedFieldErrors = this.updateOptionalFormFieldErrors(prevSavedFormFields);
    if (this.csvFileInputRef && this.csvFileInputRef.current) {
      this.csvFileInputRef.current.value = "";
    }

    this.setState({
      formfields: { ...prevSavedFormFields },
      formValid: isFormValid,
      formErrors: updatedFieldErrors,
      vehicleCsvFileFieldError: "",
      vehicleCsvFileObj: "",
      vehicleCsvFileName: ""
    });
  }

  /**
   * validate optional form fields.
   * @param {*} prevSavedFormFields 
   */
  updateOptionalFormFieldErrors(prevSavedFormFields) {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.year = prevSavedFormFields.year ? fieldValidationErrors.year : "";
    fieldValidationErrors.localId = prevSavedFormFields.localId ? fieldValidationErrors.localId : "";
    fieldValidationErrors.steeringWheelType = prevSavedFormFields.steeringWheelType ? fieldValidationErrors.steeringWheelType : "";
    fieldValidationErrors.wheelbase = prevSavedFormFields.wheelbase ? fieldValidationErrors.wheelbase : "";
    fieldValidationErrors.antennaHeight = prevSavedFormFields.antennaHeight ? fieldValidationErrors.antennaHeight : "";
    fieldValidationErrors.antennaPivot = prevSavedFormFields.antennaPivot ? fieldValidationErrors.antennaPivot : "";
    fieldValidationErrors.antennaOffset = prevSavedFormFields.antennaOffset ? fieldValidationErrors.antennaOffset : "";
    fieldValidationErrors.frontHitch = prevSavedFormFields.frontHitch ? fieldValidationErrors.frontHitch : "";
    fieldValidationErrors.rearHitch = prevSavedFormFields.rearHitch ? fieldValidationErrors.rearHitch : "";
    fieldValidationErrors.profileVersion = prevSavedFormFields.profileVersion ? fieldValidationErrors.profileVersion : "";

    return fieldValidationErrors;
  }

  /**
   * go back to previous page
   */
  goBack() {
    this.props.history.goBack();
  }

  /**
   * render create vehicle form
   */
  renderCreateVehicleForm() {
    return (
      <div>
        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
            testId="create-vehicle"
          />
        }

        <LoadingOverlay active={this.state.isWaitingApiResponse}>
          <div className="container-fluid p-4">
            <form>
              <h2> {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.createVehicle')} </h2>
              <div className="view-profile-details width-70per">
                <div className="row">
                  {/* form elements for Vehicle csv file */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.file')} </label>
                      <input type="text" disabled className="form-control upload-btn-filename"
                        value={this.state.vehicleCsvFileName} />
                      <FormErrors formErrors={this.state.vehicleCsvFileFieldError} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="upload-btn-wrapper">
                      <input title="" type="file" name="vehicleCSVFilename" onChange={this.handleUserInput}
                        disabled={this.state.shouldCreateSingleVehicle} ref={this.csvFileInputRef} />
                      <button data-testid="create-vehicle-file-btn"
                        type="button"
                        className="btn btn-primary"
                        disabled={this.state.shouldCreateSingleVehicle}>
                        {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.chooseFile')}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input type="checkbox" name="createSingleVehicle"
                        value={"no"}
                        onChange={this.handleUserInput} /> &nbsp;&nbsp;
                     <label htmlFor="createSingleVehicle">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.selectToAddVehicle')}</label>
                    </div>
                  </div>

                  {/* form elements for Vehicle */}
                  <Collapse isOpened={this.state.shouldCreateSingleVehicle}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="make">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.make')} <StyledText uiText="*" /></label>
                          <input type="text" required className="form-control" name="make"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.make')}
                            value={this.state.formfields.make}
                            onChange={this.handleUserInput} maxLength="32" />
                          <FormErrors formErrors={this.state.formErrors.make} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="model">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.model')} <StyledText uiText="*" /></label>
                          <input type="text" required className="form-control" name="model"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.model')}
                            value={this.state.formfields.model}
                            onChange={this.handleUserInput} maxLength="32" />
                          <FormErrors formErrors={this.state.formErrors.model} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.year')}</label>
                          <input type="text" className="form-control" name="year"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.year')}
                            value={this.state.formfields.year}
                            onChange={this.handleUserInput} maxLength="4" />
                          <FormErrors formErrors={this.state.formErrors.year} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="type">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.type')} <StyledText uiText="*" /></label>
                          <select className="form-control" id="type" name="type" onChange={this.handleUserInput}
                            required value={this.state.formfields.type}>
                            <option key="placeHolderVehicleType" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.type')} </option>
                            {
                              this.vehicleTypes.map((elem, id) => {
                                return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                              })
                            }
                          </select>
                          <FormErrors formErrors={this.state.formErrors.type} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="attack">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.attack')} <StyledText uiText="*" /></label>
                          <input type="text" className="form-control" name="attack"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.attack')}
                            value={this.state.formfields.attack}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.attack} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="backlash">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.backlash')} <StyledText uiText="*" /></label>
                          <input type="text" className="form-control" name="backlash"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.backlash')}
                            value={this.state.formfields.backlash}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.backlash} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="sensitivity">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.sensitivity')}<StyledText uiText="*" /></label>
                          <input type="text" className="form-control" name="sensitivity"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.sensitivity')}
                            value={this.state.formfields.sensitivity}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.sensitivity} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="localId">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.localId')}</label>
                          <input type="text" className="form-control" name="localId"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.localId')}
                            value={this.state.formfields.localId}
                            onChange={this.handleUserInput} maxLength="256" />
                          <FormErrors formErrors={this.state.formErrors.localId} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="steeringWheelType">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.steeringWheelType')}</label>
                          <input type="text" className="form-control" name="steeringWheelType"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.steeringWheelType')}
                            value={this.state.formfields.steeringWheelType}
                            onChange={this.handleUserInput} maxLength="32" />
                          <FormErrors formErrors={this.state.formErrors.steeringWheelType} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="wheelbase">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.wheelbase')}</label>
                          <input type="text" className="form-control" name="wheelbase"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.wheelbase')}
                            value={this.state.formfields.wheelbase}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.wheelbase} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="antennaHeight">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaHeight')}</label>
                          <input type="text" className="form-control" name="antennaHeight"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaHeight')}
                            value={this.state.formfields.antennaHeight}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.antennaHeight} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="antennaPivot">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaPivot')}</label>
                          <input type="text" className="form-control" name="antennaPivot"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaPivot')}
                            value={this.state.formfields.antennaPivot}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.antennaPivot} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="antennaOffset">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaOffset')}</label>
                          <input type="text" className="form-control" name="antennaOffset"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaOffset')}
                            value={this.state.formfields.antennaOffset}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.antennaOffset} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="frontHitch">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.frontHitch')}</label>
                          <input type="text" className="form-control" name="frontHitch"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.frontHitch')}
                            value={this.state.formfields.frontHitch}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.frontHitch} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="rearHitch">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.rearHitch')}</label>
                          <input type="text" className="form-control" name="rearHitch"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.rearHitch')}
                            value={this.state.formfields.rearHitch}
                            onChange={this.handleUserInput} />
                          <FormErrors formErrors={this.state.formErrors.rearHitch} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="emphasized-label" htmlFor="ecuOrientation">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.ecuOrientation')}</label>
                          <div className="row">
                          	<div className="col-md-6">
                              <label htmlFor="cableAttachDirection">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.CableAttachDirection')}</label>
                              <select className="form-control" id="cableAttachDirection" name="cableAttachDirection"
                                onChange={this.handleUserInput}
                                value={this.state.formfields.cableAttachDirection}>
                                <option key="placeHolderVehicleEcu" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.CableAttachDirection')}</option>
                                {
                                  this.cableAttachDirectionTypes.map((elem, id) => {
                                    return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="xyStickeFace">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.XYStickeFace')}</label>
                              <select className="form-control" id="xyStickeFace" name="xyStickeFace"
                                onChange={this.handleUserInput}
                                value={this.state.formfields.xyStickeFace}>
                                <option key="placeHolderVehicleEcu" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.XYStickeFace')}</option>
                                {
                                  this.xyStickeFaceTypes.map((elem, id) => {
                                    return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                                  })
                                }   
                              </select>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="profileVersion">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.profileVersion')}</label>
                          <input disabled type="text" className="form-control" name="profileVersion"
                            placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.profileVersion')}
                            value={this.state.formfields.profileVersion}
                            onChange={this.handleUserInput} maxLength="64" />
                          <FormErrors formErrors={this.state.formErrors.profileVersion} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                    	<div className="col-md-4">
							<div className="form-group">
								<input type="checkbox" name="AddCalibration"
								value={"no"}
								onChange={this.handleUserInput} /> &nbsp;&nbsp;
								<label htmlFor="AddCalibration">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.AddCalibration')}</label>
							</div>
					  	</div>
                    </div>
                    <Collapse isOpened={this.state.shouldAddCalibration}>
	                    <div className="row">
		                    <div className="col-md-8">
		                    	<div className="form-group">
								 	<Nav tabs>
								        <NavItem>
								          <NavLink disabled={this.state.aTabEnabled} onClick={() => this.setActiveTab('A')}>
								            {i18n.t('common.calibrationTypes.a')}
								          </NavLink>
								        </NavItem>
								        <NavItem>
								          <NavLink disabled={this.state.bTabEnabled} onClick={() => this.setActiveTab('B')}>
								            {i18n.t('common.calibrationTypes.b')}
								          </NavLink>
								        </NavItem>
								        <NavItem>
								          <NavLink disabled={this.state.cTabEnabled} onClick={() => this.setActiveTab('C')}>
								            {i18n.t('common.calibrationTypes.c')}
								          </NavLink>
								        </NavItem>
								        <NavItem>
								          <NavLink disabled={this.state.dTabEnabled} onClick={() => this.setActiveTab('D')}>
								            {i18n.t('common.calibrationTypes.d')}
								          </NavLink>
								        </NavItem>
							      </Nav>
							      <TabContent activeTab={this.state.activeTab} style={{maxHeight:"none"}}>
								        <TabPane tabId="A">
							        		<label htmlFor="aLeftRadius">{i18n.t('user.viewAndListUserCalibration.form.label.leftRadius')}</label>&nbsp;&nbsp;
								        	<input type="text" name="aLeftRadius" className="form-control col-md-6"
								        		value={this.state.formfields.aLeftRadius}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.aLeftRadius} />
								        	<label htmlFor="aRightRadius">{i18n.t('user.viewAndListUserCalibration.form.label.rightRadius')}</label>&nbsp;&nbsp;
								        	<input type="text" name="aRightRadius" className="form-control col-md-6"
								        		value={this.state.formfields.aRightRadius}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.aRightRadius} />								        						        	
								        </TabPane>
								        <TabPane tabId="B">
								        	<label htmlFor="bLockRight">{i18n.t('user.viewAndListUserCalibration.form.label.lockRight')}</label>&nbsp;&nbsp;
								        	<input type="text" name="bLockRight" className="form-control col-md-6" value={this.state.formfields.bLockRight}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.bLockRight} />
								        	<label htmlFor="bLockLeft">{i18n.t('user.viewAndListUserCalibration.form.label.lockLeft')}</label>&nbsp;&nbsp;
								        	<input type="text" name="bLockLeft" className="form-control col-md-6" value={this.state.formfields.bLockLeft}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.bLockLeft} />								        	
								        </TabPane>
								        <TabPane tabId="C">
											<label htmlFor="cPitchBias">{i18n.t('user.viewAndListUserCalibration.form.label.pitchBias')}</label>&nbsp;&nbsp;
								        	<input type="text" name="cPitchBias" className="form-control col-md-6" value={this.state.formfields.cPitchBias}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.cPitchBias} />
								        	<label htmlFor="cRollBias">{i18n.t('user.viewAndListUserCalibration.form.label.rollBias')}</label>&nbsp;&nbsp;
								        	<input type="text" name="cRollBias" className="form-control col-md-6" value={this.state.formfields.cRollBias}
			                                	onChange={this.handleUserInput} maxLength="10"/>
								        	<FormErrors formErrors={this.state.formErrors.cRollBias} />
										</TabPane>
								        <TabPane tabId="D">
								        	<label htmlFor="dSteeringRatio">{i18n.t('user.viewAndListUserCalibration.form.label.steeringRatio')}</label>&nbsp;&nbsp;
								        	<input type="text" name="dSteeringRatio" className="form-control col-md-6" value={this.state.formfields.dSteeringRatio}
			                                	onChange={this.handleUserInput} maxLength="10"/>	
								        	<FormErrors formErrors={this.state.formErrors.dSteeringRatio} />			        	
								        </TabPane>
							      </TabContent>
						      </div>					      
		                    </div> 	                     
	                    </div>
                    </Collapse>
                  </Collapse>
                </div>
              </div>
              <div className="col-md-12 text-right">
                <button type="button" className="btn btn-secondary mr-2"
                  onClick={this.resetVehicleFormFields.bind(this)}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.reset')}
                </button>
                <button type="button" className="btn btn-secondary mr-2"
                  onClick={this.goBack.bind(this)}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.back')}
                </button>
                <button type="submit" className="btn btn-primary"
                  disabled={(!this.state.formValid || this.state.isWaitingApiResponse)}
                  onClick={this.handleSubmit}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.save')}
                </button>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  /**
   * react render function
   */
  render() {
    let breadcrumb = [
      { id: 'home', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'vehicle', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.vehicle'), className: '', link: false },
      { id: 'create', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.create'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />

        <div className="clearfix"></div>
        {this.renderCreateVehicleForm()}
      </div>
    );
  }
}

export default CreateVehicle;
