import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListUsers from './ListUsers'; // '../../Component/ListUsers';
import UserCreate from './UserCreate';
import ViewUser from './ViewUser';
import UserEdit from './UserEdit';
import ViewProfile from './ViewProfile';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import ViewImplement from './ViewImplement';
import EditImplement from './EditImplement';
import ViewCalibration from './ViewCalibration';
import ViewCrop from './ViewCrop';
import ViewOperation from './ViewOperation';
// import EditCrop from './EditCrop';

/**
 * Main users component for holding all routings for sub components of users module.
 */
class Users extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      items: []
    };
  }

  /**
   * react render func
   */
  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/user/list-users'} component={ListUsers} />
            <Route exact path={'/user/create-user'} component={UserCreate} />
            <Route exact path={'/user/view-user/:userId'} render={props => <ViewUser {...props} key={props.match.params.userId}/>} />
            <Route exact path={'/user/edit-user/:userId'} component={UserEdit}/>
            <Route exact path={'/user/view-profile/:userId'} component={ViewProfile}/>
            <Route exact path={'/user/edit-profile/:userId'} component={EditProfile}/>
            <Route exact path={'/user/change-password/:emailId'} component={ChangePassword}/>
            <Route exact path={'/user/view-implement/:implementId'} component={ViewImplement}/>
            <Route exact path={'/user/edit-implement/:implementId'} component={EditImplement}/>
            <Route exact path={'/user/view-crop/:cropId'} component={ViewCrop}/>
            {/* <Route exact path={'/user/edit-crop/:cropId'} component={EditCrop}/> */}
            <Route exact path={'/user/view-operation/:operationId'} component={ViewOperation}/>
            <Route exact path={'/user/view-calibration/:calibrationId'} render={props => <ViewCalibration {...props} key={props.match.params.calibrationId}/>}/>
            <Redirect from="/user" to="/user/list-users" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Users;
