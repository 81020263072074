import React from 'react';
import { Alert } from 'reactstrap';

/**
 * Generic alert func for any type of success or error message.
 */
const AlertMessage = (props) => {
  const testIdSuffix = props.testId ? '-' + props.testId : '';

  const statusMessage = () => {
    return (
      <div>
        <Alert data-testid={'alert-msg' + testIdSuffix} color={props.type}
          isOpen={props.isAlertOpen}
          toggle={props.handleDismiss}
          fade={false}>
          {props.message}
        </Alert>
      </div>
    );
  };

  return statusMessage();
};

export default AlertMessage;
