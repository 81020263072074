import React from 'react';
import BaseScreen from './BaseScreen';
import { SCREENS } from '../../constants/screens.constant';
import { APP_BUILD_VERSION } from "../../constants/app.constants";

/**
 * Home component
 */
class Home extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }

    this.state = {};
  }

  render () {
    let homeBgImgSrc = process.env.PUBLIC_URL + '/assets/images/banner-home.jpg?preventCaching=' + APP_BUILD_VERSION;
    return (
      <div className="home-container card my-4-custom">
        <div className="home-banner">
          <img src={homeBgImgSrc} alt="" />
        </div>
      </div>
    );
  }
}

export default Home;
