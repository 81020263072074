import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import { CompatibilityService } from '../../../services/service.compatibility';
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../Component/SubmissionStatus";
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";

const FILE_PROCESSING_STATUS_DELAY = 3000; // 3 seconds

/**
 * Upload incompatibilities component. This component is responsible for uploading 
 * compatibilities file to backend db population.
 */
class UploadIncompatibilities extends React.Component {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                filename: '',
                fileObject: ''
            },
            formValid: false,
            uploadFileTypeMsg: '',
            alert: {
                type: null,
                message: null
            },
            isLoading: false
        }
        this.csvFileInputRef = React.createRef();

        // for processing status of incompatibilities csv file 
        this.processingStatusTimer = null;
        this.isFileStatusApiInProgress = false;
        this.backendFileName = '';

        this._isMounted = false;
    }

    /**
     * React life cycle method, called after component is first rendered.
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * cleanup resources 
     */
    componentWillUnmount() {
        this._isMounted = false;
        this.stopTimer();
    }

    /**
     * Stop and clear interval timer. 
     */
    stopTimer = () => {
        clearInterval(this.processingStatusTimer);
        this.processingStatusTimer = null;
    }

    /**
     * function to select valid incompatibilities CSV file.
     * only valid CSV and not "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet".
     * @param {*} event 
     */
    handleUserInput = (event) => {
        event.preventDefault();

        if (_.has(event, 'target') && _.isObject(event.target) && (event.target.name === "compatibilityFile")) {
            if (event.target.files && event.target.files.length > 0) {
                if (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel") {
                    this.setState({
                        formfields: {
                            filename: event.target.files[0].name,
                            fileObject: event.target.files[0]
                        },
                        formValid: true,
                        uploadFileTypeMsg: ''
                    });
                } else {
                    this.setState({
                        formfields: {
                            filename: event.target.files[0].name,
                            fileObject: event.target.files[0]
                        },
                        formValid: false,
                        uploadFileTypeMsg: i18n.t('compatibilityViews.alert.filename')
                    });
                }

                // clear input type file field, to prevent the issue when 
                // reselecting the same file again and saving to backend
                if (this.csvFileInputRef && this.csvFileInputRef.current) {
                    this.csvFileInputRef.current.value = "";
                }
            }
        }
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetFormFields = () => {
        if (this.csvFileInputRef && this.csvFileInputRef.current) {
            this.csvFileInputRef.current.value = "";
        }
        this.setState({
            formfields: {
                filename: '',
                fileObject: {}
            },
            formValid: false
        });
    }

    /**
     * Navigate to previous view.
     */
    handleBack = () => {
        this.props.history.goBack();
    }

    /**
     * handles form submit
     * @param {*} event 
     */
    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        // get the url for file upload
        CompatibilityService.getFileUploadUrl().then(res => {
            let uploadUrl = res.data.url;

            // extract backend {fileName} (to be used in status api call) from the uploadUrl
            this.backendFileName = CommonUtilities.getFileNameFromUrl(uploadUrl);

            // call backend api to upload csv file
            CompatibilityService.uploadFile(uploadUrl, this.state.formfields.fileObject).then(res => {
                this.setState({
                    alert: {
                        type: "success",
                        message: i18n.t('compatibilityViews.alert.fileUploaded')
                    }
                });

                // after successful file upload, turn on the timer to 
                // check file processing status
                if (!this.processingStatusTimer && this._isMounted) {
                    this.isFileStatusApiInProgress = false;
                    this.processingStatusTimer = setInterval(() => {
                        this.checkFileProcessingStatus();
                    }, FILE_PROCESSING_STATUS_DELAY);
                }
            }, err => {
                this.handleApiError(err);
            });
        }, err => {
            this.handleApiError(err);
        });
    }

    /**
     * Handle api error and display failure message.
     * @param {*} apiError
     */
    handleApiError = (apiError) => {
        let alertMsg = i18n.t('common.genericApiError');

        if (_.has(apiError, 'data.message')) {
            alertMsg = apiError.data.message;
        }

        this.setState({
            alert: {
                type: "danger",
                message: alertMsg
            },
            isLoading: false
        });
    }

    /**
     * Check processing status of csv file at given 
     * interval. Handle success/error status for csv file.
     */
    checkFileProcessingStatus = () => {
        // see if a status api call is already pending
        if (this.isFileStatusApiInProgress) {
            return;
        }

        this.isFileStatusApiInProgress = true;

        CompatibilityService.getFileStatus(this.backendFileName).then(res => {
            if ((res.status === 200) && !(_.has(res, 'data.error'))) {
                this.setState({
                    alert: {
                        type: "success",
                        message: i18n.t('compatibilityViews.alert.fileProcessed')
                    },
                    isLoading: false
                });
                this.stopTimer();
            } else {
                this.isFileStatusApiInProgress = false;
            }
        }, err => {
            this.handleApiError(err);
            this.stopTimer();
        });
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * encapsulate react render function
     */
    renderUpload = () => {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <div className="p-4">
                    <h2> {i18n.t('compatibilityViews.form.uploadCompatibility')} </h2>
                    <div className="view-profile-details">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="filename">
                                        {i18n.t('compatibilityViews.form.label.filename')}
                                    </label>
                                    <input type="text" id="filename" name="filename"
                                        className="form-control upload-btn-filename"
                                        placeholder={i18n.t('compatibilityViews.form.placeholder.filename')}
                                        value={this.state.formfields.filename}
                                        disabled />
                                    <FormErrors formErrors={this.state.uploadFileTypeMsg} />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <input id="compatibilityFileId" title="" type="file"
                                            name="compatibilityFile"
                                            onChange={this.handleUserInput}
                                            ref={this.csvFileInputRef} />
                                        <button type="button" className="btn btn-primary upload-btn">
                                            {i18n.t('compatibilityViews.form.button.upload')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetFormFields}>
                                {i18n.t('compatibilityViews.form.button.reset')}
                            </button>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.handleBack}>
                                {i18n.t('compatibilityViews.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.state.formValid}
                                onClick={this.submitHandler}>
                                {i18n.t('compatibilityViews.form.button.save')}
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: "home", displayName: i18n.t('compatibilityViews.breadcrumb.home'), href: "#/home", className: "", link: true },
            { id: "compatibility", displayName: i18n.t('compatibilityViews.breadcrumb.compatibility'), className: "", link: false },
            { id: "upload", displayName: i18n.t('compatibilityViews.breadcrumb.upload'), className: "", link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss}
                    />
                }
                {this.renderUpload()}
            </div>
        );
    }
}

export default UploadIncompatibilities;
