import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to get all compatibilities
 * @param {string} queryString 
 */
function getCompatibilities(queryString) {
  const url = API_INTERFACE.GET_COMPATIBILITIES + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * Fetch upload url for compatibilities file.
 */
function getFileUploadUrl() {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(API_INTERFACE.INCOMPATIBILITIES_FILE_UPLOAD_URL, header);
}

/**
 * Service to upload compatibilities file to a url.
 * @param {*} url
 * @param {*} fObject
 */
function uploadFile(url, fObject) {
  const headers = {
    'Content-Type': 'binary/octet-stream'
  };
  return baseService.put(url, headers, fObject);
}

/**
 * service to retrieve status of compatibilities file 
 * process based on fileName
 * @param {*} fileName
 */
function getFileStatus(fileName) {
  const url = API_INTERFACE.INCOMPATIBILITIES_FILE_STATUS + '/' + fileName;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to get file download url for compatibilities file
 */
function getDownloadUrl() {
  const url = API_INTERFACE.GET_INCOMPATIBILITIES_DOWNLOAD_URL;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

export const CompatibilityService = {
  getCompatibilities,
  getFileUploadUrl,
  uploadFile,
  getFileStatus,
  getDownloadUrl
};
