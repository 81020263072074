import React from 'react';
import Grid from '../../../../Component/grid/Grid';
import { FirmwareService } from '../../../../services/service.firmwares';
import BreadcrumbCustom from "../../../../Component/breadcrumb/BreadcrumbCustom";
import ModalDialog, { ModalDialogWithLazyDataLoading } from "../../../../Component/modaldialog/ModalDialog";
import { SubmissionAlert } from '../../../../Component/SubmissionStatus';
import i18n from 'i18next';

/**
 * Informations to be shown in device listing grid, along with options per rows.
 */
let firmwareListConfig = {};

/**
 * List firmware component
 */
class ListFirmwareType extends React.Component {
    constructor(props) {
        super(props);

        // initialize firmwareListConfig with updated i18n resources
        firmwareListConfig = {
            'type': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.deviceType'),
                'sort': false,
                'filter': false,
                'display': true,
                'filterMaxLength': 50
            },
            'description': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.description'),
                'sort': false,
                'filter': false,
                'display': true,
                'filterMaxLength': 50
            },
            'createdAt': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.createdAt'),
                'sort': false,
                'filter': false,
                'display': true,
                'isDate': true,
                'filterMaxLength': 0
            },
            'options': {
                'label': i18n.t('firmwareListCreateViewAndEdit.list.options.options'),
                'sort': false,
                'filter': false,
                'display': true,
                'list': [
                    {
                        'actionType': '',
                        'action': 'delete',
                        'label':  i18n.t('firmwareListCreateViewAndEdit.list.options.delete')
                    }
                ]
            }
        };
        
        this.state = {
            firmwares: [],
            isLoading: true,
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: "",
                modalMsg: "",
                modalData: "",
                modalAction: "",
                modalType: ""
            },
            reinitialize: false,
            apiResponseIsSuccess: false,
            showMessage: false,
            apiResponseMessage: ''
        };
    }

    componentDidMount() {
        this.getFirmwaresTypes().then(
            (response) => {
                this.setState({
                    firmwares: response.data.types,
                    isLoading: false
                });
                return response
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }

    /**
     * Method to fetch all Firmwares from backend.
     * @param {*} args 
     * @param {*} sortFieldsConfig 
     * @param {*} filterFieldsConfig 
     */
    getFirmwaresTypes() {
        return FirmwareService.fetchFirmwaresTypes().then(
            (response) => {
                return response
            }
        )
    }

    /**
     * delete a firmware based on passed id. 
     * @param {*} id 
     */
    deleteFirmware(id) {
        if (this.state.modal.modalData) {
            this.setState({
                isLoading: true,
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
            FirmwareService.deleteFirmwaresTypesById(id).then(
                (response) => {
                    this.setState({
                        //isLoading: true,
                        firmwares: [],
                        apiResponseMessage: i18n.t('firmwareListCreateViewAndEdit.alert.firmwareDelete'),
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                    this.getFirmwaresTypes().then(
                        (response) => {
                            this.setState({ firmwares: response.data.types, isLoading: false, modalShow: false, reinitialize: false });
                        }
                    );
                },
                (error) => {
                    let message = i18n.t('common.genericApiError');
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    }
                    this.setState({
                        isLoading: false,
                        apiResponseMessage: message,
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                }
            )
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * Modal window to show each row options.
     * @param {*} state 
     * @param {*} rowData 
     * @param {*} modalInfo 
     */
    modalPopup(state, rowData, modalInfo) {
        if (state) {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalMsg: modalInfo.modalMsg,
                    modalTitle: modalInfo.modalTitle,
                    modalData: rowData ? rowData : "",
                    modalAction: modalInfo.modalAction,
                    modalType: modalInfo.modalType
                },
            });
        } else {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalData: rowData ? rowData : "",
                    modalAction: this.state.modal.modalAction
                },
            });
        }
    }

     /**
     * modal dismissal action
     */
    handleModalDismiss() {
        this.modalPopup(false);
    }

    /**
     * handle modal delete action.
     * @param {*} data 
     */
    onModalAction(data) {
        let modalAction = this.state.modal.modalAction;
        if (modalAction === "delete") {
            this.modalPopup(false, data);
        }
    }

     /**
     * function after modal is closed, re-render listing page after delete operation.
     */
    onModalClosed() {
        if (this.state.modal.modalAction === "delete") {
            this.deleteFirmware(this.state.modal.modalData.id);
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * per row option handler
     * @param {*} actionObject 
     * @param {*} row 
     */
    optionHandler(actionObject, row) {
        let modalInfo;
        if (actionObject.action === "delete") {
            modalInfo = {
                modalMsg: i18n.t('firmwareListCreateViewAndEdit.form.modal.deleteMsgType') + "'" + row.type + "'",
                modalTitle: i18n.t('firmwareListCreateViewAndEdit.form.modal.deleteTitleType'),
                modalAction: "delete"
            };
        }
        this.modalPopup(true, row, modalInfo);
    }

     /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    /**
     * react render function to render the list of firmwares.
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'firmware', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmwareType'), className: '', link: false },
            { id: 'list', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.list'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className="clearfix"></div>
                    {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                    <div>
                        <Grid id="firmwaresListGrid"
                            keyColumn='id'
                            loading={this.state.isLoading}
                            columnDefs={firmwareListConfig}
                            rowData={this.state.firmwares}
                            reinitialize={this.state.reinitialize}
                            pageList={this.state.pageList}
                            lazyLoading={true}
                            optionHandler={this.optionHandler.bind(this)}
                            />
                    </div>
                </div>
                    {
                        this.state.modal.shouldRenderModal 
                        ? 
                            <ModalDialog
                                modalTitle={this.state.modal.modalTitle}
                                modalMessage={this.state.modal.modalMsg}
                                modalData={this.state.modal.modalData}
                                isModalOpen={this.state.modal.modalShow}
                                modalAction={this.onModalAction.bind(this)}
                                handleModalDismiss={this.handleModalDismiss.bind(this)}
                                onModalClosed={this.onModalClosed.bind(this)} />
                        : null
                    }
            </div>
        );
    }
}
export default ListFirmwareType;