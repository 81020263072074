import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import { DocumentsService } from '../../../services/service.documents';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { FormErrors } from '../../../Component/SubmissionStatus';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { StyledText } from '../../../Component/StyledText';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';

/**
 * Create document component. Encapsulate document 
 * creation functionalities.
 */
class CreateDocument extends React.Component {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                description: '',
                fileObject: '',
                documentName: ''
            },
            formErrors: {
                description: ''
            },
            formValid: false,
            alert: {
                type: null,
                message: null
            },
            isLoading: false
        }
        this.documentFileInputRef = React.createRef();
    }

    /**
     * handle user input for validation purpose.
     * @param {*} event 
     */
    handleUserInput = (event) => {
        event.preventDefault();

        if (_.has(event, 'target') && _.isObject(event.target)) {
            const name = event.target.name;
            let fieldValidationErrors = this.state.formErrors;
            let formfields = this.state.formfields;

            if (name === 'documentFile') {
                // handle input document file
                if (event.target.files && event.target.files.length > 0) {
                    formfields.fileObject = event.target.files[0];
                    formfields.documentName = event.target.files[0].name;
                }
            } else {
                // handle validation for other inputs
                const value = event.target.value;
                formfields[name] = value;
                let msg = '';

                switch (name) {
                    case 'description':
                        if (value.length === 0) {
                            msg = i18n.t('documentViews.alert.required');
                        } else if (value.length > 256) {
                            msg = i18n.t('documentViews.alert.descriptionLength');
                        }
                        break;

                    default:
                        break;
                }

                fieldValidationErrors[name] = msg;
            }

            let isFormValid = (formfields.description && formfields.fileObject) &&
                (fieldValidationErrors.description === '');
            this.setState({
                formfields: formfields,
                formErrors: fieldValidationErrors,
                formValid: isFormValid,
            });

            // clear input type file field, to prevent the issue when 
            // reselecting the same file again and saving to backend
            if (this.documentFileInputRef && this.documentFileInputRef.current) {
                this.documentFileInputRef.current.value = '';
            }
        }
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetFormFields = () => {
        if (this.documentFileInputRef && this.documentFileInputRef.current) {
            this.documentFileInputRef.current.value = '';
        }
        this.setState({
            formfields: {
                description: '',
                fileObject: '',
                documentName: ''
            },
            formValid: false
        });
    }

    /**
     * Navigate to previous view.
     */
    handleBack = () => {
        this.props.history.goBack();
    }

    /**
     * handles form submit
     * @param {*} event 
     */
    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        let reqBody = {
            fileName: this.state.formfields.documentName,
            description: this.state.formfields.description,
        };

        // get the url for file upload
        DocumentsService.createDocument(reqBody).then(res => {
            let uploadUrl = res.data.url;
            DocumentsService.uploadFile(uploadUrl, this.state.formfields.fileObject).then(res => {
                this.setState({
                    isLoading: false,
                    alert: {
                        type: 'success',
                        message: i18n.t('documentViews.alert.documentCreated')
                    }
                });
            }, err => {
                this.handleApiError(err);
            });
        }, err => {
            this.handleApiError(err);
        });
    }

    /**
     * Handle api error and display failure message.
     * @param {*} apiError
     */
    handleApiError = (apiError) => {
        let alertMsg = i18n.t('common.genericApiError');

        if (_.has(apiError, 'data.message')) {
            alertMsg = apiError.data.message;
        }

        this.setState({
            alert: {
                type: 'danger',
                message: alertMsg
            },
            isLoading: false
        });
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * render create document form ui
     */
    renderCreateDocumentForm = () => {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <div className='p-4'>
                    <h2> {i18n.t('documentViews.form.createDocument')} </h2>
                    <div className='view-profile-details'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <div className='form-group'>
                                    <label htmlFor='description'>{i18n.t('documentViews.form.label.description')} <StyledText uiText='*' /></label>
                                    <textarea className='form-control' name='description'
                                        value={this.state.formfields.description}
                                        placeholder={i18n.t('documentViews.form.placeholder.description')}
                                        onChange={this.handleUserInput}
                                        cols={50}
                                        rows={4}
                                        maxLength='256' />
                                    <FormErrors formErrors={this.state.formErrors.description} />
                                </div>
                            </div>
                        </div>
                        <div className='hr-line-dashed'></div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='documentName'>
                                        {i18n.t('documentViews.form.label.selectFile')}
                                    </label>
                                    <input type='text' id='documentName' name='documentName'
                                        className='form-control upload-btn-filename'
                                        placeholder={i18n.t('documentViews.form.placeholder.documentName')}
                                        value={this.state.formfields.documentName}
                                        disabled />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <div className='upload-btn-wrapper'>
                                        <input id='documentFileId' title='' type='file'
                                            name='documentFile'
                                            onChange={this.handleUserInput}
                                            ref={this.documentFileInputRef} />
                                        <button type='button' className='btn btn-primary upload-btn'>
                                            {i18n.t('documentViews.form.button.chooseFile')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            <button type='button' className='btn btn-secondary mr-2'
                                onClick={this.resetFormFields}>
                                {i18n.t('documentViews.form.button.reset')}
                            </button>
                            <button type='button' className='btn btn-secondary mr-2'
                                onClick={this.handleBack}>
                                {i18n.t('documentViews.form.button.back')}
                            </button>
                            <button type='submit' className='btn btn-primary'
                                disabled={!this.state.formValid}
                                onClick={this.submitHandler}>
                                {i18n.t('documentViews.form.button.save')}
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('documentViews.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'knowledge', displayName: i18n.t('documentViews.breadcrumb.knowledge'), className: '', link: false },
            { id: 'create', displayName: i18n.t('documentViews.breadcrumb.create'), className: '', link: false }
        ];
        return (
            <div className='container-fluid p-4'>
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className='clearfix'></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss}
                    />
                }
                {this.renderCreateDocumentForm()}
            </div>
        );
    }
}

export default CreateDocument;
