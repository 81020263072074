import JwtDecode from 'jwt-decode';
import i18n from 'i18next';
import { UserService } from '../../services/service.users';
import { AppStorageHelper } from './appStorageHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

/**
 * UserInfo private class. with all initial config passed in constructor.
 */
class UserInfo {
  constructor () {
    console.log('-- UserInfo constructor called....');

    this.loggedInUserData = undefined;
    this.isBusyLoadingUserInfo = false;
    this.userInfoApiPromise = undefined;
    this.shouldHandlePendingPromises = true;
  }

  /**
   * before reinitializing "UserInfo" instance, disable handling of any pending promises
   */
  disableHandlingPromises () {
    this.shouldHandlePendingPromises = false;
  }

  /**
   * function to get user information.
   */
  getUserInfo () {
    var self = this;

    if (this.loggedInUserData) {
      return Promise.resolve({ ...this.loggedInUserData });
    } else if (this.isBusyLoadingUserInfo) {
      return this.userInfoApiPromise;
    } else {
      this.isBusyLoadingUserInfo = true;
      this.userInfoApiPromise = new Promise(function (resolve, reject) {
        try {
          const apiAuthInfo = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);
          const apiAccessToken = apiAuthInfo.acessToken;
          const decodedData = JwtDecode(apiAccessToken);
          const userSub = decodedData.sub;

          UserService.getUser(userSub).then(
            (successRes) => {
              self.isBusyLoadingUserInfo = false;

              self.loggedInUserData = successRes.data;
              if (self.shouldHandlePendingPromises) {
                resolve(successRes.data);
              }
            },
            (errRes) => {
              self.isBusyLoadingUserInfo = false;

              if (self.shouldHandlePendingPromises) {
                reject(errRes);
              }
            });
        } catch (err) {
          const errorMsg = {
            data: {
              message: i18n.t('common.userInfoError')
            }
          };
          console.log(errorMsg.data.message);

          self.isBusyLoadingUserInfo = false;
          if (self.shouldHandlePendingPromises) {
            reject(errorMsg);
          }
        }
      });
      return this.userInfoApiPromise;
    }
  }
}

/**
 * public class to use in outside.
 */
class AppInfoHelperClass {
  constructor () {
    this.userInfoObj = new UserInfo();
  }

  getUserInstance () {
    return this.userInfoObj;
  }

  reInitializeUserInfo () {
    this.userInfoObj.disableHandlingPromises();
    this.userInfoObj = null;

    this.userInfoObj = new UserInfo();
  }
}

const AppInfoHelper = new AppInfoHelperClass();

export default AppInfoHelper;
