import React from 'react';
import BaseScreen from '../BaseScreen';
import { UserService } from "../../../services/service.users";
import { FormErrors, SubmissionAlert } from '../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';

/**
 * User create components. holds all functionalities of creating a user.
 */
class UserCreate extends BaseScreen {
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                roleId: 1,
            },
            formErrors: { email: '', firstName: '', lastName: '', phone: '' },
            firstNameValid: false,
            lastNameValid: false,
            phoneValid: false,
            emailValid: false,
            roleValid: false,
            formValid: false,
            roles: [],
            isLoading: true,
            apiResponseIsHandled: false,
            apiResponseMessage: '',
            isApiResponseSuccess: false
        }
    }

    componentDidMount() {
        UserService.getOemRoles().then(allRoles => {
            this.setState({ roles: allRoles, isLoading: false });
        });
    }

    /**
     * user create function in the backend
     */
    createUserSubmit = (event) => {
        event.preventDefault();
        UserService.createUserSubmission(this.state.formfields).then(
            (theResponse) => {
                let message = i18n.t('user.usersListCreateViewAndEdit.alert.userCreateSuccess');
                this.setState({ apiResponseIsHandled: true, apiResponseMessage: message, isApiResponseSuccess: true });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({ apiResponseMessage: message, apiResponseIsHandled: true, isApiResponseSuccess: false });
            }
        );
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        this.setState({ formfields: formfields },
            () => { this.validateField(name, value) });
    }

    /**
     * form field validation handler
     * @param {*} fieldName 
     * @param {*} value 
     */
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let phoneValid = this.state.phoneValid;
        let emailValid = this.state.emailValid;
        let roleValid = this.state.roleValid;

        switch (fieldName) {
            case 'firstName':
                if (value.length === 0) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = firstNameValid ? '' :  i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length > 32) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = firstNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.char32');
                } else {
                    firstNameValid = CommonUtilities.isValidName(value);
                    fieldValidationErrors.firstName = firstNameValid ? '' :  i18n.t('user.usersListCreateViewAndEdit.alert.alphabate');
                }
                break;
            case 'lastName':
                if (value.length === 0) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length > 32) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.char32');
                } else {
                    lastNameValid = CommonUtilities.isValidName(value);
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.alphabate');
                }
                break;
            case 'phone':
                if (value.length === 0) {
                    phoneValid = false;
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length < 7 || value.length > 15) {
                    phoneValid = false;
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.phone');
                } else {
                    phoneValid = value.match(/^(?:[0-9] ?){6,14}[0-9]$/i);
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.integer');
                }
                break;
            case 'email':
                if (value.length > 320) {
                    emailValid = false;
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.email320');
                } else if (value.length === 0) {
                    emailValid = false;
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else {
                    emailValid = CommonUtilities.isValidEmail(value);
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.emailInvalid');
                }
                break;
            case 'roleId':
                if (value > -1) {
                    roleValid = true;
                }
                roleValid = (value > -1) ? true : false;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            emailValid: emailValid,
            phoneValid: phoneValid,
            roleValid: roleValid,
        }, this.validateForm);
    }

    /**
     * validate if form is valid or not.
     */
    validateForm() {
        this.setState({ formValid: this.state.firstNameValid && this.state.lastNameValid && this.state.emailValid && this.state.phoneValid && this.state.roleValid });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    /**
     * handles dismissal 
     */
    onDismiss = () => {
        this.setState({ apiResponseIsHandled: false });
    }

    /**
     * go to previous page
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * user create form render
     */
    renderCreateUserForm() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'user', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.user'), className: '', link: false },
            { id: 'create', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.create'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <div className="float-left">
                        <BreadcrumbCustom breadcrumb={breadcrumb} />
                    </div>
                    <div className="clearfix"></div>
                    {
                        this.state.apiResponseIsHandled && 
                        <SubmissionAlert testId="create-user" 
                            alertMessage={this.state.apiResponseMessage} 
                            isSuccessResponse={this.state.isApiResponseSuccess} 
                            onDismiss={this.onDismiss} />
                    }
                    <form className="createUserForm" autoComplete="off" list="autocompleteOff">
                        <h2> {i18n.t('user.usersListCreateViewAndEdit.createUser')} </h2>
                        <div className="view-profile-details">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.firstName)}`}>
                                        <label htmlFor="firstName"> {i18n.t('user.usersListCreateViewAndEdit.form.label.firstName')} <StyledText uiText="*"/></label>
                                        <input type="text" required className="form-control" name="firstName"
                                            placeholder={i18n.t('user.usersListCreateViewAndEdit.form.placeholder.firstName')}
                                            autoComplete="off"
                                            list="autocompleteOff"
                                            value={this.state.formfields.firstName}
                                            onChange={this.handleUserInput} />
                                        <FormErrors formErrors={this.state.formErrors.firstName} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.lastName)}`}>
                                        <label htmlFor="lastName"> {i18n.t('user.usersListCreateViewAndEdit.form.label.lastName')} <StyledText uiText="*"/></label>
                                        <input type="text" className="form-control" name="lastName"
                                            placeholder={i18n.t('user.usersListCreateViewAndEdit.form.placeholder.lastName')}
                                            value={this.state.formfields.lastName}
                                            onChange={this.handleUserInput} />
                                        <FormErrors formErrors={this.state.formErrors.lastName} />
                                    </div>
                                </div></div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                                        <label htmlFor="email">{i18n.t('user.usersListCreateViewAndEdit.form.label.email')} <StyledText uiText="*"/></label>
                                        <input type="email" className="form-control" name="email"
                                            placeholder={i18n.t('user.usersListCreateViewAndEdit.form.placeholder.email')}
                                            value={this.state.formfields.email}
                                            onChange={this.handleUserInput} />
                                        <FormErrors formErrors={this.state.formErrors.email} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={`form-group ${this.errorClass(this.state.formErrors.phone)}`}>
                                        <label htmlFor="phone">{i18n.t('user.usersListCreateViewAndEdit.form.label.phone')} <StyledText uiText="*"/></label>
                                        <input type="text" className="form-control" name="phone"
                                            placeholder={i18n.t('user.usersListCreateViewAndEdit.form.placeholder.phone')}
                                            value={this.state.formfields.phone}
                                            onChange={this.handleUserInput} />
                                        <FormErrors formErrors={this.state.formErrors.phone} />
                                    </div>
                                </div>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={`form-group`}>
                                        <label htmlFor="roleId">{i18n.t('user.usersListCreateViewAndEdit.form.label.role')} <StyledText uiText="*"/>:</label>
                                        <select className="form-control" id="roleId" name="roleId" onChange={this.handleUserInput}>
                                            <option key="placeHolderSelectRole" value="-1">{i18n.t('user.usersListCreateViewAndEdit.form.placeholder.role')}</option>
                                            {
                                                this.state.roles.map((elem, id) => {
                                                    return <option key={elem.roleDisplayName + id} value={elem.roleId}>{elem.roleDisplayName}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <div>
                                    <button type="button" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}> {i18n.t('user.usersListCreateViewAndEdit.form.button.back')} </button>
                                    <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this.createUserSubmit} > {i18n.t('user.usersListCreateViewAndEdit.form.button.save')} </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div></div>
        )
    }

    /**
     * react render func
     */
    render() {
        if (this.state.isLoading) {
            return <p>{i18n.t('common.loaderMsg')}</p>
        } else {
            return this.renderCreateUserForm()
        }
    }
}
export default UserCreate;