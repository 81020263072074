import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to fetch list of implements from backend.
 * @param {*} queryString
 */
function getImplements (queryString) {
  const url = API_INTERFACE.GET_IMPLEMENTS + '?' + queryString;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * service to get a specific implement based on passed id.
 * @param {*} implementId
 */
function getImplement (implementId) {
  const url = API_INTERFACE.GET_IMPLEMENT + '/' + implementId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * service to update properties of a implement.
 * @param {*} body
 */
function updateImplement (body) {
  const url = API_INTERFACE.GET_IMPLEMENT;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.put(url, header, body);
}

/**
 * service to delete a implement from backend.
 * @param {*} implementId
 */
export function deleteImplement (implementId) {
  const url = API_INTERFACE.GET_IMPLEMENT + '/' + implementId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

export const ImplementsService = {
  getImplements,
  getImplement,
  deleteImplement,
  updateImplement
};