import React from 'react';
import { Link } from "react-router-dom";
import i18n from 'i18next';
import BaseScreen from "../Containers/Screens/BaseScreen";

/**
 * application left and profile menu component.
 */
export class LeftMenu extends BaseScreen {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * handle menu collapse event.
   * @param {*} arg 
   * @param {*} event 
   */
  handleCollapseOrExpand(arg, event) {
    event.preventDefault();

    try {
      /**
       * dom reference for collapsible "tab-content" wrapper (which was clicked)
       */
      let collapsibleWrapper = event.target.nextElementSibling;

      /**
       * dom reference for "tab-content" container
       */
      let collapsibleContainer = event.target.closest(".tabs");
      let childTabContents = collapsibleContainer.querySelectorAll(".tab-content");
      for (let i = 0; i < childTabContents.length; i++) {
        let elem = childTabContents[i];
        if (collapsibleWrapper !== elem) {
          elem.classList.remove("tab-content-expanded");
        }
      }

      collapsibleWrapper.classList.toggle("tab-content-expanded");
    } catch (e) {
    }
  }

  handleCollapseOrExpandSubMenu =(event)=> {
    event.preventDefault();

    try {
      /**
       * dom reference for collapsible "tab-content" wrapper (which was clicked)
       */
      let collapsibleWrapper = event.target.nextElementSibling;

      /**
       * dom reference for "tab-content" container
       */
      let collapsibleContainer = event.target.closest(".tabs");
      let childTabContents = collapsibleContainer.querySelectorAll(".sub-menu-tab-content");
      for (let i = 0; i < childTabContents.length; i++) {
        let elem = childTabContents[i];
        if (collapsibleWrapper !== elem) {
          elem.classList.remove("tab-content-expanded");
        }
      }

      collapsibleWrapper.classList.toggle("tab-content-expanded");
    } catch (e) {
    }
  }

  displayNestedSubMenu = (item) =>{
    let dataTestIdSmallScreenSuffix = this.props.isMobile ? "-small-screen" : "";
    return  (<><button className={"sub-btn tab-label left-sub-menu-item " + item.icon} onClick={this.handleCollapseOrExpandSubMenu}>
            {this.getLocalizedString(item.key, item.name)}
          </button>
          <div className="tab-content sub-menu-tab-content">
            {
              item.subMenu.map((menu, idx) => {
                return (
                  <span key={"sub-"+item.name + idx} className="sub-menu nested-sub-menu">
                    <Link data-testid={"menu-" + menu.key + dataTestIdSmallScreenSuffix}
                      to={item.screenPath+menu.screenPath}
                      onClick={this.handleNavLinkClick.bind(this, menu.name)}
                      className="left-menu-override">
                      {this.getLocalizedString(menu.key, menu.name)}
                    </Link>
                  </span>
                );
              })
            }
          </div>
          </>)
      
  }

  /**
   * render left menu.
   */
  renderMenus = (item) => {
    let dataTestIdSmallScreenSuffix = this.props.isMobile ? "-small-screen" : "";
    if (item.subMenu) {
      return (
        <div className="tab">
          <button className={"btn tab-label left-main-menu-item " + item.icon} onClick={this.handleCollapseOrExpand.bind(this, item.key)}>
            {this.getLocalizedString(item.key, item.name)}
          </button>
          <div className="tab-content">
            {
              item.subMenu.map((menu, idx) => {
                return (
                  <span key={item.name + idx} className="sub-menu">
                    { menu.subMenu ? this.displayNestedSubMenu(menu) :
                    <Link data-testid={"menu-" + menu.key + dataTestIdSmallScreenSuffix}
                      to={menu.screenPath}
                      onClick={this.handleNavLinkClick.bind(this, menu.name)}
                      className="left-menu-override">
                      {this.getLocalizedString(menu.key, menu.name)}
                    </Link>
                      }
                  </span>
                );
              })
            }
          </div>
        </div>
      );
    } else {
      if (item.isExternalUrl) {
        return (
          <div className="tab">
            <a href={item.url} target="_blank" rel="noopener noreferrer"
              className={"left-main-menu-item " + item.icon}
              onClick={this.handleNavLinkClick.bind(this, item.name)}>
              {this.getLocalizedString(item.key, item.name)}
            </a>
          </div>
        );
      } else {
        return (
          <div className="tab">
            <Link data-testid={"menu-" + item.key + dataTestIdSmallScreenSuffix}
              to={item.screenPath}
              className={"left-menu-override left-main-menu-item " + item.icon}
              onClick={this.handleNavLinkClick.bind(this, item.name)}>
              {this.getLocalizedString(item.key, item.name)}
            </Link>
          </div>
        );
      }
    }
  }

  /**
   * call back for parent component, when any link is clicked
   * @param {*} arg 
   * @param {*} event 
   */
  handleNavLinkClick(arg, event) {
    if (this.props.onSidebarNavLinkClick) {
      console.log("here" ,arg)
      this.props.onSidebarNavLinkClick(arg, event);
    }
  }

  /**
   * func to render logged in user profile at top right corner.
   */
  renderProfileMenuItems() {
    return (
      <div className="tab">
        <button className={"btn tab-label left-main-menu-item "} onClick={this.handleCollapseOrExpand.bind(this, "user_info")}>
          {i18n.t('user.editAndViewProfile.userInfo')}
        </button>
        <div className="tab-content">
          <span className="sub-menu">
            <Link to={"/user/view-profile/" + this.props.userSubId}
              onClick={this.handleNavLinkClick.bind(this, "view_profile")}
              className="left-menu-override">
              {i18n.t('user.editAndViewProfile.viewProfile')}
            </Link>
          </span>
          <span className="sub-menu">
            <Link to={"/user/change-password/" + this.props.userSubId}
              onClick={this.handleNavLinkClick.bind(this, "change_password")}
              className="left-menu-override">
              {i18n.t('user.editAndViewProfile.changePassword')}
            </Link>
          </span>
          <span className="sub-menu">
            <button className="btn left-menu-override" onClick={this.handleLogout}>
              {i18n.t('user.editAndViewProfile.logOut')}
            </button>
          </span>
        </div>
      </div>
    );
  }

  /**
   * handle logout event
   */
  handleLogout = (event) => {
    if (this.props.logoutHandler) {
      this.props.logoutHandler(event);
    }
  }

  /**
  * MenuItems are loaded from the json file. 
  * Get Localized string for menu items based on their key.
  */
  getLocalizedString = (menuItemKey, defaultValue) => {
    let localizedStr;
    switch (menuItemKey) {
      /* users menu */
      case 'users':
        localizedStr = i18n.t('menuItems.users.label');
        break;

      case 'listUsers':
        localizedStr = i18n.t('menuItems.users.subMenu.listUsers.label');
        break;

      case 'createUsers':
        localizedStr = i18n.t('menuItems.users.subMenu.createUsers.label');
        break;

      /* firmware menu */
      case 'firmware':
        localizedStr = i18n.t('menuItems.firmware.label');
        break;

      case 'listFirmware':
        localizedStr = i18n.t('menuItems.firmware.subMenu.listFirmware.label');
        break;

      case 'createFirmware':
        localizedStr = i18n.t('menuItems.firmware.subMenu.createFirmware.label');
        break;
      
      case 'firmwareType':
        localizedStr = i18n.t('menuItems.firmware.label');
        break;
      
      case 'firmwareTypes':
        localizedStr = i18n.t('menuItems.firmware.subMenu.firmwareTypes.label');
        break;
            
      case 'createFirmwareType':
        localizedStr = i18n.t('menuItems.firmware.subMenu.createFirmwareType.label');
        break;
        
	  case 'listFirmwareType':
        localizedStr = i18n.t('menuItems.firmware.subMenu.listFirmwareType.label');
        break;
      
      /* vehicle menu */
      case 'vehicle':
        localizedStr = i18n.t('menuItems.vehicle.label');
        break;

      case 'listVehicle':
        localizedStr = i18n.t('menuItems.vehicle.subMenu.listVehicle.label');
        break;

      case 'createVehicle':
        localizedStr = i18n.t('menuItems.vehicle.subMenu.createVehicle.label');
        break;

      /* device menu */
      case 'device':
        localizedStr = i18n.t('menuItems.device.label');
        break;

      case 'listCentralDevices':
        localizedStr = i18n.t('menuItems.device.subMenu.listCentralDevices.label');
        break;

      case 'createCentralDevices':
        localizedStr = i18n.t('menuItems.device.subMenu.createCentralDevices.label');
        break;

      case 'uploadCentralDevices':
        localizedStr = i18n.t('menuItems.device.subMenu.uploadCentralDevices.label');
        break;
        case 'subscriptionsCentralDevices':
          localizedStr = i18n.t('menuItems.device.subMenu.subscriptionsCentralDevices.label');
        break;
        case 'listSubscriptions':
          localizedStr = i18n.t('menuItems.device.subMenu.subscriptionsCentralDevices.subMenu.listSubscriptions.label');
        break;
        case 'createSubscription':
          localizedStr = i18n.t('menuItems.device.subMenu.subscriptionsCentralDevices.subMenu.createSubscription.label');
        break;  

      /* compatibility menu */
      case 'compatibility':
        localizedStr = i18n.t('menuItems.compatibility.label');
        break;

      case 'listIncompatibilities':
        localizedStr = i18n.t('menuItems.compatibility.subMenu.listIncompatibilities.label');
        break;

      case 'uploadIncompatibilities':
        localizedStr = i18n.t('menuItems.compatibility.subMenu.uploadIncompatibilities.label');
        break;

      /* knowledge/document menu */
      case 'knowledge':
        localizedStr = i18n.t('menuItems.knowledge.label');
        break;

      case 'listDocuments':
        localizedStr = i18n.t('menuItems.knowledge.subMenu.listDocuments.label');
        break;

      case 'createDocument':
        localizedStr = i18n.t('menuItems.knowledge.subMenu.createDocument.label');
        break;

      /* support menu */
      case 'support':
        localizedStr = i18n.t('menuItems.support.label');
        break;

      default:
        break;
    }
    return localizedStr ? localizedStr : defaultValue;
  }

  /**
   * react render func.
   */
  render() {
    return (
      <div className={"left-column " + (this.props.isMobile ? "mobile" : "")}>
        <div className="row">
          <div className="col icon-menu">
            <div className="tabs">
              {
                this.props.leftMenuItems.map((item, idx) => {
                  return (
                    <React.Fragment key={item.name + idx}>
                      {this.renderMenus(item)}
                    </React.Fragment>
                  );
                })
              }
              {
                this.props.addUserInfo ? (
                  this.renderProfileMenuItems()
                )
                  :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
