import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListFirmwares from './ListFirmwares';
import CreateFirmware from './CreateFirmware';
import ViewFirmware from './ViewFirmware';
import EditFirmware from './EditFirmware';
import CreateFirmwareType from '../firmware/types/CreateFirmwareType';
import ListFirmwareType from '../firmware/types/ListFirmwareType';

/**
 * Main Firmwares component. holds all routes for routing to other Firmwares sub components.
 */
class Firmwares extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      items: []
    };
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/firmware/list-firmwares'} component={ListFirmwares} />
            <Route exact path={'/firmware/create-firmware'} component={CreateFirmware} />
            <Route exact path={'/firmware/view-firmware/:firmwareId'} component={ViewFirmware} />
            <Route exact path={'/firmware/edit-firmware/:firmwareId'} component={EditFirmware}/>
            <Route exact path={'/firmware/types/list-firmwareType'} component={ListFirmwareType}/>
            <Route exact path={'/firmware/types/create-firmwareType'} component={CreateFirmwareType}/>
            <Redirect from="/firmware" to="/firmware/list-firmwares" />
          </Switch>
        </div>
      </div>
    );
  }
}
export default Firmwares;
