import React from 'react';
import _ from 'lodash';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import { DeviceService } from "../../../services/service.devices";
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../Component/SubmissionStatus";
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";
import i18n from 'i18next';

const FILE_PROCESSING_STATUS_DELAY = 3000; // 3 seconds

/**
 * upload device component. this component is responsible for uploading device files to backend db population.
 */
class UploadDevice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                filename: '',
                fileObject: ''
            },
            inputfileId: "devicefileid",
            formValid: false,
            devicefileType: '',
            alert: {
                type: null,
                message: null
            },
            isLoading: false
        }
        this.csvFileInputRef = React.createRef();

        // for processing status of device csv file 
        this.processingStatusTimer = null;
        this.isFileStatusApiInProgress = false;
        this.backendFileName = '';

        this._isMounted = false;
    }

    /**
     * React life cycle method, called after component is first rendered.
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * cleanup resources 
     */
    componentWillUnmount() {
        this._isMounted = false;
        this.stopTimer();
    }

    /**
     * Stop and clear interval timer. 
     */
    stopTimer = () => {
        clearInterval(this.processingStatusTimer);
        this.processingStatusTimer = null;
    }

    /**
     * function to select valid device CSV file.
     * only valid CSV and not "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet".
     * @param {*} event 
     */
    showFileName(event) {
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel") {
                this.setState({
                    formfields: {
                        filename: event.target.files[0].name,
                        fileObject: event.target.files[0]
                    },
                    formValid: true,
                    devicefileType: ''
                });
            } else {
                this.setState({
                    formfields: {
                        filename: event.target.files[0].name,
                        fileObject: event.target.files[0]
                    },
                    formValid: false,
                    devicefileType: i18n.t('deviceListCreateViewEditAndUpload.alert.filename')
                });
            }

            // clear input type file field, to prevent the issue when 
            // reselecting the same file again and saving to backend
            if (this.csvFileInputRef && this.csvFileInputRef.current) {
                this.csvFileInputRef.current.value = "";
            }
        }
    }

    /**
     * opens file explorer
     * @param {*} event 
     */
    openExplorer(event) {
        event.preventDefault();
        document.getElementById(this.state.inputfileId).click();
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetDeviceFormFields() {
        if (this.csvFileInputRef && this.csvFileInputRef.current) {
            this.csvFileInputRef.current.value = "";
        }
        this.setState({
            formfields: {
                filename: '',
                fileObject: {}
            },
            formValid: false
        });
    }

    /**
     * go back function. goes to previous view.
     */
    handleBack() {
        this.props.history.goBack();
    }

    /**
     * handles form submit
     * @param {*} event 
     */
    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        // get the url for file upload
        DeviceService.getFileUploadUrl().then(res => {
            let uploadUrl = res.data.url;

            // extract backend {fileName} (to be used in status api call) from the uploadUrl
            this.backendFileName = CommonUtilities.getFileNameFromUrl(uploadUrl);

            // call backend api to upload device csv file
            DeviceService.uploadFile(uploadUrl, this.state.formfields.fileObject).then(res => {
                this.setState({
                    alert: {
                        type: "success",
                        message: i18n.t('deviceListCreateViewEditAndUpload.alert.deviceUpload')
                    }
                });

                // after successful file upload, turn on the timer to 
                // check file processing status
                if (!this.processingStatusTimer && this._isMounted) {
                    this.isFileStatusApiInProgress = false;
                    this.processingStatusTimer = setInterval(() => {
                        this.checkFileProcessingStatus();
                    }, FILE_PROCESSING_STATUS_DELAY);
                }
            }, err => {
                this.handleApiError(err);
            });
        }, err => {
            this.handleApiError(err);
        });
    }

    /**
     * Handle api error and display failure message.
     * @param {*} apiError
     */
    handleApiError = (apiError) => {
        let alertMsg = i18n.t('common.genericApiError');

        if (_.has(apiError, 'data.message')) {
            alertMsg = apiError.data.message;
        }

        this.setState({
            alert: {
                type: "danger",
                message: alertMsg
            },
            isLoading: false
        });
    }

    /**
     * Check processing status of device csv file at given 
     * interval. Handle success/error status for device file.
     */
    checkFileProcessingStatus = () => {
        // see if a status api call is already pending
        if (this.isFileStatusApiInProgress) {
            return;
        }

        this.isFileStatusApiInProgress = true;

        DeviceService.getFileStatus(this.backendFileName).then(res => {
            if ((res.status === 200) && !(_.has(res, 'data.error'))) {
                this.setState({
                    alert: {
                        type: "success",
                        message: i18n.t('deviceListCreateViewEditAndUpload.alert.deviceCSVProcessed')
                    },
                    isLoading: false
                });
                this.stopTimer();
            } else {
                this.isFileStatusApiInProgress = false;
            }
        }, err => {
            this.handleApiError(err);
            this.stopTimer();
        });
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * encapsulate react render function
     */
    renderUpload = value => {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <div className="p-4">
                    <h2> {i18n.t('deviceListCreateViewEditAndUpload.form.uploadDevice')} </h2>
                    <div className="view-profile-details">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="filename">{i18n.t('deviceListCreateViewEditAndUpload.form.label.filename')}</label>
                                    <input type="text" id="filename" disabled name="filename" className="form-control upload-btn-filename"
                                        placeholder={i18n.t('deviceListCreateViewEditAndUpload.form.placeholder.filename')}
                                        value={this.state.formfields.filename} />
                                    <FormErrors formErrors={this.state.devicefileType} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <input id="devicefileid" title="" type="file" name="deviceFileb" onChange={this.showFileName.bind(this)} ref={this.csvFileInputRef} />
                                        <button type="button" className="btn btn-primary upload-btn" onClick={this.openExplorer.bind(this)} > {i18n.t('deviceListCreateViewEditAndUpload.form.button.upload')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetDeviceFormFields.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.reset')}
                            </button>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.handleBack.bind(this)}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={!this.state.formValid}
                                onClick={this.submitHandler}>
                                {i18n.t('deviceListCreateViewEditAndUpload.form.button.save')}
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * react render func
     */
    render() {
        let breadcrumb = [
            { id: "home", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: "#/home", className: "", link: true },
            { id: "device", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: "", link: false },
            { id: "upload", displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.upload'), className: "", link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
                }
                {this.renderUpload()}
            </div>
        );
    }
}
export default UploadDevice;