import _ from 'lodash';

export const REFRESH_TOKEN_PATH = 'auth/token';

/**
 * api interface for the application. It is updated with api configurations on app startup.
 */
let API_INTERFACE = {};

/**
 * update API_INTERFACE with all API endpoints used.
 */
export function updateApiInterface (apiConfig) {
  apiConfig = !_.isObject(apiConfig) ? {} : apiConfig;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const X_API_KEY = process.env.REACT_APP_X_API_KEY;
  const OEM_ID = process.env.REACT_APP_OEM_ID;
  const CUSTOMER_PORTAL = process.env.REACT_APP_CUSTOMER_PORTAL;
  
  API_INTERFACE = {
    OEM_ID: OEM_ID,
    X_API_KEY: X_API_KEY,
    CUSTOMER_PORTAL : CUSTOMER_PORTAL,
    /**
     * auth service endpoints
     */
    AUTH_LOGIN: BASE_URL + 'auth/login/',
    AUTH_LOGOUT: BASE_URL + 'auth/logout/',
    RESET_PWD_TOKEN_REQUEST: BASE_URL + 'auth/reset-password-request',
    RESET_PWD: BASE_URL + 'auth/reset-password',
    REFRESH_TOKEN: BASE_URL + REFRESH_TOKEN_PATH,

    /**
     * user service endpoints
     */
    GET_USER: BASE_URL + 'api/v1/user',
    GET_USERS: BASE_URL + 'api/v1/users',
    CHANGE_PASSWORD: BASE_URL + 'api/v1/user/changepassword',
    ADMIN_CHANGE_PASSWORD: BASE_URL + 'api/v1/user/adminchangepassword',
    FORCE_CHANGE_PASSWORD: BASE_URL + 'api/v1/user/forcechangepassword',
    GET_ROLES: BASE_URL + 'api/v1/roles',
    CREATE_USER: BASE_URL + 'api/v1/user',
    UPDATE_USER: BASE_URL + 'api/v1/user',
	UPDATE_USER_V2: BASE_URL + 'api/v2/user',
    ASSIGN_USER: BASE_URL + 'api/v1/user/',
    ASSIGNMENTS: BASE_URL + 'api/v1/user/',
    DELETE_ASSIGNMENT: BASE_URL + 'api/v1/user/',
    CHANGE_EMAIL: BASE_URL + 'api/v1/user/changeemail',
    CONFIRM_CHANGE_EMAIL: BASE_URL + 'api/v1/user/confirmchangeemail',
    RESEND_CONFIRMATION_EMAIL: BASE_URL + 'api/v1/user/sendverifylink',
    GET_USERS_BY_PROFILE: BASE_URL + 'api/v1/user/getbyuserprofiledata',

    /**
     * vehicle endpoints
     */
    VEHICLE_POST_FILE: BASE_URL + 'api/v1/vehicles/file',
    GET_VEHICLES: BASE_URL + 'api/v1/vehicles',
    GET_VEHICLES_BY_ID: BASE_URL + 'api/v1/vehicles',
    CREATE_VEHICLE: BASE_URL + 'api/v1/vehicles',
    CREATE_VEHICLE_V2: BASE_URL + 'api/v2/vehicles',
    GET_VEHICLE: BASE_URL + 'api/v1/vehicles',
    UPDATE_VEHICLE: BASE_URL + 'api/v1/vehicles',
    VEHICLE_FILE_STATUS: BASE_URL + 'api/v1/vehicles/file/status',

    /**
     * firmware endpoints
     */
    FIRMWARE: BASE_URL + 'api/v1/firmware',
    FIRMWARES: BASE_URL + 'api/v1/firmwares',
    FIRMWARE_TYPE: BASE_URL + 'api/v1/firmware/type',

    /**
     * implement service endpoints
     */
    GET_IMPLEMENTS: BASE_URL + 'api/v1/implements',
    GET_IMPLEMENT: BASE_URL + 'api/v1/implement',

    /**
     * Device service endpoints
     */
    LIST_DEVICES: BASE_URL + 'api/v1/devices',
    CREATE_DEVICE: BASE_URL + 'api/v1/devices',
    UPDATE_DEVICE: BASE_URL + 'api/v1/devices',
    GET_DEVICE: BASE_URL + 'api/v1/devices',
    UPLOAD_DEVICE: BASE_URL + 'api/v1/devices/file',
    DELETE_DEVICE: BASE_URL + 'api/v1/devices/',
    DEVICE_FILE_STATUS: BASE_URL + 'api/v1/devices/file/status',
    DEVICE_SUBCRIPTION_LIST: BASE_URL + 'api/v1/devices/subscriptions',
    CREATE_SUBSCODE: BASE_URL + 'api/v1/devices/subscriptions',
    GET_SUBSCODE: BASE_URL + 'api/v1/devices/subscriptions',
    UPDATE_SUBSCODE: BASE_URL + 'api/v1/devices/subscriptions',
    DELETE_SUBSCODE: BASE_URL + 'api/v1/devices/subscriptions',
    /**
     * OEM service ep
     */
    LIST_OEMS: BASE_URL + 'api/v1/oems',

    /**
     * support service ep
     */
    LIST_SUPPORT: BASE_URL + 'api/v1/support/requests',
    GET_SUPPORT: BASE_URL + 'api/v1/support/request',
	GET_SUPPORT_V2: BASE_URL + 'api/v2/support/request',
    DECRYPT_LOG: BASE_URL + 'api/v1/support/download/decrypt/link/',
    DOWNLOAD_LOG: BASE_URL + 'api/v1/support/download/link/',
    LIST_SUPPORT_BY_SUBS: BASE_URL + 'api/v1/support/getsupportlogsbyuserlist',

    /**
     * calibrations endpoint
     */
    GET_CALIBRATIONS: BASE_URL + 'api/v1/calibrations',
    GET_CALIBRATION: BASE_URL + 'api/v1/calibration',
    CREATE_CALIBRATION: BASE_URL + 'api/v2/calibrations',
    GET_DEFAULTCALIBRATIONS: BASE_URL + 'api/v2/calibrations',

    /**
     * compatibility endpoint
     */
    GET_COMPATIBILITIES: BASE_URL + 'api/v1/compatibilites',
    GET_INCOMPATIBILITIES_DOWNLOAD_URL: BASE_URL + 'api/v1/compatibility/download',
    INCOMPATIBILITIES_FILE_UPLOAD_URL: BASE_URL + 'api/v1/compatibility/file',
    INCOMPATIBILITIES_FILE_STATUS: BASE_URL + 'api/v1/compatibility/file/status',

    /**
     * documents endpoint
     */
    CREATE_DOCUMENT: BASE_URL + 'api/v1/document',
    GET_DOCUMENT: BASE_URL + 'api/v1/document',
    UPDATE_DOCUMENT: BASE_URL + 'api/v1/document',
    DELETE_DOCUMENT: BASE_URL + 'api/v1/document',
    GET_DOCUMENT_DOWNLOAD_URL: BASE_URL + 'api/v1/document/download/link',
    GET_DOCUMENTS: BASE_URL + 'api/v1/documents',

    /**
     * operation service endpoints
     */
    GET_OPERATION: BASE_URL + 'api/v1/operation',
    GET_OPERATIONS: BASE_URL + 'api/v1/operations',

    /**
     * crop service endpoints
     */
    GET_CROP: BASE_URL + 'api/v1/crop',
    GET_CROPS: BASE_URL + 'api/v1/crops'
  };
}

export { API_INTERFACE };
