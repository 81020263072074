import React from 'react';
import BaseScreen from '../BaseScreen';
import { FormErrors, SubmissionAlert } from "../../../Component/SubmissionStatus";
import { UserService } from "../../../services/service.users";
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../Component/loader/Loader";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';

/**
 * Edit user component
 */
class UserEdit extends BaseScreen {
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                id: this.props.match.params.userId,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                roleId: ''
            },
            formErrors: { email: '', firstName: '', lastName: '', phone: '' },
            firstNameValid: true,
            lastNameValid: true,
            phoneValid: true,
            emailValid: true,
            formValid: false,
            roles: [],
            isLoading: true,
            apiResponseIsHandled: false,
            apiResponseMessage: '',
            isApiResponseSuccess: false,
            isErrorOnLoad: false,
			editEmail: false
        }
    }

    componentDidMount() {
        UserService.getOemRoles().then(
            (allRoles) => {
                this.setState({ roles: allRoles });
                this.displayUserInfo();
            },
            (error) => {
                this.setState({
                    roles: []
                });
                this.displayUserInfo();
            }
        );
    }

    /**
     * function to access fetch user api.
     */
    displayUserInfo() {
        let userId = this.props.match.params.userId;
        UserService.getUser(userId).then(
            (response) => {
                this.setState({
                    userData: response.data,
                    isLoading: false
                });
            },
            (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (error && error.data && error.data.message) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    userData: {},
                    isLoading: false,
                    apiResponseMessage: errorMsg,
                    isErrorOnLoad: true,
                    apiResponseIsHandled: true
                });
            });
    }
    
    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.userData;
        formfields[name] = value;
        this.setState({ formfields: formfields },
            () => { this.validateField(name, value) });
    }

    /**
     * firmware form field validation
     * @param {*} fieldName 
     * @param {*} value 
     */
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let phoneValid = this.state.phoneValid;
        let emailValid = this.state.emailValid;
        switch (fieldName) {
            case 'firstName':
                if (value.length === 0) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = firstNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length > 32) {
                    firstNameValid = false;
                    fieldValidationErrors.firstName = firstNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.char32');
                } else {
                    firstNameValid = CommonUtilities.isValidName(value);
                    fieldValidationErrors.firstName = firstNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.alphabate');
                }
                break;
            case 'lastName':
                if (value.length === 0) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length > 32) {
                    lastNameValid = false;
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.char32');
                } else {
                    lastNameValid = CommonUtilities.isValidName(value);
                    fieldValidationErrors.lastName = lastNameValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.alphabate');
                }
                break;
            case 'phone':
                if (value.length === 0) {
                    phoneValid = false;
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else if (value.length < 7 || value.length > 15) {
                    phoneValid = false;
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.phone');
                } else {
                    phoneValid = value.match(/^(?:[0-9] ?){6,14}[0-9]$/i);
                    fieldValidationErrors.phone = phoneValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.integer');
                }
                break;
            case 'email':
                if (value.length > 320) {
                    emailValid = false;
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.email320');
                } else if (value.length === 0) {
                    emailValid = false;
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.required');
                } else {
                    emailValid = CommonUtilities.isValidEmail(value);
                    fieldValidationErrors.email = emailValid ? '' : i18n.t('user.usersListCreateViewAndEdit.alert.emailInvalid');
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            emailValid: emailValid,
            phoneValid: phoneValid
        }, this.validateForm);
    }

    /**
     * check if form is valid
     */
    validateForm() {
        this.setState({ formValid: this.state.firstNameValid && this.state.lastNameValid && this.state.emailValid && this.state.phoneValid });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    /**
     * function to call remote api to save updated user in backend
     */
    updateUserSubmit = (event) => {
        event.preventDefault();
        UserService.updateUserSubmission(this.state.formfields).then(
            (theResponse) => {
                let message = i18n.t('user.usersListCreateViewAndEdit.alert.userEditSuccess');
                this.setState({ apiResponseIsHandled: true, apiResponseMessage: message, isApiResponseSuccess: true, editEmail: false });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({ apiResponseMessage: message, apiResponseIsHandled: true, isApiResponseSuccess: false });
            }
        );
    }

    /**
     * dismissal of alert message component.
     */
    onDismiss = () => {
        this.setState({ apiResponseIsHandled: false, editEmail: false });
    }

 	/**
     * Make email field Editable
     */
    handleChangeEmailCb = (event) => {
		let checked = event.target.checked;
		this.setState({ editEmail: checked });		
    }

    /**
     * go back to previous page
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * display error message at top
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}>{i18n.t('user.usersListCreateViewAndEdit.form.button.back')}</button>
            </div>
        );
    }

    /**
     * Edit user form rendering.
     */
    renderEditUserForm() {
        return (
            <form className="createUserForm">
                <h2> {i18n.t('user.usersListCreateViewAndEdit.editUser')} </h2>
                <div className="view-profile-details">
                <div className="row">
                    
                    <div className="col-md-6">
                        <div className={`form-group ${this.errorClass(this.state.formErrors.firstName)}`}>
                            <label htmlFor="firstName"> {i18n.t('user.usersListCreateViewAndEdit.form.label.firstName')} <StyledText uiText="*"/> </label>
                            <input type="text" required className="form-control" name="firstName"
                                value={this.state.userData.firstName}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.firstName} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`form-group`}>
                            <label htmlFor="roleId">{i18n.t('user.usersListCreateViewAndEdit.form.label.role')} <StyledText uiText="*"/>:</label>
                            <select className="form-control" id="roleId" name="roleId" disabled={true}>
                                {
                                    this.state.roles.map((elem, id) => {
                                        if (elem.roleId === this.state.userData.roleId) {
                                            return <option key={elem.roleDisplayName + id} value={elem.roleId}>{elem.roleDisplayName}</option>
                                        } else {
                                            return "";
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-6">
                        <div className={`form-group ${this.errorClass(this.state.formErrors.lastName)}`}>
                            <label htmlFor="lastName"> {i18n.t('user.usersListCreateViewAndEdit.form.label.lastName')} <StyledText uiText="*"/></label>
                            <input type="text" className="form-control" name="lastName"
                                value={this.state.userData.lastName}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.lastName} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`form-group ${this.errorClass(this.state.formErrors.phone)}`}>
                            <label htmlFor="phone"> {i18n.t('user.usersListCreateViewAndEdit.form.label.phone')} <StyledText uiText="*"/></label>
                            <input type="text" className="form-control" name="phone"
                                value={this.state.userData.phone}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.phone} />
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12">	                    
						<div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                            <input type="checkbox" name="changeUserEmail"
	                        	checked = {this.state.editEmail}
	                        	onChange={this.handleChangeEmailCb} /> &nbsp;
	                     	<label htmlFor="changeUserEmail">{i18n.t('user.usersListCreateViewAndEdit.form.placeholder.changeUserEmail')}</label>
							<input type="email" className="form-control" name="email" disabled={!this.state.editEmail}
                                value={this.state.userData.email}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.email} />
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)} > {i18n.t('user.usersListCreateViewAndEdit.form.button.back')} </button>
                        <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this.updateUserSubmit} > {i18n.t('user.usersListCreateViewAndEdit.form.button.save')} </button>
                    </div>
                </div>
            </form>
        )
    }

    /**
     * react render
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'user', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.user'), className: '', link: false },
            { id: 'edit', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.edit'), className: '', link: false }
        ];

        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return (
                <div>
                    <div className="container-fluid p-4">
                        <div className="float-left">
                            <BreadcrumbCustom breadcrumb={breadcrumb} />
                        </div>
                        <div className="clearfix"></div>
                        {this.state.apiResponseIsHandled && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.isApiResponseSuccess} onDismiss={this.onDismiss} />}
                        {this.state.isErrorOnLoad ? this.renderError() : this.renderEditUserForm()}
                    </div>
                </div>
            );
        }
    }
}
export default UserEdit;