import React from "react";
import BreadcrumbCustom from "../../../../Component/breadcrumb/BreadcrumbCustom";
import BaseScreen from "../../BaseScreen";
import { SCREENS } from "../../../../constants/screens.constant";
import AlertMessage from "../../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../../Component/SubmissionStatus";
import { FirmwareService } from "../../../../services/service.firmwares";
import { StyledText } from "../../../../Component/StyledText";
import i18n from 'i18next';

/**
 * Device Create component.
 */
class CreateFirmwareType extends BaseScreen {
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }
        this.state = {
            isAuthenticated: isAuthenticated,
            isLoading: true,
            isInactive: true,
            formfields: {
                deviceType: '',
                description: ''
            },
            formErrors: {
                deviceType: '',
                description: ''
            },
            formValid: false,
            alert: {
                type: null,
                message: null
            },
            isWaitingApiResponse: false,
            isErrorOnLoad: false,
        }
    }

    componentDidMount() {
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        let fieldValidationErrors = this.state.formErrors;
        let msg = "";
        switch (name) {
            case "deviceType":
                if (value.length === 0) {
                    msg = i18n.t('firmwareListCreateViewAndEdit.alert.deviceType');
                }
                fieldValidationErrors.deviceType = msg;
                break;
            case "description":
                if (value.length === 0) {
                    msg = i18n.t('firmwareListCreateViewAndEdit.alert.description');
                }
                fieldValidationErrors.description = msg;
                break;
            default:
                break;
        }

        let isFormValid = (formfields.deviceType && formfields.description) &&
            (fieldValidationErrors.deviceType === "" && fieldValidationErrors.description === "");

        this.setState({
            formfields: formfields,
            formErrors: fieldValidationErrors,
            formValid: isFormValid,
        });
    }

    /**
     * submit handler, this method calls respective service method to get the job done by communicating to backend.
     * @param {*} event 
     */
    submitHandler(event) {
        event.preventDefault();
        let that = this;
        that.setState({
            isWaitingApiResponse: true
        });
        FirmwareService.createFirmwareTypeSubmission(this.state.formfields).then(function (res) {
            let lastSavedFormFields = that.state.formfields;
            that.setState({
                alert: {
                    type: "success",
                    message: i18n.t('firmwareListCreateViewAndEdit.alert.firmwareTypeCreate')
                },
                isWaitingApiResponse: false,
                savedFormFields: { ...lastSavedFormFields }
            });
        }, function (res) {
            let alertMsg = i18n.t('common.genericApiError');
            if (res && res.data && res.data.message) {
                alertMsg = res.data.message;
            }
            that.setState({
                alert: {
                    type: "danger",
                    message: alertMsg
                },
                isWaitingApiResponse: false
            });
        });

    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss() {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * validates the form before submitting. 
     * save button should not be activated if invalid form.
     */
    isFormValid() {
        let returnVal = (this.state.formValid);
        return returnVal;
    }

    /**
     * go back function. goes to previous view.
     */
    handleBack() {
        this.props.history.goBack();
    }

    /**
     * reset function to reset the form to previous state.
     */
    resetDeviceFormFields() {
        this.setState({
            formfields: {
                deviceType: '',
                description: ''
            },
            formErrors: {
                deviceType: '',
                description: ''
            },
            formValid: false,
        });
    }

    /**
     * encapsulate react render function
     */
    renderDeviceForm() {
        return (
            <div className="">
                <form autoComplete="off">
                    <h2> {i18n.t('firmwareListCreateViewAndEdit.form.createFirmwareType')} </h2>
                    <div className="view-profile-details">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="deviceType"> {i18n.t('firmwareListCreateViewAndEdit.form.label.deviceType')} <StyledText uiText="*"/></label>
                                <input type="text" required className="form-control" name="deviceType"
                                    placeholder={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.deviceType')}
                                    value={this.state.formfields.deviceType}
                                    onChange={this.handleUserInput} maxLength="32" />
                                <FormErrors formErrors={this.state.formErrors.deviceType} />
                            </div>
                        </div>
                        
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="form-group">
                                <label htmlFor="description"> {i18n.t('firmwareListCreateViewAndEdit.form.label.description')} <StyledText uiText="*"/> </label>
                                <textarea required className="form-control" name="description"
                                    placeholder={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.description')}
                                    cols={50} rows={6}
                                    value={this.state.formfields.description}
                                    onChange={this.handleUserInput} maxLength="256" />
                                <FormErrors formErrors={this.state.formErrors.description} />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.resetDeviceFormFields.bind(this)}>
                                {i18n.t('firmwareListCreateViewAndEdit.form.button.reset')}
                            </button>
                            <button type="button" className="btn btn-secondary mr-2"
                                onClick={this.handleBack.bind(this)}>
                                {i18n.t('firmwareListCreateViewAndEdit.form.button.back')}
                            </button>
                            <button type="submit" className="btn btn-primary"
                                disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                                onClick={this.submitHandler.bind(this)}>
                                {i18n.t('firmwareListCreateViewAndEdit.form.button.save')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>Back</button>
            </div>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: "home", displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: "#/home", className: "", link: true },
            { id: "device", displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmwareType'), className: "", link: false },
            { id: "create", displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.create'), className: "", link: false }
        ];
        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderDeviceForm()
                }
            </div>
        );
    }
}

export default CreateFirmwareType;
