import React from 'react';
import Grid from '../../../Component/grid/Grid';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { ImplementsService } from '../../../services/service.implements';
import i18n from 'i18next';

/**
 * Informations to be shown in implement listing grid, along with options per rows in support details page.
 */
let implementListConfig = {};

/**
 * List associated implement component
 */
class ListConcernedImplement extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize implementListConfig with updated i18n resources
    implementListConfig = {
      name: {
        label: i18n.t('supportListViewAndConcerns.list.name'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      length: {
        label: i18n.t('supportListViewAndConcerns.list.length'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      position: {
        label: i18n.t('supportListViewAndConcerns.list.position'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      localId: {
        label: i18n.t('supportListViewAndConcerns.list.localId'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      implementId: this.props.implementId,
      implementData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 1,
        totalCount: 0,
        pageList: [10]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      if (this.props.implementId) {
        this.getImplementsById(this.props.implementId).then(
          response => {
            const implement = [];
            implement.push(response.data);
            this.setState({
              implementData: implement,
              loader: false,
              modalShow: false
            });
          },
          error => {
            this.setState({
              implementData: [],
              loader: false,
              modalShow: false,
              reinitialize: false
            });
          });
      } else {
        this.setState(
          {
            implementData: [],
            loader: false,
            modalShow: false,
            reinitialize: false
          });
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.implementId !== this.props.implementId && this.props.implementId) {
      this.getImplementsById(this.props.implementId).then(response => {
        const implement = [];
        implement.push(response.data);
        this.setState({
          implementData: implement,
          loader: false,
          modalShow: false
        });
      }, error => {
        this.setState(
          {
            implementData: [],
            loader: false,
            modalShow: false,
            reinitialize: false
          });
      });
    }
  }

  /**
   * get implement by id from backend.
   * @param {*} id 
   */
  getImplementsById (id) {
    return ImplementsService.getImplement(id)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user/view-implement/' + row.id
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render view form
   */
  render () {
    const tableScrollStyle = {
      maxHeight: '530px',
      overflow: 'hidden',
      overflowY: 'auto'
    };

    return (
      <div>
        <div style={tableScrollStyle}>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          <div>
            <Grid id="userImplementsListGrid"
              keyColumn="id"
              rowData={this.state.implementData}
              columnDefs={implementListConfig}
              loading={this.state.loader}
              pagination={false}
              actionPaths={this.actionPaths.bind(this)} />
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListConcernedImplement;
