import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import Grid from '../../../Component/grid/Grid';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ModalDialog, { ModalDialogForFileDownload } from '../../../Component/modaldialog/ModalDialog';
import { DocumentsService } from '../../../services/service.documents';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';

/**
 * Informations to be shown in document listing grid, along with options per rows.
 */
let documentsListConfig = {};

/**
 * List documents component
 */
class ListDocuments extends BaseScreen {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }

        // initialize documentsListConfig with updated i18n resources
        documentsListConfig = {
            fileName: {
                label: i18n.t('documentViews.list.fileName'),
                sort: true,
                filter: true,
                filterMaxLength: 50,
                display: true
            },
            description: {
                label: i18n.t('documentViews.list.description'),
                sort: true,
                filter: true,
                filterMaxLength: 50,
                display: true
            },
            createdAt: {
                label: i18n.t('documentViews.list.createdAt'),
                display: true,
                sort: true,
                isDate: true
            },
            modifiedAt: {
                label: i18n.t('documentViews.list.modifiedAt'),
                display: true,
                sort: true,
                isDate: true
            },
            options: {
                label: i18n.t('documentViews.list.options.options'),
                display: true,
                list: [
                    {
                        actionType: 'link',
                        action: 'view',
                        label: i18n.t('documentViews.list.options.view')
                    }, {
                        actionType: 'link',
                        action: 'edit',
                        label: i18n.t('documentViews.list.options.edit')
                    }, {
                        action: 'document_download',
                        label: i18n.t('documentViews.list.options.download')
                    }, {
                        action: 'delete',
                        label: i18n.t('documentViews.list.options.delete')
                    }
                ]
            }
        };

        this.state = {
            isAuthenticated: isAuthenticated,
            documentsData: [],
            loader: true,
            pgnConfig: {
                currentPage: getListIndex(SCREEN_ID.ListKnowledge),
                numberOfPageLinks: 0,
                countPerPage: 25, // default page size
                totalCount: 0,
                pageList: [25, 50, 100]
            },
            alert: {
                type: null,
                message: null
            },
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: '',
                modalMsg: '',
                modalData: '',
                modalAction: '',
                modalType: ''
            },
            sortFieldsConfig: {},
            filterFieldsConfig: {}
        };
    }

    /**
     * React life cycle method, called after component is first 
     * rendered. Fetch document list data.
     */
    componentDidMount() {
        if (this.state.isAuthenticated) {
            const arg = {
                currentPage: this.state.pgnConfig.currentPage -1,
                pageSize: this.state.pgnConfig.countPerPage
            };

            // get document list data
            this.getDocumentsList(arg, this.state.sortFieldsConfig, this.state.filterFieldsConfig)
                .then(response => {
                    const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage,
                        this.state.pgnConfig.countPerPage, response.data.total);

                    this.setState({
                        documentsData: response.data.items,
                        loader: false,
                        pgnConfig: pgnConfig
                    });
                }, error => {
                    let errorMsg = i18n.t('common.genericApiError');
                    if (_.has(error, 'data.message')) {
                        errorMsg = error.data.message;
                    }
                    this.setState({
                        documentsData: [],
                        loader: false,
                        alert: {
                            type: 'danger',
                            message: errorMsg
                        }
                    });
                });
        }
    }

    componentWillUnmount () {
        setListIndex(SCREEN_ID.ListKnowledge, this.state.pgnConfig.currentPage)
    }
    /**
     * fetch documents based on pagination, sort fields and filter
     * @param {*} requiredParams 
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    getDocumentsList(requiredParams, sortFieldsObj, filterFieldsObj) {
        let reqQueryParams = '';
        let params;
        if (requiredParams) {
            params = {
                currentPage: requiredParams.currentPage,
                pageSize: requiredParams.pageSize
            };
        } else {
            params = {
                currentPage: this.state.pgnConfig.currentPage - 1,
                pageSize: this.state.pgnConfig.countPerPage
            };
        }
        reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

        let sortFieldsQueryParams = '';
        if (!sortFieldsObj) {
            sortFieldsObj = this.state.sortFieldsConfig;
        }
        let sortQueryTemp = '';
        let key;
        for (key in sortFieldsObj) {
            const sortValue = sortFieldsObj[key].sortOrder;
            if (sortValue) {
                sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
                sortFieldsQueryParams += '&' + sortQueryTemp;
            }
        }

        let filterFieldQueryParams = '';
        if (!filterFieldsObj) {
            filterFieldsObj = this.state.filterFieldsConfig;
        }

        let filterQueryTemp = '';
        for (key in filterFieldsObj) {
            const filterValue = filterFieldsObj[key].value;
            if (filterValue) {
                filterQueryTemp = key + '=' + filterValue;
                filterFieldQueryParams += '&' + filterQueryTemp;
            }
        }

        reqQueryParams += sortFieldsQueryParams + filterFieldQueryParams;

        return DocumentsService.getDocuments(reqQueryParams).then(response => {
            return response;
        }, errResponse => {
            return Promise.reject(errResponse);
        });
    }

    /**
     * helper function for pagination configuration
     * @param {*} currentPage 
     * @param {*} countPerPage 
     * @param {*} totalCount 
     */
    setPaginationConfig(currentPage, countPerPage, totalCount) {
        return {
            currentPage: currentPage,
            numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
            countPerPage: countPerPage,
            totalCount: totalCount,
            pageList: [...this.state.pgnConfig.pageList]
        };
    }

    /**
     * handler when page number change, like user move to next 
     * page or previous page from current page he is viewing
     * @param {*} pageNumber 
     */
    onPgnChange = (pageNumber) => {
        if (pageNumber !== this.state.pgnConfig.currentPage) {
            window.scrollTo(0, 0);
            this.setState({ loader: true, documentsData: [] });

            const arg = {
                currentPage: (pageNumber - 1),
                pageSize: this.state.pgnConfig.countPerPage
            };

            this.getDocumentsList(arg).then((response) => {
                const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage,
                    response.data.total);

                this.setState({
                    documentsData: response.data.items,
                    loader: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                });
            }, (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (_.has(error, 'data.message')) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    documentsData: [],
                    loader: false,
                    alert: {
                        type: 'danger',
                        message: errorMsg
                    }
                });
            });
        }
    }

    /**
     * handles the grid listing when there is any change in per page row count, like if user 
     * changes default row count 25 to 50 or something else.
     * @param {*} event 
     */
    onPgnRowsCountChange = (event) => {
        window.scrollTo(0, 0);
        const perPageItems = Number(event.target.value);
        let currentPage;
        if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
            currentPage = 1;
        } else {
            currentPage = this.state.pgnConfig.currentPage;
        }

        this.setState({ loader: true, documentsData: [] });
        const arg = {
            currentPage: currentPage - 1,
            pageSize: perPageItems
        };

        this.getDocumentsList(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
            this.setState({
                documentsData: response.data.items,
                loader: false,
                pgnConfig: pgnConfig,
                alert: {
                    type: null,
                    message: null
                }
            });
        }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (_.has(error, 'data.message')) {
                errorMsg = error.data.message;
            }
            this.setState({
                documentsData: [],
                loader: false,
                alert: {
                    type: 'danger',
                    message: errorMsg
                }
            });
        });
    }

    /**
     * lazy load function when user use filter or sort functionality from grid header
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    onLazyLoad = (sortFieldsObj, filterFieldsObj) => {
        this.setState({ loader: true, documentsData: [] });
        const pageNumber = 1;
        const arg = {
            currentPage: pageNumber - 1,
            pageSize: this.state.pgnConfig.countPerPage
        };

        this.getDocumentsList(arg, sortFieldsObj, filterFieldsObj)
            .then((response) => {
                const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage,
                    response.data.total);

                if (!sortFieldsObj) {
                    sortFieldsObj = this.state.sortFieldsConfig;
                }

                if (!filterFieldsObj) {
                    filterFieldsObj = this.state.filterFieldsConfig;
                }

                this.setState({
                    documentsData: response.data.items,
                    loader: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                    sortFieldsConfig: sortFieldsObj,
                    filterFieldsConfig: filterFieldsObj
                });
            }, (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (_.has(error, 'data.message')) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    documentsData: [],
                    loader: false,
                    alert: {
                        type: 'danger',
                        message: errorMsg
                    }
                });
            });
    }

    /**
     * alert message handling
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * routing to relevant page based on row option selection
     * @param {*} actionType 
     * @param {*} row 
     */
    actionPaths = (actionType, row) => {
        if (actionType.action === 'view') {
            return ({
                pathname: '/knowledge/view-document/' + row.id
            });
        } else if (actionType.action === 'edit') {
            return ({
                pathname: '/knowledge/edit-document/' + row.id
            });
        }
    }

    /**
     * per row option handler
     * @param {*} actionObject 
     * @param {*} row 
     */
    optionHandler = (actionObject, row) => {
        let modalInfo;
        if (actionObject.action === 'delete') {
            modalInfo = {
                modalMsg: i18n.t('documentViews.modal.deleteMsg') + row.fileName + '?',
                modalTitle: i18n.t('documentViews.modal.deleteTitle'),
                modalAction: 'delete_document'
            };
        } else if (actionObject.action === 'document_download') {
            modalInfo = {
                modalMsg: i18n.t('documentViews.modal.downloadMsg') + row.fileName + '?',
                modalTitle: i18n.t('documentViews.modal.downloadTitle'),
                modalAction: 'document_download',
                modalType: 'document_download'
            };
        }

        this.modalPopup(true, row, modalInfo);
    }

    /**
     * Modal window to show for each row options.
     * @param {*} state 
     * @param {*} rowData 
     * @param {*} modalInfo 
     */
    modalPopup(state, rowData, modalInfo) {
        if (state) {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalMsg: modalInfo.modalMsg,
                    modalTitle: modalInfo.modalTitle,
                    modalData: rowData || '',
                    modalAction: modalInfo.modalAction,
                    modalType: modalInfo.modalType
                }
            });
        } else {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalData: rowData || '',
                    modalAction: this.state.modal.modalAction
                }
            });
        }
    }

    /**
     * modal dismissal action
     */
    handleModalDismiss = () => {
        this.modalPopup(false);
    }

    /**
     * handle document delete action from modal.
     * @param {*} data 
     */
    onModalAction = (data) => {
        const modalAction = this.state.modal.modalAction;
        if (modalAction === 'delete_document') {
            this.modalPopup(false, data);
        } else if (modalAction === "document_download") {
            this.modalPopup(false);
        }
    }

    /**
     * function after modal is closed, re-render listing page after delete operation.
     */
    onModalClosed = () => {
        if (this.state.modal.modalAction === 'delete_document') {
            this.deleteDocument();
        }
    }

    /**
     * delete a document from backend
     */
    deleteDocument() {
        if (this.state.modal.modalData) {
            const modalData = this.state.modal.modalData;
            this.setState({
                loader: true,
                modal: {
                    modalShow: false,
                    shouldRenderModal: false
                }
            });
            DocumentsService.deleteDocument(modalData.id)
                .then((res) => {
                    let pageNumber = this.state.pgnConfig.currentPage;
                    if ((this.state.documentsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
                        pageNumber = this.state.pgnConfig.currentPage - 1;
                    }
                    this.setState({
                        documentsData: [],
                        alert: {
                            type: 'success',
                            message: i18n.t('documentViews.alert.documentDeleted')
                        }
                    });
                    const arg = {
                        currentPage: (pageNumber - 1),
                        pageSize: this.state.pgnConfig.countPerPage
                    };
                    this.getDocumentsList(arg).then((response) => {
                        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
                        this.setState({
                            documentsData: response.data.items,
                            loader: false,
                            pgnConfig: pgnConfig
                        });
                    }, (error) => {
                        let errorMsg = i18n.t('common.genericApiError');
                        if (_.has(error, 'data.message')) {
                            errorMsg = error.data.message;
                        }
                        this.setState(
                            {
                                documentsData: [],
                                loader: false,
                                alert: {
                                    type: 'danger',
                                    message: errorMsg
                                }
                            });
                    });
                }, (error) => {
                    let errorMsg = i18n.t('common.genericApiError');
                    if (_.has(error, 'data.message')) {
                        errorMsg = error.data.message;
                    }
                    this.setState(
                        {
                            loader: false,
                            alert: {
                                type: 'danger',
                                message: errorMsg
                            }
                        });
                });
        }
    }

    /**
     * react render function for redering list of documents
     */
    render() {
        const breadcrumb = [
            { id: 'home', displayName: i18n.t('documentViews.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'knowledge', displayName: i18n.t('documentViews.breadcrumb.knowledge'), className: '', link: false },
            { id: 'list', displayName: i18n.t('documentViews.breadcrumb.list'), className: '', link: false }
        ];

        return (
            <div>
                <div className='container-fluid p-4'>
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className='clearfix'></div>
                    {
                        this.state.alert.message &&
                        <AlertMessage message={this.state.alert.message}
                            type={this.state.alert.type}
                            isAlertOpen={!!(this.state.alert.message)}
                            handleDismiss={this.handleAlertDismiss}
                        />
                    }
                    <div>
                        <Grid id='documentsListGrid'
                            keyColumn='id'
                            rowData={this.state.documentsData}
                            columnDefs={documentsListConfig}
                            loading={this.state.loader}
                            pagination={true}
                            pgnConfig={this.state.pgnConfig}
                            onPgnChange={this.onPgnChange}
                            onPgnRowsCountChange={this.onPgnRowsCountChange}
                            pageList={this.state.pageList}
                            lazyLoading={true}
                            onLazyLoad={this.onLazyLoad}
                            optionHandler={this.optionHandler}
                            actionPaths={this.actionPaths} />
                    </div>
                </div>
                {
                    this.state.modal.shouldRenderModal &&
                    (this.state.modal.modalType === 'document_download'
                        ? <ModalDialogForFileDownload
                            modalTitle={this.state.modal.modalTitle}
                            modalMessage={this.state.modal.modalMsg}
                            modalData={this.state.modal.modalData}
                            isModalOpen={this.state.modal.modalShow}
                            modalAction={this.onModalAction}
                            handleModalDismiss={this.handleModalDismiss}
                            onModalClosed={this.onModalClosed}
                            modalType={this.state.modal.modalType} />
                        : <ModalDialog
                            modalTitle={this.state.modal.modalTitle}
                            modalMessage={this.state.modal.modalMsg}
                            modalData={this.state.modal.modalData}
                            isModalOpen={this.state.modal.modalShow}
                            modalAction={this.onModalAction}
                            handleModalDismiss={this.handleModalDismiss}
                            onModalClosed={this.onModalClosed} />
                    )
                }
            </div>
        );
    }
}

export default ListDocuments;
