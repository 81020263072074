import React from 'react';
import { UserService } from '../../../services/service.users';
import Grid from '../../../Component/grid/Grid';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import i18n from 'i18next';

/**
 * Informations to be shown in associated user listing grid, along with options per rows.
 */
let userListConfig = {};

/**
 * List Associated Operators component
 */
class ListAssociatedOperators extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize userListConfig with updated i18n resources
    userListConfig = {
      firstName: {
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.list.firstName'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      lastName: {
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.list.lastName'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      email: {
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.list.email'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      status: {
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.list.status'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      options: {
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.list.options'),
        display: true
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      accountHoldersData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10,
        totalCount: 0,
        pageList: [10]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      },
      reinitialize: false,
      forUser: this.props.userId
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage
      };
      this.getAccountHoldersByOperatorId(arg).then(
        response => {
          const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
          const accountHoldersData = this.prepareAccountHoldersData(response.data.items);
          this.setState({
            accountHoldersData: accountHoldersData,
            pgnConfig: pgnConfig,
            loader: false,
            modalShow: false,
            reinitialize: false
          });
        },
        error => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              accountHoldersData: [],
              loader: false,
              modalShow: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /**
   * prepare response received from backend to understandable format by changing assignment request status 
   * from 0 or 1 to 'Requested' or 'Request Received' or 'Assigned'
   * @param {*} data 
   */
  prepareAccountHoldersData (data) {
    let index = 0;
    for (index = 0; index < data.length; index++) {
      if (data[index].status === 0) {
        if (data[index].createdBy === this.props.userId) {
          data[index].status = i18n.t('user.listAssociatedAccountHoldersAndOperator.form.requested');
        } else if (data[index].createdBy !== this.props.userId) {
          data[index].status = i18n.t('user.listAssociatedAccountHoldersAndOperator.form.requestReceived');
        }
      } else if (data[index].status === 1) {
        data[index].status = i18n.t('user.listAssociatedAccountHoldersAndOperator.form.assigned');
      }
    }
    return data;
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, accountHoldersData: [] });
      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };
      this.getAccountHoldersByOperatorId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          accountHoldersData: this.prepareAccountHoldersData(response.data.items),
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false,
          alert: {
            type: null,
            message: null
          }
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            accountHoldersData: [],
            loader: false,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * fetch all associated operator with the account holders .
   * @param {*} requiredParams 
   */
  getAccountHoldersByOperatorId (requiredParams) {
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }
    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize + '&accountId=' + this.props.userId;
    return UserService.getAccountHoldersByOperatorId(reqQueryParams, this.props.userId).then(
      response => {
        return this.prepareUsersResponse(response);
      },
      errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * prepare response received to understandable format to display in grid.
   * @param {*} resp 
   */
  prepareUsersResponse (resp) {
    let response = {};
    response.data = {};
    response.data.total = resp.data.total;
    response.data.items = (resp.data.items).map(this.prepareResponseForOptions);
    return response;
  }

  /**
   * customized options for rows based on assignment status.
   * @param {*} value 
   */
  prepareResponseForOptions (value) {
    if (value.status === 0) {
      value.options = [{
        actionType: 'link',
        action: 'view',
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.view')
      }];
    } else if (value.status === 1) {
      value.options = [{
        actionType: 'link',
        action: 'view',
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.view')
      },
      {
        actionType: '',
        action: 'unassign',
        label: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.unassign')
      }];
    }
    return value;
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user/view-user/' + row.userId,
        state: { userData: row }
      });
    }
  }

  /**
   * modal dialogues handling
   * @param {*} actionObject 
   * @param {*} row 
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'unassign') {
      modalInfo = {
        modalMsg: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.modal.unassignMsg') + row.email + '?',
        modalTitle: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.modal.unassignTitle'),
        modalAction: 'unassign_user'
      };
    } else if (actionObject.action === 'cancel') {
      modalInfo = {
        modalMsg: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.modal.cancelMsg') + row.email + '?',
        modalTitle: i18n.t('user.listAssociatedAccountHoldersAndOperator.form.modal.cancelTitle'),
        modalAction: 'cancel_user'
      };
    }
    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * handles modal window close event
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   * handles when admin select any option from each row options
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'unassign_user') {
      this.modalPopup(false, data);
    } else if (modalAction === 'cancel_user') {
      this.modalPopup(false, data);
    }
  }

  /**
   * handles event after modal is closed
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'unassign_user') {
      this.unassignUser();
    } else if (this.state.modal.modalAction === 'cancel_user') {
      this.unassignUser();
    }
  }

  /**
   * function for revoke the mapping between operator and account holders
   */
  unassignUser () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
      UserService.deleteAssignment(modalData.id, this.state.forUser).then(
        (successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.accountHoldersData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }
          this.setState({
            accountHoldersData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.listAssociatedAccountHoldersAndOperator.alert.userUnassignment')
            }
          });
          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };
          this.getAccountHoldersByOperatorId(arg).then(
            (response) => {
              const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
              this.setState({
                accountHoldersData: this.prepareAccountHoldersData(response.data.items),
                loader: false,
                pgnConfig: pgnConfig,
                modal: {
                  modalShow: false,
                  shouldRenderModal: false
                }
              });
            }, (error) => {
              let errorMsg = 'common.genericApiError';
              if (error && error.data && error.data.message) {
                errorMsg = error.data.message;
              }
              this.setState(
                {
                  accountHoldersData: [],
                  loader: false,
                  alert: {
                    type: 'danger',
                    message: errorMsg
                  }
                });
            });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /**
   * alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display user listing along with options and pre-decided information.
   */
  render () {
    return (
      <div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }
          <div>
            <Grid id="usersAssociatedAccountHoldersGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn='id'
              rowData={this.state.accountHoldersData}
              columnDefs={userListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              actionPaths={this.actionPaths.bind(this)}
              customizedOption={true}/>
          </div>
        </div>
        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListAssociatedOperators;
