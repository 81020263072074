import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListIncompatibilities from './ListIncompatibilities';
import UploadIncompatibilities from './UploadIncompatibilities';

/**
 * Root-level compatibility component. Holds all routes for routing to other sub components.
 */
class Compatibility extends BaseScreen {
  /**
   * Component initialization
   * @param {*} props 
   */
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  /**
   * react render function
   */
  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path='/compatibility/list-incompatibilities' component={ListIncompatibilities} />
            <Route exact path='/compatibility/upload-incompatibilities' component={UploadIncompatibilities}/>
            <Redirect from="/compatibility" to="/compatibility/list-incompatibilities" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Compatibility;
