import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to get all calibrations for an "account holder" or operator
 * @param {string} queryString 
 */
function getCalibrations(queryString) {
  const url = API_INTERFACE.GET_CALIBRATIONS + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to get all calibrations for an "account holder" or operator
 * @param {string} queryString 
 */
function getDefaultCalibrations(queryString) {
  const url = API_INTERFACE.GET_DEFAULTCALIBRATIONS + '?vehicleId=' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to fetch a calibration based on calibrationId
 * @param {*} calibrationId
 */
function getCalibration (calibrationId) {
  const url = API_INTERFACE.GET_CALIBRATION + '/' + calibrationId;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * service to create a vihicle in backend.
 * @param {*} reqBody
 */
function createCalibration (reqBody) {
  const url = API_INTERFACE.CREATE_CALIBRATION;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.post(url, header, reqBody);
}

export const CalibrationsService = {
  getCalibrations,
  getDefaultCalibrations,
  getCalibration,
  createCalibration
};
