import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from '../../../services/service.users';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import ListUserVehicles from './ListUserVehicles';
import ListUserImplements from './ListUserImplements';
import ListUserCrops from './ListUserCrops';
import ListAssociatedAccountHolders from './ListAssociatedAccountHolders';
import ListAssociatedOperators from './ListAssociatedOperators';
import ListUserCalibrations from './ListUserCalibrations';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { ROLE_TYPES } from '../../../constants/app.constants';
import i18n from 'i18next';
import ListUserOperation from './ListUserOperation';

/**
 * view user component
 */
class ViewUser extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      userData: '',
      availableRoles: [],
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      UserService.getOemRoles().then(response => {
        this.setState({
          availableRoles: _.isArray(response) ? response : []
        });
        this.displayUserInfo();
      }, error => {
        this.setState({
          availableRoles: []
        });
        this.displayUserInfo();
      });
    }
  }

  /**
   * function to call remote api for fetching user details to render on UI.
   */
  displayUserInfo () {
    const userId = this.props.match.params.userId;
    UserService.getUser(userId).then(response => {
      this.setState({
        userData: response.data,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState({
        userData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * function to map role id to displayable role name
   * @param {*} roleId 
   */
  getUserRoleType (roleId) {
    const availableRoles = this.state.availableRoles;
    const userRole = availableRoles.find(role => { return role.roleId === roleId; });
    return (userRole ? userRole.roleDisplayName : '');
  }

  /**
   * go to previous page.
   */
  handleBack () {
    this.props.history.goBack();
  }

  /**
   * User detail form. 
   */
  renderUserDetails () {
    return (
      <div>
        <h2> {i18n.t('user.usersListCreateViewAndEdit.viewUser')} </h2>
        <div className="view-profile-details">
          <div className="row">
            <div className="col-md-6">
              <label className="emphasized-label"> {i18n.t('user.usersListCreateViewAndEdit.form.label.firstName')} </label>
              <p>{this.state.userData.firstName}</p>
            </div>

            <div className="col-md-6">
              <label className="emphasized-label"> {i18n.t('user.usersListCreateViewAndEdit.form.label.lastName')} </label>
              <p>{this.state.userData.lastName}</p>
            </div>

            <div className="col-md-6">
              <label className="emphasized-label"> {i18n.t('user.usersListCreateViewAndEdit.form.label.email')} </label>
              <p>{this.state.userData.email}</p>
            </div>

            <div className="col-md-6">
              <label className="emphasized-label">{i18n.t('user.usersListCreateViewAndEdit.form.label.phone')}</label>
              <p>{this.state.userData.phone}</p>
            </div>

            <div className="col-md-6">
              <label className="emphasized-label">{i18n.t('user.usersListCreateViewAndEdit.form.label.role')}</label>
              <p>{this.getUserRoleType(this.state.userData.roleId)}</p>
            </div>
          </div>
        </div>

        <div className="col-md-12 text-right f-btn">
          <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('user.usersListCreateViewAndEdit.form.button.back')}</button>
          <Link to={{
            pathname: '/user/edit-user/' + this.state.userData.id,
            state: { data: this.state.userData }
          }} className="btn btn-primary">{i18n.t('user.usersListCreateViewAndEdit.form.button.edit')}</Link>
        </div>

        <br />
        <br />

        {this.renderVehicles()}
        {this.renderImplements()}
        {this.renderCalibrations()}
        {this.renderCrops()}
        {this.renderOperations()}
        {this.renderOperators()}
        {this.renderAccountHolders()}
      </div>
    );
  }

  /**
   * function to render associated vehicles list in case user is of type account holder.
   * for operator there should be no vehicles listing.
   */
  renderVehicles () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.vehicles')}</h2>
          </div>
          <ListUserVehicles userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * function to render implement list created under the account holder.
   * for operator there is no such implement listing.
   */
  renderImplements () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.implements')}</h2>
          </div>
          <ListUserImplements userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  renderCrops () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.crops')}</h2>
          </div>
          <ListUserCrops userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  renderOperations () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.operations')}</h2>
          </div>
          <ListUserOperation userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * function to render calibrations list associated with an account holder.
   */
  renderCalibrations () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>
              {i18n.t('user.usersListCreateViewAndEdit.calibrations')}
            </h2>
          </div>
          <ListUserCalibrations userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * function to render the list of operators associated with the account holder.
   */
  renderOperators () {
    if (this.state.userData.roleId === ROLE_TYPES.ACCOUNT_HOLDER.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.operators')}</h2>
          </div>
          <ListAssociatedOperators userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * function to render the list of account holders associations with the perticular operator.
   * this listing is visible only if selected user is an operator.
   */
  renderAccountHolders () {
    if (this.state.userData.roleId === ROLE_TYPES.OPERATOR.ID) {
      return (
        <div>
          <div className="col-md-12 clear-all">
            <h2>{i18n.t('user.usersListCreateViewAndEdit.accountHolders')}</h2>
          </div>
          <ListAssociatedAccountHolders userId={this.props.match.params.userId} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * function to render errors.
   */
  renderError () {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('user.usersListCreateViewAndEdit.form.button.back')}</button>
      </div>
    );
  }

  /*
   * alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'user', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.user'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.detail'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>
        {
          this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                      type={this.state.alert.type}
                      isAlertOpen={!!(this.state.alert.message)}
                      handleDismiss={this.handleAlertDismiss.bind(this)}
                    />
        }
        <LoadingOverlay active={this.state.isLoading} customClass="login_loading_overlay">
          {
            this.state.isErrorOnLoad ? this.renderError() : this.renderUserDetails()
          }
        </LoadingOverlay>
      </div>
    );
  }
}

export default ViewUser;
