import React from 'react';
import { Link } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import AlertMessage from '../../../Component/alert/AlertMessage';
import { SupportService } from '../../../services/service.support';
import ListConcernedVehicle from './ListConcernedVehicle';
import ListConcernedImplement from './ListConcernedImplement';
import ListConcernedCalibrations from './ListConcernedCalibrations'
import { ModalDialogWithLazyDataLoading } from '../../../Component/modaldialog/ModalDialog';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import i18n from 'i18next';

let supportButtonLabel = {};
const DECODE_STATUS_DELAY = 3000; // 3 seconds

/**
 * View support components. contains all functionalities related to view.
 */
class ViewSupport extends BaseScreen {
  constructor(props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // assigining localization values (after i18 is initialized with resources)
    supportButtonLabel = {
      0: i18n.t('supportListViewAndConcerns.form.button.decryptLog'),
      1: i18n.t('supportListViewAndConcerns.form.button.download'),
      2: i18n.t('supportListViewAndConcerns.form.button.inProgress')
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      isLoading: true,
      support: '',
      vehicleId: (this.props.location.state && this.props.location.state.supports ? this.props.location.state.supports.vehicleId : ''),
      implementId: (this.props.location.state && this.props.location.state.supports ? this.props.location.state.supports.implementId : ''),
      supportFileStatusButton: supportButtonLabel[0],
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: '',
        modalType: ''
      },
      isErrorOnLoad: false,
      apiResponseMsg: '',
      alert: {
        type: null,
        message: null
      }
    };

    // for handling decode status of support file
    this.checkDecodeStatusTimer = null;
    this.isDecodeStatusInProgress = false;

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.displaySupportInfo();
  }

  /**
   * cleanup resources
   */
  componentWillUnmount() {
    this._isMounted = false;
    this.stopTimer();
  }

  /**
   * Stop and clear interval timer. 
   */
  stopTimer = () => {
    clearInterval(this.checkDecodeStatusTimer);
    this.checkDecodeStatusTimer = null;
  }

  /**
   * method to call specific support data.
   */
  displaySupportInfo() {
    const logId = this.props.match.params.logId;
    SupportService.fetchSupportById(logId).then(response => {
      this.setState({
        support: response.data,
        vehicleId: response.data.vehicleId,
        implementId: response.data.implementId,
        supportFileStatusButton: supportButtonLabel[response.data.decodeStatus],
        isLoading: false
      });

      // turn on the timer to check the decode status of support file
      if (!this.checkDecodeStatusTimer && response.data.filename
        && (response.data.decodeStatus !== 1) && this._isMounted) {
        this.checkDecodeStatusTimer = setInterval(() => {
          this.checkDecodeStatus();
        }, DECODE_STATUS_DELAY);
      }
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState({
        support: {},
        supportFileStatusButton: supportButtonLabel[0],
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * go back to previous page.
   */
  handleBack() {
    this.props.history.goBack();
  }

  /**
   * Check decode status of the support file at given interval. Once the file 
   * status changes from decode to download, enable the download button.
   */
  checkDecodeStatus = () => {
    // see if a status api call is already pending
    if (this.isDecodeStatusInProgress) {
      return;
    }

    this.isDecodeStatusInProgress = true;
    SupportService.fetchSupportById(this.props.match.params.logId).then(response => {
      if (response.data.decodeStatus === 1) {
        this.stopTimer();
        this.setState({
          supportFileStatusButton: supportButtonLabel[response.data.decodeStatus]
        });
      } else {
        this.isDecodeStatusInProgress = false;
      }
    }, error => {
      this.stopTimer();
    });
  }

  /**
   * handler to encapsulate functionality inside a modal window.
   * 
   */
  confirmationModal() {
    if (this.state.supportFileStatusButton === supportButtonLabel[0]) {
      SupportService.decryptSupportFile(this.state.support.id).then(
        (response) => {
          // change state of supportFileStatusButton, only if it is still in decrypt 
          // state (it may change in between as part of checkDecodeStatus() call)
          if (this.state.supportFileStatusButton === supportButtonLabel[0]) {
            this.setState({
              supportFileStatusButton: supportButtonLabel[2]
            });
          }
        },
        (error) => { }
      );
    } else if (this.state.supportFileStatusButton === supportButtonLabel[1]) {
      this.setState({
        modal: {
          modalShow: true,
          shouldRenderModal: true,
          modalTitle: i18n.t('supportListViewAndConcerns.form.modal.downloadTitle'),
          modalMsg: i18n.t('supportListViewAndConcerns.form.modal.downloadMsg'),
          modalData: this.state.support.id,
          modalAction: this.handleModalDismiss.bind(this),
          modalType: 'support_download'
        }
      });
    }
  }

  rawLogDownloadConfirmationModal() {
    this.setState({
      modal: {
        modalShow: true,
        shouldRenderModal: true,
        modalTitle: i18n.t('Download file'),
        modalMsg: i18n.t('Do you want to download the uploaded support log file ?'),
        modalData: this.state.support.id,
        modalAction: this.handleModalDismiss.bind(this),
        modalType: 'support_download_raw_file'
      }
    });
  }

  /**
   * handle modal closing
   */
  handleModalDismiss() {
    this.setState({
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: '',
        modalType: ''
      }
    });
  }

  /**
   * render support details form
   */
  renderSupportDetails() {
    return (
      <div>
        <h2> {i18n.t('supportListViewAndConcerns.form.viewSupport')} </h2>
        <div className="view-profile-details">
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.id')}</label>
              <p>{this.state.support.id}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.serialNumber')}</label>
              <p>{this.state.support.serialNumber}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.ecuApplicationVersion')}</label>
              <p>{this.state.support.ecuApplicationVersion ? this.state.support.ecuApplicationVersion : 'N/A' }</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.ecuBootloaderVersion')}</label>
              <p>{this.state.support.ecuBootloaderVersion ? this.state.support.ecuBootloaderVersion : 'N/A' }</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.chassisApplicationVersion')}</label>
              <p>{this.state.support.chassisApplicationVersion ? this.state.support.chassisApplicationVersion : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.chassisBootloaderVersion')}</label>
              <p>{this.state.support.chassisBootloaderVersion ? this.state.support.chassisBootloaderVersion : 'N/A'}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rimuApplicationVersion')}</label>
              <p>{this.state.support.rimuApplicationVersion ? this.state.support.rimuApplicationVersion : 'N/A' }</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rimuBootloaderVersion')}</label>
              <p>{this.state.support.rimuBootloaderVersion ? this.state.support.rimuBootloaderVersion : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.ecuPartNumber')}</label>
              <p>{this.state.support.ecuPartNumber ? this.state.support.ecuPartNumber : 'N/A'}</p>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.chassisPartNumber')}</label>
              <p>{this.state.support.chassisPartNumber ? this.state.support.chassisPartNumber : 'N/A' }</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rimuPartNumber')}</label>
              <p>{this.state.support.rimuPartNumber ? this.state.support.rimuPartNumber : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.firmwareVersion')}</label>
              <p>{this.state.support.firmwareVersion}</p>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-4">  
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.gnssStatus')}</label>
              <p>{this.state.support.gnssStatus ? 'true' : 'false'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rimuOrientation')}</label>
              <p>{this.state.support.rimuOrientation ? this.state.support.rimuOrientation : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rimuStatus')}</label>
              <p>{this.state.support.rimuStatus ? 'true' : 'false'}</p>
            </div>
          </div>
         
          <div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.message')}</label>
              <p>{this.state.support.message ? this.state.support.message : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.whirlDeviceInfo')}</label>
              <p>{this.state.support.whirlDeviceInfo ? this.state.support.whirlDeviceInfo : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.whirlDeviceOS')}</label>
              <p>{this.state.support.whirlDeviceOS ? this.state.support.whirlDeviceOS : 'N/A'}</p>
            </div>
          </div>
          
		<div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.wheelPositionOk')}</label>
              <p>{this.state.support.wheelPositionOk ? this.state.support.wheelPositionOk : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.headingFilterConvergedStatus')}</label>
              <p>{this.state.support.headingFilterConvergedStatus ? this.state.support.headingFilterConvergedStatus : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.serialCommunication')}</label>
              <p>{this.state.support.serialCommunication ? this.state.support.serialCommunication : 'N/A'}</p>
            </div>
          </div>
		<div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.encoder')}</label>
              <p>{this.state.support.encoder ? this.state.support.encoder : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.calibrationMode')}</label>
              <p>{this.state.support.calibrationMode ? this.state.support.calibrationMode : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.lockToLockCalibrationTimeLeft')}</label>
              <p>{this.state.support.lockToLockCalibrationTimeLeft ? this.state.support.lockToLockCalibrationTimeLeft : 'N/A'}</p>
            </div>
          </div>
		<div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.lockToLockCalibrationTimeRight')}</label>
              <p>{this.state.support.lockToLockCalibrationTimeRight ? this.state.support.lockToLockCalibrationTimeRight : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.radiusCalibrationLeft')}</label>
              <p>{this.state.support.radiusCalibrationLeft ? this.state.support.radiusCalibrationLeft : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.radiusCalibrationRight')}</label>
              <p>{this.state.support.radiusCalibrationRight ? this.state.support.radiusCalibrationRight : 'N/A'}</p>
            </div>
          </div>
		<div className="row">
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.pitchBias')}</label>
              <p>{this.state.support.pitchBias ? this.state.support.pitchBias : 'N/A'}</p>
            </div>
            <div className="col-md-4">
              <label className="emphasized-label">{i18n.t('supportListViewAndConcerns.form.label.rollBias')}</label>
              <p>{this.state.support.rollBias ? this.state.support.rollBias : 'N/A'}</p>
            </div>
          </div>
        </div>
        {!this.state.isLoading ? this.renderVehicles() : null}
        {!this.state.isLoading ? this.renderCalibrations() : null}
        {!this.state.isLoading ? this.renderImplements() : null}
        
        <div className="col-md-12 text-right f-btn" style={{ top: '25px' }}>
          <button type="submit" className="btn btn-secondary mr-2" disabled={!this.state.support.fileSize > 0} onClick={this.rawLogDownloadConfirmationModal.bind(this)}>Download Log</button>

          <button type="submit" className="btn btn-secondary mr-2"
            disabled={ this.state.support.supportType && this.state.support.supportType.toUpperCase() === "WHIRL_LOG" || (!!((!this.state.support.filename || (this.state.supportFileStatusButton === supportButtonLabel[2])))) } onClick={this.confirmationModal.bind(this)}>{this.state.supportFileStatusButton}
          </button>

          <Link to={{
            pathname: '/user/view-user/' + this.state.support.userId
          }} className="btn btn-secondary mr-2">{i18n.t('supportListViewAndConcerns.form.button.requester')}</Link>

          <Link to={{
            pathname: '/user/view-user/' + this.state.support.accountId
          }} className="btn btn-secondary mr-2">{i18n.t('supportListViewAndConcerns.form.button.accountHolder')}</Link>

          <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('supportListViewAndConcerns.form.button.back')}</button>
        </div>

      </div>
    );
  }

  /**
   * render associated vehicle with the support
   */
  renderVehicles() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2>{i18n.t('supportListViewAndConcerns.form.vehicleDetails')}</h2>
        </div>
        <ListConcernedVehicle vehicleId={this.state.vehicleId} />
      </div>
    );
  }

  /**
   * render associated implements with the support
   */
  renderImplements() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2>{i18n.t('supportListViewAndConcerns.form.implementDetails')}</h2>
        </div>
        <ListConcernedImplement implementId={this.state.implementId} />
      </div>
    );
  }

  renderCalibrations() {
    return (
      <div>
        <div className="col-md-12 clear-all">
          <h2>{i18n.t('supportListViewAndConcerns.form.calibrationDetails')}</h2>
        </div>
        <ListConcernedCalibrations vehicleId={this.state.vehicleId} accountId={this.state.support.accountId} />
      </div>
    );
  }

  /**
     * render form fields error in ui.
     */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('supportListViewAndConcerns.form.button.back')}</button>
      </div>
    );
  }

  /**
   * handles alert closing event.
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function
   */
  render() {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'support', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.support'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('supportListViewAndConcerns.breadcrumb.detail'), className: '', link: false }
    ];
    return (
      <div>
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={breadcrumb} />
          <div className="clearfix"></div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }
          <LoadingOverlay active={this.state.isLoading} customClass="login_loading_overlay">
            {
              this.state.isErrorOnLoad ? this.renderError() : this.renderSupportDetails()
            }
          </LoadingOverlay>
        </div>
        {this.state.modal.shouldRenderModal
          ? (<ModalDialogWithLazyDataLoading
            modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            modalType={this.state.modal.modalType} />) : null}
      </div>
    );
  }
}

export default ViewSupport;
