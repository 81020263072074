import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListDocuments from './ListDocuments';
import CreateDocument from './CreateDocument';
import ViewDocument from './ViewDocument';
import EditDocument from './EditDocument';

/**
 * Root-level knowledge/document component. Holds all routes for routing to other sub components.
 */
class Knowledge extends BaseScreen {
  /**
   * Component initialization
   * @param {*} props 
   */
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  /**
   * react render function
   */
  render () {
    return (
      <div>
        <div className='card my-4-custom'>
          <Switch>
            <Route exact path='/knowledge/list-documents' component={ListDocuments} />
            <Route exact path='/knowledge/create-document' component={CreateDocument}/>
            <Route exact path={'/knowledge/view-document/:documentId'} component={ViewDocument} />
            <Route exact path={'/knowledge/edit-document/:documentId'} component={EditDocument} />
            <Redirect from='/knowledge' to='/knowledge/list-documents' />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Knowledge;
