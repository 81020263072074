import React from 'react';
import { StyledText } from "../StyledText";
import i18n from 'i18next';

/***
 * Generic component for dual functionalities of file selection and download.
 */
class CustomFileInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filename: '',
            inputfileId: this.props.id + "-fileInput",
            value: this.props.value,
            predefinedValue: this.props.predefinedValue ? this.props.predefinedValue : ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formreset !== this.props.formreset && this.props.formreset) {
            let fileElement = document.getElementById(this.state.inputfileId);
            fileElement.value = "";
        }
    }

    /**
     * func to show chosen filename in ui when file select.
     * @param {*} event 
     */
    showFileName(event) {
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ filename: event.target.files[0].name });
            this.props.onSelection(event.target.files[0]);
        }
    }

    /**
     * func to open file explorer to choose from.
     * @param {*} event 
     */
    openExplorer(event) {
        event.preventDefault();
        document.getElementById(this.state.inputfileId).click();
        this.props.resetCustomInput();
    }

    /**
     * func for handling file download event.
     */
    onDownloadEvent = (event) => {
        event.preventDefault();
        this.props.onSelection();
    }
    /**
     * func for rendering view based on file-select or download event.
     */
    resolveActionToShow = value => {
        if (this.props.action === "select") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className={`form-group-input`}>
                            <label htmlFor="filename"> {i18n.t('common.file')} <StyledText uiText="*"/> </label>
                            <input type="text" disabled className="form-control upload-btn-filename" id="filename" name="filename"
                                value={this.props.isFilename ? this.state.filename : ''} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`form-group-btn upload-btn-wrapper`}>
                            <input id={this.state.inputfileId} type="file" style={{ opacity: 0 }} onChange={this.showFileName.bind(this)} />
                            <button className="btn btn-primary choose-file" onClick={this.openExplorer.bind(this)} > {this.state.value} </button>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.action === "download") {
            return (
                <div className="row">
                    <div className="col-md-6">
                        <div className={`form-group-input`}>
                            <label className="emphasized-label">{i18n.t('common.file')}</label>
                            <p>{this.props.predefinedName}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={`form-group-btn`}>
                            <a href={this.props.predefinedValue} className="btn btn-primary file-download"> {this.state.value} </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    /**
     * react render func
     */
    render() {
        return (
            this.resolveActionToShow(this.props.action)
        );
    }
}
export default CustomFileInput;