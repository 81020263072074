import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import _ from 'lodash';
import { UserService } from '../../../services/service.users';
import { AuthenticationServices } from '../../../services/service.authentication';
import Grid from '../../../Component/grid/Grid';
import ModalDialog, { ModalWindowForAssignment } from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { userListOptions, userRoleIds } from './UserHelperClass';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';

/**
 * List users component. renders a grid list of users of a kind.
 * 
 */
class ListUsers extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    const defaultRoleId = '2';
    this.state = {
      isAuthenticated: isAuthenticated,
      userListConfig: userListOptions(defaultRoleId),
      usersData: [],
      loader: true,
      pgnConfig: {
        currentPage: getListIndex(SCREEN_ID.ListUsers),
        numberOfPageLinks: 0,
        countPerPage: 25, // default page size
        totalCount: 0,
        pageList: [25, 50, 100]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      },
      sortFieldsConfig: {},
      filterFieldsConfig: {},
      selectedRoleID: defaultRoleId,
      availableRoles: [],
      reinitialize: false,
      // default sorting
      defaultSortField: 'modifiedAt',
      defaultSortOrder: -1
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      UserService.getOemRoles().then(
        response => {
          console.log('roles ---- ', response)
          const oemRoles = _.isArray(response) ? response : [];
          this.setState({
            availableRoles: oemRoles
          });
          const arg = {
            currentPage: this.state.pgnConfig.currentPage -1,
            pageSize: this.state.pgnConfig.countPerPage,
            roleId: this.state.selectedRoleID
          };

          this.getUserData(arg, this.state.sortFieldsConfig, this.state.filterFieldsConfig).then(
            response => {
              const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
              this.setState({ usersData: response.data.items, loader: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
            },
            error => {
              let errorMsg = i18n.t('common.genericApiError');
              if (error && error.data && error.data.message) {
                errorMsg = error.data.message;
              }
              this.setState(
                {
                  usersData: [],
                  loader: false,
                  modalShow: false,
                  reinitialize: false,
                  alert: {
                    type: 'danger',
                    message: errorMsg
                  }
                });
            });
        },
        error => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              usersData: [],
              loader: false,
              modalShow: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  componentWillUnmount () {
    setListIndex(SCREEN_ID.ListUsers, this.state.pgnConfig.currentPage)
  }

  /**
   * function to communicate with backend to fetch list of users.
   * @param {*} requiredParams 
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  getUserData (requiredParams, sortFieldsObj, filterFieldsObj) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize,
        roleId: requiredParams.roleId
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage,
        roleId: this.state.selectedRoleID
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize + '&roleId=' + params.roleId;

    // add sort parameters (if available)
    let sortFiledsQueryParams = '';
    if (!sortFieldsObj) {
      sortFieldsObj = this.state.sortFieldsConfig;
    }

    let sortQueryTemp = '';
    let key;
    for (key in sortFieldsObj) {
      const sortValue = sortFieldsObj[key].sortOrder;
      if (sortValue) {
        sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
        sortFiledsQueryParams += '&' + sortQueryTemp;
      }
    }

    let filterFielddQueryParams = '';
    if (!filterFieldsObj) {
      filterFieldsObj = this.state.filterFieldsConfig;
    }

    let filterQueryTemp = '';
    for (key in filterFieldsObj) {
      const filterValue = filterFieldsObj[key].value;
      if (filterValue) {
        filterQueryTemp = key + '=' + filterValue;
        filterFielddQueryParams += '&' + filterQueryTemp;
      }
    }

    // CHECK THIS -- 
    reqQueryParams += sortFiledsQueryParams + filterFielddQueryParams + '&status=all';

    return UserService.getUsers(reqQueryParams).then(
      response => {
        return this.prepareUsersResponse(response);
      },
      errResponse => {
        return Promise.reject(errResponse);
      });
  }

  prepareUsersResponse (resp) {
    let response = {};
    response.data = {};
    response.data.total = resp.data.total;
    response.data.items = (resp.data.items).map(this.prepareResponseForOptions, this.state.selectedRoleID);
    return response;
  }

  /**
   * customized options for rows based on assignment status.
   * @param {*} value 
   */
  prepareResponseForOptions (value) {
    value.options = [];
    if (value.status === 0) {
      value.status = i18n.t('user.usersListCreateViewAndEdit.list.inactive');
      value.options = [...value.options, {
        actionType: '',
        action: 'reactivate',
        label: i18n.t('user.usersListCreateViewAndEdit.list.options.reactivate')
      }];
    } else if (value.status === 1) {
      value.status = i18n.t('user.usersListCreateViewAndEdit.list.active');
      value.options = [...value.options, 
        {
          actionType: 'link',
          action: 'view',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.view')
        }, 
        {
          actionType: 'link',
          action: 'edit',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.edit')
        }, 
        {
          actionType: '',
          action: 'resetPassword',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.resetPassword')
        },
        {
        actionType: '',
        action: 'delete',
        label: i18n.t('user.usersListCreateViewAndEdit.list.options.delete')
      }];

    } else {
      value.status = i18n.t('user.usersListCreateViewAndEdit.list.unconfirmed')
      value.options = [...value.options, 
        {
          actionType: 'link',
          action: 'view',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.view')
        }, 
        {
          actionType: 'link',
          action: 'edit',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.edit')
        }, 
        {
        actionType: '',
        action: 'delete',
        label: i18n.t('user.usersListCreateViewAndEdit.list.options.delete')
        },
        {
          actionType: '',
          action: 'resendEmail',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.resendEmail')
        }]
    }
    if (this === userRoleIds.accountHolderRoleId && value.status === 'ACTIVE')  {
        value.options = [...value.options, {
          actionType: '',
          action: 'assign',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.assignOperator')
        },
        {
          actionType: 'link',
          action: 'changePassword',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.changePassword')
        }
        ];
      } else if (this === userRoleIds.operatorRoleId && value.status === 'ACTIVE') {
        value.options = [...value.options, {
          actionType: '',
          action: 'assign',
          label: i18n.t('user.usersListCreateViewAndEdit.list.options.assignAholder')
        }];
      }
    return value;
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      window.scrollTo(0, 0);
      this.setState({ loader: true, usersData: [] });
      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage,
        roleId: this.state.selectedRoleID
      };
      this.getUserData(arg).then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          usersData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            usersData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
   * @param {*} event 
   */
  onPgnRowsCountChange (event) {
    window.scrollTo(0, 0);
    const perPageItems = Number(event.target.value);
    let currentPage;
    if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
      currentPage = 1;
    } else {
      currentPage = this.state.pgnConfig.currentPage;
    }
    this.setState({ loader: true, usersData: [] });
    const arg = {
      currentPage: currentPage - 1,
      pageSize: perPageItems,
      roleId: this.state.selectedRoleID
    };
    this.getUserData(arg).then((response) => {
      const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
      this.setState({
        usersData: response.data.items,
        loader: false,
        pgnConfig: pgnConfig,
        alert: {
          type: null,
          message: null
        },
        modalShow: false
      });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          usersData: [],
          loader: false,
          modalShow: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * lazy load function when user use filter or sort functionality from grid header
   * @param {*} sortFieldsObj 
   * @param {*} filterFieldsObj 
   */
  onLazyLoad (sortFieldsObj, filterFieldsObj) {
    this.setState({ loader: true, usersData: [] });

    const pageNumber = 1;
    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage,
      roleId: this.state.selectedRoleID
    };

    this.getUserData(arg, sortFieldsObj, filterFieldsObj)
      .then((response) => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);

        // sortingData
        if (!sortFieldsObj) {
          sortFieldsObj = this.state.sortFieldsConfig;
        }

        // filteringData
        if (!filterFieldsObj) {
          filterFieldsObj = this.state.filterFieldsConfig;
        }

        this.setState({
          usersData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          alert: {
            type: null,
            message: null
          },
          modalShow: false,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            usersData: [],
            loader: false,
            modalShow: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
  }

  /**
   * handler to call when admin changes the user type from top right dropdown which holds :
   * account holders, operators & OEM Admin
   */
  onUserRoleSelectChange (e) {
    const selectedUserId = e.target.value;
    this.setState({ loader: true, usersData: [], selectedRoleID: selectedUserId, reinitialize: true });

    const pageNumber = 1;

    const arg = {
      currentPage: pageNumber - 1,
      pageSize: this.state.pgnConfig.countPerPage,
      roleId: selectedUserId
    };

    const sortFieldsObj = {};
    const filterFieldsObj = {};

    this.getUserData(arg, sortFieldsObj, filterFieldsObj).then(response => {
      const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
      this.setState(
        {
          userListConfig: userListOptions(selectedUserId),
          usersData: response.data.items,
          loader: false,
          pgnConfig: pgnConfig,
          modalShow: false,
          selectedRoleID: selectedUserId,
          sortFieldsConfig: sortFieldsObj,
          filterFieldsConfig: filterFieldsObj,
          reinitialize: false
        });
    }, (error) => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          usersData: [],
          loader: false,
          modalShow: false,
          selectedRoleID: selectedUserId,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user/view-user/' + row.id,
        state: { userData: row }
      });
    } else if (actionType.action === 'edit') {
      return ({
        pathname: '/user/edit-user/' + row.id,
        state: { data: row }
      });
    }  else if (actionType.action === 'changePassword') {
      return ({
        pathname: '/user/change-password/' + row.email,
        state: { data: row }
      });
    }
  }

  /*
    modal dialogues handling
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('user.usersListCreateViewAndEdit.form.modal.deleteMsg', { email: row.email }),
        modalTitle: i18n.t('user.usersListCreateViewAndEdit.form.modal.deleteTitle'),
        modalAction: 'delete_user'
      };
    } else if (actionObject.action === 'resetPassword') {
      modalInfo = {
        modalMsg: i18n.t('user.usersListCreateViewAndEdit.form.modal.resetPasswordMsg', { email: row.email }),
        modalTitle: i18n.t('user.usersListCreateViewAndEdit.form.modal.resetPasswordTitle'),
        modalAction: 'reset_password'
      };
    } else if (actionObject.action === 'assign') {
      modalInfo = {
        modalTitle: i18n.t(row.roleId === 2 ? 'user.usersListCreateViewAndEdit.form.modal.assignOperatorTitle' : 'user.usersListCreateViewAndEdit.form.modal.assignAholderTitle'),
        modalAction: 'assign'
      };
    } else if (actionObject.action === 'reactivate') {
      modalInfo = {
        modalMsg: i18n.t('user.usersListCreateViewAndEdit.form.modal.reactivateUserMsg', { email: row.email }),
        modalTitle: i18n.t('user.usersListCreateViewAndEdit.form.modal.reactivateUserTitle'),
        modalAction: 'reactivate'
      };
    } else if (actionObject.action === 'resendEmail') {
      modalInfo = {
        modalMsg: i18n.t('user.usersListCreateViewAndEdit.form.modal.resendEmailUserMsg', { email: row.email }),
        modalTitle: i18n.t('user.usersListCreateViewAndEdit.form.modal.resendEmailUserTitle'),
        modalAction: 'resend_confirm_email'
      };
    }

    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * handles modal window close event
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   * handles when admin select any option from each row options
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_user' || modalAction === 'reset_password' || modalAction === 'reactivate' || modalAction === 'resend_confirm_email') {
      this.modalPopup(false, data);
    }
  }

  /**
   * handles event after modal is closed
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_user') {
      this.deleteUser();

    } else if (this.state.modal.modalAction === 'reset_password') {
      // send reset password request
      this.resetPasswordOfSelectedUser();

    } else if (this.state.modal.modalAction === 'reactivate') {
      // send reactivation request
      this.reactivateSelectedUser();

    } else if (this.state.modal.modalAction === 'resend_confirm_email') {
      // send confirmation email request
      this.resendConfirmationEmail();  

    } else {
      this.setState({
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
    }
  }

  /**
   * reactivation of selected deleted user.
   */
  reactivateSelectedUser() {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      UserService.reactivateUser(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.usersData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            usersData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.usersListCreateViewAndEdit.alert.reactivatedUserSuccessMsg')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage,
            roleId: this.state.selectedRoleID
          };

          this.getUserData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              usersData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                usersData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    } else {
      this.setState({
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
    }
  }

  /**
   * 
   */
   resendConfirmationEmail() {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
      UserService.resendConfirmationEmail(modalData.email)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.usersData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            usersData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.usersListCreateViewAndEdit.alert.resendEmailUserSuccessMsg')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage,
            roleId: this.state.selectedRoleID
          };

          this.getUserData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              usersData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                usersData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    } else {
      this.setState({
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
    }
   }

  /**
   * password reset functionality of a selected user.
   */
  resetPasswordOfSelectedUser () {
    // execute reset password request
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      // set loader on
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      AuthenticationServices.resetPasswordTokenRequest(modalData.email).then((response) => {
        this.setState({
          loader: false,
          alert: {
            type: 'success',
            message: i18n.t('user.usersListCreateViewAndEdit.alert.resetPasswordSuccessMsg')
          }
        });
      }, (error) => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    } else {
      this.setState({
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
    }
  }

  /**
   * handles user deletion functionality
   */
  deleteUser () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      UserService.deleteUser(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.usersData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            usersData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.usersListCreateViewAndEdit.alert.deleteUserSuccessMsg')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage,
            roleId: this.state.selectedRoleID
          };

          this.getUserData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              usersData: response.data.items,
              loader: false,
              pgnConfig: pgnConfig,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                usersData: [],
                loader: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              loader: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    } else {
      this.setState({
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display user listing along with options and pre-decided information.
   */
  render () {
    const breadcrumb = [
      { id: 'home', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'user', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.user'), className: '', link: false },
      { id: 'detail', displayName: i18n.t('user.usersListCreateViewAndEdit.breadcrumb.list'), className: '', link: false }
    ];
    return (
      <div>
        <div className="container-fluid p-4">
          <BreadcrumbCustom breadcrumb={breadcrumb} />
          <div className="float-right">
            <FormGroup>
              <Input type="select"
                className="custom-select-dropdown"
                id="roleTypeSelect"
                name="roleTypeSelect"
                onChange={(e) => { this.onUserRoleSelectChange(e); }}
                value={this.state.selectedRoleID} disabled={this.state.loader}>
                {
                  this.state.availableRoles.map((elem, id) => {
                    return (
                      <option key={elem.roleId + id} value={elem.roleId} className="custom-select-dropdown-option">
                        {elem.roleDisplayName}
                      </option>
                    );
                  })
                }
              </Input>
            </FormGroup>
          </div>

          <div className="clearfix"></div>

          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)} 
              testId="usersListGrid"
            />
          }

          <div>
            <Grid id="usersListGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn="id"
              rowData={this.state.usersData}
              reinitialize={this.state.reinitialize}
              columnDefs={this.state.userListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
              pageList={this.state.pageList}
              lazyLoading={true}
              onLazyLoad={this.onLazyLoad.bind(this)}
              actionPaths={this.actionPaths.bind(this)}
              defaultSortField={this.state.defaultSortField}
              defaultSortOrder={this.state.defaultSortOrder} 
              customizedOption={true}/>
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          (this.state.modal.modalAction === 'assign'
            ? <ModalWindowForAssignment
              modalTitle={this.state.modal.modalTitle}
              modalData={this.state.modal.modalData}
              isModalOpen={this.state.modal.modalShow}
              modalAction={this.onModalAction.bind(this)}
              handleModalDismiss={this.handleModalDismiss.bind(this)}
              onModalClosed={this.onModalClosed.bind(this)} 
              modalType='userAssignment'/>
            : <ModalDialog
              modalTitle={this.state.modal.modalTitle}
              modalMessage={this.state.modal.modalMsg}
              modalData={this.state.modal.modalData}
              isModalOpen={this.state.modal.modalShow}
              modalAction={this.onModalAction.bind(this)}
              handleModalDismiss={this.handleModalDismiss.bind(this)}
              onModalClosed={this.onModalClosed.bind(this)}
            />)
        }
      </div>
    );
  }
}

export default ListUsers;
