import React from 'react';

/**
 * func for red * marking of mandatory fields in page.
 * @param {*} props
 */
export const StyledText = (props) => {
  return (
    <>
      <span className={props.className ? props.className : 'styled-text-default'}>
        {props.uiText}
      </span>
    </>
  );
};
