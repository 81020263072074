import React from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import Grid from '../../../Component/grid/Grid';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { CompatibilityService } from '../../../services/service.compatibility';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../shared/utils/screenState';

/**
 * Informations to be shown in incompatibility listing grid, along with options per rows.
 */
let compatibilityListConfig = {};

/**
 * List incompatibility component
 */
class ListIncompatibilities extends BaseScreen {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        let isAuthenticated = true;
        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }

        // initialize compatibilityListConfig with updated i18n resources
        compatibilityListConfig = {
            bridgeCardApplication: {
                label: i18n.t('compatibilityViews.list.bridgeCardApplication'),
                display: true
            },
            ecuApplication: {
                label: i18n.t('compatibilityViews.list.ecuApplication'),
                display: true
            },
            rimuApplication: {
                label: i18n.t('compatibilityViews.list.rimuApplication'),
                display: true
            },
            bridgeCardBootloader: {
                label: i18n.t('compatibilityViews.list.bridgeCardBootloader'),
                display: true
            },
            ecuBootloader: {
                label: i18n.t('compatibilityViews.list.ecuBootloader'),
                display: true
            },
            rimuBootloader: {
                label: i18n.t('compatibilityViews.list.rimuBootloader'),
                display: true
            },
            criticality: {
                label: i18n.t('compatibilityViews.list.criticality'),
                display: true,
                sort: true
            },
            firmwareFix: {
                label: i18n.t('compatibilityViews.list.firmwareFix'),
                display: true
            }
        };

        this.state = {
            isAuthenticated: isAuthenticated,
            compatibilityData: [],
            loader: true,
            pgnConfig: {
                currentPage: getListIndex(SCREEN_ID.ListCompatibility),
                numberOfPageLinks: 0,
                countPerPage: 25, // default page size
                totalCount: 0,
                pageList: [25, 50, 100]
            },
            alert: {
                type: null,
                message: null
            },
            sortFieldsConfig: {},
            filterFieldsConfig: {},
            downloadUrl: ''
        };
    }

    /**
     * React life cycle method, called after component is first rendered.
     */
    componentDidMount() {
        if (this.state.isAuthenticated) {
            const arg = {
                currentPage: this.state.pgnConfig.currentPage -1,
                pageSize: this.state.pgnConfig.countPerPage
            };

            // get compatibility list data
            this.getCompatibilityData(arg, this.state.sortFieldsConfig, this.state.filterFieldsConfig)
                .then(response => {
                    const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage,
                        this.state.pgnConfig.countPerPage, response.data.total);

                    this.setState({
                        compatibilityData: response.data.items,
                        loader: false,
                        pgnConfig: pgnConfig
                    });
                }, error => {
                    let errorMsg = i18n.t('common.genericApiError');
                    if (_.has(error, 'data.message')) {
                        errorMsg = error.data.message;
                    }
                    this.setState({
                        compatibilityData: [],
                        loader: false,
                        alert: {
                            type: 'danger',
                            message: errorMsg
                        }
                    });
                });

            // get download url for incompatibility file
            CompatibilityService.getDownloadUrl().then(res => {
                if (_.has(res, 'data.url')) {
                    this.setState({
                        downloadUrl: res.data.url
                    });
                }
            }, err => {
                let errorMsg = i18n.t('common.genericApiError');
                if (_.has(err, 'data.message')) {
                    errorMsg = err.data.message;
                }
                // this.setState({
                //     alert: {
                //         type: 'danger',
                //         message: errorMsg
                //     }
                // });
            });
        }
    }

    componentWillUnmount () {
        setListIndex(SCREEN_ID.ListCompatibility, this.state.pgnConfig.currentPage)
    }
    /**
     * fetch compatibilities based on pagination, sort fields and filter
     * @param {*} requiredParams 
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    getCompatibilityData(requiredParams, sortFieldsObj, filterFieldsObj) {
        let reqQueryParams = '';
        let params;
        if (requiredParams) {
            params = {
                currentPage: requiredParams.currentPage,
                pageSize: requiredParams.pageSize
            };
        } else {
            params = {
                currentPage: this.state.pgnConfig.currentPage - 1,
                pageSize: this.state.pgnConfig.countPerPage
            };
        }
        reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize;

        let sortFieldsQueryParams = '';
        if (!sortFieldsObj) {
            sortFieldsObj = this.state.sortFieldsConfig;
        }
        let sortQueryTemp = '';
        let key;
        for (key in sortFieldsObj) {
            const sortValue = sortFieldsObj[key].sortOrder;
            if (sortValue) {
                sortQueryTemp = 'sortfield=' + key + '&orderType=' + ((sortValue === 1) ? 'asc' : 'desc');
                sortFieldsQueryParams += '&' + sortQueryTemp;
            }
        }

        let filterFieldQueryParams = '';
        if (!filterFieldsObj) {
            filterFieldsObj = this.state.filterFieldsConfig;
        }

        let filterQueryTemp = '';
        for (key in filterFieldsObj) {
            const filterValue = filterFieldsObj[key].value;
            if (filterValue) {
                filterQueryTemp = key + '=' + filterValue;
                filterFieldQueryParams += '&' + filterQueryTemp;
            }
        }

        reqQueryParams += sortFieldsQueryParams + filterFieldQueryParams;

        return CompatibilityService.getCompatibilities(reqQueryParams)
            .then(response => {
                return response;
            }, errResponse => {
                return Promise.reject(errResponse);
            });
    }

    /**
     * helper function for pagination configuration
     * @param {*} currentPage 
     * @param {*} countPerPage 
     * @param {*} totalCount 
     */
    setPaginationConfig(currentPage, countPerPage, totalCount) {
        return {
            currentPage: currentPage,
            numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
            countPerPage: countPerPage,
            totalCount: totalCount,
            pageList: [...this.state.pgnConfig.pageList]
        };
    }

    /**
     * handler when page number change, like user move to next 
     * page or previous page from current page he is viewing
     * @param {*} pageNumber 
     */
    onPgnChange = (pageNumber) => {
        if (pageNumber !== this.state.pgnConfig.currentPage) {
            window.scrollTo(0, 0);
            this.setState({ loader: true, compatibilityData: [] });

            const arg = {
                currentPage: (pageNumber - 1),
                pageSize: this.state.pgnConfig.countPerPage
            };

            this.getCompatibilityData(arg).then((response) => {
                const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage,
                    response.data.total);

                this.setState({
                    compatibilityData: response.data.items,
                    loader: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                });
            }, (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (_.has(error, 'data.message')) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    compatibilityData: [],
                    loader: false,
                    alert: {
                        type: 'danger',
                        message: errorMsg
                    }
                });
            });
        }
    }

    /**
     * handles the grid listing when there is any change in per page row count, like if user 
     * changes default row count 25 to 50 or something else.
     * @param {*} event 
     */
    onPgnRowsCountChange = (event) => {
        window.scrollTo(0, 0);
        const perPageItems = Number(event.target.value);
        let currentPage;
        if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
            currentPage = 1;
        } else {
            currentPage = this.state.pgnConfig.currentPage;
        }

        this.setState({ loader: true, compatibilityData: [] });
        const arg = {
            currentPage: currentPage - 1,
            pageSize: perPageItems
        };

        this.getCompatibilityData(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.data.total);
            this.setState({
                compatibilityData: response.data.items,
                loader: false,
                pgnConfig: pgnConfig,
                alert: {
                    type: null,
                    message: null
                }
            });
        }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (_.has(error, 'data.message')) {
                errorMsg = error.data.message;
            }
            this.setState({
                compatibilityData: [],
                loader: false,
                alert: {
                    type: 'danger',
                    message: errorMsg
                }
            });
        });
    }

    /**
     * lazy load function when user use filter or sort functionality from grid header
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    onLazyLoad = (sortFieldsObj, filterFieldsObj) => {
        this.setState({ loader: true, compatibilityData: [] });
        const pageNumber = 1;
        const arg = {
            currentPage: pageNumber - 1,
            pageSize: this.state.pgnConfig.countPerPage
        };

        this.getCompatibilityData(arg, sortFieldsObj, filterFieldsObj)
            .then((response) => {
                const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage,
                    response.data.total);

                if (!sortFieldsObj) {
                    sortFieldsObj = this.state.sortFieldsConfig;
                }

                if (!filterFieldsObj) {
                    filterFieldsObj = this.state.filterFieldsConfig;
                }

                this.setState({
                    compatibilityData: response.data.items,
                    loader: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                    sortFieldsConfig: sortFieldsObj,
                    filterFieldsConfig: filterFieldsObj
                });
            }, (error) => {
                let errorMsg = i18n.t('common.genericApiError');
                if (_.has(error, 'data.message')) {
                    errorMsg = error.data.message;
                }
                this.setState({
                    compatibilityData: [],
                    loader: false,
                    alert: {
                        type: 'danger',
                        message: errorMsg
                    }
                });
            });
    }

    /**
     * alert message handling
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * react render function for redering list of incompatibilities
     */
    render() {
        const breadcrumb = [
            { id: 'home', displayName: i18n.t('compatibilityViews.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'compatibility', displayName: i18n.t('compatibilityViews.breadcrumb.compatibility'), className: '', link: false },
            { id: 'list', displayName: i18n.t('compatibilityViews.breadcrumb.list'), className: '', link: false }
        ];

        return (
            <div className="container-fluid p-4">
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className="clearfix"></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss}
                    />
                }
                <div className="col-md-12 text-right">
                    <a className={"btn btn-primary " + (this.state.downloadUrl ? "" : "disabled")}
                        href={this.state.downloadUrl}>
                        {i18n.t('compatibilityViews.download')}
                    </a>
                </div>
                <div>
                    <Grid id="compatibilityListGrid"
                        keyColumn="id"
                        rowData={this.state.compatibilityData}
                        columnDefs={compatibilityListConfig}
                        loading={this.state.loader}
                        pagination={true}
                        pgnConfig={this.state.pgnConfig}
                        onPgnChange={this.onPgnChange}
                        onPgnRowsCountChange={this.onPgnRowsCountChange}
                        pageList={this.state.pageList}
                        lazyLoading={true}
                        onLazyLoad={this.onLazyLoad} />
                </div>
            </div>
        );
    }
}

export default ListIncompatibilities;
