import React from 'react';
import Grid from '../../../Component/grid/Grid';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { CropsService } from '../../../services/service.crops';
import i18n from 'i18next';

/**
 * Informations to be shown in associated crop listing grid, along with options per rows, in User details page.
 */
let cropListConfig = {};

/**
 * List associated crops component
 */
class ListUserCrops extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize cropListConfig with updated i18n resources
    cropListConfig = {
      name: {
        label: i18n.t('user.viewEditAndListUserCrop.list.name'),
        sort: false,
        filter: true,
        display: true,
        filterMaxLength: 50
      },
      active: {
        label: i18n.t('user.viewEditAndListUserCrop.list.active'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 50
      },
      options: {
        label: i18n.t('user.viewEditAndListUserCrop.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('user.viewEditAndListUserCrop.list.options.view')
          }
        // ,{
        //   actionType: 'link',
        //   action: 'edit',
        //   label: i18n.t('user.viewEditAndListUserCrop.list.options.edit')
        // }
        //   , {
        //     actionType: '',
        //     action: 'delete',
        //     label: i18n.t('user.viewEditAndListUserCrop.list.options.delete')
        //   }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      // get userId from the props
      userId: this.props.userId,
      userCropsData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10, // default page size
        totalCount: 0,
        pageList: [10]
      },

      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCropsByUserId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
        const cropsData = this.prepareDataToShowActive(response.data.items);
        this.setState({
          userCropsData: cropsData,
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userCropsData: [],
            loader: false,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * function to fetch list of crops for the user
   * @param {*} requiredParams 
   */
  getCropsByUserId (requiredParams) {
    let reqQueryParams = '';

    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize + '&accountId=' + this.state.userId;

    return CropsService.getCrops(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * converting boolean active to string active to render,
   * @param {*} data 
   */
  prepareDataToShowActive (data) {
    let index = 0;
    for (index = 0; index < data.length; index++) {
      if (data[index].active) {
        data[index].active = i18n.t('common.booleanTypes.true');
      } else {
        data[index].active = i18n.t('common.booleanTypes.false');
      }
    }
    return data;
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, userCropsData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCropsByUserId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        const cropsData = this.prepareDataToShowActive(response.data.items);
        this.setState({
          userCropsData: cropsData,
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false,
          alert: {
            type: null,
            message: null
          }
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userCropsData: [],
            loader: false,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/user/view-crop/' + row.id
      });
    } else if (actionType.action === 'edit') {
      return ({
        pathname: '/user/edit-crop/' + row.id
      });
    }
  }

  /**
   * per row option handler. for handling options having confirmation modals
   * @param {*} actionObject 
   * @param {*} row 
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('user.viewEditAndListUserCrop.form.modal.deleteMsg') + row.name + '?',
        modalTitle: i18n.t('user.viewEditAndListUserCrop.form.modal.deleteTitle'),
        modalAction: 'delete_crop'
      };
    }

    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   * handle modal delete action.
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_crop') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_crop') {
      this.deleteCrop();
    }
  }

  /**
   * execute delete crop query
   */
  deleteCrop () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      CropsService.deleteCrop(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.userCropsData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            userCropsData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.viewEditAndListUserCrop.alert.cropDelete')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };

          this.getCropsByUserId(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            const cropsData = this.prepareDataToShowActive(response.data.items);
            this.setState({
              userCropsData: cropsData,
              pgnConfig: pgnConfig,
              loader: false,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                userCropsData: [],
                loader: false,
                modalShow: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              userCropsData: [],
              loader: false,
              modalShow: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display associated crops with selected user.
   */
  render () {
    return (
      <div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          <div>
            <Grid id="userCropsListGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn="id"
              rowData={this.state.userCropsData}
              columnDefs={cropListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              actionPaths={this.actionPaths.bind(this)} />
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListUserCrops;
