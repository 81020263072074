import _ from 'lodash';
import i18n from 'i18next';
import AppInfoHelper from './appInfoHelper';
import { APP_COMMON_CONSTANTS } from '../../constants/app.constants';

/**
 * set object to local storage.
 * @param {*} storageKey 
 * @param {*} objToSave 
 */
function setTokenToLocalStorage (storageKey, objToSave) {
  try {
    localStorage.setItem(storageKey, JSON.stringify(objToSave));
  } catch (err) {
    console.log('error while persisting json object to local storage');
  }
}

/**
 * fetch saved token from local storage
 * @param {*} storageKey 
 */
function getTokenFromLocalStorage (storageKey) {
  let token;
  try {
    token = JSON.parse(localStorage.getItem(storageKey));
  } catch (err) {
    console.log('error while retrieving json token from local storage');
  }
  return token;
}

/**
 * remove token from local storage
 * @param {*} storageKey 
 */
function removeTokenFromLocalStorage (storageKey) {
  return localStorage.removeItem(storageKey);
}

/**
 * clear all data from session and local storage.
 */
function clearApplicationData () {
  AppInfoHelper.reInitializeUserInfo();
  localStorage.removeItem(APP_COMMON_CONSTANTS.AUTH_INFO);
  sessionStorage.removeItem(APP_COMMON_CONSTANTS.USER_ROLES);
}

/**
 * manipulate roleDisplayName to be UI displayable
 * @param {*} allroles 
 */
function setUserTypesToSessionStorage (allroles) {
  const rolesWithRoleDisplayName = allroles.map(function (item) {
    const roleDisplayName = item.roleDisplayName.toLowerCase();
    if (roleDisplayName.indexOf('admin') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.admin');
    } else if (roleDisplayName.indexOf('account holder') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.accHolder');
    } else if (roleDisplayName.indexOf('operator') > -1) {
      item.roleDisplayName = i18n.t('common.userRoleTypes.label.operator');
    }
    return item;
  });

  try {
    sessionStorage.setItem(APP_COMMON_CONSTANTS.USER_ROLES, JSON.stringify(rolesWithRoleDisplayName));
  } catch (err) {
    console.log('error while persisting json object to session storage');
  }
}

/**
 * resolve type of logged in user from session storage.
 */
function getUserTypesFromSessionStorage () {
  let userRoles;
  try {
    userRoles = JSON.parse(sessionStorage.getItem(APP_COMMON_CONSTANTS.USER_ROLES));
  } catch (err) {
    console.log('error while retrieving json user roles from local storage');
  }
  return userRoles;
}

/**
 * fetch language preferrence saved in local storage.
 */
function getPreferredLangFromLocalStorage () {
  const lang = getTokenFromLocalStorage(APP_COMMON_CONSTANTS.PREFERRED_LANGUAGE);
  return _.isObject(lang) ? lang : {};
}

/**
 * save language preferrence saved in local storage.
 * @param {*} langObj
 */
function setPreferredLangToLocalStorage (langObj) {
  setTokenToLocalStorage(APP_COMMON_CONSTANTS.PREFERRED_LANGUAGE, langObj);
}

export const AppStorageHelper = {
  clearApplicationData,
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  setUserTypesToSessionStorage,
  getUserTypesFromSessionStorage,
  getPreferredLangFromLocalStorage,
  setPreferredLangToLocalStorage
};
