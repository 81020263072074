import React from 'react';
import { UncontrolledAlert, Alert } from 'reactstrap';

/**
 * Generic component for showing success or failure alerts.
 */
export class SubmissionAlert extends React.Component {
  constructor (props) {
    super(props);
    this.onDismiss = this.onDismiss.bind(this);
  }

  /**
   * dismiss func of alert
   */
  onDismiss () {
    this.props.onDismiss();
  }

  /**
   * react render
   */
  render () {
    const testIdSuffix = this.props.testId ? '-' + this.props.testId : '';
    return (
      <Alert color={this.props.isSuccessResponse ? 'success' : 'danger'} toggle={this.props.onDismiss} fade={false}>
        <p data-testid={'alert-msg' + testIdSuffix}>
          {this.props.alertMessage}
        </p>
      </Alert>
    );
  }
}

/**
 * submission status
 * @param {*} param0
 */
export const SubmissionStatus = ({ alertMessage }) => {
  return (
    <div className="alert alert-success alert-dismissible alert-success">
      <div className="alert-section">
        <UncontrolledAlert color="info">
          <p> {alertMessage} </p>
        </UncontrolledAlert>
      </div>
    </div>
  );
};

/**
 * func for showing form error
 * @param {*} param0
 */
export const FormErrors = ({ formErrors, testId }) => {
  const testIdSuffix = testId ? '-' + testId : '';

  return (
    <div className='formErrors'>
      <p data-testid={'status-msg' + testIdSuffix}>
        {formErrors}
      </p>
    </div>
  );
};
