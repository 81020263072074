import React from 'react';
import Grid from '../../../../Component/grid/Grid';
import BaseScreen from '../../BaseScreen';
import BreadcrumbCustom from "../../../../Component/breadcrumb/BreadcrumbCustom";
import ModalDialog from "../../../../Component/modaldialog/ModalDialog";
import { SubmissionAlert } from '../../../../Component/SubmissionStatus';
import { DeviceService } from "../../../../services/service.devices";
import i18n from 'i18next';
import { filter } from 'lodash';
import {getListIndex, setListIndex, SCREEN_ID} from '../../../../shared/utils/screenState';
/**
 * To show activation status of device.
 */
const deviceActivationStatus = {
    "Inactive": 0,
    "Active": 1
}

/**
 * Information to be shown in device listing grid, along with options per rows.
 */
let deviceListConfig = {};

/**
 * Listing device components.
 */
class ListSubcriptions extends BaseScreen {
    constructor(props) {
        super(props);

        // initialize deviceListConfig with updated i18n resources
        deviceListConfig = {
            'code': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.code'),
                'sort': true,
                'filter': true,
                'display': true,
                'filterMaxLength': 50
            },
            'partId': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.partid'),
                'sort': true,
                'filter': true,
                'display': true,
                'filterMaxLength': 40
            },
            'deviceSerialId': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.serialNumber'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 40
            },            
            'firstname': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.firstname'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 32
            },            
            'lastname': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.lastname'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 32
            },
            'accountEmailId': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.accoundholderemail'),
                'sort': false,
                'filter': true,
                'display': true,
                'filterMaxLength': 80
            },            
            'options': {
                'label': i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.options.options'),
                'display': true
            }
        };

        this.state = {
            devices: [],
            isLoading: true,
            pgnConfig: {
                currentPage: getListIndex(SCREEN_ID.ListSubcriptions),
                numberOfPageLinks: 0,
                countPerPage: 10,
                totalCount: 0,
                pageList: [25, 50, 100]
            },
            modal: {
                modalShow: false,
                shouldRenderModal: false,
                modalTitle: "",
                modalMsg: "",
                modalData: "",
                modalAction: ""
            },
            reinitialize: false,

            sortFieldsConfig: {},
            filterFieldsConfig: {},
            defaultSortField: "modifiedAt",
            defaultSortOrder: -1,
            
            apiResponseIsSuccess: false,
            showMessage: false,
            apiResponseMessage: ''
        }
    }

    componentDidMount () {
        let args = {
            currentPage: this.state.pgnConfig.currentPage -1,
            pageSize: this.state.pgnConfig.countPerPage
        };
        this.getDevices(args).then(
            (response) => {
                let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.total);
                console.log('-----', response.items)
                this.setState({
                    devices: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig
                });
                return response
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }

    componentWillUnmount () {
        setListIndex(SCREEN_ID.ListSubcriptions, this.state.pgnConfig.currentPage)
    }

    /**
     * Method to fetch all codes from backend.
     * @param {*} args 
     * @param {*} sortFieldsConfig 
     * @param {*} filterFieldsConfig 
     */
    getDevices (args, sortFieldsConfig, filterFieldsConfig) {
        let queryParams = "?currentPage=" + args.currentPage + "&pageSize=" + args.pageSize;
        let sortFiledsQueryParams = '';
        if (!sortFieldsConfig) {
            sortFieldsConfig = this.state.sortFieldsConfig;
        }
        let sortQueryTemp = "";
        let key;
        for (key in sortFieldsConfig) {
            let sortValue = sortFieldsConfig[key]["sortOrder"];
            if (sortValue) {
                sortQueryTemp = "sortField=" + key + "&orderType=" + ((sortValue === 1) ? "asc" : "desc");
                sortFiledsQueryParams += "&" + sortQueryTemp;
            }
        }

        let filterFielddQueryParams = "";
        if (!filterFieldsConfig) {
            filterFieldsConfig = this.state.filterFieldsConfig;
        }
        let filterQueryTemp = "";
        for (key in filterFieldsConfig) {
            let filterValue = filterFieldsConfig[key]["value"];
            if (filterValue) {
                filterQueryTemp = key + "=" + filterValue;
                filterFielddQueryParams += "&" + filterQueryTemp;
            }
        }
        queryParams += sortFiledsQueryParams + filterFielddQueryParams;
        return DeviceService.getDeviceSubscriptionsList (queryParams).then(
            (response) => {
                return this.prepareDeviceResponseForActivationStatus (response.data);
            }
        )
    }

    /**
     * helper method to re-prepare the subscription code listing received from backend.
     * @param {*} devices 
     */
    prepareDeviceResponseForActivationStatus (devices) {
        let response = {};
        let total = devices.total;
        let preparedDeviceData = (devices.items).map(this.changeDeviceActivationStatus);
        response["total"] = total;
        response["items"] = preparedDeviceData;
        return response;
    }

    /**
     * function for rendering option based on activation value of device. 
     * inactive device: view edit & delete.
     * active device: view & edit
     * @param {*} value 
     */
    changeDeviceActivationStatus (value) {
            value.activationStatus = i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.options.inactive');
            value.options = [{
                actionType: 'link',
                action: 'view',
                label: i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.options.view')
              },
              {
                actionType: 'link',
                action: 'edit',
                label: i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.options.edit')
              },
              {
                actionType: '',
                action: 'delete',
                label: i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.options.delete')
              }];  
        
        return value;
    }

    /**
     * function to call delete a subcription code based on id by calling a service.
     * @param {*} id
     */
    deleteDevice (id) {
        if (this.state.modal.modalData) {
            this.setState({
                isLoading: true,
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
            let params = [id];
            DeviceService.deleteDeviceSubcriptionById(params).then(
                (response) => {
                    this.setState({
                        isLoading: true,
                        devices: [],
                        apiResponseMessage: i18n.t('deviceListCreateViewEditAndUpload.alert.deviceDelete'),
                        showMessage: true,
                        apiResponseIsSuccess: true,
                        isSuccessResponse : true
                    });
                    let args = {
                        currentPage: this.state.pgnConfig.currentPage - 1,
                        pageSize: this.state.pgnConfig.countPerPage,
                        sortField: this.state.pgnConfig.sortField,
                        orderType: this.state.pgnConfig.orderType
                    };
                    this.getDevices(args).then(
                        (response) => {
                            let pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.total);
                            this.setState({ devices: response.items, isLoading: false, pgnConfig: pgnConfig, modalShow: false, reinitialize: false });
                        }
                    );
                },
                (error) => {
                    let message = i18n.t('common.genericApiError');
                    if (error.data && error.data.message) {
                        message = error.data.message;
                    }
                    this.setState({
                        isLoading: false,
                        apiResponseMessage: message,
                        showMessage: true,
                        apiResponseIsSuccess: false
                    });
                }
            )
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * Modal window to show each row options.
     * @param {*} state 
     * @param {*} rowData 
     * @param {*} modalInfo 
     */
    modalPopup (state, rowData, modalInfo) {
        if (state) {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalMsg: modalInfo.modalMsg,
                    modalTitle: modalInfo.modalTitle,
                    modalData: rowData ? rowData : "",
                    modalAction: modalInfo.modalAction
                },
            });
        } else {
            this.setState({
                modal: {
                    modalShow: state,
                    shouldRenderModal: true,
                    modalData: rowData ? rowData : "",
                    modalAction: this.state.modal.modalAction
                },
            });
        }
    }

    /**
     * modal dismissal action
     */
    handleModalDismiss() {
        this.modalPopup(false);
    }

    /**
     * handle modal delete action.
     * @param {*} data 
     */
    onModalAction(data) {
        let modalAction = this.state.modal.modalAction;
        if (modalAction === "delete") {
            this.modalPopup(false, data);
        }
    }

    /**
     * function after modal is closed, re-render listing page after delete operation.
     */
    onModalClosed() {
        if (this.state.modal.modalAction === "delete") {
            this.deleteDevice(this.state.modal.modalData.id);
        } else {
            this.setState({
                modal: {
                    modalShow: false,
                    shouldRenderModal: false,
                },
            });
        }
    }

    /**
     * helper function for pagination configuration
     * @param {*} currentPage 
     * @param {*} countPerPage 
     * @param {*} totalCount 
     */
    setPaginationConfig(currentPage, countPerPage, totalCount) {
        return {
            currentPage: currentPage,
            numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
            countPerPage: countPerPage,
            totalCount: totalCount,
            pageList: [...this.state.pgnConfig.pageList]
        }
    }

    /**
     * handler when page number change, like user move to next page or previous page from current page he is viewing
     * @param {*} pageNumber 
     */
    onPgnChange(pageNumber) {
        if (pageNumber !== this.state.pgnConfig.currentPage) {
            window.scrollTo(0, 0);
            this.setState({ isLoading: true, devices: [] });
            let arg = {
                currentPage: (pageNumber - 1),
                pageSize: this.state.pgnConfig.countPerPage,
                sortField: this.state.pgnConfig.sortField,
                orderType: this.state.pgnConfig.orderType
            };
            this.getDevices(arg).then(
                (response) => {
                    let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.total);
                    this.setState({
                        devices: response.items,
                        isLoading: false,
                        pgnConfig: pgnConfig,
                        modalShow: false
                    });
                });
        }
    }

    /**
     * per row option handler
     * @param {*} actionObject 
     * @param {*} row 
     */
    optionHandler(actionObject, row) {
        let modalInfo;
        if (actionObject.action === "delete") {
            modalInfo = {
                modalMsg: i18n.t('deviceListCreateViewEditAndUpload.form.modal.deleteDeviceSubsCodeMsg') + ':' + row.code,
                modalTitle: i18n.t('deviceListCreateViewEditAndUpload.form.modal.deleteSubsCodeTitle'),
                modalAction: "delete"
            };
        }
        this.modalPopup(true, row, modalInfo);
    }

    /**
     * handles the grid listing when there is any change per page row count, like if user chose default row count 25 to 50 or something else.
     * @param {*} event 
     */
    onPgnRowsCountChange(event) {
        window.scrollTo(0, 0);
        let perPageItems = Number(event.target.value);
        let currentPage;
        if ((this.state.pgnConfig.currentPage - 1) * perPageItems >= this.state.pgnConfig.totalCount) {
            currentPage = 1;
        } else {
            currentPage = this.state.pgnConfig.currentPage;
        }
        this.setState({ isLoading: true, firmwares: [] });
        let args = {
            currentPage: currentPage - 1,
            pageSize: perPageItems,
            sortField: this.state.pgnConfig.sortField,
            orderType: this.state.pgnConfig.orderType
        };
        this.getDevices(args).then(
            (response) => {
                let pgnConfig = this.setPaginationConfig(currentPage, perPageItems, response.total);
                this.setState({
                    devices: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig,
                    modalShow: false
                });
            });
    }

    /**
     * lazy load function when user use filter or sort functionality from grid header
     * @param {*} sortFieldsObj 
     * @param {*} filterFieldsObj 
     */
    onLazyLoad(sortFieldsObj, filterFieldsObj) {
        this.setState({ isLoading: true, devices: [] });
        let pageNumber = 1;
        let arg = {
            currentPage: pageNumber - 1,
            pageSize: this.state.pgnConfig.countPerPage
        };
        this.getDevices(arg, sortFieldsObj, filterFieldsObj)
            .then((response) => {
                let pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.total);
                if (!sortFieldsObj) {
                    sortFieldsObj = this.state.sortFieldsConfig;
                }
                if (!filterFieldsObj) {
                    filterFieldsObj = this.state.filterFieldsObj
                }
                this.setState({
                    devices: response.items,
                    isLoading: false,
                    pgnConfig: pgnConfig,
                    alert: {
                        type: null,
                        message: null
                    },
                    modalShow: false,
                    sortFieldsConfig: sortFieldsObj,
                    filterFieldsConfig: filterFieldsObj,
                });
            });
    }

    /**
     * routing to relevant page based on row option selection
     * @param {*} actionType 
     * @param {*} row 
     */
    actionPaths(actionType, row) {
        if (actionType.action === "view") {
            return ({
                pathname: '/device/subscriptions/view-subscription/'+row.code+"/"+row.partId+"/"+row.deviceSerialId,
                state: { devices: row }
            });
        } else if (actionType.action === "edit") {

            return ({
                pathname: '/device/subscriptions/edit-subscription/'+row.code+"/"+row.partId+"/"+row.deviceSerialId,
                state: { devices: row }
            });
        }
    }

    /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: '', link: false },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.subscriptions'), className: '', link: false },
            { id: 'list', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.list'), className: '', link: false }
        ];
        return (
            <div>
                <div className="container-fluid p-4">
                    <BreadcrumbCustom breadcrumb={breadcrumb} />
                    <div className="clearfix"></div>
                    {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                    <div>
                        <Grid id="subcriptionListGrid"
                            keyColumn='id'
                            loading={this.state.isLoading}
                            columnDefs={deviceListConfig}
                            rowData={this.state.devices}
                            reinitialize={this.state.reinitialize}
                            pagination={true}
                            pgnConfig={this.state.pgnConfig}
                            pageList={this.state.pageList}
                            lazyLoading={true}
                            onPgnChange={this.onPgnChange.bind(this)}
                            optionHandler={this.optionHandler.bind(this)}
                            onPgnRowsCountChange={this.onPgnRowsCountChange.bind(this)}
                            onLazyLoad={this.onLazyLoad.bind(this)}
                            actionPaths={this.actionPaths.bind(this)}
                            defaultSortField={this.state.defaultSortField}
                            defaultSortOrder={this.state.defaultSortOrder} 
                            customizedOption={true}/>
                    </div>
                </div>
                {
                    this.state.modal.shouldRenderModal 
                    ? (<ModalDialog
                            modalTitle={this.state.modal.modalTitle}
                            modalMessage={this.state.modal.modalMsg}
                            modalData={this.state.modal.modalData}
                            isModalOpen={this.state.modal.modalShow}
                            modalAction={this.onModalAction.bind(this)}
                            handleModalDismiss={this.handleModalDismiss.bind(this)}
                            onModalClosed={this.onModalClosed.bind(this)}/>) 
                    : null
                }
            </div>
        );
    }
}
export default ListSubcriptions;