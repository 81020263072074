import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListVehicle from './ListVehicle';
import CreateVehicleV2 from './CreateVehicleV2';
import ViewVehicle from './ViewVehicleV2';
import EditVehicle from './EditVehicleV2';

/**
 * main vehicle components. holds routings to different vehicle sub components
 */
class Vehicle extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      isAuthenticated: isAuthenticated,
      items: []
    };
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/vehicle/list-vehicle'} component={ListVehicle} />
            <Route exact path={'/vehicle/create-vehicle'} component={CreateVehicleV2} />
            <Route exact path={'/vehicle/view-vehicle/:vehicleId'} component={ViewVehicle} />
            <Route exact path={'/vehicle/edit-vehicle/:vehicleId'} component={EditVehicle} />
            <Redirect from="/vehicle" to="/vehicle/list-vehicle" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Vehicle;
