import React from 'react';
import { Collapse } from "react-collapse";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import _ from 'lodash';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import BaseScreen from "../BaseScreen";
import { SCREENS } from "../../../constants/screens.constant";
import { VehiclesService } from "../../../services/service.vehicles";
import { CalibrationsService } from "../../../services/service.calibrations";
import AlertMessage from "../../../Component/alert/AlertMessage";
import { FormErrors } from "../../../Component/SubmissionStatus";
import { CommonConstants } from "../../../constants/app.constants";
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';
import LoadingOverlay from "../../../Component/loader/LoadingOverlay";
import { CommonUtilities } from "../../../shared/utils/commonUtilities";

/**
 * Edit vehicle components. holds all functionalities for editing a vehicle.
 */
class EditVehicle extends BaseScreen {
  constructor(props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    this.state = {
      isAuthenticated: isAuthenticated,
      formfields: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
        ecuOrientation: "",
        aLeftRadius: "",
	    aRightRadius: "",
	    bLockRight: "",
	    bLockLeft: "",
	    cPitchBias: "",
	    cRollBias: "",
	    dSteeringRatio: ""
      },
      xyStickeFace: "", 
      cableAttachDirection: "",
      formErrors: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
        aLeftRadius: "",
	    aRightRadius: "",
	    bLockRight: "",
	    bLockLeft: "",
	    cPitchBias: "",
	    cRollBias: "",
	    dSteeringRatio: ""
      },
      formValid: false,
      shouldAddCalibration: false,
      aTabEnabled: true,
      bTabEnabled: true,
      cTabEnabled: true,
      dTabEnabled: true,
      savedVehicleId:0,
      alert: {
        type: null,
        message: null
      },

      isWaitingApiResponse: false,
      isLoading: true,
      savedFormFields: {},
      
      isErrorOnLoad: false,
    }

    this.vehicleTypes = CommonConstants.getVehicleTypes();
    this.ecuOrientationTypes = CommonConstants.getEcuOrientationTypes();
    this.xyStickeFaceTypes = CommonConstants.getXYStickeFace();
    this.cableAttachDirectionTypes = CommonConstants.getCableAttachDirection();
  }

  /**
   * utility function to remove null values
   * @param {*} obj 
   */
  removeNullValues(obj) {
    let newObj = _.mapValues(obj, (value, key) => {
      if (key !== "accountId" && key !== "operatorId") {
        if ((value === null) || (value === undefined)) {
          value = "";
        }
      }
      return value;
    });
    return newObj;
  }

  componentDidMount() {
    if (this.state.isAuthenticated) {
      this.displayVehicleInfo();
    }
  }
  
  /**
   * enable and disabple the calibration panel.
   * @param {*} event 
   */
  handleAddCalibration(event){
	let checked = event.target.checked;
	this.setState({
        shouldAddCalibration: checked
      });
	}

  /**
   * enable and disabple the calibration panel.
   * @param {*} event 
   */
  setActiveTab = (index) => {
		this.setState({
			activeTab : index
	  });	
	}
	
	updateCalibTabs(vType){
	  if(vType === 'STANDARD' || vType === 'MFWD' || vType === 'SPRAYER'){
	  		this.setState({aTabEnabled:false, bTabEnabled:false, cTabEnabled:false, dTabEnabled:true});
	  }else if(vType === "ARTICULATED" || vType === 'COMBINE'){
	  		this.setState({aTabEnabled:false, bTabEnabled:false, cTabEnabled:false, dTabEnabled:false});
	  }else if(vType === "RICE_TRANSPLANTER" || vType === 'ONION_PLANTER'){
	  		this.setState({aTabEnabled:true, bTabEnabled:true, cTabEnabled:false, dTabEnabled:true});
	  }else{
		//nothing
	  }
	}

  /**
   * fetch specific vehicle data from backend
   */
  displayVehicleInfo() {
    let vehicleData = {};
    let vehicleId = this.props.match.params.vehicleId;

    VehiclesService.getVehicle(vehicleId).then(response => {
      vehicleData = this.removeNullValues(response.data);
      this.updateCalibTabs(vehicleData.type);
      this.setState({
        formfields: vehicleData,
        xyStickeFace: CommonUtilities.getxyStickeFace(vehicleData.ecuOrientation), 
        cableAttachDirection: CommonUtilities.getcableAttachDirection(vehicleData.ecuOrientation),
        savedFormFields: { ...vehicleData },
        savedVehicleId : vehicleId,
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState({
        vehicleData: {},
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: "danger",
          message: errorMsg
        },
      });
    });
    
    CalibrationsService.getDefaultCalibrations(vehicleId).then(calibResponse => {
      let cbData = {};
      let tempData = calibResponse.data.items;
      tempData.forEach((element) => {
			if(element.calibrationType === 'A'){
				cbData.aLeftRadius = element.leftRadius;
				cbData.aRightRadius = element.rightRadius;
				this.setState({aTabEnabled:false});
			}else if(element.calibrationType === 'B'){
				cbData.bLockLeft = element.lockToLockLeft;
				cbData.bLockRight = element.lockToLockRight;
				this.setState({bTabEnabled:false});
			}else if(element.calibrationType === 'C'){
				cbData.cRollBias = element.rollBias;
				cbData.cPitchBias = element.pitchBias;
				this.setState({cTabEnabled:false});
			}else if(element.calibrationType === 'D'){
				cbData.dSteeringRatio = element.steeringRatio;
				this.setState({dTabEnabled:false});
			}
		});
      
      this.setState({
        formfields: Object.assign(vehicleData, cbData),
        savedFormFields: { ...cbData },
        isLoading: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState({
        isLoading: false,
        isErrorOnLoad: true,
        apiResponseMsg: errorMsg,
        alert: {
          type: 'danger',
          message: errorMsg
        }
      });
    });
  }

  /**
   * handler method for form validation
   */
  handleUserInput = (e) => {
    let name = e.target.name;
    if (name === "AddCalibration") {
      /**
       * handle checkbox
       */
      this.handleAddCalibration(e);
    }else
    	this.handleVehicleFormFields(e);
  }

  /**
   * helper function to validate decimal values entered.
   * @param {*} num 
   */
  validateDecimalNumbers(num) {
    if ((num.length > 0) &&
      ((isNaN(num) || (num.indexOf(".") === 0) || (num.indexOf(".") === num.length - 1)) ||
        !(/^\d+(\.\d{1,6})?$/.test(num)))) {
      return false;
    }

    return true;
  }
  
  /**
   * helper function to createCalibrationRequest.
   * @param {*} num 
   */

	getCalibRequestBody(vehicleId){
		let calibReqBody;
		let commonCalibObj = {
			"calibrationType": "",
	        "leftRadius": 0,
	        "lockToLockLeft": 0,
	        "lockToLockRight": 0,
	        "pitchBias": 0,
	        "rightRadius": 0,
	        "rollBias": 0,
	        "tiltPointALat": 0.0,
	        "tiltPointALong": 0.0,
	        "tiltPointBLat": 0.0,
	        "tiltPointBLong": 0.0,
	        "vehicleId": vehicleId
		};
		
		const aObject = {...commonCalibObj , ...{
				"calibrationType": "A",
	        	"leftRadius": this.state.formfields.aLeftRadius,
	        	"rightRadius": this.state.formfields.aRightRadius
			}};
		
		const bObject = {...commonCalibObj , ...{
				"calibrationType": "B",
	        	"lockToLockLeft": this.state.formfields.bLockLeft,
	        	"lockToLockRight": this.state.formfields.bLockRight
			}};	
		
		const cObject = {...commonCalibObj , ...{
			"calibrationType": "C",
	        "pitchBias": this.state.formfields.cPitchBias,
	        "rollBias":this.state.formfields.cRollBias    
			}};	
		
		const dObject = {...commonCalibObj , ...{
			"calibrationType": "D",
	        "steeringRatio": this.state.formfields.dSteeringRatio  
			}};	
			
		if(this.state.formfields.type === 'ARTICULATED' ||
			this.state.formfields.type === 'COMBINE'){
			calibReqBody = [ aObject, bObject, cObject, dObject ];
		}else if(this.state.formfields.type === 'STANDARD' ||
			this.state.formfields.type === 'MFWD' || 
			this.state.formfields.type === 'SPRAYER') {
			calibReqBody = [ aObject, bObject, cObject ];
		}else if(this.state.formfields.type === 'RICE_TRANSPLANTER' ||
			this.state.formfields.type === 'ONION_PLANTER' ) {
			calibReqBody = [ cObject ];
		}else{
			calibReqBody = [ aObject, bObject, cObject, dObject ];
		}
		return calibReqBody;	
	}

  /**
   * helper function to validate decimal values entered.
   * @param {*} event 
   */
  handleVehicleFormFields(event) {
    const name = event.target.name;
    const value = event.target.value;

    let formfields = this.state.formfields;
    formfields[name] = value;

    let fieldValidationErrors = this.state.formErrors;
    let msg = "";

    switch (name) {
      case 'make':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if (value.length > 32) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char32');
        }
        fieldValidationErrors.make = msg;
        break;

      case 'model':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if (value.length > 32) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char32');
        }
        fieldValidationErrors.model = msg;
        break;

      case 'year':
        let parsedValue = parseInt(value, 10);
        if ((value.length > 0) && (!(/^\d+$/.test(value)) || (parsedValue < 1970) || (parsedValue > 9999))) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.year');
        }
        fieldValidationErrors.year = msg;
        break;

      case 'type':
        if (value === "") {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.type');
        }
        this.updateCalibTabs(value);
        fieldValidationErrors.type = msg;
        break;

      case 'localId':
        if (value.length > 256) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char256');
        }
        fieldValidationErrors.localId = msg;
        break;

      case 'steeringWheelType':
        if (value.length > 32) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char32');
        }
        fieldValidationErrors.steeringWheelType = msg;
        break;

      case 'wheelbase':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        }
        fieldValidationErrors.wheelbase = msg;
        break;

      case 'antennaHeight':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        }
        fieldValidationErrors.antennaHeight = msg;
        break;

      case 'antennaPivot':
        if ((value.length > 0) && !CommonUtilities.validateDecimalNum(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimalAll');
        }
        fieldValidationErrors.antennaPivot = msg;
        break;

      case 'antennaOffset':
        if ((value.length > 0) && !CommonUtilities.validateDecimalNum(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimalAll');
        }
        fieldValidationErrors.antennaOffset = msg;
        break;

      case 'frontHitch':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        }
        fieldValidationErrors.frontHitch = msg;
        break;

      case 'rearHitch':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        }
        fieldValidationErrors.rearHitch = msg;
        break;

      case 'attack':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length >= 1) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 100 || value < 1) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.attack');
        }
        fieldValidationErrors.attack = msg;
        break;

      case 'backlash':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 90 || value < 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.backlash');
        }
        fieldValidationErrors.backlash = msg;
        break;

      case 'sensitivity':
        if (value.length === 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.required');
        } else if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');
        } else if (value > 100 || value < 0) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.sensitivity');
        }
        fieldValidationErrors.sensitivity = msg;
        break;

      case 'profileVersion':
        if (value.length > 64) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.char64');
        }
        fieldValidationErrors.profileVersion = msg;
        break;

      case 'ecuOrientation':
        if (value === "") {
          //msg = "Please select vehicle type";
        }
        fieldValidationErrors.ecuOrientation = msg;
        break;
	  
	  case 'cableAttachDirection':
        if (value.length > 0) {
			this.state.cableAttachDirection = value.charAt(0).toUpperCase() + value.slice(1);
		}
        fieldValidationErrors.cableAttachDirection = msg;
        break;
	  
	  case 'xyStickeFace':
        if (value.length > 0) {
			this.state.xyStickeFace = value.charAt(0).toUpperCase() + value.slice(1);
		}
        fieldValidationErrors.xyStickeFace = msg;
        break;
	  
	  case 'aLeftRadius':
	    if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.aLeftRadius = msg;
        break; 
      case 'aRightRadius':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.aRightRadius = msg;
        break;
      case 'bLockRight':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');         
        }
        fieldValidationErrors.bLockRight = msg;
        break;
      case 'bLockLeft':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.bLockLeft = msg;
        break;
      case 'cPitchBias':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.bLockRight = msg;
        break;
      case 'cRollBias':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.cRollBias = msg;
        break;
      case 'dSteeringRatio':
        if ((value.length > 0) && !this.validateDecimalNumbers(value)) {
          msg = i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.decimal');          
        }
        fieldValidationErrors.dSteeringRatio = msg;        
        break;
      default:
        break;
    }

    let isFormValid = (formfields.make && formfields.model && formfields.type 
      && formfields.attack && !CommonUtilities.isEmpty(formfields.backlash) && !CommonUtilities.isEmpty(formfields.sensitivity)) &&
      (fieldValidationErrors.make === "" && fieldValidationErrors.model === "" && fieldValidationErrors.year === "" &&
        fieldValidationErrors.type === "" && fieldValidationErrors.localId === "" && fieldValidationErrors.steeringWheelType === "" &&
        fieldValidationErrors.wheelbase === "" && fieldValidationErrors.antennaHeight === "" && fieldValidationErrors.antennaPivot === "" &&
        fieldValidationErrors.antennaOffset === "" && fieldValidationErrors.frontHitch === "" && fieldValidationErrors.rearHitch === "" &&
        fieldValidationErrors.profileVersion === "" && fieldValidationErrors.attack === "" && fieldValidationErrors.backlash === "" && fieldValidationErrors.sensitivity === "")/* && fieldValidationErrors.ecuOrientation === "")*/;
	this.setState({
      formfields: formfields,
      formErrors: fieldValidationErrors,
      formValid: isFormValid,
    });
  }

  /**
   * handles vehicle submit function. this calls api to upload the updated vehicle.
   * @param {*} event 
   */
  createVehicleSubmit(event) {
    event.preventDefault();

	let var_array = ["CONNECTOR", this.state.cableAttachDirection, "TOP", this.state.xyStickeFace];
    this.state.formfields['ecuOrientation'] = var_array.join('_');
    
    let that = this;
    that.setState({
      isWaitingApiResponse: true
    });

    VehiclesService.updateVehicle(this.state.formfields).then(function (res) {
      let lastSavedFormFields = that.state.formfields;
      that.setState({
        alert: {
          type: "success",
          message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.vehicleUpdate')
        },
        isWaitingApiResponse: false,
        savedFormFields: { ...lastSavedFormFields }
      });
    }, function (res) {
      let alertMsg = i18n.t('common.genericApiError');

      if (res && res.data && res.data.message) {
        alertMsg = res.data.message;
      }

      that.setState({
        alert: {
          type: "danger",
          message: alertMsg
        },
        isWaitingApiResponse: false
      });
    });
    
    //update calibration details
    let vehicleId = this.props.match.params.vehicleId;
    if(this.state.savedVehicleId != 0  && this.state.shouldAddCalibration){
		CalibrationsService.createCalibration(this.getCalibRequestBody(vehicleId)).then( calibResp => {
			this.setState({
	        alert: {
	          type: "success",
	          message: i18n.t('vehicleListCreateViewAndEditAndAssignment.alert.calibrationUpdate')
	        },
	        isWaitingApiResponse: false	                
	      });
	      
		}, err => {
	      let alertMsg = i18n.t('common.genericApiError');
	      if (err && err.data && err.data.message) {
	        alertMsg = err.data.message;
	      }
	
	      that.setState({
	        alert: {
	          type: "danger",
	          message: alertMsg
	        },
	        isWaitingApiResponse: false
	      });
	    }		
		);
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  isEqual(vehicleObj1, vehicleObj2) {
    let result = true;
    _.forOwn(vehicleObj1, function (value, key) {
      if (_.toString(value) !== _.toString(vehicleObj2[key])) {
        result = false;
      }
    });

    return result;
  }

  /**
   * func to check if form is valid
   */
  isFormValid() {
    let result = this.isEqual(this.state.formfields, this.state.savedFormFields);
    let returnVal = (!result && this.state.formValid);
    return returnVal;
  }

  /**
   * go back to previous page
   */
  handleBack() {
    this.props.history.goBack();
  }


  /**
   * reset form and set it to previous state
   */
  resetVehicleFormFields() {
    let prevSavedFormFields = this.state.savedFormFields;
    this.setState({
      formfields: { ...prevSavedFormFields },
      formErrors: {
        make: "",
        model: "",
        year: "",
        type: "",
        localId: "",
        steeringWheelType: "",
        wheelbase: "",
        antennaHeight: "",
        antennaPivot: "",
        antennaOffset: "",
        frontHitch: "",
        rearHitch: "",
        attack: "",
        backlash: "",
        sensitivity: "",
        profileVersion: "",
      },
    });
  }

  /**
   * render vehicle form with data fetched from backend 
   */
  renderCreateVehicleForm() {
    
    return (
      <LoadingOverlay active={this.state.isWaitingApiResponse || this.state.isLoading}>
        <div className="container-fluid ">
          <form autoComplete="off">
            <h2> {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.editVehicle')}  </h2>
            <div className="view-profile-details width-70per">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="make">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.make')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="make"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.make')}
                      value={this.state.formfields.make}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.make} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="model">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.model')} <StyledText uiText="*" /></label>
                    <input type="text" required className="form-control" name="model"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.model')}
                      value={this.state.formfields.model}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.model} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="year">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.year')}</label>
                    <input type="text" className="form-control" name="year"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.year')}
                      value={this.state.formfields.year}
                      onChange={this.handleUserInput} maxLength="4" />
                    <FormErrors formErrors={this.state.formErrors.year} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="type">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.type')} <StyledText uiText="*" /></label>
                    <select className="form-control" id="type" name="type" onChange={this.handleUserInput}
                      required value={this.state.formfields.type}>
                      <option key="placeHolderVehicleType" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.type')}</option>
                      {
                        this.vehicleTypes.map((elem, id) => {
                          return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                        })
                      }
                    </select>
                    <FormErrors formErrors={this.state.formErrors.type} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="attack">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.attack')} <StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="attack"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.attack')}
                      value={this.state.formfields.attack}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.attack} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="backlash">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.backlash')} <StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="backlash"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.backlash')}
                      value={this.state.formfields.backlash}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.backlash} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="sensitivity">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.sensitivity')}<StyledText uiText="*" /></label>
                    <input type="text" className="form-control" name="sensitivity"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.sensitivity')}
                      value={this.state.formfields.sensitivity}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.sensitivity} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="localId">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.localId')}</label>
                    <input type="text" className="form-control" name="localId"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.localId')}
                      value={this.state.formfields.localId ? this.state.formfields.localId : ""}
                      onChange={this.handleUserInput} maxLength="256" />
                    <FormErrors formErrors={this.state.formErrors.localId} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="steeringWheelType">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.steeringWheelType')}</label>
                    <input type="text" className="form-control" name="steeringWheelType"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.steeringWheelType')}
                      value={this.state.formfields.steeringWheelType}
                      onChange={this.handleUserInput} maxLength="32" />
                    <FormErrors formErrors={this.state.formErrors.steeringWheelType} />
                  </div>
                </div>
              </div>

              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="wheelbase">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.wheelbase')}</label>
                    <input type="text" className="form-control" name="wheelbase"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.wheelbase')}
                      value={this.state.formfields.wheelbase}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.wheelbase} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaHeight">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaHeight')}</label>
                    <input type="text" className="form-control" name="antennaHeight"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaHeight')}
                      value={this.state.formfields.antennaHeight}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaHeight} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaPivot">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaPivot')}</label>
                    <input type="text" className="form-control" name="antennaPivot"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaPivot')}
                      value={this.state.formfields.antennaPivot}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaPivot} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="antennaOffset">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.antennaOffset')}</label>
                    <input type="text" className="form-control" name="antennaOffset"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.antennaOffset')}
                      value={this.state.formfields.antennaOffset}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.antennaOffset} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="frontHitch">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.frontHitch')}</label>
                    <input type="text" className="form-control" name="frontHitch"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.frontHitch')}
                      value={this.state.formfields.frontHitch}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.frontHitch} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="rearHitch">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.rearHitch')}</label>
                    <input type="text" className="form-control" name="rearHitch"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.rearHitch')}
                      value={this.state.formfields.rearHitch}
                      onChange={this.handleUserInput} />
                    <FormErrors formErrors={this.state.formErrors.rearHitch} />
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="ecuOrientation">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.ecuOrientation')}</label>
                    <div className="row">
                      <div className="col-md-6">
                      <label htmlFor="ecuOrientation">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.CableAttachDirection')}</label>
                        <select className="form-control" id="cableAttachDirection" name="cableAttachDirection"
                          onChange={this.handleUserInput} value={this.state.cableAttachDirection.charAt(0).toUpperCase() + this.state.cableAttachDirection.slice(1)} >
                          <option key="placeHolderVehicleEcu" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.CableAttachDirection')}</option>
                          {
                            this.cableAttachDirectionTypes.map((elem, id) => {
                              return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="ecuOrientation">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.XYStickeFace')}</label>
                        <select className="form-control" id="xyStickeFace" name="xyStickeFace"
                          onChange={this.handleUserInput} value={this.state.xyStickeFace.charAt(0).toUpperCase() + this.state.xyStickeFace.slice(1)} >
                          <option key="placeHolderVehicleEcu" value="">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.XYStickeFace')}</option>
                          {
                            this.xyStickeFaceTypes.map((elem, id) => {
                              return <option key={elem.value + id} value={elem.value}>{elem.displayName}</option>
                            })
                          }
                        </select>
                    </div>                    
                  </div>
                </div>
              </div>
              <div className="hr-line-dashed"></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="profileVersion">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.profileVersion')}</label>
                    <input type="text" className="form-control" name="profileVersion"
                      placeholder={i18n.t('vehicleListCreateViewAndEditAndAssignment.form.placeholder.profileVersion')}
                      value={this.state.formfields.profileVersion}
                      onChange={this.handleUserInput} maxLength="64" />
                    <FormErrors formErrors={this.state.formErrors.profileVersion} />
                  </div>
                </div>
              </div>  
              <div className="hr-line-dashed"></div>
              <div className="row">
            	<div className="col-md-4">
					<div className="form-group">
						<input type="checkbox" name="AddCalibration"
						value={"no"}
						onChange={this.handleUserInput} /> &nbsp;&nbsp;
						<label htmlFor="AddCalibration">{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.label.AddCalibration')}</label>
					</div>
			  	</div>
			  	<Collapse isOpened={this.state.shouldAddCalibration}>
		            <div className="row">
		                <div className="col-md-8">
		                	<div className="form-group">
							 	<Nav tabs>
							        <NavItem>
							          <NavLink disabled={this.state.aTabEnabled} onClick={() => this.setActiveTab('A')}>
							            {i18n.t('common.calibrationTypes.a')}
							          </NavLink>
							        </NavItem>
							        <NavItem>
							          <NavLink disabled={this.state.bTabEnabled} onClick={() => this.setActiveTab('B')}>
							            {i18n.t('common.calibrationTypes.b')}
							          </NavLink>
							        </NavItem>
							        <NavItem>
							          <NavLink disabled={this.state.cTabEnabled} onClick={() => this.setActiveTab('C')}>
							            {i18n.t('common.calibrationTypes.c')}
							          </NavLink>
							        </NavItem>
							        <NavItem>
							          <NavLink disabled={this.state.dTabEnabled} onClick={() => this.setActiveTab('D')}>
							            {i18n.t('common.calibrationTypes.d')}
							          </NavLink>
							        </NavItem>
						      </Nav>
						      <TabContent activeTab={this.state.activeTab} style={{maxHeight:"none"}}>
							        <TabPane tabId="A">							        	
							        	<label htmlFor="aLeftRadius">{i18n.t('user.viewAndListUserCalibration.form.label.leftRadius')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="aLeftRadius" className="form-control col-md-6"
							        		value={this.state.formfields.aLeftRadius}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.aLeftRadius} />
							        	<label htmlFor="aRightRadius">{i18n.t('user.viewAndListUserCalibration.form.label.rightRadius')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="aRightRadius" className="form-control col-md-6"
							        		value={this.state.formfields.aRightRadius}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.aRightRadius} />							        						        	
							        </TabPane>
							        <TabPane tabId="B">
							        	<label htmlFor="bLockRight">{i18n.t('user.viewAndListUserCalibration.form.label.lockRight')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="bLockRight" className="form-control col-md-6" value={this.state.formfields.bLockRight}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.bLockRight} />
							        	<label htmlFor="bLockLeft">{i18n.t('user.viewAndListUserCalibration.form.label.lockLeft')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="bLockLeft" className="form-control col-md-6" value={this.state.formfields.bLockLeft}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.bLockLeft} />								        	
							        </TabPane>
							        <TabPane tabId="C">
										<label htmlFor="cPitchBias">{i18n.t('user.viewAndListUserCalibration.form.label.pitchBias')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="cPitchBias" className="form-control col-md-6" value={this.state.formfields.cPitchBias}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.cPitchBias} />
							        	<label htmlFor="cRollBias">{i18n.t('user.viewAndListUserCalibration.form.label.rollBias')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="cRollBias" className="form-control col-md-6" value={this.state.formfields.cRollBias}
		                                	onChange={this.handleUserInput} maxLength="10"/>
							        	<FormErrors formErrors={this.state.formErrors.cRollBias} />
									</TabPane>
							        <TabPane tabId="D">
							        	<label htmlFor="dSteeringRatio">{i18n.t('user.viewAndListUserCalibration.form.label.steeringRatio')}</label>:&nbsp;&nbsp;
							        	<input type="text" name="dSteeringRatio" className="form-control col-md-6" value={this.state.formfields.dSteeringRatio}
		                                	onChange={this.handleUserInput} maxLength="10"/>	
							        	<FormErrors formErrors={this.state.formErrors.dSteeringRatio} />			        	
							        </TabPane>
						      </TabContent>
					      </div>					      
		                </div> 	                     
		            </div>
		        </Collapse>
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button type="button" className="btn btn-secondary mr-2"
                  onClick={this.resetVehicleFormFields.bind(this)}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.reset')}
                </button>
                <button type="button" className="btn btn-secondary mr-2"
                  onClick={this.handleBack.bind(this)}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.back')}
                </button>
                <button type="submit" className="btn btn-primary"
                  disabled={(!this.isFormValid() || this.state.isWaitingApiResponse)}
                  onClick={this.createVehicleSubmit.bind(this)}>
                  {i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </LoadingOverlay>
    );
  }

  /**
   * renders error message to form.
   */
  renderError() {
    return (
      <div className="col-md-12 text-right f-btn">
        <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleBack.bind(this)}>{i18n.t('vehicleListCreateViewAndEditAndAssignment.form.button.back')}</button>
      </div>
    );
  }

  /**
   * react render func
   */
  render() {
    let breadcrumb = [
      { id: 'home', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.home'), href: '#/home', className: '', link: true },
      { id: 'vehicle', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.vehicle'), className: '', link: false },
      { id: 'edit', displayName: i18n.t('vehicleListCreateViewAndEditAndAssignment.breadcrumb.edit'), className: '', link: false }
    ];

    return (
      <div className="container-fluid p-4">
        <BreadcrumbCustom breadcrumb={breadcrumb} />
        <div className="clearfix"></div>

        {
          this.state.alert.message &&
          <AlertMessage message={this.state.alert.message}
            type={this.state.alert.type}
            isAlertOpen={!!(this.state.alert.message)}
            handleDismiss={this.handleAlertDismiss.bind(this)}
          />
        }

        {
          this.state.isErrorOnLoad ? this.renderError() : this.renderCreateVehicleForm()
        }
      </div>
    );
  }
}

export default EditVehicle;
