import React from 'react';
import { SubmissionAlert } from '../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../Component/loader/Loader";
import { DeviceService } from "../../../services/service.devices";
import { Link } from 'react-router-dom';
import i18n from 'i18next';

/**
 * to show activation status of device.
 */
const deviceActivationStatus = {
    "Inactive": 0,
    "Active": 1
}

/**
 * device detail component. renders view component.
 */
class ViewDevice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            serialNumber: '',
            oemId: '',
            activationStatus: '',
            partNumber: '',
            activationCode: '',
            manufactureData: '',

            activationCodeType: 'password',
            isErrorOnLoad: false,
            showMessage: false,
            apiResponseIsSuccess: false,
            apiResponseMessage: ''
        }
    }

    componentDidMount() {
        let deviceId = this.props.match.params.deviceId;
        DeviceService.fetchDeviceById(deviceId).then(
            (response) => {
                let devices = this.prepareDeviceResponseForActivationStatus(response.data);
                this.setState({
                    isLoading: false,
                    serialNumber: devices.serialNumber,
                    oemId: devices.oemId,
                    partNumber: devices.partNumber,
                    activationCode: devices.activationCode,
                    activationStatus: devices.activationStatus,
                    manufactureData: devices.manufactureData,
                    apiResponseIsSuccess: true,
                    showMessage: false
                });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    isErrorOnLoad: true,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }

    /**
     * helper method to re-prepare the device details received from backend.
     * @param {*} devices 
     */
    prepareDeviceResponseForActivationStatus(devices) {
        if (devices.activationStatus === deviceActivationStatus["Inactive"]) {
            devices.activationStatus = i18n.t('deviceListCreateViewEditAndUpload.list.options.inactive');
        } else if (devices.activationStatus === deviceActivationStatus["Active"]) {
            devices.activationStatus = i18n.t('deviceListCreateViewEditAndUpload.list.options.active');
        }
        return devices;
    }

    /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }
    
    /**
     * handles cancel event
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}> {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')} </button>
            </div>
        );
    }

    /**
     * toggle activation field from normal text to encrypted and vice-versa.
     * @param {*} fieldName 
     * @param {*} event 
     */
    toggleFieldType(fieldName, event) {
        event.preventDefault();
        if (fieldName === "activationCode") {
            this.setState({ activationCodeType: this.state.activationCodeType === "password" ? "text" : "password" });
        }
    }

     /**
     * encapsulate react render function
     */
    renderViewDeviceForm() {
        const { history } = this.props;
        return (
            <div>
                <h2>{i18n.t('deviceListCreateViewEditAndUpload.form.viewDevice')} </h2>
                <div className="view-profile-details">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.serialNumber')} </label>
                            <p>{this.state.serialNumber}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.oem')} </label>
                            <p>{this.state.oemId}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.partNumber')} </label>
                            <p>{this.state.partNumber}</p>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="activationCode"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.activationCode')} </label>
                                <div className="input-group">
                                    <input type={this.state.activationCodeType} disabled name="activationCode"
                                        className="form-control show-hide-password"
                                        value={this.state.activationCode}
                                        maxLength="32" />
                                    <button onClick={this.toggleFieldType.bind(this, "activationCode")}>
                                        <i className={"fa " + (this.state.activationCodeType === "password" ? "fa-eye-slash" : "fa-eye")}></i>
                                    </button>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.manufactureData')} </label>
                            <p>{(this.state.manufactureData && this.state.manufactureData !== "") ? this.state.manufactureData : "N/A"}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.form.label.activationStatus')} </label>
                            <p>{this.state.activationStatus}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-right f-btn">
                    <div>
                        <button type="button" className="btn btn-secondary mr-2" onClick={history.goBack} > {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')} </button>
                        <Link to={{ pathname: '/device/edit-device/' + this.props.match.params.deviceId }}
                            className="btn btn-primary mr-2">{i18n.t('deviceListCreateViewEditAndUpload.form.button.edit')}</Link>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * react render func
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: '', link: false },
            { id: 'detail', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.detail'), className: '', link: false }
        ];
        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return (
                <div>
                    <div className="container-fluid p-4">
                        <div className="float-left">
                            <BreadcrumbCustom breadcrumb={breadcrumb} />
                        </div>
                        <div className="clearfix"></div>
                        {/* {this.state.apiResponseIsHandled && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.isApiResponseSuccess} onDismiss={this.onDismiss} />} */}
                        {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                        {this.state.isErrorOnLoad ? this.renderError() : this.renderViewDeviceForm()}
                    </div>
                </div>
            );
        }
    }
}

export default ViewDevice;