import axios from 'axios';
import _ from 'lodash';
import { DEFAULT_LANG } from '../constants/app.constants';
import { CommonUtilities } from '../shared/utils/commonUtilities';

const baseService = {
  get,
  put,
  post,
  delete: _delete,
  _deleteWithBody: _deleteWithBody,
  getCancelToken,
  fetchDataByIdAsParam,
  updateDataById,
  mapUsersForAssignment
};

export const methodType = {
  get: 'GET',
  put: 'PUT',
  post: 'POST',
  delete: 'DELETE'
};

//= ==========CRUD Operations abstractions=====================//

/**
 * Description: Use AXIOS GET METHOD
 * @param {string} url
 * @param {object} headers
 * @param {object} body
 * @return {promise}
 */
function get (url, headers) {
  headers = addAdditionalHeaders(headers);
  return axios.get(
    url,
    {
      headers: headers
    }).then(handleResponse).catch(error);
}

/**
 * Description: Use AXIOS PUT METHOD
 * @param {string} url
 * @param {object} headers
 * @param {object} body
 * @return {promise}
 */
function put (url, headers, body) {
  headers = addAdditionalHeaders(headers);
  return axios.put(
    url,
    body,
    {
      headers: headers
    }).then(handleResponse).catch(error);
}

/**
 * Description: Use AXIOS POST METHOD
 * @param {string} url
 * @param {object} headers
 * @param {object} body
 * @return {promise}
 */
function post (url, headers, body) {
  headers = addAdditionalHeaders(headers);
  return axios.post(
    url,
    body,
    {
      headers: headers
    }).then(handleResponse).catch(error);
}

/**
 * Description: Use AXIOS DELETE METHOD
 * @param {string} url
 * @param {object} headers
 * @param {object} body
 * @return {promise}
 */
function _delete (url, headers, params) {
  headers = addAdditionalHeaders(headers);
  return axios.delete(
    url,
    {
      headers: headers
    }).then(handleResponse).catch(error);
}

/**
 * Description: Use AXIOS DELETE METHOD
 * @param {string} url
 * @param {object} headers
 * @param {object} body
 * @return {promise}
 */
function _deleteWithBody (url, headers, params) {
  headers = addAdditionalHeaders(headers);
  return axios.delete(
    url,
    {
      headers: headers,
      data: params
    }).then(handleResponse).catch(error);
}

/**
 * fetch any data passed in url.
 * @param {*} url 
 * @param {*} headers 
 * @param {*} cancelToken 
 */
function fetchDataByIdAsParam (url, headers, cancelToken) {
  headers = addAdditionalHeaders(headers);
  return axios.get(
    url,
    {
      headers,
      cancelToken: cancelToken.token
    }).then(handleResponse).catch(error);
}

/**
 * update data with id passed in url
 * @param {*} url 
 * @param {*} headers 
 * @param {*} body 
 */
function updateDataById (url, headers, body) {
  headers = addAdditionalHeaders(headers);
  return axios.put(
    url,
    body,
    {
      headers
    }).then(handleResponse).catch(error);
}

/**
 * service to map assignments. asssigner to assignee mapping.
 * @param {*} url
 * @param {*} headers
 * @param {*} body
 */
function mapUsersForAssignment (url, headers, body) {
  headers = addAdditionalHeaders(headers);
  return axios.post(
    url,
    body,
    {
      headers
    }).then(handleResponse).catch(error);
}

/**
 * Description: handle valid response code got after axios
 * @param {object} response
 * @return {promise || response}
 */
function handleResponse (response) {
  if (!CommonUtilities.isValidHttpCode(response.status)) {
    return Promise.reject(response);
  }
  return response;
}

/**
 * Description: called in for error axios
 * @param {object} error
 * @return {object}
 */
function error (error) {
  let errorResponse = '';
  if (axios.isCancel(error)) {
    errorResponse = error.message;
  } else {
    errorResponse = error.response ? error.response : { response: { status: 500 } };
  }
  return Promise.reject(errorResponse);
}

function getCancelToken () {
  return axios.CancelToken.source();
}

/**
 * function for adding header for language.
 * @param {*} headers
 */
function addAdditionalHeaders (headers) {
  if (_.isObject(headers)) {
    let lng = CommonUtilities.getI18SelectedLanguage();
    lng = lng ? lng : DEFAULT_LANG;
    headers = _.assign(headers, { 'Accept-Language': lng });
  }
  return headers;
}

export default baseService;
