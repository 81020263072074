import React from 'react';
import axios from 'axios';
import Grid from '../../../Component/grid/Grid';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { UserService } from "../../../services/service.users";
import { CalibrationsService } from '../../../services/service.calibrations';
import i18n from 'i18next';

/**
 * Informations to be shown in calibration listing grid, along with options per rows in support details page.
 */
let calibrationListConfig = {};

/**
 * List associated calibration component
 */
class ListConcernedCalibrations extends BaseScreen {
  constructor(props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    calibrationListConfig = {
      calibrationType: {
        label: i18n.t('supportListViewAndConcerns.list.type'),
        display: true,
      },
      calibrationStatus: {
        label: i18n.t('supportListViewAndConcerns.list.status'),
        display: true,
      },
      calibrationStartTime: {
        label: i18n.t('supportListViewAndConcerns.list.startTime'),
        display: true,
        isDate: true,
      },
      calibrationEndTime: {
        label: i18n.t('supportListViewAndConcerns.list.endTime'),
        display: true,
        isDate: true,
      },
      userId: {
        label: i18n.t('supportListViewAndConcerns.list.createdBy'),
        display: true,
      }
    };

    this.state = {
      cancelToken: '',
      isAuthenticated: isAuthenticated,
      vehicleId: this.props.vehicleId,
      accountId: this.props.accountId,
      calibrationData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10,
        totalCount: 0,
        pageList: [10]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount() {
    const arg = {
      currentPage: 0,
      pageSize: this.state.pgnConfig.countPerPage
    };
    this.getCalibrationsByVehicleId(arg).then(response => {
      const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.total);
      this.setState({
        calibrationData: response.items,
        pgnConfig: pgnConfig,
        loader: false
      });
    }, error => {
      let errorMsg = i18n.t('common.genericApiError');
      if (error && error.data && error.data.message) {
        errorMsg = error.data.message;
      }
      this.setState(
        {
          calibrationData: [],
          loader: false,
          alert: {
            type: 'danger',
            message: errorMsg
          }
        });
    });
  }

  getCalibrationsByVehicleId(requiredParams) {
    let reqQueryParams = '';
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' +
      params.pageSize + '&accountId=' + this.props.accountId + '&vehicleId=' + this.props.vehicleId;

    if (this.state.cancelToken) {
      this.state.cancelToken.cancel("Cancelling request");
    }
    const CancelToken = axios.CancelToken;
    this.state.cancelToken = CancelToken.source();

    return CalibrationsService.getCalibrations(reqQueryParams)
      .then(response => {
        this.resolveRequesterDataFromSupportLog(response.data);
        return response.data;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  resolveRequesterDataFromSupportLog(data) {
    let response = {};
    response.total = data.total;
    for (var i = 0; i < data.items.length; i++) {
      this.changeIdToUserEmailToDisplay(data.items[i].userId).then(
        (resp) => {
          if (resp) {
            (data.items).forEach(element => {
              if (resp.id === element.userId) {
                element.userId = resp.email;
              }
            })
          }
          response.items = data.items;
          this.setState({
            calibrationData: response.items
          });
          return response;
        },
        (error) => {
          (data.items).forEach(element => {
            if (error === element.userId) {
              element.userId = 'Email Unavailable';
            }
          })
          this.setState({
            calibrationData: data.items
          });
        }
      )
    }
  }

  changeIdToUserEmailToDisplay(userId) {
    return UserService.fetchUserById(userId, this.state.cancelToken).then(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(userId);
      }
    )
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig(currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange = (pageNumber) => {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, calibrationData: [] });
      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getCalibrationsByVehicleId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.total);
        this.setState({
          calibrationData: response.items,
          pgnConfig: pgnConfig,
          loader: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            calibrationData: [],
            loader: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss() {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render view form
   */
  render() {
    const tableScrollStyle = {
      maxHeight: '530px',
      overflow: 'hidden',
      overflowY: 'auto'
    };

    return (
      <div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          <div >
            <Grid id="userImplementsListGrid"
              keyColumn="id"
              rowData={this.state.calibrationData}
              columnDefs={calibrationListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange}
            />
          </div>
        </div>
        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListConcernedCalibrations;
