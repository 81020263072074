import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FirmwareService } from '../../services/service.firmwares';
import { SupportService } from '../../services/service.support';
import { DocumentsService } from '../../services/service.documents';
import ListUserForAssignment from '../../Containers/Screens/user/ListUserForAssignment';
import ListUsersForVehicleAssignment from '../../Containers/Screens/vehicle/ListUsersForVehicleAssignment';
import LoadingOverlay from '../../Component/loader/LoadingOverlay';
import i18n from 'i18next';

/**
 * Modal dialog functionality encapsulation.
 * This modal open separate modal window for specific functionality rendring.
 * @param {*} props
 */
const ModalDialog = (props) => {
  const modalPopup = () => {
    return (
      <div>
        <Modal isOpen={props.isModalOpen}
          onClosed={props.onModalClosed}
          unmountOnClose={true}
          centered={true}
          wrapClassName="modal-wrapper-custom"
        >
          {modalHeader(props)}
          <ModalBody>{props.modalMessage}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={props.modalAction.bind(this, props.modalData)}>
              {i18n.t('common.modal.footer.button.yes')}
            </Button>
            <Button color="secondary" onClick={props.handleModalDismiss}>
              {i18n.t('common.modal.footer.button.no')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  return modalPopup();
};

/**
 * based on modal type, calls type of functionality to render.
 * @param {*} props
 */
function resolveModal (props) {
  if (props.modalType === 'userAssignment') {
    return <ListUserForAssignment data={props}/>;
  } else if (props.modalType === 'vehicleAssignment') {
    return <ListUsersForVehicleAssignment data={props} />;
  }
};

/**
 * modal window to display in case of any assignment type use case.
 * @param {*} props 
 */
export const ModalWindowForAssignment = (props) => {
  const modalPopup = () => {
    return (
      <div>
        <Modal isOpen={props.isModalOpen}
          onClosed={props.onModalClosed}
          unmountOnClose={true}
          centered={true}
          size="lg"
          wrapClassName="modal-wrapper-custom"
        >
          {modalHeader(props)}
          <ModalBody>
            {resolveModal(props)}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={props.handleModalDismiss}>{i18n.t('common.modal.footer.button.back')}</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  return modalPopup();
};

/**
 * modal window for lazy loading. used when data is not available when showing the modal.
 * like in case of download, when presigned url is not available. it should show some intermediate prompt window for user interaction.
 */
export class ModalDialogWithLazyDataLoading extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      location: ''
    };
  }

  componentDidMount () {
    if (this.props.modalType === 'firmware_download') {
      FirmwareService.fetchFirmwareByIdSubmission(this.props.modalData.id).then(
        (response) => {
          const location = response.data.location;
          this.setState({
            isLoading: false,
            location: location
          });
        });
    } else if (this.props.modalType === 'support_download') {
        SupportService.decryptSupportFile(this.props.modalData).then(
          (response) => {
            const location = response.data.url;
            this.setState({
              isLoading: false,
              location: location
            });
          });
    } else if (this.props.modalType === 'support_download_raw_file') {
      SupportService.downloadRawSupportFile(this.props.modalData).then(
        (response) => {
          const location = response.data.url;
          this.setState({
            isLoading: false,
            location: location
          });
        });
    } else {
      this.setState({ isLoading: false });
    }
  }

  /**
   * react render func.
   */
  render () {
    if (this.state.isLoading) {
      return (
        <LoadingOverlay active={this.state.isLoading} customClass="login_loading_overlay">
          <div>
            <Modal isOpen={this.props.isModalOpen}
              onClosed={this.props.onModalClosed}
              unmountOnClose={true}
              centered={true}
              wrapClassName="modal-wrapper-custom">
              {modalHeader(this.props)}
              <ModalBody>{i18n.t('common.loaderMsg')}</ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          </div>
        </LoadingOverlay>
      );
    } else {
      return (
        <div>
          <Modal isOpen={this.props.isModalOpen}
            onClosed={this.props.onModalClosed}
            unmountOnClose={true}
            centered={true}
            wrapClassName="modal-wrapper-custom">
            {modalHeader(this.props)}
            <ModalBody>{this.props.modalMessage}</ModalBody>
            <ModalFooter>
              <div className={'form-group-btn'}>
                <a href={this.state.location} className="btn btn-primary" onClick={this.props.handleModalDismiss}>
                  {i18n.t('common.modal.footer.button.download')}
                </a>
              </div>
              <Button color="secondary" onClick={this.props.handleModalDismiss}>
                {i18n.t('common.modal.footer.button.no')}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
}

/**
 * Modal window for file download. This modal queries the download url from 
 * backend and handles success/error status.
 * @param {*} props 
 */
export const ModalDialogForFileDownload = (props) => {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (props.modalType === 'document_download') {
      DocumentsService.getDownloadUrl(props.modalData.id).then(res => {
        setLoading(false);
        setDownloadUrl(res.data.url);
      }, err => {
        let errorMsg = i18n.t('common.genericApiError');
        if (_.has(err, 'data.message')) {
          errorMsg = err.data.message;
        }
        setLoading(false);
        setErrorMsg(errorMsg);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Modal isOpen={props.isModalOpen}
        onClosed={props.onModalClosed}
        unmountOnClose={true}
        centered={true}>
        {
          modalHeader(props)
        }
        {
          loading ? (<ModalBody>{i18n.t('common.loaderMsg')}</ModalBody>)
            :
            (errorMsg ? (<Fragment>
              <ModalBody>{errorMsg}</ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={props.handleModalDismiss}>
                  {i18n.t('common.modal.footer.button.back')}
                </Button>
              </ModalFooter>
            </Fragment>)
              :
              (<Fragment>
                <ModalBody>{props.modalMessage}</ModalBody>
                <ModalFooter>
                  <div className={'form-group-btn'}>
                    <a href={downloadUrl}
                      className="btn btn-primary"
                      onClick={props.modalAction}>
                      {i18n.t('common.modal.footer.button.download')}
                    </a>
                  </div>
                  <Button color="secondary"
                    onClick={props.handleModalDismiss}>
                    {i18n.t('common.modal.footer.button.no')}
                  </Button>
                </ModalFooter>
              </Fragment>)
            )
        }
      </Modal>
    </Fragment>
  );
};


/**
 * func to display contextual text in header part of modal window.
 * @param {*} props
 */
function modalHeader (props) {
  return (
    <div className="modal-header custom-modal-header">
      <h5 className="modal-title">{props.modalTitle}</h5>
      <Button className="close" onClick={props.handleModalDismiss}>
        <span>×</span>
      </Button>
    </div>
  );
}

export default ModalDialog;
