import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { APP_BUILD_VERSION } from "../constants/app.constants";

/**
 * component for actions of logged in user.
 */
export class LoggedInUserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSubId: ''
    };
  }

  componentDidMount() {
    this.setState({ userSubId: this.props.userSubId });
  }

  /**
   * logout event handling
   */
  handleLogout = (event) => {
    if (this.props.logoutHandler) {
      this.props.logoutHandler(event);
    }
  }

  /**
   * react render func.
   */
  render() {
    let userIconSrc = process.env.PUBLIC_URL + '/assets/images/user_icon.png?preventCaching=' + APP_BUILD_VERSION;
    return (
      <UncontrolledDropdown direction="left">
        <DropdownToggle color="primary" data-testid="user-icon-btn">
          <img src={userIconSrc} className="user_logo" alt="" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="span" className="btn" >
            <Link to={{
              pathname: '/user/view-profile/' + this.props.userSubId
            }} data-testid="view-profile-link">
              {i18n.t('user.editAndViewProfile.viewProfile')}
            </Link>
          </DropdownItem>
          <DropdownItem tag="span" className="btn">
            <Link to={{
              pathname: '/user/change-password/' + this.props.userSubId
            }} data-testid="change-password-link">
              {i18n.t('user.editAndViewProfile.changePassword')}
            </Link>
          </DropdownItem>
          <DropdownItem data-testid="logout-btn"
            tag="button"
            className="btn"
            onClick={this.handleLogout}>
            {i18n.t('user.editAndViewProfile.logOut')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}
