import React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import { LoggedInUserActions } from './LoggedInUserActions';
import BaseScreen from '../Containers/Screens/BaseScreen';
import { AppStorageHelper } from '../shared/utils/appStorageHelper';
import { APP_COMMON_CONSTANTS, APP_BUILD_VERSION } from '../constants/app.constants';
import JwtDecode from 'jwt-decode';
import { SCREENS } from '../constants/screens.constant';
import { LeftMenu } from './LeftMenu';
import { AuthenticationServices } from '../services/service.authentication';

/**
 * generic header class for application.
 */
export default class Header extends BaseScreen {
  constructor (props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      userSubId: ''
    };
  }

  componentDidMount () {
    const theToken = AppStorageHelper.getTokenFromLocalStorage(APP_COMMON_CONSTANTS.AUTH_INFO);
    const decodedData = JwtDecode(theToken.acessToken);
    this.setState({ userSubId: decodedData.sub });
  }

  /**
   * left menu toggle.
   */
  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  /**
   * left menu navigation on click event.
   * @param {*} arg 
   * @param {*} event 
   */
  onNavLinkClick (arg, event) {
    this.toggleNavbar();
  }

  /**
   * func to handle logout event
   */
  handleLogout = (event) => {
    AuthenticationServices.logout().then(
      (response) => {
        console.log("logout api returned SUCCESS response");
      }, (error) => {
        console.log("logout api returned ERROR response");
      });
    this.gotoLogin();
  }

  /**
   * func to show login page
   */
  gotoLogin = () => {
    AppStorageHelper.clearApplicationData();
    this.goToScreen(SCREENS.login);
  }

  /**
   * react render.
   */
  render () {
    let logoHeaderSrc = process.env.PUBLIC_URL + '/assets/images/oem_logo_header.png?preventCaching=' + APP_BUILD_VERSION;

    return (
      <header>
        <div className="logo-inner">
          <a href="#/home" data-testid="header-logo-link">
            <img src={logoHeaderSrc} alt="" />
          </a>
        </div>
        <div className="header-right">
          <LoggedInUserActions logoutHandler={this.handleLogout} userSubId={this.state.userSubId} />
        </div>
        <Navbar color="dark" className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
          <NavbarBrand href="#/home">
            <img src={logoHeaderSrc} height="32" alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <LeftMenu leftMenuItems={this.props.leftMenuItems}
              routerData={this.props.routerData}
              isMobile={true}
              onSidebarNavLinkClick={this.onNavLinkClick.bind(this)}
              addUserInfo={true}
              userSubId={this.state.userSubId} 
              logoutHandler={this.handleLogout}/>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
