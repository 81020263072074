import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './services/api_interceptor';
import DefaultLayout from './Containers/Screens/DefaultLayout';
import ResetPassword from './Containers/Screens/no-auth/ResetPassword';
import ForceChangePassword from './Containers/Screens/no-auth/ForceChangePassword';
import Login from './Containers/Screens/no-auth/Login';
import './Containers/css/app/app.scss';
import 'font-awesome/css/font-awesome.min.css';

/**
 * App.
 */
class App extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" render={props => <Login routerData={props} />} />
          <Route exact path="/reset-password" render={props => <ResetPassword routerData={props} />} />
          <Route exact path="/force-change-password" render={props => <ForceChangePassword routerData={props} />} />
          <Route path="/" render={props => <DefaultLayout routerData={props} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
