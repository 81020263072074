import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import i18n from 'i18next';
import BreadcrumbCustom from '../../../Component/breadcrumb/BreadcrumbCustom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { DocumentsService } from '../../../services/service.documents';
import AlertMessage from '../../../Component/alert/AlertMessage';
import LoadingOverlay from '../../../Component/loader/LoadingOverlay';
import { CommonUtilities } from '../../../shared/utils/commonUtilities';

/**
 * View document component, for displayng document details
 */
class ViewDocument extends BaseScreen {
    /**
     * Component initialization
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        let isAuthenticated = true;

        if (!this.isAuth()) {
            isAuthenticated = false;
            this.goToScreen(SCREENS.login);
        }

        this.state = {
            isAuthenticated: isAuthenticated,
            documentData: {},
            alert: {
                type: null,
                message: null
            },
            isLoading: true,
            isErrorOnLoad: false
        }
    }

    /**
     * React life cycle method, called after component is first rendered.
     */
    componentDidMount() {
        if (this.state.isAuthenticated) {
            this.displayDocumentInfo();
        }
    }

    /**
     * fetch specific document data from backend
     */
    displayDocumentInfo() {
        let documentId = this.props.match.params.documentId;

        DocumentsService.getDocument(documentId).then(res => {
            this.setState({
                documentData: res.data,
                isLoading: false
            });
        }, err => {
            let errorMsg = i18n.t('common.genericApiError');
            if (_.has(err, 'data.message')) {
                errorMsg = err.data.message;
            }
            this.setState({
                isLoading: false,
                isErrorOnLoad: true,
                alert: {
                    type: 'danger',
                    message: errorMsg
                },
            });
        });
    }

    /**
     * Navigate to previous view.
     */
    handleBack = () => {
        this.props.history.goBack();
    }

    /**
     * dismiss the alert component shown as a result of successful or failed results.
     */
    handleAlertDismiss = () => {
        this.setState({
            alert: {
                type: null,
                message: null
            }
        });
    }

    /**
     * render view document ui
     */
    renderViewDocument = () => {
        return (
            <LoadingOverlay active={this.state.isLoading}>
                <div className='p-4'>
                    <h2> {i18n.t('documentViews.form.viewDocument')} </h2>
                    <div className='view-profile-details'>
                        <div className='row'>
                            <div className='col-md-10'>
                                <label className='emphasized-label'>
                                    {i18n.t('documentViews.form.label.description')}
                                </label>
                                <p>{this.state.documentData.description}</p>
                            </div>
                            <div className='col-md-10'>
                                <label className='emphasized-label'>
                                    {i18n.t('documentViews.form.label.fileName')}
                                </label>
                                <p>{this.state.documentData.fileName}</p>
                            </div>
                            <div className='col-md-10'>
                                <label className='emphasized-label'>
                                    {i18n.t('documentViews.form.label.fileSize')}
                                </label>
                                <p>
                                    {
                                        CommonUtilities.isEmpty(this.state.documentData.fileSize)
                                            ?
                                            '' : (this.state.documentData.fileSize + ' ' +
                                                i18n.t('documentViews.form.bytes'))
                                    }
                                </p>
                            </div>
                            <div className='col-md-10'>
                                <a className={'btn btn-primary' + (this.state.documentData.location ? '' : ' disabled')}
                                    href={this.state.documentData.location}>
                                    {i18n.t('documentViews.form.button.download')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            <button type='button' className='btn btn-secondary mr-2'
                                onClick={this.handleBack}>
                                {i18n.t('documentViews.form.button.back')}
                            </button>
                            <Link to={{
                                pathname: '/knowledge/edit-document/' + this.state.documentData.id
                            }} className='btn btn-primary'>
                                {i18n.t('documentViews.form.button.edit')}
                            </Link>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }

    /**
     * function to render errors
     */
    renderError() {
        return (
            <div className='col-md-12 text-right f-btn'>
                <button className='btn btn-secondary mr-2' onClick={this.handleBack}>
                    {i18n.t('documentViews.form.button.back')}
                </button>
            </div>
        );
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('documentViews.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'knowledge', displayName: i18n.t('documentViews.breadcrumb.knowledge'), className: '', link: false },
            { id: 'detail', displayName: i18n.t('documentViews.breadcrumb.detail'), className: '', link: false }
        ];
        return (
            <div className='container-fluid p-4'>
                <BreadcrumbCustom breadcrumb={breadcrumb} />
                <div className='clearfix'></div>
                {
                    this.state.alert.message &&
                    <AlertMessage message={this.state.alert.message}
                        type={this.state.alert.type}
                        isAlertOpen={!!(this.state.alert.message)}
                        handleDismiss={this.handleAlertDismiss}
                    />
                }
                {
                    this.state.isErrorOnLoad ? this.renderError() : this.renderViewDocument()
                }
            </div>
        );
    }
}

export default ViewDocument;
