import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number
};

const defaultProps = {
  initialPage: 1,
  pageSize: 10
};

const MAX_PAGE_LINKS_TO_DISPLAY = 5;

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if ((this.props.totalPageItems !== prevProps.totalPageItems) ||
      ((this.props.totalPageItems > 0) && (this.props.initialPage !== prevState.pager.currentPage)) ||
      (this.props.pageSize !== prevProps.pageSize)) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    var { totalPageItems, pageSize } = this.props;
    var pager = this.state.pager;

    if (page < 1 || (pager.totalPages > 0 && page > pager.totalPages)) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(totalPageItems, page, pageSize);

    // update state
    this.setState({ pager: pager });
  }

  onChangePage(pageNumber) {
    if (pageNumber !== this.state.pager.currentPage) {
      this.setPage(pageNumber);
      this.props.onChangePage(pageNumber);
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= MAX_PAGE_LINKS_TO_DISPLAY) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= (Math.ceil(MAX_PAGE_LINKS_TO_DISPLAY / 2))) {
        startPage = 1;
        endPage = MAX_PAGE_LINKS_TO_DISPLAY;
      } else if (currentPage + (Math.floor(MAX_PAGE_LINKS_TO_DISPLAY / 2) - 1) >= totalPages) {
        startPage = totalPages - (MAX_PAGE_LINKS_TO_DISPLAY - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - (Math.floor(MAX_PAGE_LINKS_TO_DISPLAY / 2));
        endPage = currentPage + (Math.floor(MAX_PAGE_LINKS_TO_DISPLAY / 2));
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <ul className="pagination pagination-custom">
        <li className={pager.currentPage === 1 ? 'custom-disabled' : ''}>
          {
            // eslint-disable-next-line
            <a onClick={() => this.onChangePage(1)}> <i className="fa fa-angle-double-left"></i></a>
          }
        </li>
        <li className={pager.currentPage === 1 ? 'custom-disabled' : ''}>
          {
            // eslint-disable-next-line
            <a onClick={() => this.onChangePage(pager.currentPage - 1)}><i className="fa fa fa-angle-left"></i></a>
          }
        </li>
        {pager.pages.map((page, index) =>
          <li key={index} className={pager.currentPage === page ? 'custom-active' : ''}>
            {
              // eslint-disable-next-line
              <a onClick={() => this.onChangePage(page)}>{page}</a>
            }
          </li>
        )}
        <li className={pager.currentPage === pager.totalPages ? 'custom-disabled' : ''}>
          {
            // eslint-disable-next-line
            <a onClick={() => this.onChangePage(pager.currentPage + 1)}> <i className="fa fa fa-angle-right"></i></a>
          }
        </li>
        <li className={pager.currentPage === pager.totalPages ? 'custom-disabled' : ''}>
          {
            // eslint-disable-next-line
            <a onClick={() => this.onChangePage(pager.totalPages)}><i className="fa fa-angle-double-right"></i></a>
          }
        </li>
      </ul>
    );
  }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
