import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListSupport from './ListSupport';
import ViewSupport from './ViewSupport';

/**
 * main component for support modules, contains routes for other sub components.
 */
class Support extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/support/list-logs'} component={ListSupport} />
            <Route exact path={'/support/view-log/:logId'} component={ViewSupport} />
            <Redirect from="/support" to="/support/list-logs" />
          </Switch>
        </div>
      </div>
    );
  }
}
export default Support;
