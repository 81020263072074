import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import _ from 'lodash';
import i18n from './shared/i18n';
import App from './App';
import './Containers/css/app/index.scss';
import elementClosest from './shared/closestpolyfill';
import { CommonUtilities } from './shared/utils/commonUtilities';
import { ColorUtilities } from './shared/utils/colorUtilities';
import { DEFAULT_LANG, DEFAULT_THEME } from "./constants/app.constants";
import { updateApiInterface } from './constants/uri.constant';
import { updateSupportedLanguages } from './constants/app.constants';
import { updateAppBuildVersion } from './constants/app.constants';
import { updateCustomerPortalUrl } from './constants/app.constants';

/**
 * applying dom closest polyfill for ie browser
 */
elementClosest(window);

// before rendering the application, make sure that style and language resources are loaded
let isStyleLoaded = false;
let isLanguageResourceLoaded = false;
let appConfig = {};

/**
 * Load application configurations for api endpoint, theme settings and supported languages  
 */
function loadAppConfig() {
    let timeStamp = Date.now();
    let apiConfigUrl = "./config/appConfig.json?preventCaching=" + timeStamp;
    
    axios.get(apiConfigUrl).then(response => {
        appConfig = response.data;
        
        if (!_.isObject(appConfig) || _.isEmpty(appConfig)) {
            console.log('appConfig is either empty or not a valid json.');
        }

        initializeAppResources();
    }, error => {
        console.log('Unable to load appConfig.json');
    });
}

/**
 * Initiate loading of application resources for styling and languages. Set 
 * page title and fav icon of the web application.
 */
function initializeAppResources() {
    // set page title
    if (_.has(appConfig.themeConfig, 'webPageTitle')) {
        document.title = appConfig.themeConfig.webPageTitle;
    }

    // set fav icon
    let favIcon = document.querySelector('link[rel="shortcut icon"]');
    if (favIcon) {
        favIcon.setAttribute('href', process.env.PUBLIC_URL + '/assets/images/favicon.ico?preventCaching=' + appConfig.buildVersion);
    }
    // parallel load of theme styles and language resources
    loadStyleResources();
    loadLanguageResources();
}

/**
 * Extract color, font, buildVersion from themeConfig. Manipulate colors for 
 * various states of ui elements (hover, active, box-shadow). Load css stylesheet, replace 
 * placeholders with correct values and apply to DOM.
 */
function loadStyleResources() {
    let themeConfig = appConfig.themeConfig;

    // for easy color manipulation, convert valid colorValues to hex representation
    let primaryColor = ColorUtilities.getHex(themeConfig.primaryColor, DEFAULT_THEME.PRIMARY_COLOR);
    let secondaryColor = ColorUtilities.getHex(themeConfig.secondaryColor, DEFAULT_THEME.SECONDARY_COLOR);
    let foregroundColor = ColorUtilities.getHex(themeConfig.foregroundColor, DEFAULT_THEME.FOREGROUND_COLOR);
    let genericColor = ColorUtilities.getHex(themeConfig.genericColor, DEFAULT_THEME.GENERIC_COLOR);

    /**
     * color manipulation for various states of ui elements (hover, active, box-shadow)
     */
    // primary hover state
    let primaryHoverColor = ColorUtilities.darken(primaryColor, 10);

    // primary active state
    let primaryActiveColor = ColorUtilities.darken(primaryColor, 15);

    // primary light box-shadow color
    let primaryRgbaColor = ColorUtilities.getRgba(primaryColor, 0.4);

    // secondary hover state
    let secondaryHoverColor = ColorUtilities.darken(secondaryColor, 10);

    // secondary active state
    let secondaryActiveColor = ColorUtilities.darken(secondaryColor, 15);

    // secondary light box-shadow color
    let secondaryRgbaColor = ColorUtilities.getRgba(secondaryColor, 0.4);

    /**
     * generic(common) color manipulation
     */
    let genericColorDark1 = genericColor;
    let genericColorDark2 = ColorUtilities.lighten(genericColor, 40);

    let buildVersion = appConfig.buildVersion;

    // font settings
    let fontFaceName = themeConfig.fontFaceName;
    let fontFileName = themeConfig.fontFileName;
    let fontFamilyWithFallback = themeConfig.fontFamilyWithFallback;

    /**
     * fetch text content of theme.override.css, and 
     * replace placeholders with actual values
     */
    let cssUrl = './assets/css/theme.override.css?buildVersion=' + appConfig.buildVersion;
    axios.get(cssUrl).then(response => {
        let cssStr = response.data;
        cssStr = cssStr.replace(/@primary-color@/gi, primaryColor)
            .replace(/@foreground-color@/gi, foregroundColor)
            .replace(/@primary-hover-color@/gi, primaryHoverColor)
            .replace(/@primary-active-color@/gi, primaryActiveColor)
            .replace(/@primary-shadow-rgba-color@/gi, primaryRgbaColor)
            // replace placeholders for secondary color
            .replace(/@secondary-color@/gi, secondaryColor)
            .replace(/@secondary-hover-color@/gi, secondaryHoverColor)
            .replace(/@secondary-active-color@/gi, secondaryActiveColor)
            .replace(/@secondary-shadow-rgba-color@/gi, secondaryRgbaColor)
            // replace placeholders for generic color
            .replace(/@generic-color-dark-1@/gi, genericColorDark1)
            .replace(/@generic-color-dark-2@/gi, genericColorDark2)
            // replace placeholders for build version (used to avoid caching)
            .replace(/@build-version@/gi, buildVersion)
            // replace placeholders for font setting
            .replace(/@font-face-name@/gi, fontFaceName)
            .replace(/@font-file-name@/gi, fontFileName)
            .replace(/@font-family-with-fallback@/gi, fontFamilyWithFallback);

        // create style dom element for the new css and append to head section
        let head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        style.appendChild(document.createTextNode(cssStr));

        // call render function for react app
        isStyleLoaded = true;
        renderReactApp();
    }, error => {
        console.log('Unable to load theme.override.css');
    });
}

/**
 * Fetch translation resource for the provided language.
 * @param {*} langCode 
 */
function getLanguageResource(langCode) {
    return axios.get('./assets/languages/translation_' + langCode + '.json?preventCaching=' + appConfig.buildVersion)
        .then((response) => {
            return response;
        }, function (error) {
            // in error case resolving with empty resource value
            console.log('Unable to load language resource for=' + langCode);
            return Promise.resolve({});
        });
}

/**
 * Load language resources. At minimum load translations for default 
 * language. If preferred language is different than the default 
 * language, then load translations for preferred language as well.
 */
function loadLanguageResources() {
    let defaultLang = DEFAULT_LANG;
    let prefLang = CommonUtilities.getPreferredLangCodeFromLocalStorage();

    let promises = [];
    promises.push(getLanguageResource(defaultLang));
    if ((defaultLang !== prefLang) && !_.isEmpty(prefLang)) {
        promises.push(getLanguageResource(prefLang));
    }

    Promise.all(promises).then(response => {
        // add resources to i18n (validate if the response is valid json object)
        if (_.isArray(response) && response.length > 0) {
            // add default language resource
            if (_.has(response[0], 'data') && _.isObject(response[0].data)) {
                i18n.addResourceBundle(defaultLang, 'translation', response[0].data, true, true);
            } else {
                console.log('Unable to load default language resource. This might be because the resource does not exist or it is not a valid json object.');
            }

            // add preferred language resource (if different than the default language)
            if (response.length > 1) {
                if (_.has(response[1], 'data') && _.isObject(response[1].data)) {
                    i18n.addResourceBundle(prefLang, 'translation', response[1].data, true, true);
                } else {
                    console.log('Unable to load preferred language resource for=' + prefLang);
                    console.log('This might be because the resource does not exist or it is not a valid json object.');
                }
            }
        }

        // call render function for react app
        isLanguageResourceLoaded = true;
        renderReactApp();
    }, error => {
        console.log('Unable to load language resources. This might be because the resource does not exist.');
        // render the React dom application (in case of error as well)
        isLanguageResourceLoaded = true;
        renderReactApp();
    });
}

/**
 * Render react application after style and language resources are loaded. Update 
 * application configurations with the settings provided in appConfig.json  
 */
function renderReactApp() {
    if (isStyleLoaded && isLanguageResourceLoaded) {
        // update app configurations which are used in react components
        updateApiInterface(appConfig.apiConfig);
        updateSupportedLanguages(appConfig.languages);
        updateAppBuildVersion(appConfig.buildVersion);
        updateCustomerPortalUrl(process.env.REACT_APP_CUSTOMER_PORTAL);
        ReactDOM.render(<App />, document.getElementById('root'));
    }
}

// start from loading the application config
loadAppConfig();
