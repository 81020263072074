import React from 'react';
import { SubmissionAlert, FormErrors } from '../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../Component/loader/Loader";
import CustomFileInput from '../../../Component/customFileInput/CustomFileInput';
import { FirmwareService } from '../../../services/service.firmwares';
import { StyledText } from "../../../Component/StyledText";
import i18n from 'i18next';
import { CommonConstants } from "../../../constants/app.constants";

/**
 * Edit firmware component. encapsulate all edit functionalities. 
 */
class EditFirmware extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formfields: {
                id: this.props.match.params.firmwareId,
                version: '',
                deviceType: '',
                description: '',
                fname: '',
                location: '',
            },
            prevVersion: '',
            prevDescription: '',
            isLoading: true,
            formErrors: { version: '', description: '' },
            isErrorOnLoad: false,
            versionIsValid: true,
            descriptionIsValid: true,
            apiResponseIsHandled: false,
            apiResponseIsSuccess: false,
            apiResponseMessage: '',
            showMessage: false
        };
    }

    componentDidMount() {
        let firmwareId = this.props.match.params.firmwareId;
        FirmwareService.fetchFirmwareByIdSubmission(firmwareId).then(
            (response) => {
                let firmware = response.data;
                this.setState({
                    isLoading: false,
                    showMessage: false,
                    formfields: {
                        id: firmware.id,
                        version: firmware.version,
                        description: firmware.description,
                        deviceType: firmware.deviceType,
                        fname: firmware.fileName,
                        location: firmware.location
                    },
                    prevVersion: firmware.version,
                    prevDescription: firmware.description,
                    prevDeviceType: firmware.deviceType
                });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    apiResponseIsSuccess: false,
                    apiResponseMessage: message,
                    showMessage: true,
                    isErrorOnLoad: true

                });
            }
        );
    }

    /**
     * handler for updating the firmware. communicates with backend and shows either success or error response.
     */
    updateFirmwareOnSubmit = (event) => {
        event.preventDefault();
        FirmwareService.updateFirmwareSubmission(this.state.formfields).then(
            (theResponse) => {
                let message = i18n.t('firmwareListCreateViewAndEdit.alert.firmwareEdit');
                this.setState({ showMessage: true, apiResponseMessage: message, apiResponseIsSuccess: true });
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({ showMessage: true, apiResponseMessage: message, apiResponseIsSuccess: false });
            }
        );
    }

    /**
     * handle user input for validation purpose.
     */
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let formfields = this.state.formfields;
        formfields[name] = value;
        this.setState({ formfields: formfields },
            () => { this.validateField(name, value) });
    }

    /**
     * helper function for version string validation.
     */
    validateProvidedVersion = value => {
        let isValidVerion = false;
        if (value.length >= 1) {
            isValidVerion = value.match(/^[0-9]+[\\.][0-9]+[\\.][0-9]+[\\.][0-9]+[\\.][0-9]+$/i);
        }
        return isValidVerion;
    }

    /**
     * firmware form field validation
     * @param {*} fieldName 
     * @param {*} value 
     */
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let versionIsValid = this.state.versionIsValid;
        let descriptionIsValid = this.state.descriptionIsValid;
        switch (fieldName) {
            case 'version':
                if (value.length === 0) {
                    versionIsValid = false;
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.version');
                } else if (value.length > 32) {
                    versionIsValid = false;
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.char32');
                } else {
                    versionIsValid = this.validateProvidedVersion(value);
                    fieldValidationErrors.version = versionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.versionFormat') + ': {major version}.{minor version}.{release candidate}.{configuration}.{revision}. ';
                }
                break;
            case 'description':
                if (value.length === 0) {
                    descriptionIsValid = false;
                    fieldValidationErrors.description = descriptionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.description');
                } else {
                    descriptionIsValid = (value.length >= 1 && value.length <= 256);
                    fieldValidationErrors.description = descriptionIsValid ? '' : i18n.t('firmwareListCreateViewAndEdit.alert.char256');
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            versionIsValid: versionIsValid,
            descriptionIsValid: descriptionIsValid
        }, this.validateForm);
    }

    /**
     * check if form is valid
     */
    validateForm() {
        this.setState({ formValid: this.state.versionIsValid && this.state.descriptionIsValid });
    }

    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    /**
     * reset function to reset the form to previous state.
     */
    onResetForm = () => {
        this.setState({
            formfields: {
                id: this.state.formfields.id,
                version: this.state.prevVersion,
                description: this.state.prevDescription,
                deviceType: this.state.prevDeviceType,
                location: this.state.formfields.location,
                fname: this.state.formfields.fname
            },
            formValid: false
        });
    }

    /**
     * handles alert dismiss
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }

    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}> {i18n.t('firmwareListCreateViewAndEdit.form.button.back')} </button>
            </div>
        );
    }

    /**
     * edit firmware form redering
     */
    renderEditFirmwareForm() {
        const { history } = this.props;
        return (
            <form className="editFirmwareForm" id="editFirmwareForm">
                <h2> {i18n.t('firmwareListCreateViewAndEdit.form.editFirmware')} </h2>
                <div className="view-profile-details">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deviceType">{i18n.t('firmwareListCreateViewAndEdit.form.label.deviceType')} <StyledText uiText="*" /></label>
                            <input type="text" className="form-control" name="deviceType" disabled
                                value={this.state.formfields.deviceType}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 ">
                        <div className={`form-group ${this.errorClass(this.state.formErrors.version)}`}>
                            <label htmlFor="version"> {i18n.t('firmwareListCreateViewAndEdit.form.label.version')} <StyledText uiText="*"/> </label>
                            <input type="text" className="form-control" name="version" disabled
                                value={this.state.formfields.version}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.version} />
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-6 ">
                        <div className={`form-group ${this.errorClass(this.state.formErrors.description)}`}>
                            <label htmlFor="description"> {i18n.t('firmwareListCreateViewAndEdit.form.label.description')} <StyledText uiText="*"/> </label>
                            <textarea className="form-control" name="description" cols={50} rows={6}
                                value={this.state.formfields.description}
                                onChange={this.handleUserInput} />
                            <FormErrors formErrors={this.state.formErrors.description} />
                        </div>
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                    <div className="col-md-12 ">
                        <CustomFileInput id="firmware" value={i18n.t('firmwareListCreateViewAndEdit.form.placeholder.download')} action="download" predefinedName={this.state.formfields.fname} predefinedValue={this.state.formfields.location} />
                    </div>
                </div>
                <div className="hr-line-dashed"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <div>
                            <button type="button" className="btn btn-secondary mr-2" onClick={history.goBack} > {i18n.t('firmwareListCreateViewAndEdit.form.button.back')} </button>
                            <button type="button" className="btn btn-secondary mr-2" onClick={this.onResetForm} > {i18n.t('firmwareListCreateViewAndEdit.form.button.reset')} </button>
                            <button type="submit" className="btn btn-primary" disabled={!this.state.formValid} onClick={this.updateFirmwareOnSubmit} > {i18n.t('firmwareListCreateViewAndEdit.form.button.save')} </button>
                        </div>
                    </div>
                </div>

            </form>
        )
    }

    /**
     * react render function
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'firmware', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.firmware'), className: '', link: false },
            { id: 'edit', displayName: i18n.t('firmwareListCreateViewAndEdit.breadcrumb.edit'), className: '', link: false }
        ];
        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return (
                <div>
                    <div className="container-fluid p-4">
                        <div className="float-left">
                            <BreadcrumbCustom breadcrumb={breadcrumb} />
                        </div>
                        <div className="clearfix"></div>
                        {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                        {this.state.isErrorOnLoad ? this.renderError() : this.renderEditFirmwareForm()}
                    </div>
                </div>
            )
        }
    }
}
export default EditFirmware;