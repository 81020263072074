import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import ListDevices from './ListDevices';
import CreateDevice from './CreateDevice';
import ViewDevice from './ViewDevice';
import EditDevice from './EditDevice';
import UploadDevice from './UploadDevice';
import ListSubscrptions from './subscriptions/ListSubscrptions';
import CreateSubscrption from './subscriptions/CreateSubscrption';
import ViewSubscrption from './subscriptions/ViewSubscrption';
import EditSubscrption from './subscriptions/EditSubscrption';

/**
 * Main device component. holds all routes for routing to other device sub components.
 */
class Device extends BaseScreen {
  constructor (props) {
    super(props);
    if (!this.isAuth()) {
      this.goToScreen(SCREENS.login);
    }
    this.state = {
      items: []
    };
  }

  render () {
    return (
      <div>
        <div className="card my-4-custom">
          <Switch>
            <Route exact path={'/device/list-devices'} component={ListDevices} />
            <Route exact path={'/device/create-device'} component={CreateDevice} />
            <Route exact path={'/device/view-device/:deviceId'} component={ViewDevice} />
            <Route exact path={'/device/edit-device/:deviceId'} component={EditDevice}/>
            <Route exact path={'/device/upload-device'} component={UploadDevice}/>
            <Route exact path={'/device/subscriptions/list-subscriptions'} component={ListSubscrptions}/>
            <Route exact path={'/device/subscriptions/create-subscription'} component={CreateSubscrption}/>
            <Route exact path={'/device/subscriptions/edit-subscription/:code/:partId/:serialId'} component={EditSubscrption}/>
            <Route exact path={'/device/subscriptions/view-subscription/:code/:partId/:serialId'} component={ViewSubscrption}/>
            <Redirect from="/device" to="/device/list-devices" />
          </Switch>
        </div>
      </div>
    );
  }
}
export default Device;
