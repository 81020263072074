import { API_INTERFACE } from '../constants/uri.constant';
import Method from './services';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

const SEPARATOR = '/';

/**
 * service to fetch all support logs created by field users.
 * @param {*} values
 */
function fetchSupports (values) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.LIST_SUPPORT + values,
    header
  );
}

/**
 * service to fetch details of specific support log created by field users.
 * @param {*} id
 */
function fetchSupportById (id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.GET_SUPPORT_V2 + SEPARATOR + id,
    headers
  );
}

/**
 * service to decrypt the encrypted support log file uploaded by field user using mobile device to backend.
 * @param {*} id
 */
function decryptSupportFile (id) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  const url = API_INTERFACE.DECRYPT_LOG + id;
  return Method.get(url, header);
}

/**
 * service to download the specific decrypted file processed through backend mechanism.
 * @param {*} id
 */
function downloadDecryptedSupportFile (id) {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  const url = API_INTERFACE.DOWNLOAD_LOG + id;
  return Method.get(url, headers);
}

/**
 * service call for fetching logs based on subs
 * @param {*} values 
 * @param {*} body 
 * @returns 
 */
function fetchSupportsBySubs (values, body) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  const subs = {
    'subs': body.subs
  }
  return baseService.post(
    API_INTERFACE.LIST_SUPPORT_BY_SUBS + values,
    header,
    subs
  );
}

/**
 * service call for fetching raw logs based on subs
 * @param {*} id 
 * @returns 
 */
function downloadRawSupportFile (id) {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  const url = API_INTERFACE.DOWNLOAD_LOG + id;
  return Method.get(url, header);
}


export const SupportService = {
  fetchSupports,
  fetchSupportById,
  decryptSupportFile,
  downloadDecryptedSupportFile,
  fetchSupportsBySubs,
  downloadRawSupportFile
};
