import React from 'react';
import Grid from '../../../Component/grid/Grid';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { VehiclesService } from '../../../services/service.vehicles';
import i18n from 'i18next';

/**
 * Informations to be shown in associated vehicle listing grid, along with options per rows, in User details page.
 */
let vehicleListConfig = {};

/**
 * component to display user's associated vehicles.
 */
class ListUserVehicles extends BaseScreen {
  constructor (props) {
    super(props);

    let isAuthenticated = true;

    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize vehicleListConfig with updated i18n resources
    vehicleListConfig = {
      make: {
        label: i18n.t('user.listUserVehicles.list.make'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      model: {
        label: i18n.t('user.listUserVehicles.list.model'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      year: {
        label: i18n.t('user.listUserVehicles.list.year'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      options: {
        label: i18n.t('user.listUserVehicles.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('user.listUserVehicles.list.options.view')
          }, {
            actionType: 'link',
            action: 'edit',
            label: i18n.t('user.listUserVehicles.list.options.edit')
          }, {
            actionType: '',
            action: 'delete',
            label: i18n.t('user.listUserVehicles.list.options.delete')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      userId: this.props.userId,
      userVehiclesData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 10, // default page size
        totalCount: 0,
        pageList: [10]
      },

      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      const arg = {
        currentPage: 0,
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getVehiclesByUserId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(this.state.pgnConfig.currentPage, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          userVehiclesData: response.data.items,
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userVehiclesData: [],
            loader: false,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * function to communicate backend to fetch al associated vehicles by a user Id.
   * @param {*} requiredParams 
   */
  getVehiclesByUserId (requiredParams) {
    let reqQueryParams = '';

    // build required params
    let params;
    if (requiredParams) {
      params = {
        currentPage: requiredParams.currentPage,
        pageSize: requiredParams.pageSize
      };
    } else {
      params = {
        currentPage: this.state.pgnConfig.currentPage - 1,
        pageSize: this.state.pgnConfig.countPerPage
      };
    }

    reqQueryParams = 'currentPage=' + params.currentPage + '&pageSize=' + params.pageSize + '&accountId=' + this.state.userId;

    return VehiclesService.getVehicles(reqQueryParams)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * helper function for pagination configuration
   * @param {*} currentPage 
   * @param {*} countPerPage 
   * @param {*} totalCount 
   */
  setPaginationConfig (currentPage, countPerPage, totalCount) {
    return {
      currentPage: currentPage,
      numberOfPageLinks: this.state.pgnConfig.numberOfPageLinks,
      countPerPage: countPerPage,
      totalCount: totalCount,
      pageList: [...this.state.pgnConfig.pageList]
    };
  }

  /**
   * handler when page number change, like user move to next page or previous page from current page he is viewing
   * @param {*} pageNumber 
   */
  onPgnChange (pageNumber) {
    if (pageNumber !== this.state.pgnConfig.currentPage) {
      this.setState({ loader: true, userVehiclesData: [] });

      const arg = {
        currentPage: (pageNumber - 1),
        pageSize: this.state.pgnConfig.countPerPage
      };

      this.getVehiclesByUserId(arg).then(response => {
        const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
        this.setState({
          userVehiclesData: response.data.items,
          pgnConfig: pgnConfig,
          loader: false,
          modalShow: false,
          alert: {
            type: null,
            message: null
          }
        });
      }, error => {
        let errorMsg = i18n.t('common.genericApiError');
        if (error && error.data && error.data.message) {
          errorMsg = error.data.message;
        }
        this.setState(
          {
            userVehiclesData: [],
            loader: false,
            modalShow: false,
            reinitialize: false,
            alert: {
              type: 'danger',
              message: errorMsg
            }
          });
      });
    }
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      // link type action
      return ({
        pathname: '/vehicle/view-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    } else if (actionType.action === 'edit') {
      // link type action
      return ({
        pathname: '/vehicle/edit-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    }
  }

  /**
   * per row option handler. for handling options having confirmation modals
   * @param {*} actionObject 
   * @param {*} row 
   */
  optionHandler (actionObject, row) {
    let modalInfo;
    if (actionObject.action === 'delete') {
      modalInfo = {
        modalMsg: i18n.t('user.listUserVehicles.form.modal.deleteMsg') + row.make + '?',
        modalTitle: i18n.t('user.listUserVehicles.form.modal.deleteTitle'),
        modalAction: 'delete_vehicle'
      };
    }

    this.modalPopup(true, row, modalInfo);
  }

  /**
   * Modal window to show each row options.
   * @param {*} state 
   * @param {*} rowData 
   * @param {*} modalInfo 
   */
  modalPopup (state, rowData, modalInfo) {
    if (state) {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalMsg: modalInfo.modalMsg,
          modalTitle: modalInfo.modalTitle,
          modalData: rowData || '',
          modalAction: modalInfo.modalAction
        }
      });
    } else {
      this.setState({
        modal: {
          modalShow: state,
          shouldRenderModal: true,
          modalData: rowData || '',
          modalAction: this.state.modal.modalAction
        }
      });
    }
  }

  /**
   * modal dismissal action
   */
  handleModalDismiss () {
    this.modalPopup(false);
  }

  /**
   * handle modal delete action.
   * @param {*} data 
   */
  onModalAction (data) {
    const modalAction = this.state.modal.modalAction;
    if (modalAction === 'delete_vehicle') {
      this.modalPopup(false, data);
    }
  }

  /**
   * function after modal is closed, re-render listing page after delete operation.
   */
  onModalClosed () {
    if (this.state.modal.modalAction === 'delete_vehicle') {
      this.deleteVehicle();
    }
  }

  /**
   * delete a user's vehicle based on passed id.
   */
  deleteVehicle () {
    if (this.state.modal.modalData) {
      const modalData = this.state.modal.modalData;
      this.setState({
        loader: true,
        modal: {
          modalShow: false,
          shouldRenderModal: false
        }
      });

      VehiclesService.deleteVehicle(modalData.id)
        .then((successResponse) => {
          let pageNumber = this.state.pgnConfig.currentPage;
          if ((this.state.userVehiclesData.length <= 1) && (this.state.pgnConfig.currentPage > 1)) {
            pageNumber = this.state.pgnConfig.currentPage - 1;
          }

          this.setState({
            userVehiclesData: [],
            alert: {
              type: 'success',
              message: i18n.t('user.listUserVehicles.alert.deleteVehicleMsg')
            }
          });

          const arg = {
            currentPage: (pageNumber - 1),
            pageSize: this.state.pgnConfig.countPerPage
          };

          this.getVehiclesByUserId(arg).then((response) => {
            const pgnConfig = this.setPaginationConfig(pageNumber, this.state.pgnConfig.countPerPage, response.data.total);
            this.setState({
              userVehiclesData: response.data.items,
              pgnConfig: pgnConfig,
              loader: false,
              modal: {
                modalShow: false,
                shouldRenderModal: false
              }
            });
          }, (error) => {
            let errorMsg = i18n.t('common.genericApiError');
            if (error && error.data && error.data.message) {
              errorMsg = error.data.message;
            }
            this.setState(
              {
                userVehiclesData: [],
                loader: false,
                modalShow: false,
                reinitialize: false,
                alert: {
                  type: 'danger',
                  message: errorMsg
                }
              });
          });
        }, (error) => {
          let errorMsg = i18n.t('common.genericApiError');
          if (error && error.data && error.data.message) {
            errorMsg = error.data.message;
          }
          this.setState(
            {
              userVehiclesData: [],
              loader: false,
              modalShow: false,
              reinitialize: false,
              alert: {
                type: 'danger',
                message: errorMsg
              }
            });
        });
    }
  }

  /*
   alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * react render function to display associated vehicles with selected user.
   */
  render () {
    return (
      <div>
        <div>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          <div>
            <Grid id="userVehiclesListGrid"
              optionHandler={this.optionHandler.bind(this)}
              keyColumn="id"
              rowData={this.state.userVehiclesData}
              columnDefs={vehicleListConfig}
              loading={this.state.loader}
              pagination={true}
              pgnConfig={this.state.pgnConfig}
              onPgnChange={this.onPgnChange.bind(this)}
              actionPaths={this.actionPaths.bind(this)} />
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListUserVehicles;
