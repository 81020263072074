import { API_INTERFACE } from '../constants/uri.constant';
import baseService from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * fetch operation from backend
 * @param {*} operationId
 */
function getOperation (operationId) {
  const url = API_INTERFACE.GET_OPERATION + '/' + operationId;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.get(url, header);
}

/**
 * get all Operations
 * @param {*} queryString 
 */
function getOperations (queryString) {
  const url = API_INTERFACE.GET_OPERATIONS + '?' + queryString;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.get(url, header);
}

/**
 * update an Operation
 * @param {*} body 
 */
function updateOperation (body) {
  const url = API_INTERFACE.GET_OPERATION;

  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.put(url, header, body);
}

/**
 * delete an Operation
 * @param {*} operationId 
 */
function deleteOperation (operationId) {
  const url = API_INTERFACE.GET_OPERATION + '/' + operationId;
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return baseService.delete(url, header);
}

/**
 * create an Operation
 * @param {*} body 
 * @param {*} id 
 */
function createOperation (body, id) {
  const url = API_INTERFACE.GET_OPERATION;
  body.accountId = id;
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };

  return baseService.post(url, header, body);
}

export const OperationsService = {
  getOperation,
  createOperation,
  updateOperation,
  deleteOperation,
  getOperations
};
