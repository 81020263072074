import { API_INTERFACE } from '../constants/uri.constant';

import Method from './services';
import { AuthUtils } from './service.auth.utils';

/**
 * service to fetch all OEMs registered in backend.
 */
function fetchOEMS () {
  const header = {
    'Content-Type': 'application/json',
    'x-api-key': API_INTERFACE.X_API_KEY,
    Authorization: AuthUtils.getAccessToken()
  };
  return Method.get(
    API_INTERFACE.LIST_OEMS,
    header
  );
}

export const OemService = {
  fetchOEMS
};
