
import i18n from 'i18next';
import _ from 'lodash';

/**
 * pre defined role ids
 */
export const userRoleIds = {
  adminRoleId: '1',
  accountHolderRoleId: '2',
  operatorRoleId: '3'
};

/**
 * assignment types, in backend values are stored as 0 or 1
 */
const assignmentType = {
  ASSIGNED: 1,
  UNASSIGNED: 0
};

/**
 * user listing headers to be displayed.
 * @param {*} roleId 
 */
export function userListOptions (roleId) {
  const userListConfig = {
    firstName: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.firstName'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    lastName: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.lastName'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    email: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.email'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    phone: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.phone'),
      sort: false,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    status: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.status'),
      sort: false,
      filter: false,
      display: true,
      filterMaxLength: 50
    },
    createdAt: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.createdAt'),
      sort: true,
      filter: false,
      display: true,
      isDate: true,
      filterMaxLength: 50
    },
    modifiedAt: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.modifiedAt'),
      sort: true,
      filter: false,
      display: true,
      isDate: true,
      filterMaxLength: 50
    },
    options: {
      label: i18n.t('user.usersListCreateViewAndEdit.list.options.options'),
      sort: false,
      filter: false,
      display: true
    }
  };

  return userListConfig;
}

/**
 * user listing headers to be displayed in case of modal window which display user listing.
 * for example in case of account holders, listing of available operator in the oem is to be displayed as modal from which
 * assignment can be requested by admin on behalf of account holder.
 */
export function userAssignmentListOptions () {
  const userAssignmentList = {
    firstName: {
      label: i18n.t('user.listUserForAssignment.list.firstName'),
      sort: false,
      filter: false,
      display: true,
      filterMaxLength: 50
    },
    lastName: {
      label: i18n.t('user.listUserForAssignment.list.lastName'),
      sort: false,
      filter: false,
      display: true,
      filterMaxLength: 50
    },
    email: {
      label: i18n.t('user.listUserForAssignment.list.email'),
      sort: true,
      filter: true,
      display: true,
      filterMaxLength: 50
    },
    options: {
      sort: false,
      filter: false,
      display: true,
      list: [
        {
          actionType: 'button',
          action: 'assign',
          label: i18n.t('user.listUserForAssignment.form.button.assign')
        }
      ]
    }
  };
  return userAssignmentList;
}

/**
 * resolve type of user to be displayed in modal. in case of account holders,
 * only operator types should be visible in modal window for assignment request.
 * @param {*} providedRoleId 
 */
export function requiredRoleIdForAssigneeListing (providedRoleId) {
  let requiredRoleId;
  if (providedRoleId === _.parseInt(userRoleIds.accountHolderRoleId)) {
    requiredRoleId = userRoleIds.operatorRoleId;
  } else if (providedRoleId === _.parseInt(userRoleIds.operatorRoleId)) {
    requiredRoleId = userRoleIds.accountHolderRoleId;
  }
  return requiredRoleId;
}

/**
 * function to resolve who is the assigner (either account holder or operator)
 * @param {*} assignerId 
 * @param {*} assignerRoleId 
 * @param {*} assignerEmail 
 */
export function resolveAssigner (assignerId, assignerRoleId, assignerEmail) {
  const assigner = {
    operatorId: '',
    accountId: '',
    assignerEmail: '',
    assign: assignmentType.UNASSIGNED
  };
  assigner.assignerEmail = assignerEmail;
  if (assignerRoleId === _.parseInt(userRoleIds.accountHolderRoleId)) {
    assigner.accountId = assignerId;
  } else if (assignerRoleId === _.parseInt(userRoleIds.operatorRoleId)) {
    assigner.operatorId = assignerId;
  }
  return assigner;
}
