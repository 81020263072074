import React from 'react';
import { SubmissionAlert } from '../../../../Component/SubmissionStatus';
import BreadcrumbCustom from "../../../../Component/breadcrumb/BreadcrumbCustom";
import Loader from "../../../../Component/loader/Loader";
import { DeviceService } from "../../../../services/service.devices";
import { UserService } from "../../../../services/service.users";
import { Link } from 'react-router-dom';
import i18n from 'i18next';

/**
 * to show activation status of device.
 */
const deviceActivationStatus = {
    "Inactive": 0,
    "Active": 1
}

/**
 * device detail component. renders view component.
 */
class ViewSubsCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            code: '',
            partId: '',
            deviceSerialId: '',
            expiration: '',
            accountemail: '',
            createdat: '',
            modifiedat: "",
            firstname: "",
            lastname: "",
            isErrorOnLoad: false,
            showMessage: false,
            apiResponseIsSuccess: false,
            apiResponseMessage: ''
        }
    }

     componentDidMount() {
        let str = this.props.match.params;
        let params =  "?deviceSerialId="+str.serialId+"&partId="+str.partId+"&currentPage=0&pageSize=10";
        DeviceService.getDeviceSubscriptionsList(params).then(
            async (response) => {
                let details = response.data.items.filter(r=>r.code=str.code);
                details = details.length ? details[0] : null;

               
                if(details){
                    let devices = await this.formateData(details);
                    this.setState({
                        isLoading: false,
                        code: devices.code,
                        deviceSerialId: devices.deviceSerialId,
                        partId: devices.partId,                        
                        accountemail: devices.accountemail,
                        createdat: devices.createdat,
                        modifiedat: devices.modifiedat,
                        expiration: devices.expiration,
                        firstname: devices.firstname,
                        lastname: devices.lastname,
                        apiResponseIsSuccess: true,
                        showMessage: false
                    });
             }
            },
            (error) => {
                let message = i18n.t('common.genericApiError');
                if (error.data && error.data.message) {
                    message = error.data.message;
                }
                this.setState({
                    isLoading: false,
                    isErrorOnLoad: true,
                    apiResponseMessage: message,
                    showMessage: true,
                    apiResponseIsSuccess: false
                });
            }
        );
    }


    getUserData =  (id)=>{
           let user =  UserService.getUser(id);
           return user ? user.email : '';
    }
   
    /**
     * handles formate of data like date
     */

    formateData = async (row)=>{
        row.createdat = new Date(row.createdAt).toDateString();
        row.expiration = new Date(row.expiration).toDateString() + ", 23:59";
        row.modifiedat = new Date(row.modifiedAt).toDateString();
        row.accountemail = row.accountEmailId;        
      return row;

    }

  

    /**
     * handles dismissal of alert text component
     */
    onDismiss = () => {
        this.setState({ showMessage: false });
    }
    
    /**
     * handles cancel event
     */
    handleCancel() {
        this.props.history.goBack();
    }

    /**
     * render form fields error in ui.
     */
    renderError() {
        return (
            <div className="col-md-12 text-right f-btn">
                <button type="submit" className="btn btn-secondary mr-2" onClick={this.handleCancel.bind(this)}> {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')} </button>
            </div>
        );
    }

    /**
     * toggle activation field from normal text to encrypted and vice-versa.
     * @param {*} fieldName 
     * @param {*} event 
     */
    toggleFieldType(fieldName, event) {
        event.preventDefault();
        if (fieldName === "activationCode") {
            this.setState({ activationCodeType: this.state.activationCodeType === "password" ? "text" : "password" });
        }
    }

     /**
     * encapsulate react render function
     */
    renderViewDeviceForm() {
        const { history } = this.props;
        console.log(this.state)
        return (
            <div>
                <h2>{i18n.t('deviceListCreateViewEditAndUpload.form.viewSubscription')} </h2>
                <div className="view-profile-details">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.code')} </label>
                            <p>{this.state.code}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.accoundholderemail')} </label>
                            <p>{this.state.accountemail}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.partid')} </label>
                            <p>{this.state.partId}</p>
                        </div>                        
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.firstname')} </label>
                            <p>{this.state.firstname}</p>
                        </div>                         
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.serialNumber')} </label>
                            <p>{this.state.deviceSerialId}</p>
                        </div>
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.lastname')} </label>
                            <p>{this.state.lastname}</p>
                        </div>               
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.expiration')} </label>
                            <p>{(this.state.expiration)}</p>
                        </div>
                        <div className="col-md-6">
                           <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.createdat')} </label>
                           <p>{this.state.createdat}</p>
                       </div>
                    </div>
                    <div className="row">                  
                       <div className="col-md-6">
                           <label className="emphasized-label"> {i18n.t('deviceListCreateViewEditAndUpload.subscriptionlist.modifiedat')} </label>
                           <p>{(this.state.modifiedat)}</p>
                       </div>
                   </div>
                </div>
                <div className="col-md-12 text-right f-btn">
                    <div>
                        <button type="button" className="btn btn-secondary mr-2" onClick={history.goBack} > {i18n.t('deviceListCreateViewEditAndUpload.form.button.back')} </button>
                        <Link to={{ pathname: '/device/subscriptions/edit-subscription/' + this.props.match.params.code+"/"+this.props.match.params.partId+"/"+this.props.match.params.serialId }}
                            className="btn btn-primary mr-2">{i18n.t('deviceListCreateViewEditAndUpload.form.button.edit')}</Link>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * react render func
     */
    render() {
        let breadcrumb = [
            { id: 'home', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.home'), href: '#/home', className: '', link: true },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.device'), className: '', link: false },
            { id: 'device', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.subscriptions'), className: '', link: false },
            { id: 'detail', displayName: i18n.t('deviceListCreateViewEditAndUpload.breadcrumb.detail'), className: '', link: false }
        ];
        if (this.state.isLoading) {
            return (
                <Loader />
            );
        } else {
            return (
                <div>
                    <div className="container-fluid p-4">
                        <div className="float-left">
                            <BreadcrumbCustom breadcrumb={breadcrumb} />
                        </div>
                        <div className="clearfix"></div>
                        {/* {this.state.apiResponseIsHandled && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.isApiResponseSuccess} onDismiss={this.onDismiss} />} */}
                        {this.state.showMessage && <SubmissionAlert alertMessage={this.state.apiResponseMessage} isSuccessResponse={this.state.apiResponseIsSuccess} onDismiss={this.onDismiss} />}
                        {this.state.isErrorOnLoad ? this.renderError() : this.renderViewDeviceForm()}
                    </div>
                </div>
            );
        }
    }
}

export default ViewSubsCode;