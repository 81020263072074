import React from 'react';
import Grid from '../../../Component/grid/Grid';
import ModalDialog from '../../../Component/modaldialog/ModalDialog';
import AlertMessage from '../../../Component/alert/AlertMessage';
import BaseScreen from '../BaseScreen';
import { SCREENS } from '../../../constants/screens.constant';
import { VehiclesService } from '../../../services/service.vehicles';
import i18n from 'i18next';

/**
 * Informations to be shown in vehicle listing grid, along with options per rows in support details page.
 */
let vehicleListConfig = {};

/**
 * List associated vehicles with the support
 */
class ListConcernedVehicle extends BaseScreen {
  constructor (props) {
    super(props);
    let isAuthenticated = true;
    if (!this.isAuth()) {
      isAuthenticated = false;
      this.goToScreen(SCREENS.login);
    }

    // initialize vehicleListConfig with updated i18n resources
    vehicleListConfig = {
      make: {
        label: i18n.t('supportListViewAndConcerns.list.make'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      model: {
        label: i18n.t('supportListViewAndConcerns.list.model'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      year: {
        label: i18n.t('supportListViewAndConcerns.list.year'),
        sort: false,
        filter: false,
        display: true,
        filterMaxLength: 0
      },
      options: {
        label: i18n.t('supportListViewAndConcerns.list.options.options'),
        sort: false,
        filter: false,
        display: true,
        list: [
          {
            actionType: 'link',
            action: 'view',
            label: i18n.t('supportListViewAndConcerns.list.options.view')
          }
        ]
      }
    };

    this.state = {
      isAuthenticated: isAuthenticated,
      vehicleId: this.props.vehicleId,
      vehiclesData: [],
      loader: true,
      pgnConfig: {
        currentPage: 1,
        numberOfPageLinks: 0,
        countPerPage: 1,
        totalCount: 0,
        pageList: [10]
      },
      modal: {
        modalShow: false,
        shouldRenderModal: false,
        modalTitle: '',
        modalMsg: '',
        modalData: '',
        modalAction: ''
      },
      alert: {
        type: null,
        message: null
      }
    };
  }

  componentDidMount () {
    if (this.state.isAuthenticated) {
      if (this.props.vehicleId) {
        this.getVehiclesById(this.props.vehicleId).then(response => {
          const vehicle = [];
          vehicle.push(response.data);
          this.setState({
            vehiclesData: vehicle,
            loader: false,
            modalShow: false
          });
        }, error => {
          this.setState(
            {
              vehiclesData: [],
              loader: false,
              modalShow: false,
              reinitialize: false
            });
        });
      } else {
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            reinitialize: false
          });
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.vehicleId !== this.props.vehicleId && this.props.vehicleId) {
      this.getVehiclesById(this.props.vehicleId).then(response => {
        const vehicle = [];
        vehicle.push(response.data);
        this.setState({
          vehiclesData: vehicle,
          loader: false,
          modalShow: false
        });
      }, error => {
        this.setState(
          {
            vehiclesData: [],
            loader: false,
            modalShow: false,
            reinitialize: false
          });
      });
    }
  }

  /**
   * function to fetch vehicle details by id
   * @param {*} id 
   */
  getVehiclesById (id) {
    return VehiclesService.getVehicle(id)
      .then(response => {
        return response;
      }, errResponse => {
        return Promise.reject(errResponse);
      });
  }

  /**
   * routing to relevant page based on row option selection
   * @param {*} actionType 
   * @param {*} row 
   */
  actionPaths (actionType, row) {
    if (actionType.action === 'view') {
      return ({
        pathname: '/vehicle/view-vehicle/' + row.id,
        state: { vehicleData: row }
      });
    }
  }

  /**
   * alert message handling
   */
  handleAlertDismiss () {
    this.setState({
      alert: {
        type: null,
        message: null
      }
    });
  }

  /**
   * render listing of associated vehicle with the support log.
   */
  render () {
    const tableScrollStyle = {
      maxHeight: '530px',
      overflow: 'hidden',
      overflowY: 'auto'
    };
    return (
      <div>
        <div style={tableScrollStyle}>
          {
            this.state.alert.message &&
            <AlertMessage message={this.state.alert.message}
              type={this.state.alert.type}
              isAlertOpen={!!(this.state.alert.message)}
              handleDismiss={this.handleAlertDismiss.bind(this)}
            />
          }

          <div>
            <Grid id="userVehiclesListGrid"
              keyColumn="id"
              rowData={this.state.vehiclesData}
              columnDefs={vehicleListConfig}
              loading={this.state.loader}
              pagination={false}
              actionPaths={this.actionPaths.bind(this)} />
          </div>
        </div>

        {
          this.state.modal.shouldRenderModal &&
          <ModalDialog modalTitle={this.state.modal.modalTitle}
            modalMessage={this.state.modal.modalMsg}
            modalData={this.state.modal.modalData}
            isModalOpen={this.state.modal.modalShow}
            modalAction={this.onModalAction.bind(this)}
            handleModalDismiss={this.handleModalDismiss.bind(this)}
            onModalClosed={this.onModalClosed.bind(this)}
          />
        }
      </div>);
  }
}

export default ListConcernedVehicle;
